import { observer } from 'mobx-react';
import { TreeItem } from '@mui/x-tree-view';

import NodeList from './NodeList';
import { SelfTreeStore, SelfTreeNode } from './SelfTreeStore';

interface NodeLineProps {
    store: SelfTreeStore;
    node: SelfTreeNode;
}

const NodeLine = observer(({ node, store }: NodeLineProps) => (
    <TreeItem key={node.id} nodeId={(node.id || 0).toString()} label={node.caption}>
        {node.children?.length ? <NodeList store={store} list={node.children} /> : null}
    </TreeItem>
));

export default NodeLine;
