import { useEffect, useState } from 'react';

import { ComponentType } from 'forms/interfaces';

import CustomDataset from 'dataObj/customDataset';
import PropContainer from 'dataObj/PropContainer';

import { getLocalizedString } from 'utils/index';

type ObjectType = { [key: string]: string | number };

const makeLocalized = (key: string) => (item: ObjectType) => {
    item[key] = getLocalizedString(item[key]);

    return item;
};

export default function useLookupData(
    descr: any,
    dataset?: CustomDataset,
    propContainer?: PropContainer
) {
    const [lookupData, setLookupData] = useState<ObjectType[]>([]);

    const nameField = descr.nameField || 'val';

    useEffect(() => {
        if (descr.lookupDataset && propContainer) {
            const list = propContainer.dataStock.getDataset(descr.lookupDataset) as ObjectType[];

            setLookupData(list.map(makeLocalized(nameField)));
        } else {
            const datafieldDescr = (dataset?.descr?.fields || []).find(
                (fld: ComponentType) => fld.name === descr.fieldName
            );
            let list: ObjectType[] = [];
            try {
                if (datafieldDescr) {
                    list = (
                        typeof datafieldDescr.lookupData === 'string'
                            ? JSON.parse(datafieldDescr.lookupData)
                            : datafieldDescr.lookupData
                    ) as ObjectType[];
                }
            } catch (err) {
                console.log(`Can\`t parse json: ${datafieldDescr?.lookupData ?? 'undefined'}`);
            }

            setLookupData(list.map(makeLocalized(nameField)));
        }
    }, [descr, dataset]);

    return lookupData;
}
