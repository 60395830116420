import { useState, FunctionComponent, useEffect } from 'react';

import { ControlPropsType, DatasetType, ResourceInfo } from 'forms/interfaces';
import CustomForm from 'forms/CustomForm';
import { generateGrid } from 'forms/form-utils';

import useGenerateForm from 'hooks/generateForm';
import PropContainer from 'dataObj/PropContainer';

interface LookupFormProps extends ControlPropsType {
    dataset: DatasetType;
    setGeneratedFrmGuid: any;
    parentPropContainer: PropContainer;
    propContainerGuid?: string;
}

const LookupGeneratedForm: FunctionComponent<LookupFormProps> = ({
    dataset,
    propContainer,
    setGeneratedFrmGuid,
    parentPropContainer,
    propContainerGuid
}) => {
    const [formDescr, setFormDescr] = useState<ResourceInfo | null>(null);

    const generatedDescr = useGenerateForm(dataset, propContainer, generateGrid, 'lookupForm');

    useEffect(() => {
        if (generatedDescr && !formDescr) {
            setFormDescr(generatedDescr);
            setGeneratedFrmGuid(generatedDescr.guid);
        }
    }, [generatedDescr]);

    return formDescr ? (
        <CustomForm
            key={formDescr.guid}
            formConfig={formDescr}
            parentPropContainer={parentPropContainer}
            propContainerGuid={propContainerGuid}
        />
    ) : null;
};

export default LookupGeneratedForm;
