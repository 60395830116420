import { ReportType, SimpleObject } from 'forms/interfaces';
import PropContainer from 'dataObj/PropContainer';
import DataStock from 'dataObj/DataStock';
import { CustomFilter, IFilterOwner } from 'dataObj/CustomFilter';

import { downloadBlobFile, jsonFetch } from 'utils/index';

import NotificationStore, { BackdropInstance } from 'store/notificationStore';
import ConfigurationStore from '../configurationStore';

type ResultType = {
    filename: string;
    file: any;
    created: Date;
    parObj: SimpleObject | null;
};

export default class Report implements IFilterOwner {
    public dataStock: DataStock;
    private propContainer: PropContainer;

    // private request: ResourceInfo;
    private view: string;
    // private kind: string;
    private backdrop: BackdropInstance | null = null;
    private waitType: 'sync' | 'async';
    private waitTime: number;
    private resultFormat: 'pdf' | 'html';
    public useFilter = false;
    private autoSave = false;
    private keepAllResults = false;
    private reportName: string;

    public filter: CustomFilter;

    public results: ResultType[] = [];

    // Сессия асинхронного запроса
    private session?: string;

    get name() {
        return this.view;
    }

    constructor(report: ReportType, propContainer: PropContainer, session?: string) {
        this.propContainer = propContainer;
        this.dataStock = propContainer.dataStock;

        // this.request = report.request;
        this.view = report.view;
        // this.kind = report.kind;
        this.waitType = report.waitType || 'sync';
        this.waitTime = report.waitTime || 15000;
        this.resultFormat = report.resultFormat || 'html';
        this.autoSave = !!report.autoSave;
        this.keepAllResults = !!report.keepAllResults;
        this.useFilter = report.useFilter;
        this.reportName = report.name;

        this.session = session;
        this.filter = new CustomFilter(this);
    }

    private lockScreen(timeout?: number) {
        if (this.backdrop) {
            this.backdrop.updateBackdrop(timeout);
        } else {
            this.backdrop = new BackdropInstance(timeout);
        }
    }

    private unlockScreen() {
        this.backdrop?.clearBackdrop();
    }

    async run(parObj: SimpleObject | null = {}, filters?: SimpleObject): Promise<any> {
        const { waitType, waitTime, resultFormat, reportName } = this;

        const { formGuid } = this.propContainer;

        const body = {
            formGuid,
            reportName,
            reportParams: {
                wait: waitTime,
                // waitType,
                // view,
                // kind,
                resultFormat
            },
            parObj,
            filters
        };

        if (this.waitType === 'sync') {
            this.lockScreen(this.waitTime);

            try {
                const file = await jsonFetch('task/report2', 'POST', body, {}, 'blob');

                this.unlockScreen();

                if (file) {
                    const filename = `report${Math.floor(Math.random() * 1000000)}.${
                        this.resultFormat
                    }`;

                    const result = {
                        file,
                        filename,
                        created: new Date(),
                        parObj
                    };

                    if (this.keepAllResults) {
                        this.results.unshift(result);
                    } else this.results = [result];

                    if (this.autoSave) {
                        NotificationStore.showConfirmation(
                            `${
                                ConfigurationStore.content.application.reports.saveReport as string
                            } «${filename}»?`,
                            (confirm: boolean) => {
                                confirm && downloadBlobFile(file, filename);
                            }
                        );
                    } else
                        NotificationStore.enqueueSnackbar({
                            message: ConfigurationStore.content.application.reports.done,
                            options: { variant: 'success' }
                        });

                    return result;
                }
            } catch (err: any) {
                NotificationStore.showAlert(err.message);
            }
        } else if (this.waitType === 'async') {
            try {
                const res: { [key: string]: string } = await jsonFetch(
                    'task/report2',
                    'POST',
                    body,
                    {},
                    'json'
                );

                if (res.session) {
                    this.session = res.session;

                    return res.session;
                }
            } catch (err: any) {
                NotificationStore.showAlert(err.message);
            }
        }

        return false;
    }
}
