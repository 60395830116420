import { MutableRefObject, useEffect, useState, useRef, useMemo } from 'react';

import useOnScreen from 'hooks/onScreen';

export default function useNodeResize(ref: MutableRefObject<HTMLElement>) {
    const [nodeWidth, setWidth] = useState(ref?.current?.offsetWidth);
    const [nodeHeight, setHeight] = useState(ref?.current?.offsetHeight);

    const onScreen = useOnScreen(ref);

    const previous: {
        current: {
            width?: number;
            height?: number;
        };
    } = useRef({
        width: undefined,
        height: undefined
    });

    const handleResize = (element: any) => {
        setTimeout(() => {
            const boundingRect = element.target.getBoundingClientRect();
            const { width, height } = boundingRect;

            if (previous.current.width !== width || previous.current.height !== height) {
                previous.current.width = width;
                previous.current.height = height;
                setWidth(Math.round(width));
                setHeight(Math.round(height));
            }
        }, 10);
    };

    const resizeObserver = useMemo(
        () =>
            new ResizeObserver(entry => {
                handleResize(entry[0]);
            }),
        []
    );

    useEffect(() => {
        if (onScreen && ref?.current) {
            const elem = ref.current;
            resizeObserver.observe(elem);

            setWidth(elem?.offsetWidth);
            setHeight(elem?.offsetHeight);

            return () => {
                resizeObserver.unobserve(elem);
            };
        }
    }, [onScreen, ref?.current]);

    return [nodeWidth, nodeHeight];
}
