import { useState, useEffect, FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Typography, Checkbox, TextField, InputAdornment } from '@mui/material';

import { FormType, SimpleObject, DataFormulaType, IconType, ResourceItem } from 'forms/interfaces';
import ComboBox from 'components/Inputs/ComboBox';

import { ResourceClass } from 'store/resourceStore';

import SelectResource from './SelectResource';
import { EventType } from './ResInterfaces';

import resValidator from '../clientResValidator';
import ResPropertyEditor from './ResPropertyEditor';
import ResScriptEditor from './ResScriptEditor/ResScriptEditor';
import ResServerFormula from './ResScriptEditor/ResServerFormula';
import ResTranslationEditor from './ResTranslationEditor';
import ResIconEditor from './ResIconEditor/ResIconEditor';
import ResOverlap from './ResOverlap';
import InheritorPropertyList from './ResOverlap/PropertyList';

import { InheritHookType, Resource } from './interfaces';
import ResLookup from './ResLookup';

interface ResPropsType {
    ResourceStore: ResourceClass;
    curDescr: Resource;
    prop: string;
    propValue?: string | boolean | object;
    applyValue: (prop: string, newValue: unknown) => void;
    inheritor?: InheritHookType;
    // Этот метод только для быстрого доступа, он вызывает applyValue
    inputChangeHandler: (event: EventType) => void;
}

const wuiAppCmpExclude = ['cdo', 'inheritor', 'tableStruct', 'groupModificatorStruct', 'cacheSet'];

const isAppCmp = (it: string) => it.startsWith('wui') || wuiAppCmpExclude.includes(it);

const ResProp: FunctionComponent<ResPropsType> = observer(
    ({ ResourceStore, curDescr, prop, propValue, inputChangeHandler, applyValue, inheritor }) => {
        const { curRes, editRes, curCtrl, editCtrl, availableCtrl, changeCtrlType, getList } =
            ResourceStore;

        const rule = curDescr.fields[prop];
        const info = rule.comment;

        const [store, setStore] = useState<SimpleObject[]>([]);

        const propResource =
            propValue && typeof propValue === 'object' && 'code' in propValue
                ? (propValue as ResourceItem)
                : undefined;

        const checkboxChangeHandler = (event: EventType) => {
            const { target } = event;
            applyValue(prop, target.checked);
        };

        useEffect(() => {
            if (rule && (rule.getList || rule.list)) {
                let res;

                if (rule.getList) {
                    res = getList(rule.getList);
                } else if (Array.isArray(rule.list)) {
                    res = rule.list?.map((it: string) => ({ key: it }));
                } else {
                    console.error(`Unknown list type ${rule.list || rule.getList || ''}`);
                }

                if (res instanceof Promise) {
                    setStore([]);
                    res.then(list => setStore(list)).catch(e => console.error(e));
                } else {
                    setStore(res || []);
                }
            }
        }, [
            curCtrl,
            curCtrl?.datasetName,
            curCtrl?.dataset,
            curCtrl?.resourceDatasetName,
            curCtrl?.eventDatasetName,
            getList,
            rule
        ]);

        const typeChangeHandler = (event: EventType) => {
            const newType = event.target.value;
            const isExistingType = resValidator.ctrlTypes.some(type => type === newType);
            if (newType && isExistingType) {
                changeCtrlType(newType);
            }
        };

        const resourceSelectHandler = (propName: string, code: string, value: ResourceItem) => {
            applyValue(prop, value ? { code, name: value.name, type: value.type } : {});
        };

        const overlapSelectHandler = (guid: string) => {
            applyValue(prop, guid);
        };

        const resourceChangeHandler = (event: EventType) => {
            const { target } = event;
            applyValue(prop, target ? { guid: target.value } : {});
        };

        function getPropRow() {
            if (rule.lookup) {
                return (
                    <div>
                        <ResLookup value={(propValue || '') as string} lookup={rule.lookup} />
                    </div>
                );
            }

            if (rule.propertyEditor) {
                return (
                    <div>
                        <ResPropertyEditor
                            type={rule.propertyEditor}
                            resource={curRes}
                            control={curCtrl}
                            prop={prop}
                            value={propValue}
                            onResult={inputChangeHandler}
                            ResourceStore={ResourceStore}
                        />
                    </div>
                );
            }

            switch (rule.render) {
                case 'javascript':
                case 'json':
                case 'sql':
                case 'ejs':
                    return (
                        <ResScriptEditor
                            name={prop}
                            text={(propValue as string) || ''}
                            language={rule.render}
                            onConfirm={inputChangeHandler}
                            resourceStore={ResourceStore}
                        />
                    );
                case 'script':
                    return (
                        <ResScriptEditor
                            name={prop}
                            text={(propValue as string) || ''}
                            language="javascript"
                            onConfirm={inputChangeHandler}
                            showText
                        />
                    );
                case 'svg':
                    return (
                        <ResIconEditor
                            name={prop}
                            text={(propValue as string) || ''}
                            fullColor={(editCtrl as IconType)?.fullColor}
                            onConfirm={inputChangeHandler}
                        />
                    );
                case 'translator':
                    return (
                        <ResTranslationEditor
                            name={prop}
                            formDescr={curDescr as unknown as FormType}
                            formRes={editRes}
                            translation={propValue as string}
                            onConfirm={inputChangeHandler}
                        />
                    );
            }

            if (rule.multiLine) {
                return (
                    <TextField
                        multiline
                        size="small"
                        style={{
                            width: '100%',
                            boxSizing: 'border-box'
                        }}
                        name={prop}
                        rows={5}
                        disabled={!!rule?.readOnly}
                        value={propValue || ''}
                        onChange={inputChangeHandler}
                    />
                );
            }

            if (prop === 'type') {
                return (
                    <ComboBox
                        name={prop}
                        value={(propValue as string) || ''}
                        store={resValidator.ctrlTypes
                            .filter(it =>
                                curRes?.guid === curCtrl?.guid ? isAppCmp(it) : !isAppCmp(it)
                            )
                            .filter(it => !availableCtrl || availableCtrl.includes(it))
                            .sort((a: string, b: string) => a.localeCompare(b))
                            .map(it => ({ key: it }))}
                        nameField="key"
                        valueField="key"
                        onChange={typeChangeHandler}
                        editable
                        clearable
                        freeSolo={false}
                    />
                );
            }

            if (rule.type === 'boolean') {
                return (
                    <Checkbox
                        name={prop}
                        color="primary"
                        size="small"
                        onChange={checkboxChangeHandler}
                        checked={!!propValue}
                    />
                );
            }

            if (rule.type === 'dataFormula') {
                return (
                    <ResServerFormula
                        name={prop}
                        formula={propValue as DataFormulaType}
                        onConfirm={inputChangeHandler}
                        showText
                    />
                );
            }
            if (rule.type === 'resource') {
                return (
                    <div>
                        <TextField
                            size="small"
                            fullWidth
                            helperText={
                                propResource
                                    ? `${propResource.type}: ${propResource.name || ''}`
                                    : null
                            }
                            name={prop}
                            value={propResource?.code || ''}
                            onChange={resourceChangeHandler}
                            InputProps={{
                                endAdornment: rule ? (
                                    <InputAdornment position="end">
                                        <SelectResource
                                            types={rule.list}
                                            property={prop}
                                            resourceItem={propResource as ResourceItem}
                                            guid={propResource?.guid || ''}
                                            onSelect={resourceSelectHandler}
                                            ResourceStore={ResourceStore}
                                        />
                                    </InputAdornment>
                                ) : null
                            }}
                        />
                    </div>
                );
            }
            if (rule.type === 'overlapGuid' && inheritor) {
                return (
                    <ResOverlap
                        name={prop}
                        value={propValue as string}
                        inheritor={inheritor}
                        onChange={resourceChangeHandler}
                        onSelect={overlapSelectHandler}
                    />
                );
            }
            if (rule.type === 'inheritPropertyList' && inheritor) {
                return (
                    <InheritorPropertyList
                        name={prop}
                        inheritor={inheritor}
                        value={propValue as string}
                        parentGuid={(editCtrl as any)?.overlapGuid}
                        onChange={inputChangeHandler}
                    />
                );
            }
            if (rule.type === 'inheritCollectionList' && inheritor) {
                return (
                    <InheritorPropertyList
                        collection
                        name={prop}
                        inheritor={inheritor}
                        value={propValue as string}
                        parentGuid={(editCtrl as any)?.overlapGuid}
                        onChange={inputChangeHandler}
                    />
                );
            }

            if (rule.list ?? rule.getList) {
                let value;
                if (rule.multiSelect) {
                    value = typeof propValue === 'string' ? propValue.split(',') : [];
                } else {
                    value = propValue;
                }
                return (
                    <ComboBox
                        name={prop}
                        // тут строка или массив изза listBox
                        value={value as string[]}
                        store={store}
                        nameField="key"
                        valueField="key"
                        onChange={inputChangeHandler}
                        onConfirm={({ target: t1 }) => {
                            const target = {
                                name: t1.name || '',
                                value: t1.value ? t1.value.join(',') : ''
                            };
                            inputChangeHandler({ target });
                        }}
                        clearable
                        editable
                        multiselect={rule.multiSelect}
                    />
                );
            }

            return (
                <TextField
                    size="small"
                    style={{
                        width: '100%',
                        boxSizing: 'border-box'
                    }}
                    name={prop}
                    disabled={!!rule.readOnly}
                    value={propValue || ''}
                    onChange={inputChangeHandler}
                />
            );
        }

        function getLabel() {
            return (
                <Typography
                    style={
                        rule.type === 'resource' && propResource?.type
                            ? { position: 'relative', top: -14 }
                            : {}
                    }
                >
                    {prop}
                </Typography>
            );
        }

        //--------------------------------------------------------
        return curCtrl && curDescr ? (
            <tr key={prop}>
                <td>
                    {info ? (
                        <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{info}</div>}>
                            {getLabel()}
                        </Tooltip>
                    ) : (
                        getLabel()
                    )}
                </td>
                <td>{getPropRow()}</td>
            </tr>
        ) : null;
    }
);

export default ResProp;
