import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import useCtrlProps from 'hooks/ctrlProps';

import { ControlPropsType } from 'forms/interfaces';

interface PropsType extends ControlPropsType {
    descr: any;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
    disabled?: boolean;
}

const RadioGroupButton: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, labelPlacement, disabled }) => {
        const { guid, value, caption, labelPlacement: btnLabelPlacement } = descr;

        const { ctrlVisible, ctrlEnabled, ctrlCaption } = useCtrlProps(propContainer, guid);

        return (
            <FormControlLabel
                key={value}
                value={value}
                control={<Radio sx={{ m: 1, p: 2 }} />}
                label={ctrlCaption || caption}
                labelPlacement={btnLabelPlacement || labelPlacement}
                sx={{ visibility: ctrlVisible ? 'visible' : 'hidden' }}
                disabled={disabled || !ctrlEnabled}
            />
        );
    }
);

export default RadioGroupButton;
