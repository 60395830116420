import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import { ControlDataRowType, DBImageType } from 'forms/interfaces';

import Loading from 'components/utils/Loading/Loading';

import useCtrlProps from 'hooks/ctrlProps';
import useCtrlData from 'hooks/ctrlData';

import Image from './DBImage';
import ImageList from './DBImageList';

interface PropsType extends ControlDataRowType {
    descr: DBImageType;
}

const DBImageContainer: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, dataRow }) => {
        const { guid, datasetName, fieldName } = descr;
        const { dataset } = useCtrlData(propContainer, datasetName, fieldName, dataRow);
        const { ctrlVisible } = useCtrlProps(propContainer, guid);

        const styles = {
            box: {
                visibility: ctrlVisible ? 'inherit' : ('hidden' as any),
                height: '100%',
                width: '100%',
                position: 'relative',
                overflow: 'auto'
            }
        };

        const renderImage = () =>
            descr.showList ? (
                <ImageList descr={descr} propContainer={propContainer} />
            ) : (
                <Image descr={descr} propContainer={propContainer} dataRow={dataRow} />
            );

        const renderLoading = () => (dataset ? <Loading delay /> : <Loading type="error" />);

        return <Box sx={styles.box}>{dataset?.hasData() ? renderImage() : renderLoading()}</Box>;
    }
);

export default DBImageContainer;
