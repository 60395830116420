import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Typography } from '@mui/material';

import SimpleDialog from 'components/Feedback/SimpleDialog';

import NotificationStore from 'store/notificationStore';
import ConfigurationStore from 'store/configurationStore';

const Confirmation: FunctionComponent = observer(() => {
    const {
        confirmation,
        confirmationTitle,
        confirmationMessage,
        confirmationCallback,
        closeConfirmation
    } = NotificationStore;

    const { content } = ConfigurationStore;

    const handleClose = (result: boolean) => {
        if (confirmationCallback) {
            confirmationCallback(result);
        }

        closeConfirmation();
    };

    if (confirmation)
        return (
            <SimpleDialog
                editorName={confirmationTitle}
                handleClose={handleClose}
                maxWidth="xs"
                confirmLabel={content.controls.buttons.yes}
                cancelLabel={content.controls.buttons.no}
            >
                <Typography>{confirmationMessage}</Typography>
            </SimpleDialog>
        );

    return null;
});

export default Confirmation;
