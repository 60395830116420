import moment, { Moment } from 'moment';

import { DataType, EventDataType, ModelType, SubjectType, TypeOfView } from '../../interfaces';

export const isOverlap = (el1: HTMLElement, el2: HTMLElement) => {
    const rect1 = el1.getBoundingClientRect();
    const rect2 = el2.getBoundingClientRect();

    return !(
        rect1.top > rect2.bottom ||
        rect1.right < rect2.left ||
        rect1.bottom < rect2.top ||
        rect1.left > rect2.right
    );
};

export const makeHour = (time: number, date?: Date) => {
    const hour = Math.floor(time / 100);
    const minutes = time % 100;

    return moment(date).hours(hour).minutes(minutes);
};

export const getCurrentWeek = (date: Date) => {
    const currentDate = moment(date);
    const weekStart = currentDate.clone().startOf('week');

    const days = [];
    for (let i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, 'days').format('YYYY-MM-DD'));
    }

    return days;
};

export const getSelectedArea = (start: HTMLElement, end: HTMLElement) => ({
    top: start.offsetTop <= end.offsetTop ? start.offsetTop : end.offsetTop,
    height:
        start.offsetTop <= end.offsetTop
            ? end.offsetTop + end.offsetHeight - start.offsetTop
            : start.offsetTop + start.offsetHeight - end.offsetTop,
    left: start.offsetLeft <= end.offsetLeft ? start.offsetLeft : end.offsetLeft,
    width:
        start.offsetLeft <= end.offsetLeft
            ? end.offsetLeft + end.offsetWidth - start.offsetLeft
            : start.offsetLeft + start.offsetWidth - end.offsetLeft
});

const analyzeData = (data: any[]) => {
    const uniq = (arr: any[]) => {
        const seen: any = { hours: {} };

        return arr.filter((item: any) => {
            if (seen.hours[item.hour]) {
                if (seen.hours[item.hour].includes(item.duration)) return false;

                seen.hours[item.hour].push(item.duration);
            }

            seen.hours[item.hour] = [item.duration];

            return true;
        });
    };

    return uniq(data)
        .map((d: any) => ({ hour: d.hour, duration: d.duration }))
        .sort((a: any, b: any) => a.duration - b.duration)
        .sort((a: any, b: any) => a.hour - b.hour);
};

export const addEmptyCells = (
    model: ModelType,
    duration: number,
    tmpRows: any[],
    data: any[],
    fitSlots?: boolean
) => {
    const { start, end } = model;

    const modelEndTime = makeHour(end);

    let currentTime = makeHour(start);

    const dataHours = analyzeData(data);

    while (
        currentTime.hour() < modelEndTime.hour() ||
        currentTime.minute() < modelEndTime.minute()
    ) {
        const hour = Number(currentTime.format('HHmm'));

        if (fitSlots) {
            const found = dataHours.find(d => d.hour === hour);
            if (found) {
                tmpRows.push({
                    id: currentTime.format('HH:mm'),
                    time: currentTime.format('HH:mm'),
                    hour: currentTime.format('HH'),
                    minutes: currentTime.format('mm'),
                    duration: found.duration,
                    subject: found.duration
                });
                currentTime = currentTime.add(found.duration, 'minutes');
                continue;
            }
        }
        tmpRows.push({
            id: currentTime.format('HH:mm'),
            time: currentTime.format('HH:mm'),
            hour: currentTime.format('HH'),
            minutes: currentTime.format('mm'),
            duration,
            subject: ''
        });

        currentTime = currentTime.add(duration, 'minutes');
    }
};

export const addStartDateCells = (
    dayStart: Moment,
    modelStartTime: Moment,
    duration: number,
    tmpRows: any[]
) => {
    if (dayStart.diff(modelStartTime, 'minutes') < 0) {
        const beginRows: {
            id: string;
            time: string;
            hour: string;
            minutes: string;
            duration: number;
            subject: string;
        }[] = [];

        while (modelStartTime.diff(dayStart, 'minutes') > 0) {
            const diff = modelStartTime.diff(dayStart, 'minutes');
            if (diff < duration) {
                modelStartTime.subtract(diff, 'minutes');
            }
            modelStartTime.subtract(duration, 'minutes');

            beginRows.unshift({
                id: 'start',
                time: modelStartTime.format('HH:mm'),
                hour: modelStartTime.format('HH'),
                minutes: modelStartTime.format('mm'),
                duration,
                subject: 'disabled'
            });
        }

        tmpRows.unshift(...beginRows);
    }
};

export const addEndDateCells = (
    dayEnd: Moment,
    modelEndTime: Moment,
    duration: number,
    tmpRows: any[]
) => {
    if (dayEnd.diff(modelEndTime, 'minutes') > 0) {
        const endRows: {
            id: string;
            time: string;
            hour: string;
            minutes: string;
            duration: number;
            subject: string;
        }[] = [];

        while (dayEnd.diff(modelEndTime, 'minutes') > 0) {
            endRows.push({
                id: 'end',
                time: modelEndTime.format('HH:mm'),
                hour: modelEndTime.format('HH'),
                minutes: modelEndTime.format('mm'),
                duration,
                subject: 'disabled'
            });
            if (dayEnd.diff(modelEndTime, 'minutes') < duration) {
                modelEndTime.add(dayEnd.diff(modelEndTime, 'minutes'), 'minutes');
            }
            modelEndTime.add(duration, 'minutes');
        }

        tmpRows.push(...endRows);
    }
};

// Дробим переносимые события по дням
const addRescheduling = (events: EventDataType[]) => {
    const rescheduled: EventDataType[] = [];

    while (events.length > 0) {
        const event = events.shift();

        if (event) {
            if (
                !event.dateStart.clone().startOf('day').isSame(event.dateEnd.clone().startOf('day'))
            ) {
                const diff = event.dateEnd.diff(event.dateStart, 'days');

                for (let d = 0; d <= diff; d++) {
                    const dateStart =
                        d > 0
                            ? event.dateStart.clone().add(d, 'days').startOf('day')
                            : event.dateStart.clone().add(d, 'days');
                    const dateEnd = d < diff ? dateStart.clone().endOf('day') : event.dateEnd;
                    const duration = dateEnd.diff(dateStart, 'minutes');

                    rescheduled.push({
                        ...event,
                        key: `${event.id}/${d + 1}`,
                        duration,
                        dateStart,
                        dateEnd
                    });
                }
            } else rescheduled.push(event);
        }
    }

    return rescheduled;
};

// Формируем список событий
export const makeEventList = (
    data: DataType[],
    type: TypeOfView,
    model: ModelType,
    subject: SubjectType,
    currentDate: Date
): any[] =>
    addRescheduling(
        data.map(event => ({
            ...event,
            key: event.id.toString(),
            dateStart: event.dateStart
                ? moment(event.dateStart)
                : makeHour(event.hour as number, new Date(event.date as string)),
            dateEnd: event.dateEnd
                ? moment(event.dateEnd)
                : makeHour(event.hour as number, new Date(event.date as string)).add(
                      event.duration,
                      'minutes'
                  ),
            duration:
                event.dateStart && event.dateEnd
                    ? moment(event.dateEnd).diff(moment(event.dateStart), 'minutes')
                    : event.duration
        }))
    )
        .filter(event => {
            if (type === 'day') {
                const includesStart = moment(event.dateStart || event.date).isBetween(
                    moment(currentDate).startOf('day'),
                    moment(currentDate).endOf('day'),
                    undefined,
                    '[]'
                );
                const includesEnd =
                    event.dateEnd &&
                    moment(event.dateEnd).isBetween(
                        moment(currentDate).startOf('day'),
                        moment(currentDate).endOf('day'),
                        undefined,
                        '[]'
                    );

                return (
                    model.subjects
                        .map(subj => subj.id.toString())
                        .includes(event.ownerId.toString()) &&
                    (includesStart || includesEnd)
                );
            }

            const weekStart = moment(currentDate).startOf('week').startOf('day');
            const weekEnd = moment(currentDate).endOf('week').endOf('day');

            return (
                event.ownerId.toString() === subject.id.toString() &&
                (moment(event.dateStart || event.date).isBetween(
                    weekStart,
                    weekEnd,
                    undefined,
                    '[)'
                ) ||
                    (event.dateEnd &&
                        moment(event.dateEnd).isBetween(
                            moment(currentDate).startOf('day'),
                            moment(currentDate).endOf('day'),
                            undefined,
                            '[)'
                        )))
            );
        })
        .sort((a, b) => a.dateStart.diff(b.dateStart, 'minutes'));

// Выявляем наиболее близкое время
const getClosestTime = (targetTime: string, times: string[]) => {
    const timesToMs = times.map((t: any) => +moment(t, 'HH:mm').format('x'));

    const closestTime = (arr: any, time: any) =>
        arr.reduce((prev: any, curr: any) =>
            Math.abs(curr - time) < Math.abs(prev - time) ? curr : prev
        );

    return moment(closestTime(timesToMs, +moment(targetTime, 'HH:mm').format('x'))).format('HH:mm');
};

// Выявляем наиболее близкий по времени слот расписания
const getClosestSlot = (
    slotId: string,
    ownerId: number,
    rowId: string,
    duration: number,
    heightIndex: number,
    date: Moment,
    counter = 0
): undefined | { id: string; shift: number } => {
    // Постепенно расширяем зону поиска слотов
    const findId = `${ownerId}-${moment(date).format('YYYY-MM-DD')}-${rowId.substring(
        0,
        4 - counter
    )}`;
    const slots: any = document.querySelectorAll(`[id^="${findId}"]`);

    if (slots?.length) {
        const times = Array.from(slots).map((slot: any) =>
            slot.id.substring(`${ownerId}`.length + 12, slot.id.length)
        );

        if (times.length === 0) {
            return;
        }

        const closestSlotId = getClosestTime(rowId, times);
        const shift =
            (slots[0].offsetHeight / (duration / heightIndex)) *
            moment(rowId, 'HH:mm').diff(moment(closestSlotId, 'HH:mm'), 'minutes');

        return {
            id: `${ownerId}-${moment(date).format('YYYY-MM-DD')}-${closestSlotId}`,
            shift
        };
    }

    if (counter <= 2)
        return getClosestSlot(slotId, ownerId, rowId, duration, heightIndex, date, counter + 1);

    return;
};

// Вычисляем стартовую позицию события на временной шкале
export const getEventPosition = (
    slotId: string,
    bottomBorder: number,
    ownerId: number,
    rowId: string,
    duration: number,
    heightIndex: number,
    date: Moment,
    topShift = 0
):
    | {
          top: number;
          height: number;
          left: number;
          width: number;
          continuing: boolean;
          prolongation: boolean;
      }
    | undefined => {
    // Только если знаем высоту столбца
    if (bottomBorder) {
        const parentSlot = document.getElementById(slotId);

        // Если удалось найти точный слот для размещения
        if (parentSlot) {
            // Вычисляем положение по вертикали
            const eventTop = parentSlot.offsetTop + topShift;
            // Вычисляем высоту
            const eventHeight =
                // Если положение по вертикали выше верхней границы - уменьшаем высоту на размер сдвига
                heightIndex * parentSlot.offsetHeight + (eventTop < 0 ? topShift : 0);

            const prolongation = eventTop < 0;

            // Если выше рабочей области - установим верхнюю границу равной нулю
            const top = prolongation ? 0 : eventTop;
            // Если высота события больше высоты рабочей области - обрезаем
            const height =
                top + eventHeight <= bottomBorder
                    ? eventHeight
                    : eventHeight - (top + eventHeight - bottomBorder);
            const left = parentSlot.offsetLeft;
            const width = parentSlot.offsetWidth - 30;

            const continuing = top + height >= bottomBorder;

            return {
                top,
                height,
                left,
                width,
                continuing,
                prolongation
            };
        }

        // Ищем ближайший слот из отрисованных в рабоче области
        const closestSlot = getClosestSlot(slotId, ownerId, rowId, duration, heightIndex, date);

        if (closestSlot) {
            return getEventPosition(
                closestSlot.id,
                bottomBorder,
                ownerId,
                rowId,
                duration,
                heightIndex,
                date,
                closestSlot.shift
            );
        }
    }
};

// Группируем элементы по колонке, начиная с текущего, вырезая найденные пересечения из изначального набора
const makeElementGroup = (
    elements: HTMLElement[],
    currentElement?: HTMLElement,
    elementGroup: HTMLElement[] = []
): HTMLElement[] => {
    if (currentElement) {
        if (elementGroup.indexOf(currentElement) === -1) elementGroup.push(currentElement);

        const currentElementType = currentElement.getAttribute('event-type');

        const overlappingElements = elements
            .sort((a, b) => a.offsetTop - b.offsetTop)
            .filter(
                el =>
                    el.getAttribute('event-type') === currentElementType &&
                    el !== currentElement &&
                    el.offsetLeft <
                        currentElement.offsetLeft + currentElement.getBoundingClientRect().width &&
                    el.offsetLeft + el.getBoundingClientRect().width > currentElement.offsetLeft &&
                    el.offsetTop < currentElement.offsetTop + currentElement.offsetHeight &&
                    el.offsetTop + el.offsetHeight > currentElement.offsetTop
            );

        if (overlappingElements.length > 0) {
            overlappingElements.forEach(el => {
                if (elementGroup.indexOf(el) === -1) {
                    elements.splice(elements.indexOf(el), 1);
                    elementGroup.push(el);
                }

                return makeElementGroup(elements, el, elementGroup);
            });
        }

        return elementGroup;
    }

    return elementGroup;
};

const getLeft = (el: HTMLElement): number => {
    const { x } = el.getBoundingClientRect();
    const { left } = (el.parentNode as HTMLElement)?.getBoundingClientRect() || 0;

    return x - left;
};

const checkHorizontalOverlap = (
    left: number,
    width: number,
    checkEl: HTMLElement,
    borders = '[]'
): boolean => {
    const leftInclude = borders.includes('[');
    const rightInclude = borders.includes(']');

    const leftOverlap =
        (leftInclude ? left >= getLeft(checkEl) : left > getLeft(checkEl)) &&
        (rightInclude
            ? left <= getLeft(checkEl) + checkEl.getBoundingClientRect().width
            : left < getLeft(checkEl) + checkEl.getBoundingClientRect().width);

    const rightOverlap =
        (leftInclude ? left + width >= getLeft(checkEl) : left + width > getLeft(checkEl)) &&
        (rightInclude
            ? left + width <= getLeft(checkEl) + checkEl.getBoundingClientRect().width
            : left + width < getLeft(checkEl) + checkEl.getBoundingClientRect().width);

    return leftOverlap || rightOverlap;
};

export const allocateElementsEqually = (elements: HTMLElement[], column: HTMLElement): void => {
    const columnWidth = column.getBoundingClientRect().width - 30;

    while (elements.length > 0) {
        const currentElement = elements.sort((a, b) => a.offsetTop - b.offsetTop).shift();
        const group = makeElementGroup(elements, currentElement);

        if (group.length > 0) {
            const elWidth = columnWidth / group.length;

            group.forEach((el, index) => {
                el.style.left = `${getLeft(column) + elWidth * index}px`;

                el.style.width = `${elWidth}px`;
            });

            group.forEach((el, i, arr) => {
                let shiftBlock = getLeft(column);

                // В обратном порядке обходим элементы
                for (let k = i - 1; k >= 0; k--) {
                    if (
                        // Ищем элементы, нижняя граница которых не пересекает верхнюю границу текущего
                        el.offsetTop >= arr[k].offsetTop + arr[k].offsetHeight &&
                        getLeft(arr[k]) !== shiftBlock &&
                        // Если элемент при этом левее текущего, то ...
                        getLeft(el) > getLeft(arr[k]) &&
                        // ... проверяем на пересечения
                        !arr
                            .filter(
                                f =>
                                    f.id !== el.id &&
                                    f.id !== arr[k].id &&
                                    el.offsetTop <= f.offsetTop + f.offsetHeight &&
                                    getLeft(el) > getLeft(f)
                            )
                            ?.find(
                                f =>
                                    checkHorizontalOverlap(
                                        getLeft(arr[k]) + 1,
                                        arr[k].getBoundingClientRect().width,
                                        f
                                    ) && el.offsetTop < f.offsetTop + f.offsetHeight
                            )
                    ) {
                        // Сдвигаем текущий
                        el.style.left = `${getLeft(arr[k])}px`;
                    } else shiftBlock = getLeft(arr[k]);
                }

                // Если слева есть доступное место, то сдвигаем с любом случае
                let leftShift = getLeft(el);

                while (leftShift > column.offsetLeft) {
                    leftShift -= el.getBoundingClientRect().width;

                    if (
                        !arr
                            .filter(
                                // eslint-disable-next-line @typescript-eslint/no-loop-func
                                f =>
                                    f.id !== el.id &&
                                    el.offsetTop < f.offsetTop + f.offsetHeight &&
                                    leftShift >= getLeft(f)
                            )
                            // eslint-disable-next-line @typescript-eslint/no-loop-func
                            .find(f =>
                                checkHorizontalOverlap(
                                    leftShift,
                                    el.getBoundingClientRect().width,
                                    f,
                                    '[)'
                                )
                            ) &&
                        leftShift >= shiftBlock
                    ) {
                        el.style.left = `${leftShift}px`;
                    }
                }
            });

            const rightEl = group.sort((a, b) => getLeft(b) - getLeft(a))[0];
            const groupWidth =
                getLeft(rightEl) + rightEl.getBoundingClientRect().width - getLeft(column);
            const groupCol = groupWidth / group[0].getBoundingClientRect().width;
            const widthRatio = (columnWidth - groupWidth) / groupCol;

            // Распределяем элементы по ширине колонки
            group.forEach(el => {
                const newWidth = el.getBoundingClientRect().width + widthRatio;
                const groupColIndex =
                    Math.round(((getLeft(el) - getLeft(column)) * groupCol) / groupWidth) - 1;
                const leftShift =
                    widthRatio + groupColIndex * (newWidth - el.getBoundingClientRect().width);

                el.style.width = `${newWidth}px`;
                if (getLeft(el) > getLeft(column)) {
                    el.style.left = `${getLeft(el) + leftShift}px`;
                }

                el.style.visibility = 'visible';
            });
        }
    }
};
