import { FunctionComponent, ReactNode } from 'react';

import { Box } from '@mui/material';

interface PropsType {
    children?: ReactNode;
    index: string;
    value: string;
}

const TabPanel: FunctionComponent<PropsType> = ({ children, value, index, ...other }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ padding: 0, height: 'calc(100% - 80px)' }}
        {...other}
    >
        {value === index && <Box sx={{ height: '100%' }}>{children}</Box>}
    </div>
);

export default TabPanel;
