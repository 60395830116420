import React, { FunctionComponent, useCallback, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Buffer } from 'buffer';

import { useTheme } from '@mui/material/styles';
import useCtrlData from 'hooks/ctrlData';

import Image from 'components/Image/Image';

import { ControlDataRowType, DBImageType } from 'forms/interfaces';

import ConfigurationStore from 'store/configurationStore';

const MIME_TYPE = 'image/png'; // 'image/svg+xml', 'image/jpeg', 'application/pdf'

interface PropsType extends ControlDataRowType {
    descr: DBImageType;
}

const DBImageList: FunctionComponent<PropsType> = observer(({ descr, propContainer, dataRow }) => {
    const { dataset, dataVal } = useCtrlData(
        propContainer,
        descr.datasetName,
        descr.fieldName,
        dataRow
    );
    const theme = useTheme();

    const { fit, position } = descr;
    const { api, token } = ConfigurationStore;

    const valueToText = useCallback(
        val => (val === undefined || val === null ? '' : val),
        [dataVal]
    );

    const [val, setVal] = useState(() => valueToText(dataVal));

    const styles = {
        image: {
            padding: theme.spacing(2),
            maxHeight: `calc(100% - ${theme.spacing(4)}`,
            maxWidth: `calc(100% - ${theme.spacing(4)}`
        }
    };

    useEffect(() => {
        if (dataset) setVal(valueToText(dataVal));
    }, [dataset, dataVal, valueToText]);

    const imageSrc = useMemo(() => {
        if (!val) return '';

        if (descr.srcType === 'blob' && typeof val === 'string') {
            return val;
        }
        if (descr.srcType === 'blob' && typeof val === 'object' && Array.isArray(val.data)) {
            return `data:${MIME_TYPE};base64,${Buffer.from(val).toString('base64')}`;
        }
        return `${api ?? '/'}getfile/${encodeURIComponent(val)}/?token=${
            token ?? ''
        }`;
    }, [val]);

    return (
        <Image
            key={descr.guid}
            src={imageSrc}
            alt={descr.labelFieldName ? dataset?.getFieldValue(descr.labelFieldName) : undefined}
            position={position || undefined}
            fit={fit || undefined}
            style={styles.image}
            title={descr.showTitle}
        />
    );
});

export default DBImageList;
