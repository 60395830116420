import { useCallback, useEffect, useState } from 'react';

import { ResourceClass } from 'store/resourceStore';

import { InheritHookType, Resource } from 'components/resources/ResEditor/interfaces';

function useInheritor(ResourceStore: ResourceClass): InheritHookType | undefined {
    const ancestor = ResourceStore?.initRes?.ancestor;

    const [loading, setLoading] = useState(!ResourceStore?.ancestorRes);
    const [error, setError] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [helperText, setHelperText] = useState('loading...');
    const [overlapResource, setOverlapResource] = useState<ResourceClass | undefined>();

    const applyOverlapResource = useCallback(
        (ancestorResStore: ResourceClass, ancestorRes: Resource) => {
            ancestorResStore.initResource(ancestorRes);

            setOverlapResource(ancestorResStore);
        },
        [ancestor]
    );

    useEffect(() => {
        if (ancestor?.type && ancestor?.code) {
            if (!ResourceStore?.ancestorRes) {
                setLoading(true);
                setError(false);
                ResourceStore?.readResource(ancestor.type, ancestor.code, true)
                    .then(ancestorRes => {
                        if (ResourceStore) ResourceStore.ancestorRes = ancestorRes as Resource;
                        const ancestorResStore = new ResourceClass();

                        applyOverlapResource(ancestorResStore, ancestorRes as Resource);

                        setLoading(false);
                    })
                    .catch(err => {
                        setHelperText('errored');
                        setError(true);
                        console.error(err.message);
                    });
            } else {
                const ancestorResStore = new ResourceClass();

                applyOverlapResource(ancestorResStore, ResourceStore.ancestorRes as Resource);
            }
        }
    }, [ancestor]);

    if (ancestor)
        return {
            ancestorGuid: ancestor.code,
            loading,
            error,
            setError,
            notFound,
            setNotFound,
            helperText,
            setHelperText,
            overlapResource,
            setOverlapResource
        };

    return;
}

export default useInheritor;
