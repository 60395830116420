import React, { useState, useEffect, FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Editor } from '@tinymce/tinymce-react';
import { Box } from '@mui/material';

import useDBEdit from 'hooks/dbEdit';
import useDebounce from 'hooks/debounce';

import { ControlPropsType } from '../interfaces';

interface PropsType extends ControlPropsType {
    descr: any;
}

const DBTinyMCE: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { fieldName, rows } = descr;

    const { dataset, dataVal, ctrlVisible, ctrlEnabled } = useDBEdit(descr, propContainer);

    const [val, setVal] = useState(dataVal);

    // Фиксируем значение, передаваемое в датасет с задержкой, чтобы избежать лишних ререндеров
    const activeVal = useDebounce(val);

    useEffect(() => {
        if (dataset) setVal(dataVal);
    }, [dataset, dataVal]);

    useEffect(() => {
        dataset?.trySetFieldValue(fieldName, activeVal).catch(err => console.error(err.message));
    }, [activeVal]);

    const changeHandler = (value: string) => {
        if (dataset?.inEdit) {
            setVal(value);
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                p: 2,
                boxSizing: 'border-box',
                visibility: ctrlVisible ? 'inherit' : ('hidden' as any),
                '& .tox-tinymce': {
                    borderRadius: 1
                }
            }}
        >
            <Editor
                tinymceScriptSrc={`${process.env.PUBLIC_URL as string}/tinymce/tinymce.min.js`}
                disabled={!ctrlEnabled}
                init={{
                    height: rows ? 29 * rows + 57 : 200,
                    branding: false,
                    menubar: false,
                    plugins: 'code autolink codesample',
                    codesample_languages: [
                        { text: 'HTML/XML', value: 'markup' },
                        { text: 'JavaScript', value: 'javascript' },
                        { text: 'CSS', value: 'css' },
                        { text: 'PHP', value: 'php' },
                        { text: 'Ruby', value: 'ruby' },
                        { text: 'Python', value: 'python' },
                        { text: 'Java', value: 'java' },
                        { text: 'C', value: 'c' },
                        { text: 'C#', value: 'csharp' },
                        { text: 'C++', value: 'cpp' }
                    ],
                    language: 'ru',
                    toolbar: 'undo redo | bold italic forecolor | code | codesample',
                    content_style:
                        'body {line-height: 1.0;} p { margin-block-start: 0.75rem; margin-block-end: 0.75rem }'
                }}
                value={val}
                onEditorChange={changeHandler}
            />
        </Box>
    );
});

export default DBTinyMCE;
