export default {
    functions: {},
    types: {
        ScriptClient: {
            methods: {
                getFieldValue: {
                    params: [
                        { type: 'string', description: null, name: 'dsName' },
                        { type: 'string', description: null, name: 'fieldName' }
                    ],
                    description: 'Прочитать значение поля датасета',
                    type: 'method',
                    return: { type: 'any' }
                },
                setFieldValue: {
                    params: [
                        { type: 'string', description: null, name: 'dsName' },
                        { type: 'string', description: null, name: 'fieldName' },
                        { type: 'string', description: null, name: 'value' }
                    ],
                    description: 'Установить значение поля датасета',
                    type: 'method'
                },
                selectRow: {
                    params: [
                        { type: 'string', description: null, name: 'dsName' },
                        { type: 'string', description: null, name: 'value' }
                    ],
                    description: 'Выбрать строку по идентификатору',
                    type: 'method'
                },
                getAF: {
                    params: [{ type: 'string', description: null, name: 'key' }],
                    description: 'Прочитать значение активного фильтра',
                    type: 'method',
                    return: { type: 'any' }
                },
                setAF: {
                    params: [
                        { type: 'string', description: null, name: 'key' },
                        { type: 'string', description: null, name: 'value' }
                    ],
                    description: 'Установить значение активного фильтра',
                    type: 'method'
                },
                refreshDataset: {
                    params: [{ type: 'string', description: null, name: 'dsName' }],
                    description: 'Перечитать набор данных',
                    type: 'method'
                },
                setData: {
                    params: [
                        { type: 'string', description: null, name: 'dsName' },
                        { type: 'Array', name: 'data', complexType: [{ type: 'object' }] }
                    ],
                    description: 'Загрузить данные из массива',
                    type: 'method'
                },
                getRecCount: {
                    params: [{ type: 'string', description: null, name: 'dsName' }],
                    description: 'Количество записей',
                    type: 'method',
                    return: { type: 'number' }
                },
                getParamValue: {
                    params: [{ type: 'string', description: null, name: 'name' }],
                    description: 'Получить значение параметра',
                    type: 'method',
                    return: { type: 'any' }
                },
                setParamValue: {
                    params: [
                        { type: 'string', description: null, name: 'name' },
                        { type: 'string', description: null, name: 'value' }
                    ],
                    description: 'Установить значение параметра (только const)',
                    type: 'method'
                },
                showForm: {
                    params: [
                        { type: 'string', description: null, name: 'name' },
                        {
                            type: 'Array',
                            complexType: [{ type: 'object' }],
                            description: null,
                            name: 'values'
                        }
                    ],
                    description: 'Показать форму',
                    type: 'method',
                    return: { type: 'boolean' }
                },
                showEditor: {
                    params: [
                        { type: 'string', description: null, name: 'name' },
                        {
                            type: 'Array',
                            complexType: [{ type: 'object' }],
                            description: null,
                            name: 'values'
                        }
                    ],
                    description: 'Отобразить форму редактор',
                    type: 'method',
                    return: { type: 'boolean' }
                },
                showApiErrors: {
                    description: 'Вывести ошибки api',
                    type: 'method'
                },
                recalcFormulas: {
                    description: 'Пересчитать формулы',
                    type: 'method'
                },
                encodeData: {
                    params: [
                        { type: 'string', description: null, name: 'data' },
                        { type: 'string', description: null, name: 'key' }
                    ],
                    description: 'Шифровать данные публичным ключом',
                    type: 'method',
                    return: { type: 'string' }
                },
                notification: {
                    params: [
                        { type: 'string', description: null, name: 'message' },
                        { type: 'string', description: null, name: 'variant' }
                    ],
                    description: 'Вывод сообщения',
                    type: 'method'
                },
                executeDynamicForm: {
                    params: [
                        { type: 'object', description: null, name: 'descr' },
                        { type: 'object', description: null, name: 'params' }
                    ],
                    description: 'Открыть динамическую форму модально и дождаться результат',
                    type: 'method',
                    return: { type: 'object' }
                },
                unlockScreen: {
                    description: 'Разблокировать экран',
                    type: 'method'
                },
                lockScreen: {
                    params: [{ type: 'number', description: null, name: 'timeout' }],
                    description: 'Заблокировать экран',
                    type: 'method'
                },
                wait: {
                    params: [{ type: 'number', description: null, name: 'ms' }],
                    description: 'Ожидание в мс',
                    type: 'method'
                },
                executeCsbTask: {
                    params: [
                        { type: 'string', description: 'Тип задачи', name: 'recType' },
                        {
                            type: 'object',
                            description: 'Параметры задачи',
                            name: 'dataParams'
                        },
                        { type: 'number', description: null, name: 'ms' }
                    ],
                    description: 'Обращение к csb',
                    type: 'method',
                    return: { type: 'object' }
                },
                executeServerScript: {
                    params: [
                        {
                            type: 'string',
                            description: 'ownerGuid Владелец скрипта (cdo)',
                            name: 'ownerGuid'
                        },
                        {
                            type: 'string',
                            description: 'actionName Имя или guid скрипта',
                            name: 'actionName'
                        },
                        {
                            type: 'object',
                            description: 'Параметры задачи',
                            name: 'dataParams'
                        }
                    ],
                    description: 'Выполнить серверный скрипт cdo',
                    type: 'method',
                    return: { type: 'any' }
                },
                executeFormScript: {
                    params: [
                        {
                            type: 'string',
                            description: 'scriptName или guid скрипта',
                            name: 'scriptName'
                        },
                        {
                            type: 'object',
                            description: 'Параметры задачи',
                            name: 'dataParams'
                        }
                    ],
                    description: 'Выполнить серверный скрипт формы',
                    type: 'method',
                    return: { type: 'any' }
                },
                langString: {
                    params: [
                        {
                            type: 'string',
                            description: 'Ключ перевода (имя строки перевода)',
                            name: 'key'
                        },
                        {
                            type: 'any',
                            description: 'Любые числовые или строковые параметры',
                            name: '...args'
                        }
                    ],
                    description:
                        'Возвращает переведенную языковую константу из коллекции Lang strings формы. Если в константе присутсвую символы %s, то они последовательно заменяются на аргументы из args',
                    type: 'method',
                    return: { type: 'string' }
                }
            },
            description: null,
            type: 'class'
        }
    },
    objects: {
        Client: { type: 'object', dataType: 'ScriptClient' }
    }
};
