import React from 'react';

import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import { EventDataType } from 'components/Scheduler/interfaces';

const EventTemplate = (
    data: any,
    event: EventDataType,
    bgColor: string,
    isStart: boolean,
    isEnd: boolean
) => {
    const { title, color, type } = event;

    return (
        <div
            style={{
                height: 'calc(100% - 2px)',
                width: 'calc(100% - 2px)',
                margin: 1,
                backgroundColor: color || '#1274AC',
                opacity: type === 'exceptional' ? 0.5 : 1,
                borderBottomLeftRadius: isEnd ? 6 : 0,
                borderBottomRightRadius: isEnd ? 6 : 0,
                borderTopLeftRadius: isStart ? 6 : 0,
                borderTopRightRadius: isStart ? 6 : 0
            }}
        >
            <Grid container direction="row" paddingLeft={1} paddingTop={1} flexWrap="nowrap">
                {type !== 'exceptional' ? (
                    <Grid item>
                        <CircleIcon color="success" fontSize="small" />
                    </Grid>
                ) : null}
                <Grid item paddingLeft={2} overflow="hidden">
                    <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                        {title}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default EventTemplate;
