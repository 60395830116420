import React, { useState, useEffect, useMemo, useRef, FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Grid, Box } from '@mui/material';
import { TreeView } from '@mui/x-tree-view';
import { Edit as EditIcon, ExpandMore, ChevronRight } from '@mui/icons-material';

import { DatasetType } from 'forms/interfaces';

import formStyles from 'forms/form.module.scss';

import Loading from 'components/utils/Loading/Loading';

import ConfigurationStore from 'store/configurationStore';
import GlossaryStore from 'store/glossaryStore';

import glossStyles from './gloss.module.scss';
import { GlossElem, GlossElemObj } from './GlossElem';
import GlossEditor from './GlossEditor';
import Carriage from './Carriage';
import { addTextToField, getNextElem } from './gloss-utils';

import { GlossaryProps, ActionType, GlossaryKey } from './interfaces';

const Glossary: FunctionComponent<GlossaryProps> = observer(({ propContainer, formDescr }) => {
    const { content } = ConfigurationStore;
    const { activeCtrlDescr } = propContainer;

    const [glossKey, setGlossKey] = useState<GlossaryKey | null>(null);
    const [glossText, setGlossText] = useState<string>('');
    const [glossElems, setGlossElems] = useState<GlossElemObj[] | null>(null);
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const glossRef = useRef<HTMLElement | null>(null);
    const buttonsRef = useRef<HTMLDivElement | null>(null);

    const pages = useMemo(
        () => [
            { caption: content.controls.glossary.public, options: { isPersonal: 0, isProfile: 0 } },
            { caption: content.controls.glossary.private, options: { isPersonal: 1, isProfile: 0 } }
        ],
        []
    );

    const [activePage, setActivePage] = useState(0);

    useEffect(() => {
        if (activeCtrlDescr?.datasetName) {
            const ds = propContainer.dataStock.getDatasetObj(activeCtrlDescr.datasetName);

            if (ds) {
                const dsDescr = ds.descr;
                const { fieldName } = activeCtrlDescr;
                const fieldDescr = dsDescr.fields.find(f => f.name === fieldName);

                const reqBody = {
                    guid: ds.cdo?.guid || formDescr.guid,
                    datasetName: activeCtrlDescr.datasetName,
                    field: fieldDescr?.glossField || fieldName,
                    // isProfile, isPersonal =>
                    ...pages[activePage].options
                };

                const cachedGlossText = GlossaryStore.getGlossaryText(reqBody);

                if (cachedGlossText) {
                    setGlossKey(reqBody);
                    setGlossText(cachedGlossText);
                } else {
                    setGlossText('');
                    setLoading(true);

                    GlossaryStore.glossaryRequest(reqBody)
                        .then((text: string) => {
                            setGlossKey(reqBody);
                            setGlossText(text);
                        })
                        .catch(err => {
                            console.error(err.message);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                }
            }
        } else {
            setGlossElems([]);
        }
    }, [formDescr, activeCtrlDescr, pages, activePage]);

    useEffect(() => {
        const parseGloss = (text: string): GlossElemObj[] | undefined => {
            const res: any[] | null = [];

            if (!text) return;

            const elems: GlossElemObj[] = text
                .replace(/\r/gim, '')
                .split('\n')
                .map(line => {
                    let level = 0;
                    for (let i = 0; i < line.length; i++) {
                        if (line[i] === '\t') {
                            level++;
                        } else break;
                    }
                    return new GlossElemObj({ level, text: line.substring(level) });
                });

            if (elems.length > 0) {
                let curArr: any[] | null = res;
                elems[0].level = 0;
                curArr.push(elems[0]);
                let activeEl: GlossElemObj | null = elems[0];
                for (let i = 1; i < elems.length; i++) {
                    const el = elems[i];
                    if (el.level === activeEl.level) {
                        el.parent = activeEl.parent;
                    } else if (el.level > activeEl.level) {
                        el.parent = activeEl;
                        curArr = activeEl.children;
                    } else {
                        // el.level < activeEl.level
                        while (true) {
                            activeEl = activeEl.parent;
                            if (!activeEl?.parent) {
                                curArr = res;
                                break;
                            } else if (activeEl.level === el.level) {
                                el.parent = activeEl.parent;
                                curArr = activeEl.parent.children;
                                break;
                            }
                        }
                    }
                    curArr?.push(el);
                    activeEl = el;
                }
            }
            return res;
        };

        setGlossElems(parseGloss(glossText) ?? null);
    }, [glossText]);

    const clickGlossButton = (type: ActionType) => {
        const signs = {
            point: '.',
            comma: ',',
            enter: '\n'
        } as any;

        const sign = signs[type];

        if (sign) {
            addTextToField(activeCtrlDescr, propContainer, sign, false);
        } else if (type === 'forward' || type === 'backward') {
            const elDescr = getNextElem(
                formDescr,
                activeCtrlDescr.guid,
                type === 'forward' ? 1 : -1
            );

            if (elDescr) {
                const ctrl = document.getElementById(elDescr.guid) as HTMLInputElement;

                if (ctrl) {
                    ctrl.focus();
                    if (ctrl.value) {
                        ctrl.selectionStart = ctrl.value.length;
                        ctrl.selectionEnd = ctrl.value.length;
                    }
                }
            }
        }
    };

    const mouseOverHandler = (e: MouseEvent) => {
        const gloss = glossRef.current;
        const buttons = buttonsRef.current;

        if (gloss && buttons) {
            const rectGloss = gloss.getBoundingClientRect();
            const rectButtons = buttons.getBoundingClientRect();
            const x0 = e.clientX - rectGloss.left;
            const y0 = e.clientY - rectGloss.top;

            if (x0 > rectButtons.width) {
                let y1 = y0 - rectButtons.height / 2;
                // не выходим за нижнюю границу
                y1 =
                    y1 + rectButtons.height >= rectGloss.height
                        ? rectGloss.height - rectButtons.height - 2
                        : y1;
                // не выходим за верхнюю границу
                y1 = y1 >= 0 ? y1 : 0;
                buttons.style.top = `${y1}px`;
            }
        }

        e.stopPropagation();
    };

    function glossaryPages() {
        return pages.map((page, index) => (
            <>
                <Box
                    key={index}
                    className={[
                        formStyles.pageButtonCommon,
                        activePage === index
                            ? formStyles.pageButtonActive
                            : formStyles.pageButtonInactive
                    ].join(' ')}
                    onClick={() => setActivePage(index)}
                >
                    {page.caption}
                    <div className={formStyles.pageButtonUnderline} />
                </Box>
                <div className={formStyles.pageButtonDivider} />
            </>
        ));
    }

    const renderGlossaryList = () => {
        if (loading) return <Loading />;

        if (glossElems?.length) {
            return (
                <TreeView
                    disableSelection
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    sx={{ flexGrow: 1, paddingLeft: 0 }}
                >
                    {glossElems.map((elem, index) => (
                        <GlossElem
                            nodeId={`${index}`}
                            key={index}
                            propContainer={propContainer}
                            activeCtrlDescr={activeCtrlDescr}
                            elem={elem}
                        />
                    ))}
                </TreeView>
            );
        }

        return <Loading message={content.controls.data.noData} type="empty" />;
    };

    return (
        <>
            {isEditorOpen ? (
                <GlossEditor
                    glossKey={glossKey}
                    glossText={glossText}
                    setGlossText={setGlossText}
                    setIsEditorOpen={setIsEditorOpen}
                />
            ) : null}

            {!loading && glossElems?.length ? (
                <Carriage ref={buttonsRef} onClick={clickGlossButton} />
            ) : null}

            <Grid container direction="column" sx={{ height: '100%' }}>
                <Grid item width="100%" className={formStyles.pageButtonContainer}>
                    {glossaryPages()}
                    <Box
                        style={{
                            width: '24px',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            cursor: 'pointer'
                        }}
                        onClick={() => setIsEditorOpen(true)}
                    >
                        <EditIcon />
                    </Box>
                </Grid>
                <Grid
                    item
                    component="div"
                    onMouseOver={mouseOverHandler as any}
                    ref={glossRef as any}
                    className={glossStyles.gloss}
                >
                    {renderGlossaryList()}
                </Grid>
            </Grid>
        </>
    );
});

export default Glossary;
