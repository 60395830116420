import JSZip from 'jszip';

import { VariantType } from 'notistack';

import DataStock from 'dataObj/DataStock';
import CustomDataset from 'dataObj/customDataset';
import PropContainer from 'dataObj/PropContainer';

import ConfigurationStore from 'store/configurationStore';
import DataStore from 'store/dataStore';
import ReportStore from 'store/reportStore';
import ActionStore from 'store/actionStore';
import LookupStore from 'store/lookupStore';
import NotificationStore, { BackdropInstance } from 'store/notificationStore';
import RoutingStore from 'store/routingStore';

import { EditModeType, ParamType } from 'forms/interfaces';

import {
    saveFile,
    jsonFetch,
    dateToString,
    encryptRSA,
    checkGuid,
    goToPath,
    loadFile
} from 'utils/index';

import { getLangString } from 'utils/utils';

interface SetModalMessageParam {
    type: string;
    text: string;
}

interface CsbTaskParams {
    wait?: number;
    type: string;
    payload: any;
}

interface CsbTaskResult {
    error?: {
        code: string;
        text: string;
    };
    result?: any;
    id?: number;
    guid?: string;
    tag?: string;
}

type DynamicFormParams = {
    type: 'glossedit';
    dateformat: string;
    emptySkip?: boolean;
};

class ScriptClient {
    private params: ParamType[];
    private propContainer: PropContainer;
    private dataStock: DataStock;
    private values: any;
    private backdrop: BackdropInstance | null;
    private context: {
        setForm: (
            guid: null | string,
            dataStock?: DataStock,
            propContainer?: PropContainer
        ) => void;
        setFormDescr: (config: any) => void;
        setCallback: (cb: any) => void;
        setValues: (values: Array<{ [key: string]: string }> | undefined) => void;
        setEditMode: (mode: string | undefined) => void;
        setModalMessage: (param: SetModalMessageParam) => void;
        setCustomControl: (customControl: any, cb?: any) => void;
    };

    private content = ConfigurationStore.content;

    constructor(propContainer: PropContainer, values: any, context: any, params?: ParamType[]) {
        this.propContainer = propContainer;
        this.dataStock = propContainer.dataStock;
        this.params = params || [];
        this.values = values;
        this.backdrop = null;
        this.context = context;
    }

    fetchWrapper = async (...args: Parameters<typeof jsonFetch>) => {
        try {
            return await jsonFetch(...args);
        } catch (error: any) {
            NotificationStore.showAlert(error.message, this.content.resource.alert.errorTitle);

            return {
                error: {
                    code: '501',
                    text: error.message as string
                }
            };
        }
    };

    #getDataSet = (dsName: string): CustomDataset => this.dataStock.getDatasetObj(dsName);

    getFieldValue = (dsName: string, fieldName: string) =>
        this.#getDataSet(dsName).getFieldValue(fieldName);

    setFieldValue = (dsName: string, fieldName: string, value: string) => {
        const ds = this.#getDataSet(dsName);
        ds.setFieldValue(fieldName, value);
        ds.post(true);
    };

    postChanges = (dsName: string, options?: { keepInEdit: boolean }) =>
        this.#getDataSet(dsName).post(options?.keepInEdit);

    appendRecord = async (dsName: string) => {
        const ds = this.#getDataSet(dsName);

        if (ds) {
            ds.cdo && ds.currentDataChunk?.key >= 0 ? await ds.cdo.append(ds) : await ds.append();
        }
    };

    setPageBadge = (guid: string, value: number) => {
        this.propContainer.setPageBadge(guid, value);
    };

    openLookup = async (lookupName: string, options?: { append?: boolean }) => {
        const lookup = LookupStore.getLookup(lookupName);
        const ds = this.#getDataSet(lookup?.descr.datasetName);

        if (options?.append) {
            ds?.cdo ? await ds.cdo.append(ds) : await ds?.append();
        } else ds?.edit();

        return new Promise(resolve => {
            lookup?.actions.setOpen(true, () => (state: boolean) => {
                ds?.post(true);
                resolve(state);
            });
        });
    };

    getLookupData = (lookupName: string, recordNumber = 0) => {
        const lookup = LookupStore.getLookup(lookupName);

        if (!lookup) {
            return;
        }

        const calcLookupVal = () => {
            const { datasetName, fieldName } = lookup.descr;
            const lookupVal = this.#getDataSet(datasetName)?.getFieldValue(fieldName) || null;

            if (!lookupVal) {
                return null;
            }

            return Array.isArray(lookupVal) ? lookupVal : [lookupVal];
        };

        return LookupStore.getData(lookupName, calcLookupVal(), recordNumber);
    };

    getLookupDataValue = (lookupName: string, fieldName: string, recordNumber = 0) => {
        const lookupData = this.getLookupData(lookupName, recordNumber) as {
            [key: string]: any;
        } | null;

        return lookupData?.[fieldName];
    };

    getLookupDataArray = (lookupName: string) => {
        const lookup = LookupStore.getLookup(lookupName);

        if (!lookup) {
            return;
        }

        const { datasetName, fieldName } = lookup.descr;
        const lookupVal = this.#getDataSet(datasetName).getFieldValue(fieldName);

        LookupStore.getLookupData(lookupName, lookupVal);
    };

    selectRow = (dsName: string, value: string) => this.#getDataSet(dsName).findById(value);

    selectRowByOrder = async (dsName: string, recordNumber: number) => {
        await this.#getDataSet(dsName).setActiveRec(recordNumber);
    };

    getDatasetData = (dsName: string) => this.#getDataSet(dsName)?.data;

    getSelectedIDs = (dsName: string) => [...this.#getDataSet(dsName).selectedIDs];

    setSelectedIDs = (dsName: string, ids: number[]) => {
        const ds = this.#getDataSet(dsName);
        if (ds) {
            const olds = ds?.selectedIDs as number[];
            let all = [...ids, ...olds];
            all = Array.from(new Set(all));
            all.forEach(id => {
                ds.setSelectedField(id, ids.includes(id));
            });
        } else {
            console.error(`Dataset ${dsName} not found`);
        }
    };

    getFastFilter = (dsName: string, fieldName: string) => {
        const ff = this.#getDataSet(dsName)?.fastFilters as Record<string, any>;
        if (ff) {
            return ff[fieldName];
        }
    };

    setFastFilter = (dsName: string, flt: any) => {
        const fltObj = this.#getDataSet(dsName).fastFilters as Record<string, any>;
        if (flt.fieldName !== undefined && flt.operator !== undefined && flt.value !== undefined) {
            fltObj[flt.fieldName] = flt;
        } else console.error('Filter must be an object {fieldName, operator, value}');
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAF = (key: string): any => DataStore.AF.getAF(key, this.propContainer);

    setAF = (key: string, value: string) => DataStore.AF.setAF(key, value, {}, this.propContainer);

    refreshDataset = async (dsName: string) => {
        const ds = this.#getDataSet(dsName);

        const keepPosition = { info: { keepPosition: true } };
        ds.requestParams = { ...ds.requestParams, ...keepPosition };

        return ds.loadData();
    };

    reloadForm = () => {
        ActionStore.getFormActions(this.propContainer.guid)?.setForceReload();
    };

    saveData = async () => {
        const result = await ActionStore.getFormActions(this.propContainer.guid)?.save();

        if (result === false) return;

        this.reloadForm();
    };

    setData(dsName: string, data: object[]) {
        const dsObj = this.#getDataSet(dsName);
        if (!dsObj) {
            throw new Error(`не найден датасет ${dsName}`);
        }

        dsObj.setData(data);
    }

    getRecCount = (dsName: string) => this.#getDataSet(dsName).recCount;

    checkData = async () => {
        const errAccum = { err: [], warn: [] };

        await this.dataStock.checkData(this.propContainer, errAccum);

        if (errAccum.err.length || errAccum.warn.length) {
            NotificationStore.showAlert(`${errAccum.err.join('\n')}\n${errAccum.warn.join('\n')}`);

            return false;
        }

        return true;
    };

    /** Методы для получения параметров обьектов */
    getParam(name: string) {
        return (this.params || []).find((p: any) => p.name === name);
    }

    getParamValues() {
        return this.params?.length ? this.dataStock.getParams(this.params) : null;
    }

    /**
     * Получить значение параметра
     * @param name
     * @returns {any}
     */
    getParamValue(name: string) {
        const paramValues = this.getParamValues();
        return paramValues ? paramValues[name] : null;
    }

    // Работать умею только с константой
    setParamValue(name: string, value: any) {
        const param = this.getParam(name);
        if (param && param.paramType === 'const') {
            param.value = value;
        }
    }

    getValues() {
        return this.values;
    }

    showFormByGuid(
        guid: string,
        values?: Array<{ [key: string]: string }>,
        editMode?: string,
        isSubForm = false
    ) {
        return new Promise(resolve => {
            // Вернуть первое значение массива если оно задано, иначе state
            const callback = (state: boolean, ...vals: unknown[]) =>
                resolve(
                    state && Array.isArray(vals?.[1]) && vals?.[1]?.length && vals[1][0]
                        ? vals[1][0]
                        : state
                );

            this.context.setForm(
                guid,
                isSubForm ? this.dataStock : undefined,
                isSubForm ? this.propContainer : undefined
            );

            this.context.setCallback(() => callback);
            values && this.context.setValues(values);
            editMode && this.context.setEditMode(editMode);
        });
    }

    async showSubFormByGuid(
        guid: string,
        values?: Array<{ [key: string]: string }>,
        editMode?: string
    ) {
        return this.showFormByGuid(guid, values, editMode, true);
    }

    #getFormGuidByName(name: string): string {
        if (checkGuid(name)) return name;

        let guid: string | undefined = this.dataStock?.formDescr?.resourceLinks?.filter(
            (resource: any) => resource?.name === name
        )[0]?.resource?.code;

        if (guid) return guid;

        guid = this.dataStock?.formDescr?.subForms?.filter(
            (subFormDescr: any) => subFormDescr.name === name
        )[0]?.guid;

        return guid || '';
    }

    async showForm(name: any | string, values?: Array<{ [key: string]: string }>) {
        if (typeof name !== 'string') return this.context.setFormDescr(name);

        return this.showFormByGuid(this.#getFormGuidByName(name), values);
    }

    async showSubForm(name: string, values?: Array<{ [key: string]: string }>) {
        return this.showFormByGuid(this.#getFormGuidByName(name), values, undefined, true);
    }

    async modalMessage(type: string, text: string) {
        return this.context.setModalMessage({ type, text });
    }

    async showEditor(name: string, values?: Array<{ [key: string]: string }>) {
        return this.showFormByGuid(this.#getFormGuidByName(name), values, 'edit');
    }

    /**
     * Метод отображения формы создания CDO
     *
     * @param {string} dsName - датасет-источник создания
     * @param {string} guid - идентификатор вызываемой формы
     * @param extParamVals - внешние параметры формы
     */
    async showCreator(dsName: string, guid?: string, extParamVals?: { [key: string]: string }) {
        const ds = this.#getDataSet(dsName);
        const dsAction = ActionStore.getDatasetActions(ds.guid);

        return new Promise(resolve => {
            dsAction.setCreator(true, guid, extParamVals, this.propContainer, (state: boolean) =>
                resolve(state)
            );
        });
    }

    addToRoute(route: string) {
        RoutingStore.push(route, true);
    }

    swapFormExtended(form: {
        name: string;
        guid: string;
        extParamVals?: { [key: string]: string };
        editMode?: EditModeType;
    }) {
        const formActions = ActionStore.getFormActions(this.propContainer.guid);

        formActions.swapForm(form);
    }

    swapForm(guid: string, name?: string) {
        const mainDS = this.dataStock.getMainDataset();

        if (mainDS) {
            const extParamVals = {
                [mainDS.keyField]: mainDS.getFieldValue(mainDS.keyField)
            };

            const form = {
                guid: this.#getFormGuidByName(guid),
                name: name ?? guid,
                // editMode: 'view',
                extParamVals
            };

            this.swapFormExtended(form);
        }
    }

    showApiErrors() {
        NotificationStore.showApiErrors();
    }

    /**
     * пересчет формул свойств контролов
     * вызываем обработчик onFormShow(),
     * т.к. он обновляет все свойства контролов
     */
    async recalcFormulas() {
        await this.propContainer.formulaCalculator.onFormShow();
    }

    /**
     * Шифровать данные
     * @param {string} data Данные для шифрования
     * @param {string} key Открытый ключ
     * @returns {Promise<string>} base64 шифрованная строка
     */
    async encodeData(data: string, key: string) {
        return encryptRSA(key, data);
    }

    /**
     * Вывод сообщения
     * @param message
     * @param variant
     */
    notification(message: string, variant: VariantType = 'success') {
        NotificationStore.enqueueSnackbar({ message, options: { variant } });
    }

    /**
     * Вывод диалогового окна
     * @param {string} message
     * @returns {Promise<boolean>}
     */
    async confirmDialog(message: string) {
        return new Promise(resolve =>
            NotificationStore.showConfirmation(message, result => resolve(result))
        );
    }

    /**
     * Открыть динамическую форму модально и дождаться результат
     * @param {any} descr Описатель формы
     * @param {DynamicFormParams} params
     * @returns {Promise<any>}
     */
    async executeDynamicForm(descr: any, params: DynamicFormParams) {
        const getValues = (dataStock: DataStock) => {
            if (dataStock) {
                const { extraData } = dataStock.formDescr;
                const ds = dataStock.getDatasetObj('Cache');
                const extraDataObj =
                    extraData && typeof extraData === 'string' ? JSON.parse(extraData) : {};

                const { glossConfig } = extraDataObj;

                return (glossConfig || [])
                    .map((it: any) => {
                        const field = ds.getFieldDescr(it.fieldName);
                        const value = ds.getFieldValue(it.fieldName);

                        let str = '';
                        if (value) {
                            str = ['KRN_DATE', 'KRN_DATETIME'].includes(field.dataType)
                                ? dateToString(value, params.dateformat || 'DD.MM.YYYY')
                                : value;
                        }

                        return params.emptySkip && str === ''
                            ? ''
                            : `${it.before as string}${str}${it.after as string}`;
                    })
                    .join('');
            }

            return null;
        };

        const setForm = (formDescr: object, editMode: string, callback?: any) => {
            this.context.setFormDescr(formDescr);
            editMode && this.context.setEditMode(editMode);
            callback && this.context.setCallback(() => callback);
        };

        return new Promise(resolve =>
            setForm(descr, 'edit', (state: any, dataStock: any) => {
                if (params.type === 'glossedit') {
                    resolve(state ? getValues(dataStock) : state);
                } else {
                    resolve(state);
                }
            })
        );
    }

    getExtParam(name: string) {
        const extParamVals = this.dataStock?.extParamVals as any;
        return extParamVals ? extParamVals[name] : null;
    }

    async logout() {
        return ConfigurationStore.serverLogout();
    }

    lockScreen(timeout?: number) {
        if (this.backdrop) {
            this.backdrop.updateBackdrop(timeout);
        } else {
            this.backdrop = new BackdropInstance(timeout);
        }
    }

    unlockScreen() {
        this.backdrop?.clearBackdrop();
    }

    async wait(ms = 500) {
        this.lockScreen();
        return new Promise<void>(resolve => {
            setTimeout(() => {
                this.unlockScreen();
                resolve();
            }, ms);
        });
    }

    async executeCsbTask(
        recType: string,
        dataParams: CsbTaskParams,
        wait: number
    ): Promise<CsbTaskResult> {
        this.lockScreen(wait * 1000);

        const body = {
            taskType: 'csb',
            taskParams: { recType, wait },
            dataParams
        };

        try {
            const result = await jsonFetch(`task/csb`, 'POST', body, {}, 'json');
            return result as any;
        } catch (e: any) {
            return {
                error: {
                    code: '501',
                    text: e.message as string
                }
            };
        } finally {
            this.unlockScreen();
        }
    }

    /**
     * Выполнить серверный скрипт
     * @param ownerGuid Владелец скрипта (cdo)
     * @param actionName Имя или guid скрипта
     * @param params Набор параметров
     */
    async executeServerScript(ownerGuid: string, actionName?: string, parObj?: object) {
        const body = {
            guid: ownerGuid,
            actionName,
            // scriptGuid,
            parObj
        };

        return this.fetchWrapper('dataobject/execute', 'POST', body, {}, 'json');
    }

    /**
     * Выполнить BP
     * @param guid Владелец скрипта (cdo/form/package)
     * @param bptype_id
     * @param name Имя скрипта
     * @param parObj Набор параметров
     */
    async executeBusinessProcess(guid: string, bptype_id?: number, name?: string, parObj?: object) {
        const body = {
            guid,
            name,
            bptype_id,
            parObj
        };

        return this.fetchWrapper('dataobject/businessprocess', 'POST', body, {}, 'json');
    }

    /**
     * Удалить запись cdo
     * @param Идентификатор (cdo)
     * @param parObj Набор параметров
     */
    async deleteCdo(
        cdo: { cdotype_id?: number; guid?: string; semdType?: number } | number,
        __ID: number,
        parObj?: object
    ) {
        cdo = typeof cdo === 'number' ? { cdotype_id: cdo } : cdo;

        return this.fetchWrapper('dataobject/delete', 'POST', {
            ...cdo,
            parObj: { ...parObj, __ID }
        });
    }

    /**
     * Выполнить серверный скрипт формы
     * @param ownerGuid Владелец скрипта (форма)
     * @param actionName Имя или guid скрипта
     * @param params Набор параметров
     */
    async executeFormScript(scriptName?: string, parObj?: object) {
        const body = {
            formGuid: this.dataStock?.formDescr?.guid,
            scriptName,
            // scriptGuid,
            parObj
        };

        return this.fetchWrapper('script/calc', 'POST', body, {}, 'json');
    }

    async getCdoForm(cdotype_id: number, formType = 'creator') {
        const body = { cdotype_id, formType };

        return this.fetchWrapper('dataobject/form', 'POST', body, {}, 'json');
    }

    /**
     * Построить отчет по имени
     * @param reportName
     */
    async runReport(reportName: string) {
        const report = ReportStore.findReport(reportName);

        const reportCallback = async (state: boolean, vals?: any) =>
            state && ReportStore.runReport(reportName, this.getParamValues(), vals);

        if (report.useFilter) {
            return new Promise((resolve, reject) => {
                const promiseCallback = (state: boolean, vals?: any) => {
                    // Убрать форму фильтра
                    this.context.setCustomControl(undefined);

                    reportCallback(state, vals)
                        .then(res => resolve(res))
                        .catch(reason => reject(reason));
                };

                this.context.setCustomControl({
                    type: 'filterDialog',
                    props: { dataset: report, handleClose: promiseCallback }
                });
            });
        }

        return reportCallback(true);
    }

    getReportResult(reportName: string, index?: number) {
        return ReportStore.getReportResult(reportName, index);
    }

    getReportResultList(reportName: string) {
        return ReportStore.getReportResultList(reportName) || [];
    }

    saveReport(reportName: string, index?: number) {
        ReportStore.saveReport(reportName, index);
    }

    goToPath(path: string) {
        goToPath(path);
    }

    getJSZip() {
        return new JSZip();
    }

    fetch(...args: Parameters<typeof jsonFetch>) {
        return this.fetchWrapper(...args);
    }

    saveFile(...args: Parameters<typeof saveFile>) {
        saveFile(...args);
    }

    loadFile(...args: Parameters<typeof loadFile>) {
        return loadFile(...args);
    }

    langString = (key: string, ...args: (string | number)[]) =>
        getLangString(this.dataStock?.formDescr, key, args);
}

const executeScript = async (
    propContainer: PropContainer,
    actionName: string,
    senderParams?: ParamType[],
    values?: any,
    context?: any,
    event?: { [key: string]: any }
) => {
    if (!actionName) {
        return;
    }

    try {
        const objScript = propContainer.find(actionName, 'script');
        if (objScript?.func) {
            return objScript.func(
                new ScriptClient(propContainer, values, context, senderParams),
                event
            );
        }
    } catch (err: any) {
        console.error(err.message);
        alert(`Ошибка выполнения скрипта: ${err.message as string}`);
    }
};

export default executeScript;
