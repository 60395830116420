import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CircularProgress } from '@mui/material';

import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InboxIcon from '@mui/icons-material/Inbox';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

import styles from './Loading.module.scss';

interface PropsType {
    message?: string;
    type?: 'loading' | 'warning' | 'error' | 'broken' | 'empty';
    style?: { [key: string]: string };
    delay?: boolean;
    delayTime?: number;
    showEmpty?: boolean;
}

const Loading = (props: PropsType) => {
    const {
        message,
        type = 'loading',
        style,
        delay = false,
        delayTime = 2000,
        showEmpty = false
    } = props;

    const [show, setShow] = useState(!delay);

    const iconStyle = { marginRight: message ? 18 : 0 };

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (delay && type === 'loading') {
            setShow(false);
            timeoutId = setTimeout(() => setShow(true), delayTime);
        } else setShow(true);

        return () => {
            setShow(false);
            clearTimeout(timeoutId);
        };
    }, [type, delay, delayTime, message]);

    const setIcon = useCallback(() => {
        switch (type) {
            case 'loading':
                return <CircularProgress style={iconStyle} />;

            case 'warning':
                return <WarningIcon fontSize="large" style={iconStyle} color="warning" />;

            case 'error':
                return <ErrorIcon fontSize="large" style={iconStyle} color="warning" />;

            case 'broken':
                return <BrokenImageIcon fontSize="large" style={iconStyle} color="warning" />;

            case 'empty':
                return showEmpty ? (
                    <InboxIcon fontSize="large" style={iconStyle} color="action" />
                ) : null;

            default:
                return null;
        }
    }, [type]);

    const icon = useMemo(() => setIcon(), [type]);

    useEffect(() => {
        !icon && setShow(false);
    }, [icon, setShow]);

    return show ? (
        <div className={styles.loading_wrapper} style={style}>
            <div>
                {icon}
                {message && icon && (
                    <Typography variant="h6" component="div">
                        {message}
                    </Typography>
                )}
            </div>
        </div>
    ) : null;
};

export default Loading;
