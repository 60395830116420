const langFra = {
    loading: 'Chargement',
    refreshList: 'Rafraîchir la liste',
    importEditor: `Importer l'éditeur`,
    loadReqFields: 'Charger les champs et les paramètres de requête',
    loadDSColumns: 'Charger les colonnes à partir du Dataset',
    loadDSEdit: 'Charger les champs à partir du Dataset',
    clearNestedControls: 'Supprimer les contrôles imbriqués',
    resSelect: 'Sélection de la ressource',
    needSelect: 'Sélectionnez une ressource',
    guidEditor: 'Modification du GUID',
    alert: {
        idRequest: 'ID de requête non spécifié',
        missingDS: "Dataset spécifié n'est pas trouvé",
        specDS: 'Dataset source non spécifié',
        statReNotExist: (name: string): string =>
            `Erreur d'importation ! Рossible que la requête statistique ${name} n'existe pas !` as any,
        nameNotSpec: 'Nom de la requête statistique non spécifié !',
        cantImport: 'La ressource ne peut pas être importée',
        needSave: 'Les modifications doivent être enregistrées ou annulées !',
        guidChange:
            "Attention! La modification du guid d'un élément peut avoir des conséquences irréparables!",
        errorTitle: 'Erreur!',
        guidErrorMsg: 'Format guid incorrect!'
    },
    dialog: {
        title: "Assistant d'insertion de champ",
        chooseDS: 'Choisissez DataSet',
        chooseFields: 'Choisissez les champs',
        exportLinkedResource: 'Exporter les ressources associées?'
    },
    clearList: {
        title: 'Attention!',
        content: 'Voulez-vous vraiment supprimer tous les contrôles imbriqués?'
    },
    resList: {
        groupStore: {
            all: 'Tous les objets',
            wuiForm: 'Formes',
            subForm: 'Formulaires enfants',
            wuiQuery: 'Requêtes SQL',
            wuiExtQuery: 'Requêtes externes',
            wuiStatQuery: 'Requêtes statistiques',
            cdo: 'CDO',
            table: 'Tables',
            settings: 'Réglages',
            wuiRouter: 'Routage',
            wuiIconSet: `Jeu d'icônes`,
            wuiApplication: `paramètres de l'application`,
            wuiGroup: 'Groupe de ressources',
            wuiPackage: 'Paquet de scripts',
            inheritor: 'Ressource héritière'
        },
        show: 'Afficher',
        type: 'Type',
        searchLabel: 'Nom / GUID',
        searchTag: '#Tag',
        fromDate: 'Changements depuis la date'
    },
    resProps: {
        property: 'Attribut',
        value: 'Valeur',
        saving: 'Conservation',
        refresh: 'Mise à jour'
    },
    buttons: {
        ok: 'Ok',
        cancel: 'Annuler',
        add: 'Ajouter',
        next: 'Prochain',
        back: 'Arrière',
        refresh: 'Rafraîchir',
        close: 'Fermer',
        create: 'Créer',
        save: 'Enregistrer',
        undo: 'Annuler',
        copy: 'Copie',
        cut: 'Couper',
        paste: 'Coller',
        delete: 'Delete',
        clear: 'Effacer',
        pasteFromDataset: 'Insérer des champs à partir du DataSet',
        export: 'Exporter',
        import: 'Importer',
        load: 'Télécharger',
        open: 'Ouvrir',
        changeLocale: 'Langue',
        editor: 'Éditeur',
        guid: 'Modifier GUID',
        generate: 'Générer un nouveau guid',
        resetCache: 'Réinitialiser le cache',
        getDescriptor: 'Exportation de descripteur',
        homePage: "Aller à la page d'accueil",
        search: 'Recherche',
        upward: 'Soulever',
        downward: 'Omettre',
        updateResource: 'Mettre à jour la ressource',
        selectAll: 'Sélectionner tout',
        clearAll: 'Décocher'
    },
    scriptEditor: {
        title: 'Éditeur de code',
        change: 'Modifier',
        apply: 'Adopter',
        cancel: 'Annuler',
        options: 'Options de calcul',
        asDefault: 'valeur par défaut',
        asValue: 'valeur calculée'
    },
    iconEditor: {
        title: "Éditeur d'icônes",
        preview: 'Aperçu',
        fontSize: 'Taille de la police'
    },
    gridProps: {
        caption: 'Éditeur de propriétés de grille',
        selectFields: 'Sélection des champs',
        tabs: {
            cols: 'Colonnes',
            fastFilter: 'Filtre rapide',
            sort: 'Tri',
            group: 'Regroupement',
            aggregation: 'Agrégation',
            toolbar: 'Barre',
            settings: 'Réglage'
        },
        columns: {
            caption: { headerName: 'Nom', description: 'Nom du champ' },
            resource: { headerName: 'Ressource', description: 'Ressource' },
            direction: { headerName: 'Tri', description: 'Paramètres de tri des champs' },
            visible: { headerName: 'Visibilité', description: 'Visibilité du champ' },
            align: { headerName: 'Alignement', description: 'Alignement horizontal' },
            width: { headerName: 'Ширина', description: 'Largeur du champ spécifié' },
            fixWidth: {
                headerName: 'Фиксация',
                description: 'Fixation de la largeur du champ spécifié'
            },
            sortable: { headerName: 'Сортировка', description: 'Trier par champ spécifié' },
            func: {
                headerName: "Fonction d'agrégation",
                description: "Paramètres de la fonction d'agrégation"
            },
            buttons: {
                caption: { headerName: 'Nom', description: 'Nom du champ' },
                type: {
                    headerName: 'Type de bouton',
                    description: "Sélection d'une action de base pour un bouton"
                },
                variant: {
                    headerName: "Option d'affichage",
                    description: "Option d'affichage du bouton"
                },
                color: {
                    headerName: 'Couleur des icônes',
                    description: 'Couleur du bouton en mode icône'
                },
                iconSet: {
                    headerName: 'Icon set',
                    description: "Jeu d'icônes contenant une icône pour un bouton"
                },
                icon: {
                    headerName: 'Icône',
                    description: 'Icône de gauche pour le bouton'
                },
                script: {
                    headerName: 'Script',
                    description: 'Script exécuté lorsque vous cliquez sur un bouton'
                }
            }
        },
        alignText: {
            left: 'gauche',
            right: 'droite',
            center: 'centre',
            justify: 'largeur'
        },
        sortModeText: {
            asc: 'croissant',
            desc: 'décroissant',
            deny: 'interdit de changer',
            none: ''
        },
        aggMode: {
            sum: 'Montant',
            avg: 'Moyenne',
            min: 'Moins',
            max: 'Maximal',
            count: 'Nombre'
        },
        configuration: {
            hideRefresh: 'Désactiver le bouton Actualiser',
            hideToolbar: "Masquer la barre d'outils"
        }
    },
    cdoProps: {
        caption: 'Éditeur de propriétés CDO',
        selectDataset: 'Dataset',
        selectFields: 'Sélection des champs',
        tabs: {
            fields: 'Champs',
            groups: 'Groupes de champs'
        },
        columns: {
            caption: { headerName: 'Champ', description: 'Nom du champ' },
            dataset: { headerName: 'Dataset', description: 'Nom du DataSet' },
            group: { headerName: 'Groupe', description: 'Nom du groupe' },
            groupCaption: { headerName: 'En-tête', description: 'Titre du groupe affiché' },
            groupFields: {
                headerName: 'Champs de groupe',
                description: 'Liste des champs du groupe'
            },
            width: { headerName: 'Largeur', description: 'Largeur de contrôle' }
        }
    },
    routerProps: {
        caption: 'Éditeur de routeur',
        columns: {
            caption: { headerName: 'Nom', description: 'Nom du routeur' },
            route: { headerName: 'Route', description: 'Routeur' },
            resource: { headerName: 'Resource', description: 'Resource' },
            viewMode: {
                headerName: "Mode d'affichage",
                description: "Mode d'affichage du formulaire"
            },
            editMode: { headerName: 'Mode édition', description: 'Modifier le mode de formulaire' }
        }
    },
    snackbars: {
        operation: {
            success: 'Opération terminée avec succès'
        },
        save: {
            success: 'La ressource a été enregistrée avec succès',
            error: "Erreur lors de l'enregistrement d'une ressource"
        },
        import: {
            success: "L'importation de la ressource a réussi",
            error: "Erreur lors de l'importation de la ressource",
            fields: (count: number): string =>
                count ? `${count} сhamps ajoutés` : `Aucun champ à ajouter`,
            params: (count: number): string =>
                count > 0 ? `${count} paramètres ajoutés` : `${count * -1} paramètres supprimés`,
            columns: (count: number): string =>
                count ? `${count} colonnes ajoutées` : `Aucune colonne à ajouter`,
            filters: (count: number) =>
                count > 0 ? `${count} filtres ajoutés` : `${count * -1} filtres supprimés`
        },
        details: 'Détails'
    },
    translation: {
        fieldName: 'Nom du champ',
        baseValue: 'Traduction de base',
        exists: 'Un champ avec ce nom existe déjà'
    }
};

export default langFra;
