import React, { useState, useEffect, FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import NotificationStore from '../../store/notificationStore';

const BackdropPanel: FunctionComponent = observer(() => {
    const { backdrop } = NotificationStore;
    const [showProgress, setShowProgress] = useState(false);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (backdrop.length) {
            timer = setTimeout(() => setShowProgress(true), 1500);
        } else {
            setShowProgress(false);
        }
        return () => {
            timer && clearTimeout(timer);
        };
    }, [backdrop.length]);

    return (
        <Backdrop
            open={backdrop.length > 0}
            sx={{ color: '#c9c9c9', zIndex: theme => theme.zIndex.modal + 1 }}
        >
            {showProgress && <CircularProgress color="inherit" size={60} />}
        </Backdrop>
    );
});

export default BackdropPanel;
