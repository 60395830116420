import { FunctionComponent, useState } from 'react';

import { ModelType, ScheduleType } from './interfaces';

import Toolbar from './Toolbar';
import Container from './SchedulerContainer';

const Schedule: FunctionComponent<ScheduleType> = ({
    model,
    dayStartFrom,
    dayStopTo,
    data = [],
    initType = 'day',
    controls = true,
    fitSlots,
    eventItemClick,
    newEvent,
    eventItemTemplateResolver
}) => {
    const [type, setType] = useState(initType);

    const [currentModel, setCurrentModel] = useState<ModelType>(
        Array.isArray(model) ? model[0] : model
    );
    const [subject, setSubject] = useState(currentModel.subjects[0]);

    const handleSubject = (subjId: string) => {
        setSubject(
            currentModel.subjects.find(subj => subj.id === Number(subjId)) ||
                currentModel.subjects[0]
        );
    };

    const handleModel = (modelId: string) => {
        if (Array.isArray(model)) {
            const curModel = model.find(m => m.id === Number(modelId)) || model[0];

            setCurrentModel(curModel);
            setSubject(curModel.subjects[0]);
        }
    };

    const eventItemClickHandler = (event: any) => {
        eventItemClick && eventItemClick(data, event);
    };

    const newEventHandler = (
        slotId: string | number,
        ownerId: string | number,
        slotName: string,
        start: string,
        end: string,
        eventType?: string
    ) => {
        if (newEvent) {
            newEvent(data, slotId, ownerId, slotName, start, end, eventType);
        }
    };

    return (
        <div style={{ height: '100%' }}>
            <Toolbar
                type={type}
                setType={setType}
                model={currentModel}
                models={Array.isArray(model) ? model : [model]}
                subject={subject}
                subjects={currentModel.subjects || []}
                setModel={handleModel}
                setSubject={handleSubject}
            />
            <Container
                type={type}
                model={currentModel}
                dayStartFrom={dayStartFrom || currentModel.start}
                dayStopTo={dayStopTo || currentModel.end}
                subject={subject}
                data={data}
                controls={controls}
                fitSlots={fitSlots}
                setSubject={handleSubject}
                eventClickedHandler={eventItemClickHandler}
                newEventHandler={newEventHandler}
                eventItemTemplateResolver={eventItemTemplateResolver}
            />
        </div>
    );
};

export default Schedule;
