import React, { ReactNode } from 'react';

import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';

interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{ [key: string]: any }>, newValue: number) => void;
    children?: ReactNode;
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span /> }}
        variant="scrollable"
        scrollButtons="auto"
    />
))(({ theme }) => ({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        transition:
            'left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '& > span': {
            width: '100%',
            backgroundColor: '#F57069'
        }
    }
}));

export default StyledTabs;
