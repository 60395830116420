import { FunctionComponent, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { InputAdornment, TextField } from '@mui/material';

import { ResourceClass } from 'store/resourceStore';

import SelectOverlap from './SelectOverlap';
import { InheritHookType } from '../interfaces';

interface PropsType {
    name: string;
    value: string;
    inheritor: InheritHookType;
    onChange: any;
    onSelect: any;
}

const ResOverlap: FunctionComponent<PropsType> = observer(
    ({ inheritor, name, value, onChange, onSelect }) => {
        const {
            ancestorGuid,
            loading,
            error,
            notFound,
            setNotFound,
            helperText,
            setHelperText,
            overlapResource,
            setOverlapResource
        } = inheritor;

        const applyOverlapResource = useCallback(
            (ancestorResStore: ResourceClass, val: string) => {
                const openedTree = ancestorResStore.selectCtrlBy('guid', val);

                if (openedTree) {
                    ancestorResStore.setOpenedTree(openedTree);

                    setOverlapResource(ancestorResStore);

                    setNotFound(false);
                    setHelperText(
                        `${ancestorResStore?.curCtrl?.type as string}: ${
                            ancestorResStore?.curCtrl?.name as string
                        }`
                    );
                } else {
                    setNotFound(true);
                    setHelperText('Resource not found');
                }
            },
            [value]
        );

        useEffect(() => {
            if (ancestorGuid && overlapResource) {
                applyOverlapResource(overlapResource, value);
            }
        }, [value, overlapResource]);

        return (
            <div>
                <TextField
                    size="small"
                    fullWidth
                    helperText={helperText}
                    name={name}
                    value={value || ''}
                    onChange={onChange}
                    disabled={!ancestorGuid}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SelectOverlap
                                    loading={loading}
                                    error={error}
                                    notFound={notFound}
                                    onSelect={onSelect}
                                    resource={overlapResource}
                                />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
        );
    }
);

export default ResOverlap;
