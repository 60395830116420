import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { ViewTypes } from 'react-big-scheduler';

import Week from './Week';
import Month from './Month';
import Day from './Day';
import Year from './Year';

interface PropsType {
    date: any;
    setDate: any;
    schedulerData: any;
    handleClose: any;
}

const PeriodCalendar: FunctionComponent<PropsType> = observer(
    ({ date, setDate, schedulerData, handleClose }) => {
        const { viewType } = schedulerData;

        const props = {
            date,
            setDate,
            handleClose
        };

        const getCalendar = () => {
            switch (viewType) {
                case ViewTypes.Week:
                    return <Week {...props} />;

                case ViewTypes.Month:
                case ViewTypes.Quarter:
                    return <Month {...props} />;

                case ViewTypes.Year:
                    return <Year {...props} />;

                default:
                    return <Day {...props} />;
            }
        };

        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>{getCalendar()}</LocalizationProvider>
        );
    }
);

export default PeriodCalendar;
