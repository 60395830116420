import React, { useState, useEffect, FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import useDBEdit from 'hooks/dbEdit';
import { getLocalizedString } from 'utils/index';

import { ControlDataRowType, DBCheckBoxType } from 'forms/interfaces';

import { CssCheckBox, CssFormControlLabel } from '../StyledCtrl';

interface PropsType extends ControlDataRowType {
    descr: DBCheckBoxType;
    index?: number;
}

const DBCheckBox: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, dataRow, index }) => {
        const {
            dataset,
            fieldName,
            dataVal,
            dataType,
            ctrlEnabled,
            ctrlVisible,
            ctrlColorToken,
            fieldRequired,
            onScreen
        } = useDBEdit(descr, propContainer, dataRow);

        const [value, setValue] = useState(() => dataVal);

        const label = useMemo(() => getLocalizedString(descr.label), [descr.label]);

        useEffect(() => {
            if (dataset) setValue(dataVal);
        }, [dataset, dataVal]);

        const inputChangeHandler = (event: any) => {
            if (dataset?.inEdit) {
                // Новое значение
                const val = event.target.checked;
                // Запоминаем старое значение
                const oldValue = value;

                // Обновляем значение
                setValue(val);

                dataset
                    .trySetFieldValue(
                        fieldName,
                        dataType === 'KRN_LOGICAL' ? Number(val) : val,
                        index
                    )
                    .then(allowed => {
                        // Восстанавливаем старое значение, если изменение недопустимо
                        if (!allowed) setValue(oldValue);
                    })
                    .catch(err => console.error(err.message));
            }
        };

        return (
            <Box
                sx={{
                    width: '100%',
                    p: 2,
                    boxSizing: 'border-box',
                    visibility: ctrlVisible ? 'inherit' : 'hidden'
                }}
                className={fieldRequired && onScreen && ctrlEnabled ? 'required-field' : ''}
            >
                <CssFormControlLabel
                    control={
                        <CssCheckBox
                            size="small"
                            color={ctrlColorToken}
                            onChange={inputChangeHandler}
                            checked={value}
                            disabled={!ctrlEnabled || !dataset?.inEdit}
                        />
                    }
                    disabled={!ctrlEnabled || !dataset?.inEdit}
                    label={label}
                    sx={{ pl: 2, color: ctrlColorToken ? `${ctrlColorToken}.main` : 'inherit' }}
                />
            </Box>
        );
    }
);

export default DBCheckBox;
