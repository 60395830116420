import React, { useState, useEffect, FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import useDBEdit from 'hooks/dbEdit';
import useLookupData from 'hooks/lookupData';
import { getLocalizedString } from 'utils/index';

import ComboBox from 'components/Inputs/ComboBox';

import { ControlDataRowType, DBComboBoxType, DBLookupComboboxType } from 'forms/interfaces';

interface PropsType extends ControlDataRowType {
    descr: DBComboBoxType | DBLookupComboboxType;
    index?: number;
}

const DBComboBox: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, dataRow, index }) => {
        const {
            dataset,
            fieldName,
            dataVal,
            dataType,
            fieldRequired,
            ctrlEnabled,
            ctrlVisible,
            editRef,
            onScreen
        } = useDBEdit(descr, propContainer, dataRow);
        const lookupData = useLookupData(descr, dataset, propContainer);

        const [value, setValue] = useState<string | string[] | null>(() => dataVal);

        const label = useMemo(() => getLocalizedString(descr.label), [descr.label]);

        useEffect(() => {
            if (dataset) setValue(dataVal);
        }, [dataset, dataVal]);

        useEffect(() => {
            propContainer.setPageRequirement(descr.name, fieldRequired && ctrlEnabled && !value);
        }, [value, fieldRequired, ctrlEnabled]);

        const handleOnChange = (event: any) => {
            if (dataset?.inEdit) {
                // Новое значение
                const val = event.target.value;
                // Запоминаем старое значение
                const oldValue = value;

                // Обновляем значение
                setValue(val);

                dataset
                    .trySetFieldValue(
                        fieldName,
                        dataType === 'KRN_INTEGER' ? Number(val) : val,
                        index
                    )
                    .then(allowed => {
                        // Восстанавливаем старое значение, если изменение недопустимо
                        if (!allowed) setValue(oldValue);
                    })
                    .catch(err => console.error(err.message));
            }
        };

        const handleOnConfirm = (event: { target: { name?: string; value?: string[] } }) => {
            const val = event.target.value;

            if (dataset?.inEdit && dataType === 'KRN_ARRAY') {
                dataset
                    .trySetFieldValue(fieldName, val, index)
                    .catch(err => console.error(err.message));
            }
        };

        return (
            <Box
                ref={editRef}
                sx={{ width: '100%', p: 2, boxSizing: 'border-box' }}
                className={fieldRequired && onScreen && ctrlEnabled ? 'required-field' : ''}
            >
                <ComboBox
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        visibility: ctrlVisible ? 'inherit' : 'hidden'
                    }}
                    size="small"
                    store={lookupData}
                    label={label}
                    nameField={descr.nameField}
                    valueField={descr.valueField}
                    variant="outlined"
                    disabled={!ctrlEnabled}
                    onChange={handleOnChange}
                    onConfirm={handleOnConfirm}
                    value={value !== null ? value : ''}
                    clearable={descr.clearable}
                    editable={descr.editable}
                    multiselect={descr.multiselect}
                    required={fieldRequired}
                />
            </Box>
        );
    }
);

export default DBComboBox;
