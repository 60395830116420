import { useState, useEffect, useRef, useCallback } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Grid, Typography } from '@mui/material';

import usePrevious from 'hooks/usePrevious';
import useNodeResize from 'hooks/nodeResize';

import ConfigurationStore from 'store/configurationStore';

import DRAW_MODE from './constants';
import CanvasDrawToolbar from './CanvasDrawToolbar';
import CanvasDrawShape from './CanvasDrawShape';

interface PropsType {
    content?: any | undefined;
    wrapperHeight: number;
    onSaveHandler: (value: string) => void;
}

const CanvasDrawPane = (props: PropsType) => {
    const { content, wrapperHeight, onSaveHandler } = props;
    const {
        content: {
            controls: { canvasDraw }
        }
    } = ConfigurationStore;

    const wrapperRef = useRef(null) as any;
    const stageRef = useRef(null) as any;
    const toolbarRef = useRef(null) as any;
    const fileUploadRef = useRef(null) as any;
    const imageRef = useRef(null) as any;

    const [imageDraw, setImageDraw] = useState<HTMLImageElement>();
    const [canvasDimensions, setCanvasDimensions] = useState({ height: 0, width: 0 });
    const [canvasScale, setCanvasScale] = useState<number>(1);
    const [selectedId, setSelectedId] = useState<string | undefined>();
    const [mode, setMode] = useState<string | undefined>(DRAW_MODE.IMAGE);
    const [drawNotes, setDrawNotes] = useState<any[]>([]);
    const [newDrawNotes, setNewDrawNotes] = useState<any[]>([]);
    const [undo, setUndo] = useState<any>([]);
    const [redo, setRedo] = useState<any>([]);
    const [isUndoRedoRecords, setIsUndoRedoRecords] = useState(false);

    const [wrapperWidth] = useNodeResize(wrapperRef);
    const prevDrawNotes: any = usePrevious(drawNotes);

    const addStepToUndo = useCallback(() => {
        if (isUndoRedoRecords) setIsUndoRedoRecords(false);
        const past = cloneDeep(undo);
        const present = cloneDeep(drawNotes);
        past.push(present);
        setUndo(past);
    }, [undo, drawNotes, isUndoRedoRecords]);

    const onLoadImage = (data: string | ArrayBuffer | null, handleLoad: boolean) => {
        if (!data) return;

        const img = new (window as any).Image();
        img.src = data;
        img.onload = function () {
            const scaleFactor = Math.min(
                canvasDimensions.width / img.width,
                canvasDimensions.height / img.height
            );
            img.setAttribute('width', img.width * scaleFactor);
            img.setAttribute('height', img.height * scaleFactor);

            if (handleLoad) {
                const uploadedImage = {
                    element: mode,
                    data,
                    scaleFactor,
                    id: drawNotes.length > 0 ? (drawNotes.length + 1).toString() : '1'
                };
                drawNotes.push(uploadedImage);
            }

            setImageDraw(img);
            setMode(undefined);
            imageRef.current.cache();
        };
    };

    useEffect(() => {
        if (wrapperRef.current && toolbarRef.current) {
            setCanvasDimensions({
                height: wrapperRef.current.offsetHeight - toolbarRef.current.offsetHeight,
                width: wrapperRef.current.offsetWidth
            });
        }
    }, []);

    useEffect(() => {
        if (wrapperWidth) {
            const scale = canvasDimensions.width > 0 ? wrapperWidth / canvasDimensions.width : 1;
            if (wrapperWidth >= canvasDimensions.width) {
                setCanvasScale(1);
                return;
            }
            setCanvasScale(scale);
        }
    }, [wrapperWidth, canvasDimensions]);

    useEffect(() => {
        if (imageDraw) {
            setCanvasDimensions({
                height: imageDraw.height,
                width: imageDraw.width
            });
            addStepToUndo();
        }
    }, [imageDraw]);

    useEffect(() => {
        if (drawNotes.length > 0 && !isEqual(prevDrawNotes, drawNotes) && !isUndoRedoRecords) {
            addStepToUndo();
        }
    }, [drawNotes, prevDrawNotes]);

    useEffect(() => {
        if (content) {
            const contentFromJson = JSON.parse(content);
            const imageShape = contentFromJson.find(
                (shape: any) => shape.element === DRAW_MODE.IMAGE
            );
            if (imageShape) {
                onLoadImage(imageShape.data, false);
            }
            setDrawNotes(contentFromJson);
        }
    }, [content]);

    const handleSetMode = (drawMode: string) => {
        setMode(drawMode);
    };

    const handleDrawImage = () => {
        handleSetMode(DRAW_MODE.IMAGE);
        fileUploadRef.current.click();
        if (imageDraw) setImageDraw(undefined);
        setCanvasDimensions({
            height: wrapperRef.current.clientHeight - toolbarRef.current.clientHeight,
            width: wrapperRef.current.clientWidth
        });
    };

    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        const fileReader = new FileReader();
        if (file) fileReader.readAsDataURL(file);

        fileReader.onload = function () {
            fileUploadRef.current.value = null;

            onLoadImage(fileReader.result, true);
        };
    };

    const onSelect = (id: string | undefined) => {
        setSelectedId(id);
    };

    const handleMouseDown = (event: any) => {
        if (!selectedId && newDrawNotes.length === 0) {
            const { x, y } = event.target.getStage().getRelativePointerPosition();
            if (mode === DRAW_MODE.RECTANGLE) {
                setNewDrawNotes([{ element: mode, x, y, width: 0, height: 0, id: '1' }]);
            }
            if (mode === DRAW_MODE.CIRCLE) {
                setNewDrawNotes([{ element: mode, x, y, radius: 0, id: '1' }]);
            }
            if (mode === DRAW_MODE.PENCIL) {
                setNewDrawNotes([{ element: mode, points: [x, y], id: '1' }]);
            }
        }
    };

    const handleMouseMove = (event: any) => {
        if (!selectedId && newDrawNotes.length === 1) {
            const sx = newDrawNotes[0].x;
            const sy = newDrawNotes[0].y;
            const { x, y } = event.target.getStage().getRelativePointerPosition();
            const id = drawNotes ? drawNotes.length + 1 : 1;

            if (y - sy <= 10 || x - sx <= 10) return;

            if (mode === DRAW_MODE.RECTANGLE) {
                setNewDrawNotes([
                    {
                        element: mode,
                        x: sx,
                        y: sy,
                        width: x - sx,
                        height: y - sy,
                        id: id.toString(),
                        fill: 'transparent',
                        stroke: '#DF4B26',
                        strokeWidth: 3
                    }
                ]);
            }

            if (mode === DRAW_MODE.CIRCLE) {
                setNewDrawNotes([
                    {
                        element: mode,
                        x: sx,
                        y: sy,
                        radius: y - sy,
                        id: id.toString(),
                        fill: 'transparent',
                        stroke: '#DF4B26',
                        strokeWidth: 3
                    }
                ]);
            }

            if (mode === DRAW_MODE.PENCIL) {
                const points = newDrawNotes[0]?.points;
                setNewDrawNotes([
                    {
                        element: mode,
                        // closed: true, // create polygon
                        // tension: 0.3, // closed & tension create blob line
                        points: [...points, x, y],
                        id: id.toString(),
                        stroke: '#DF4B26',
                        strokeWidth: 8
                    }
                ]);
            }
        }
    };

    const handleMouseUp = (event: any) => {
        if (!selectedId && newDrawNotes.length === 1) {
            const verifiableNote = newDrawNotes[0];
            const isCorrectNote =
                (verifiableNote?.width > 0 && verifiableNote?.height > 0) ||
                verifiableNote?.radius > 0 ||
                verifiableNote?.points?.length > 0;
            if (isCorrectNote) {
                drawNotes.push(...newDrawNotes);
                setDrawNotes(drawNotes);
                addStepToUndo();
            }
            setNewDrawNotes([]);
        }
    };

    const handleMouseEnter = (event: any) => {
        event.target.getStage().container().style.cursor = 'crosshair';
    };

    const handleDeleteShape = () => {
        if (drawNotes && selectedId) {
            const filteredDrawNotes = drawNotes.filter(note => note.id !== selectedId);
            setDrawNotes(filteredDrawNotes);
            setSelectedId(undefined);
            addStepToUndo();
        }
    };

    const handleDownloadImage = (data: any, name: string) => {
        const link = document.createElement('a');
        link.download = name;
        link.href = data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleSaveAsImage = () => {
        setSelectedId(undefined);
        // Даем время на перерисовку холста после снятия выделения с элемента
        setTimeout(() => {
            const dataURL = stageRef.current.toDataURL();
            handleDownloadImage(dataURL, 'canvas.jpeg');
        }, 100);
    };

    const handleSave = () => {
        const data = JSON.stringify(drawNotes);
        onSaveHandler(data);
    };

    const handleUndoShape = () => {
        const history = cloneDeep(undo);
        const lastStep = history.pop();
        const beforeLastStep = history[history.length - 1];

        if (lastStep && beforeLastStep) {
            setIsUndoRedoRecords(true);
            setUndo(history);
            setDrawNotes(beforeLastStep);
            redo.push(lastStep);
            setRedo(redo);
        }
    };

    const handleRedoShape = () => {
        const lastStep = redo[redo.length - 1];
        if (lastStep) {
            setIsUndoRedoRecords(false);
            setDrawNotes(lastStep);
        }
        redo.pop();
        setRedo(redo);
    };

    const notes = [...drawNotes, ...newDrawNotes];

    const toolbarActionsProps = {
        handleSetMode,
        handleDeleteShape,
        handleUndoShape,
        handleRedoShape,
        handleDrawImage,
        handleSaveAsImage,
        handleSave
    };

    return (
        <Grid
            ref={wrapperRef}
            container
            className="canvas-draw"
            direction="column"
            wrap="nowrap"
            sx={{
                height: wrapperHeight || 0,
                width: '100%',
                overflow: 'auto'
            }}
        >
            <Grid
                ref={toolbarRef}
                item
                className="canvas-draw__toolbar"
                sx={{ textAlign: 'center', width: '100%' }}
            >
                <CanvasDrawToolbar
                    mode={mode}
                    selected={selectedId}
                    disabledUndo={undo.length <= 1}
                    disabledRedo={redo.length === 0}
                    disabledSave={drawNotes.length <= 1}
                    {...toolbarActionsProps}
                />
            </Grid>
            <Grid item container className="canvas-draw__container" sx={{ margin: 'auto' }}>
                {imageDraw ? (
                    <Stage
                        ref={stageRef}
                        style={{
                            margin: '0 auto'
                        }}
                        className="canvas-draw__stage"
                        width={canvasDimensions.width * canvasScale}
                        height={canvasDimensions.height * canvasScale}
                        scaleY={canvasScale}
                        scaleX={canvasScale}
                        onMouseEnter={handleMouseEnter}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                    >
                        <Layer>
                            {imageDraw && (
                                <Image
                                    ref={imageRef}
                                    image={imageDraw}
                                    onMouseDown={() => {
                                        setSelectedId(undefined);
                                    }}
                                />
                            )}
                            {notes.map((note, index) => (
                                <CanvasDrawShape
                                    shapeProps={note}
                                    key={index}
                                    onSelect={() => onSelect(note.id)}
                                    isSelected={selectedId === note.id}
                                    onChange={newAttrs => {
                                        const shapes = cloneDeep(drawNotes);
                                        shapes[index] = newAttrs;
                                        setDrawNotes(shapes);
                                    }}
                                />
                            ))}
                        </Layer>
                    </Stage>
                ) : (
                    <Grid item sx={{ margin: 'auto' }}>
                        <Typography>{canvasDraw.setImage}</Typography>
                    </Grid>
                )}
            </Grid>
            <input
                ref={fileUploadRef}
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                onChange={handleFileChange}
            />
        </Grid>
    );
};

export default CanvasDrawPane;
