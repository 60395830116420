import { observer } from 'mobx-react';

import { SelfTreeStore, SelfTreeNode } from './SelfTreeStore';
import NodeLine from './NodeLine';

interface NodeListProps {
    store: SelfTreeStore;
    list: SelfTreeNode[];
}

const NodeList = observer(({ list, store }: NodeListProps) => (
    <>
        {list.map(node => (
            <NodeLine key={node.id} store={store} node={node} />
        ))}
    </>
));

export default NodeList;
