import React, {
    useState,
    useEffect,
    FunctionComponent,
    useRef,
    MutableRefObject,
    createRef,
    useMemo,
    useCallback
} from 'react';
import { observer } from 'mobx-react';

import cloneDeep from 'lodash/cloneDeep';

import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import useDatasetLoading from 'hooks/datasetLoading';
import useCtrlProps from 'hooks/ctrlProps';
import useOnScreen from 'hooks/onScreen';
import useNodeResize from 'hooks/nodeResize';

import Loading from 'components/utils/Loading/Loading';

import ActionStore from 'store/actionStore';

import { generateGridColumnsFromDS } from 'forms/form-utils';

import ControlTree from 'forms/ControlTree';

import GridToolbar from './Toolbar/Toolbar';
import Pagination from './Pagination';
import DataGroup from './DataGroup';
import Header from './Header/Header';

import { ColumnType, ControlPropsType, DatasetFieldType, GridType } from '../../interfaces';
import { HeaderColumnType } from './interface';

import { dataGridStyles } from './styles';

const dropHiddenColumn = (columns: ColumnType[], fieldName: string) => {
    const ind = columns.findIndex(col => col.fieldName === fieldName);
    if (ind > -1) columns.splice(ind, 1);
};

const prepareColumns = (
    columns: ColumnType[],
    ctrlOptions: any,
    dsFields?: DatasetFieldType[]
): HeaderColumnType[] => {
    const tmpColumns = cloneDeep(columns);

    if (ctrlOptions) {
        ctrlOptions.columns?.forEach((optColumn: any) => {
            if (!optColumn.visible) dropHiddenColumn(tmpColumns, optColumn.fieldName);
        });

        ctrlOptions.group?.fields?.forEach((fldName: string) =>
            dropHiddenColumn(tmpColumns, fldName)
        );
    } else {
        dsFields?.forEach(field => {
            if (field.hidden) dropHiddenColumn(tmpColumns, field.name);
        });
    }

    tmpColumns.forEach((col: any) => {
        const optColumn = ctrlOptions?.columns?.find(
            (optCol: any) => optCol.fieldName === col.fieldName
        );
        col.align = optColumn?.align ? optColumn?.align : 'left';
        col.caption = optColumn?.caption || col.caption;
        col.ref = createRef();
        col.width = optColumn?.width;
        col.fixWidth = optColumn?.fixWidth;
        col.sortable = optColumn?.sortable;
    });

    return tmpColumns as HeaderColumnType[];
};

export const getGridWidth = (width: string) => {
    const gridWidth = parseInt(width, 10);

    if (gridWidth > 12) return 0;

    const viewportWidth = window.visualViewport?.width || 0;

    return Math.round((viewportWidth / 12) * gridWidth);
};

interface PropsType extends ControlPropsType {
    descr: GridType;
}

const DataGrid: FunctionComponent<PropsType> = observer(({ descr, propContainer }: PropsType) => {
    const ctrlProps = useCtrlProps(propContainer, descr.guid);

    const { ctrlEnabled, ctrlVisible, ctrlOptions } = ctrlProps;

    const dataset = useMemo(
        () => propContainer.dataStock.getDatasetObj(descr.datasetName),
        [descr?.datasetName]
    );

    const { loading, loadingType, loadingMessage } = useDatasetLoading(dataset);

    const gridRef = useRef(null) as MutableRefObject<any>;
    const tableRef = useRef(null) as MutableRefObject<any>;

    const [settled, setSettled] = useState(false);
    const [columns, setColumns] = useState<HeaderColumnType[]>([]);

    const onScreen = useOnScreen(gridRef);

    const [gridWidth] = useNodeResize(gridRef);

    useEffect(() => {
        setSettled(true);
    }, [onScreen]);

    const wrapperKeyboardListener = useCallback(e => {
        if (['Space', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(e.code) > -1) {
            e.preventDefault();
        }
    }, []);

    useEffect(() => {
        if (gridRef?.current) {
            if (onScreen) {
                gridRef.current.addEventListener('keydown', wrapperKeyboardListener, false);
            } else gridRef.current.removeEventListener('keydown', wrapperKeyboardListener);
        }
    }, [onScreen, gridRef?.current, wrapperKeyboardListener]);

    useEffect(() => {
        if (descr.columns?.length) {
            setColumns(prepareColumns(descr.columns, ctrlOptions, dataset?.descr.fields));
        } else {
            dataset && setColumns(prepareColumns(generateGridColumnsFromDS(dataset), ctrlOptions));
        }
    }, [descr, ctrlOptions, dataset]);

    const datasetActions = useMemo(() => {
        if (dataset?.guid) {
            return ActionStore.getDatasetActions(dataset.guid);
        }

        return null;
    }, [dataset?.guid]);

    useEffect(
        () => () => {
            ActionStore.removeActions(descr.guid);
        },
        []
    );

    const owner = useMemo(
        () => ({
            resource: descr,
            getOptions: () => ctrlOptions || {},
            setOptions: async (options: any) => {
                const el = propContainer.find(descr.guid);
                propContainer.setElemProperty(el, 'options', options);

                const ds = propContainer.find(descr.datasetName);
                if (ds) {
                    propContainer.setElemProperty(ds, 'options', options);
                    dataset.readOptions();
                    dataset.loadData();
                }
            }
        }),
        [descr, ctrlOptions, propContainer]
    );

    const styles = dataGridStyles({
        visible: ctrlVisible,
        hasData: dataset?.accum?.root?.cldGroups?.size && dataset.hasData()
    });

    const refreshGrid = () => {
        dataset.loadData({ forceRefresh: true });
    };

    const showHeader = () => {
        if (descr.controls?.length) return false;

        return [...columns].reduce(
            (check: boolean, col: ColumnType) => check || !!col.caption,
            false
        );
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'ArrowDown') {
            dataset.nextRecord();
        }

        if (event.key === 'ArrowUp') {
            dataset.prevRecord();
        }
    };

    const setFocused = () => {
        tableRef?.current.focus();
    };

    const controlGrid = (dataRow: any) => (
        <TableCell colSpan={12}>
            <ControlTree
                formDescr={descr.controls[0]}
                propContainer={propContainer}
                dataRow={dataRow}
            />
        </TableCell>
    );

    return (
        <Grid id={`grid-${dataset?.descr?.guid}`} container direction="column" sx={styles.wrapper}>
            {datasetActions && (
                <Grid item>
                    <GridToolbar
                        propContainer={propContainer}
                        gridDescr={descr}
                        dataset={dataset}
                        fastFilters={ctrlOptions?.search ?? []}
                        gridWidth={gridWidth || undefined}
                        owner={owner}
                        gridFocus={setFocused}
                    />
                </Grid>
            )}
            <Grid
                ref={gridRef}
                item
                flex="1 1"
                className="scrollbar-hidden"
                sx={{
                    overflow: 'auto',
                    width: '100%',
                    zIndex: 0
                }}
            >
                <TableContainer sx={{ overflow: 'initial' }}>
                    <Table
                        ref={tableRef}
                        tabIndex={0}
                        stickyHeader
                        sx={styles.table}
                        size="small"
                        onKeyDown={handleKeyDown}
                    >
                        {showHeader() && (
                            <Header dataset={dataset} columns={columns} styles={styles} />
                        )}
                        <TableBody sx={styles.tableBody}>
                            {loading ? (
                                <TableRow>
                                    <TableCell sx={styles.tableCell} className="borderless">
                                        <Loading
                                            type={loadingType}
                                            message={loadingMessage}
                                            style={{ visibility: 'visible' }}
                                            delay
                                        />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <DataGroup
                                    index={0}
                                    gridDescr={descr}
                                    gridWidth={gridWidth || undefined}
                                    dataGroup={dataset?.accum?.root}
                                    dataset={dataset}
                                    columns={columns}
                                    enabled={ctrlEnabled}
                                    controlGrid={descr.controls?.length ? controlGrid : null}
                                    propContainer={propContainer}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {dataset && dataset.descr?.type !== 'clientDataset' ? (
                <Grid item>
                    <Pagination
                        dataset={dataset}
                        refreshGrid={refreshGrid}
                        gridWidth={gridWidth || undefined}
                        settled={settled}
                        propContainer={propContainer}
                        owner={owner}
                    />
                </Grid>
            ) : null}
        </Grid>
    );
});

export default DataGrid;
