import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';

import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('app');

const root = createRoot(container!);

root.render(
    <SnackbarProvider>
        <App />
    </SnackbarProvider>
);

reportWebVitals();
