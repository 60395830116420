import React from 'react';
import moment from 'moment';

import { Box, Grid, Button } from '@mui/material';

import { getStateColor, showEditor, getMinutes, getDuration } from '../utils';

const PopoverTemplate =
    (setBackdrop: (backdrop: boolean) => void) =>
    (schedulerData: any, eventItem: any, title: any, start: any, end: any, statusColor: any) => {
        const { state } = eventItem;

        const handleOpenJob = () => {
            showEditor(
                eventItem,
                eventItem?.props.job_cdotype_id,
                eventItem?.props.job_tt_rec_id,
                setBackdrop
            ).catch(err => {
                console.error(err.message);
            });
        };

        const handleOpenSpec = () => {
            showEditor(
                eventItem,
                eventItem?.props.spec_cdotype_id,
                eventItem?.props.spec_tt_rec_id,
                setBackdrop
            ).catch(err => {
                console.error(err.message);
            });
        };

        return (
            <Box sx={{ width: '300px' }}>
                <Grid>
                    <Grid direction="row" container alignItems="center">
                        <Grid direction="column" item xs={1}>
                            <div
                                className="status-dot"
                                style={{ backgroundColor: getStateColor(state) }}
                            />
                        </Grid>
                        <Grid direction="column" item xs={11}>
                            <span className="header2-text" title={title}>
                                {title}
                            </span>
                        </Grid>
                    </Grid>
                    <Grid direction="row" container>
                        <Grid direction="column" item>
                            <span className="header2-text" title={title}>
                                Предельная дата:
                            </span>
                        </Grid>
                    </Grid>
                    <Grid direction="row" container>
                        <Grid direction="column" item>
                            <span className="header2-text">
                                {eventItem?.props.deadline
                                    ? moment(eventItem?.props.deadline).format('DD.MM.YYYY')
                                    : 'Не определена'}
                            </span>
                        </Grid>
                    </Grid>
                    {eventItem?.props.exec_time ? (
                        <>
                            <Grid direction="row" container>
                                <Grid direction="column" item>
                                    <span className="header2-text" title={title}>
                                        Отведённое время:
                                    </span>
                                </Grid>
                            </Grid>
                            <Grid direction="row" container>
                                <Grid direction="column" item>
                                    <span className="header1-text">
                                        {getDuration(getMinutes(eventItem?.props.exec_time))}
                                    </span>
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                    <Grid direction="row" container>
                        <Grid direction="column" item ml="auto">
                            <Button onClick={handleOpenJob}>Работа</Button>
                            <Button onClick={handleOpenSpec}>Спецификация</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    };

export default PopoverTemplate;
