import PropContainer from 'dataObj/PropContainer';

import useDatasetLoading from 'hooks/datasetLoading';
import useCtrlData from 'hooks/ctrlData';

export default function useTaskTree(descr: any, propContainer: PropContainer) {
    const { dataset, dataVal } = useCtrlData(propContainer, descr.datasetName, 'tt_node_id');

    const parentTask = dataset?.data.find((rec: any) => rec.child_level === 0);

    const { loading, loadingType, loadingMessage } = useDatasetLoading(dataset);

    return {
        dataset,
        parentTask,
        value: dataVal,
        loading,
        loadingType,
        loadingMessage
    };
}
