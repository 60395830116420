import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react';

import { AppBar, Button, IconButton, Toolbar, Typography, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ConfigurationStore from 'store/configurationStore';
import { ResourceLinkType } from 'forms/interfaces';

import ModalForm from 'hoc/ModalForm';
import CustomForm from 'forms/CustomForm';
import { jsonFetch } from 'utils/index';

import { HeaderPropsType } from '../interfaces';

const Header: FunctionComponent<HeaderPropsType> = observer(({ open, onOpen }) => {
    const { applicationConfig, taskId, taskName } = ConfigurationStore;
    const [formGuid, setFormGuid] = useState<string>('');
    const [showContent, setShowContent] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const propContainerGuid = useMemo(() => uuidv4(), []);

    const showPopup = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const executeFormScript = async (method: string) =>
        jsonFetch(
            'script/calc',
            'POST',
            { formGuid, scriptName: 'execResManager', parObj: { method, taskId } },
            {},
            'json'
        );

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(null);
        const { method } = event.currentTarget.dataset;
        if (method === 'selectResTask') {
            return setShowContent(true);
        }
        if (method === 'changeResTask') {
            return ConfigurationStore.selectTask();
        }
        return method ? executeFormScript(method) : undefined;
    };

    useEffect(() => {
        if (applicationConfig.resourceLinks) {
            const resourceLinks = applicationConfig.resourceLinks as ResourceLinkType[];
            // В настройках приложения поместил форму
            const link = resourceLinks.find(item => item.name === 'RES_TASK');
            setFormGuid(link?.resource?.code || '');
        }
    }, [applicationConfig]);

    const renderTaskControl = () => (
        <>
            <div>
                <Button
                    variant="contained"
                    disableElevation
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleClick}
                >
                    {taskName}
                </Button>
                <Menu anchorEl={anchorEl} open={showPopup} onClose={handleClose}>
                    <MenuItem data-method="selectResTask" onClick={handleClose}>
                        Выбрать
                    </MenuItem>
                    <MenuItem data-method="closeResTask" disabled={!taskId} onClick={handleClose}>
                        Завершить
                    </MenuItem>
                    <MenuItem data-method="installResTask" disabled={!taskId} onClick={handleClose}>
                        Установить
                    </MenuItem>
                    <MenuItem data-method="changeResTask" disabled={!taskId} onClick={handleClose}>
                        Выйти из задачи
                    </MenuItem>
                </Menu>
            </div>
            {showContent && formGuid && (
                <ModalForm
                    propContainerGuid={propContainerGuid}
                    formGuid={formGuid}
                    handleClose={async (state, value) => {
                        setShowContent(false);
                        if (state && value?.length) {
                            await ConfigurationStore.selectTask(value[0].taskId);
                        }
                    }}
                >
                    <CustomForm
                        propContainerGuid={propContainerGuid}
                        formGuid={formGuid}
                        // handleError={(err: boolean) => setError(err)}
                    />
                </ModalForm>
            )}
        </>
    );

    return (
        <AppBar position="fixed">
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1 }}>
                    Редактор ресурсов
                </Typography>
                {formGuid && renderTaskControl()}
            </Toolbar>
        </AppBar>
    );
});

export default Header;
