import { FunctionComponent, MouseEvent } from 'react';
import { observer } from 'mobx-react';
import { Stack, TablePagination, LabelDisplayedRowsArgs } from '@mui/material';

import { dsStates } from 'dataObj/customDataset';

import Settings from './Settings';

import { PaginationPropsType } from './interface';

const Pagination: FunctionComponent<PaginationPropsType> = observer(
    ({ dataset, owner, refreshGrid, gridWidth, settled, propContainer }: PaginationPropsType) => {
        const breakpointWidth = 250;

        const psize = owner?.getOptions()?.configuration?.pageSize || dataset.pageSize || 0;

        const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            dataset.nextPage(newPage - dataset.page);
        };

        const getPaginationCount = () => {
            if (psize && dataset.state === dsStates.dsBrowse) {
                return dataset.recCount === psize
                    ? (dataset.page + 1) * psize + 1
                    : dataset.page * psize + ((dataset.recCount as number) || 0);
            }

            return 0;
        };

        return (
            <Stack direction="row" sx={{ pl: 5, pr: 5 }}>
                {settled && !!gridWidth && !!owner && (
                    <Settings
                        dataset={dataset}
                        refreshGrid={refreshGrid}
                        owner={owner}
                        propContainer={propContainer}
                        gridWidth={gridWidth}
                    />
                )}
                <TablePagination
                    id={`pagination-${dataset?.descr?.guid}`}
                    rowsPerPageOptions={psize ? [psize] : []}
                    colSpan={3}
                    component="div"
                    count={getPaginationCount()}
                    page={dataset.page}
                    onPageChange={handleChangePage}
                    rowsPerPage={psize}
                    labelDisplayedRows={({ from, to }: LabelDisplayedRowsArgs) =>
                        to && psize && gridWidth && gridWidth > breakpointWidth
                            ? `${from}-${to}`
                            : ''
                    }
                    backIconButtonProps={{ style: { visibility: psize ? 'inherit' : 'hidden' } }}
                    nextIconButtonProps={{ style: { visibility: psize ? 'inherit' : 'hidden' } }}
                />
            </Stack>
        );
    }
);

export default Pagination;
