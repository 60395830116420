import { makeAutoObservable, observable, action, computed } from 'mobx';

import ResourceStore, { SettingType } from 'store/resourceStore';
import { FilterDataType, DatasetType, ReportType } from 'forms/interfaces';
import { safeJSONParse } from 'utils/utils';
import moment from 'moment/moment';

export type FilterElementValue = { value: unknown | unknown[] } | { afName: string };
type SafeFilterItemValue = { afName?: string; value?: unknown };

export type FilterType = 'interval' | 'value';

export const enum Condition {
    inInterval,
    outInterval,
    startWith,
    contain,
    notContain,
    endWith,
    equal,
    notEqual,
    notEmpty,
    empty,
    include,
    notInclude,
    skipIt,
    yes,
    no
}

// @ts-ignore
export const conditions = Condition;

export const intervalConditions: Condition[] = [Condition.inInterval, Condition.outInterval];

export const unarConditions: Condition[] = [
    Condition.no,
    Condition.yes,
    Condition.skipIt,
    Condition.empty,
    Condition.notEmpty
];

export interface FilterValue {
    id: number;
    filterDataType: FilterDataType;
    type: FilterType;
    value: FilterElementValue;
    value2?: FilterElementValue;
    condition: Condition;
}

export type FilterValues = FilterValue[];

interface IDataStock {
    formGuid: string;
}
export interface IFilterOwner {
    name: string;
    dataStock: IDataStock;
    filter: CustomFilter;
    descr?: DatasetType | ReportType;
}

const isEmpty = (val: unknown) => typeof val === 'undefined' || val === null || val === '';

const emptyVal = (condition: Condition, value: SafeFilterItemValue | undefined) =>
    !value ||
    (!(unarConditions.includes(condition) && condition !== Condition.skipIt) &&
        !value.afName &&
        isEmpty(value.value));

const isEmptyFilter = (filterValue: FilterValue) => {
    const { type, condition, value, value2 } = filterValue;

    if (intervalConditions.includes(condition) || type === 'interval') {
        if (emptyVal(condition, value) && emptyVal(condition, value2)) {
            return true;
        }
    } else if (emptyVal(condition, value)) {
        return true;
    }

    return false;
};

const transformValue = (value: any | any[]): any | any[] => {
    if (Array.isArray(value)) return value.map(val => transformValue(val));

    // Если дата на начало суток, то приводим к началу дня по Гринвичу
    if (
        typeof value?.getMonth === 'function' &&
        value.getHours() === 0 &&
        value.getMinutes() === 0 &&
        value.getSeconds() === 0
    )
        return `${moment(value).format('YYYY-MM-DD')}T00:00:00.000Z`;

    return value;
};

export class CustomFilter {
    @observable values: FilterValues = [];

    owner: IFilterOwner;

    constructor(owner: IFilterOwner) {
        this.owner = owner;
        this.values = safeJSONParse(owner.descr?.filterValues, []);

        makeAutoObservable(this);
    }

    @action setValue(filterValue: FilterValue) {
        const idx = this.values.findIndex(f => f.id === filterValue.id);
        if (idx < 0) {
            this.values.push(filterValue);
        } else {
            this.values[idx] = filterValue;
        }
    }

    @action setValues(avalues: FilterValues) {
        this.values = avalues;
    }

    @computed getNotEmpty() {
        return this.values.filter(f => !isEmptyFilter(f));
    }

    @computed isEmpty() {
        return this.values.length === 0;
    }

    @action remove(id: number) {
        this.values = this.values.filter(f => f.id === id);
    }

    getValue(filterId: number) {
        return this.values.find(f => f.id === filterId);
    }

    async load(type: SettingType): Promise<FilterValues | null> {
        const { name, dataStock } = this.owner;
        const { formGuid } = dataStock;
        const res = await ResourceStore.loadFilter(formGuid, name, type);
        return res !== null ? (JSON.parse(res) as FilterValues) : null;
    }

    async save(type: SettingType, vals?: FilterValues) {
        const { name, dataStock } = this.owner;
        const { formGuid } = dataStock;
        return vals
            ? ResourceStore.saveFilter(formGuid, name, type, JSON.stringify(vals || {}))
            : null;
    }

    async drop(type: SettingType) {
        const { name, dataStock } = this.owner;
        const { formGuid } = dataStock;
        return ResourceStore.dropFilter(formGuid, name, type);
    }

    getProperty(id: number, name: string): unknown {
        const filter = this.getValue(id);
        switch (name) {
            case 'condition':
                return filter?.condition;
            case 'value':
                return filter?.value;
            case 'value2':
                return filter?.value2;
        }
    }

    @action setProperty(id: number, name: string, value: unknown) {
        const filter = this.getValue(id);
        const getValue = (val: unknown) =>
            Array.isArray(val) && !val.length ? undefined : transformValue(val);

        if (filter) {
            let itValue;
            let proxy;
            switch (name) {
                case 'condition':
                    filter.condition = value as number;
                    filter.type = intervalConditions.includes(filter.condition)
                        ? 'interval'
                        : 'value';
                    filter.value2 = filter.type !== 'interval' ? undefined : { value: null };
                    break;
                case 'value':
                    itValue = value as FilterElementValue;
                    proxy = filter.value as SafeFilterItemValue;

                    if ('afName' in itValue) {
                        proxy.afName = itValue.afName;
                        proxy.value = undefined;
                    } else {
                        proxy.value = getValue(itValue.value);
                        proxy.afName = undefined;
                    }
                    break;
                case 'value2':
                    if (!value) {
                        filter.value2 = undefined;
                        return;
                    }
                    if (!filter.value2) {
                        filter.value2 = { value: null };
                    }

                    itValue = value as FilterElementValue;
                    proxy = filter.value2 as SafeFilterItemValue;

                    if ('afName' in itValue) {
                        proxy.afName = itValue.afName;
                        proxy.value = undefined;
                    } else {
                        proxy.value = getValue(itValue.value);
                        proxy.afName = undefined;
                    }
                    break;
            }
        }
    }
}

export default CustomFilter;
