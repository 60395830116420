import React, { FunctionComponent } from 'react';
import { Button, ButtonGroup } from '@mui/material';

import { SchedulerData, ViewTypes, Event } from 'react-big-scheduler';

interface PropsType {
    schedulerData: SchedulerData;
    events: Event[];
    filter?: boolean;
    updateData: (data: SchedulerData) => void;
}

const ViewTypeSwitcher: FunctionComponent<PropsType> = ({
    schedulerData,
    events,
    filter = false,
    updateData
}) => {
    const toggleViewType = (viewType: number) => {
        schedulerData.setViewType(viewType, false, false);
        schedulerData.setEvents(events);

        updateData(schedulerData);
    };

    const viewTypeColor = (viewType: number) =>
        schedulerData.viewType === viewType ? 'warning' : 'primary';

    return (
        <ButtonGroup
            variant="outlined"
            aria-label="outlined primary button group"
            sx={{ ml: filter ? 2 : 'auto' }}
        >
            <Button
                color={viewTypeColor(ViewTypes.Day)}
                onClick={() => toggleViewType(ViewTypes.Day)}
            >
                День
            </Button>
            <Button
                color={viewTypeColor(ViewTypes.Week)}
                onClick={() => toggleViewType(ViewTypes.Week)}
            >
                Неделя
            </Button>
            <Button
                color={viewTypeColor(ViewTypes.Month)}
                onClick={() => toggleViewType(ViewTypes.Month)}
            >
                Месяц
            </Button>
            <Button
                color={viewTypeColor(ViewTypes.Quarter)}
                onClick={() => toggleViewType(ViewTypes.Quarter)}
            >
                Квартал
            </Button>
        </ButtonGroup>
    );
};

export default ViewTypeSwitcher;
