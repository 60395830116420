import React from 'react';
import { observer } from 'mobx-react';

import { List } from '@mui/material';
import { ControlPropsType, MenuItemType } from 'forms/interfaces';

import SideBarListItem from './ListItem';

interface PropsType extends ControlPropsType {
    items: MenuItemType[];
}

const ListContent = observer(({ items, propContainer }: PropsType) => (
    <List>
        {items.map(item => (
            <SideBarListItem key={item.guid} descr={item} propContainer={propContainer} />
        ))}
    </List>
));

export default ListContent;
