import PropContainer from '../dataObj/PropContainer';
import { GridDataRow, PaletteColorKeys } from '../forms/interfaces';

export default function useCtrlProps(
    propContainer: PropContainer,
    guid: string,
    dataRow?: GridDataRow
) {
    const res: {
        ctrlVisible: boolean;
        ctrlEnabled: boolean;
        ctrlHidden: boolean;
        ctrlCaption?: string;
        ctrlMask: string;
        ctrlColorToken?: PaletteColorKeys;
        ctrlGridWidth?: string | number;
        ctrlOptions?: { [key: string]: any };
        ctrlBadge?: string | boolean;
        ctrlIcon?: string;
        ctrlTooltip?: string;
    } = {
        ctrlVisible: true,
        ctrlEnabled: true,
        ctrlHidden: false,
        ctrlCaption: undefined,
        ctrlMask: '',
        ctrlColorToken: undefined,
        ctrlGridWidth: undefined,
        ctrlOptions: undefined,
        ctrlBadge: undefined,
        ctrlIcon: undefined,
        ctrlTooltip: undefined
    };

    const key = dataRow?.dataset?.keyField ? dataRow.data[dataRow.dataset.keyField] : undefined;

    const el = propContainer.find(guid);
    if (el) {
        // Параметр key передается если свойство может быть использовано в строкаж грида,
        // и соответствует значению ключевого поля датасета для данной строки
        res.ctrlVisible = Boolean(
            propContainer.getElemHierarchyProperty(el, 'visible', key) ?? true
        );
        res.ctrlEnabled = Boolean(
            propContainer.getElemHierarchyProperty(el, 'enabled', key) ?? true
        );
        res.ctrlHidden = Boolean(propContainer.getElemProperty(el, 'hidden', key) ?? true);
        res.ctrlCaption = propContainer.getElemProperty(el, 'caption', key);
        res.ctrlMask = propContainer.getElemProperty(el, 'mask') || '';
        res.ctrlColorToken = propContainer.getElemProperty(el, 'colorToken', key);
        res.ctrlGridWidth = propContainer.getElemProperty(el, 'gridWidth', key);
        res.ctrlOptions = propContainer.getElemProperty(el, 'options');
        res.ctrlBadge = propContainer.getElemProperty(el, 'badge');
        res.ctrlIcon = propContainer.getElemProperty(el, 'icon');
        res.ctrlTooltip = propContainer.getElemProperty(el, 'tooltip');
    }
    return res;
}
