import { FunctionComponent, useCallback, useMemo } from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
    CheckBox,
    CheckBoxOutlineBlank,
    Clear,
    FiberManualRecordOutlined,
    FolderOpen,
    Report,
    SmartDisplay
} from '@mui/icons-material';

import PropContainer from 'dataObj/PropContainer';

import SVGIcon from 'forms/controls/SVGIcon';

interface StateType {
    state?: string;
    iconSet?: string;
    propContainer: PropContainer;
    stateColor: () => string;
}

const State: FunctionComponent<StateType> = ({ iconSet, state, propContainer, stateColor }) => {
    const getIcon = useCallback((): any => {
        if (iconSet && state) {
            return () => (
                <SVGIcon
                    descr={{ iconSet, icon: state }}
                    propContainer={propContainer}
                    sx={{ mr: 1, fontSize: 'medium' }}
                />
            );
        }

        switch (state) {
            case 'A':
                return SmartDisplay;

            case 'P':
                return FolderOpen;

            case 'C':
                return Clear;

            case 'F':
                return CheckBox;

            case 'I':
                return Report;

            case 'W':
                return FiberManualRecordOutlined;

            case 'R':
                return CheckBoxOutlineBlank;

            default:
                return FolderOpen;
        }
    }, []);

    const getLabel = useCallback(() => {
        switch (state) {
            case 'R':
                return 'Можно приступать';

            case 'S':
                return 'Начата';

            case 'I':
                return 'Прервана';

            case 'F':
                return 'Завершена';

            case 'C':
                return 'Отменена';

            case 'W':
                return 'В ожидании';

            default:
                return 'В ожидании';
        }
    }, []);

    const label = useMemo(() => getLabel(), []);
    const labelIcon = useMemo(() => getIcon(), []);

    return (
        <Stack
            spacing={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ ml: 'auto !important' }}
        >
            <Typography
                variant="body2"
                sx={{
                    fontWeight: 'inherit',
                    flexGrow: 1,
                    color: stateColor(),
                    whiteSpace: 'nowrap',
                    fontSize: '1.2rem'
                }}
            >
                {label}
            </Typography>
            <Box
                component={labelIcon}
                color="inherit"
                sx={{ mr: 1, color: stateColor() }}
                fontSize="medium"
            />
        </Stack>
    );
};

export default State;
