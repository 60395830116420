import React, {
    FunctionComponent,
    useCallback,
    forwardRef,
    ReactNode,
    useEffect,
    useRef
} from 'react';
import { observer } from 'mobx-react';

import { useHistory } from 'react-router-dom';

import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { ListItemText, Divider } from '@mui/material';

import useCtrlProps from 'hooks/ctrlProps';
import useScriptHandler from 'hooks/scriptHandler';

import { ControlPropsType, MenuItemType } from 'forms/interfaces';

import MainFormStore from 'store/mainFormStore';
import DataStore from 'store/dataStore';
import routingStore from 'store/routingStore';

interface MenuItemContainerPropsType extends ControlPropsType, Omit<MenuItemProps, 'button'> {
    descr: MenuItemType;
    closeMenu?: any;
    children?: ReactNode;
}

const MenuItemContainer: FunctionComponent<MenuItemContainerPropsType> = forwardRef(
    ({ descr, propContainer, children, closeMenu, ...otherProps }, ref) => {
        const { guid, name, route, caption, params, onClickScript, afName, items, variant } = descr;

        const { open, currentMenuItem, setCurrentItem } = MainFormStore;

        const { ctrlEnabled, ctrlVisible } = useCtrlProps(propContainer, guid);
        const scriptHandler = useScriptHandler(onClickScript, propContainer, params);

        const setActiveFilter = useCallback(
            (value: string) => {
                if (DataStore?.AF) {
                    DataStore.AF.setAF(afName || 'currentForm', value, {}, propContainer);
                }
            },
            [descr]
        );

        const handleClick = () => {
            let value = descr?.value;

            if (route || value) setCurrentItem(name);

            if (route) {
                const routeItem = routingStore.getRoute(route);
                if (routeItem) {
                    routingStore.push(`/${route}`);
                    // value для af в приоритете
                    if (!value) value = routeItem.resource?.code;
                }
            } // else routingStore.push(`/`);

            if (value) setActiveFilter(value);
            !items.length && closeMenu && closeMenu();

            scriptHandler().catch(err => console.error(err.message));
        };

        const getItem = () => {
            if (variant === 'divider')
                return caption ? (
                    <MenuItem disabled>
                        <ListItemText
                            sx={theme => ({
                                '& span': {
                                    width: open ? '100%' : theme.spacing(7),
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }
                            })}
                        >
                            <b style={{ fontSize: '.8rem' }}>{caption}</b>
                        </ListItemText>
                    </MenuItem>
                ) : (
                    <Divider />
                );

            return (
                <MenuItem
                    ref={ref}
                    onClick={handleClick}
                    disabled={!ctrlEnabled}
                    selected={currentMenuItem === route || currentMenuItem === name}
                    {...otherProps}
                >
                    {children ?? <ListItemText>{caption}</ListItemText>}
                </MenuItem>
            );
        };

        return ctrlVisible ? getItem() : null;
    }
);

export default observer(MenuItemContainer);
