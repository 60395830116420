import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Add, Delete, Edit, Code, Visibility } from '@mui/icons-material';

import IconButton from 'components/Inputs/IconButton';
import MUIButton from 'components/Inputs/Button';
import WithTooltip from 'components/utils/withTooltip';

import { ControlPropsType, ToolButtonType } from 'forms/interfaces';
import CustomDataset from 'dataObj/customDataset';

import useScriptHandler from 'hooks/scriptHandler';
import useCtrlProps from 'hooks/ctrlProps';

import ConfigurationStore from 'store/configurationStore';
import ActionStore from 'store/actionStore';
import { add, del, view, edit } from 'store/actionStore/dataset/actions';

import ScriptButton from 'forms/controls/ScriptButton/ScriptButton';
import SVGIcon from 'forms/controls/SVGIcon';

interface PropsType extends ControlPropsType {
    descr: ToolButtonType;
    fullWidth?: boolean;
    dataset?: CustomDataset;
}

const getIcon = (action?: string) => {
    switch (action) {
        case add:
            return <Add />;

        case view:
            return <Visibility />;

        case edit:
            return <Edit />;

        case del:
            return <Delete />;

        default:
            return <Code />;
    }
};

const ToolbarButton: FunctionComponent<PropsType> = observer(
    ({ descr, fullWidth, dataset, propContainer }) => {
        const { locale, content } = ConfigurationStore;

        const {
            guid,
            caption,
            tooltip,
            onClickScript,
            params,
            variant,
            iconSet,
            icon,
            iconButton,
            items
        } = descr;

        const datasetActions = ActionStore.getDatasetActions(dataset?.guid);

        const { ctrlEnabled, ctrlVisible, ctrlHidden, ctrlCaption, ctrlTooltip } = useCtrlProps(
            propContainer,
            guid
        );

        const enabled = useMemo(
            () =>
                ctrlEnabled &&
                (!dataset ||
                    (dataset && dataset.state === 1 && dataset.activeRec) ||
                    descr.action === add),
            [ctrlEnabled, dataset?.state, dataset?.activeRec]
        );

        const handleScript = useScriptHandler(onClickScript, propContainer, params);

        const handleOnClick = async () => {
            if (onClickScript) return handleScript();

            if ('action' in descr && descr.action && datasetActions) {
                const dsAction = datasetActions.getAction(descr.action);

                if (dsAction) return dsAction(propContainer);
            }

            return;
        };

        const getCaption = useCallback(
            (action?: string) => {
                switch (action) {
                    case add:
                        return content.controls.buttons.add;

                    case view:
                        return content.controls.buttons.view;

                    case edit:
                        return content.controls.buttons.change;

                    case del:
                        return content.controls.buttons.delete;

                    default:
                        return '';
                }
            },
            [locale]
        );

        if (ctrlHidden || !ctrlVisible) return null;

        if (items?.length) {
            return (
                <ScriptButton
                    descr={descr}
                    propContainer={propContainer}
                    icon={!fullWidth}
                    style={{ width: 'inherit', height: 'fit-content', alignSelf: 'center' }}
                    fullWidth={fullWidth}
                />
            );
        }

        return fullWidth && !iconButton ? (
            <MUIButton
                variant={variant || 'contained'}
                onClick={() => handleOnClick()}
                disabled={!enabled}
                sx={{
                    whiteSpace: 'nowrap',
                    visibility: ctrlVisible ? 'inherit' : 'hidden',
                    height: 'fit-content',
                    alignSelf: 'center'
                }}
                color={descr.action === del ? 'warning' : undefined}
                startIcon={
                    descr.action ? (
                        getIcon(descr.action)
                    ) : (
                        <SVGIcon
                            descr={{ iconSet: descr.iconSet, icon: descr.icon }}
                            propContainer={propContainer}
                        />
                    )
                }
                title={ctrlTooltip || tooltip}
            >
                {ctrlCaption || caption || getCaption(descr.action)}
            </MUIButton>
        ) : (
            <WithTooltip
                title={ctrlTooltip || tooltip || ctrlCaption || caption}
                wrap
                wrapperStyle={{ alignSelf: 'center' }}
            >
                <IconButton
                    onClick={() => handleOnClick()}
                    disabled={!enabled}
                    color={descr.action === del ? 'warning' : undefined}
                >
                    {iconSet && icon ? (
                        <SVGIcon
                            descr={{ iconSet: descr.iconSet, icon: descr.icon }}
                            propContainer={propContainer}
                        />
                    ) : (
                        getIcon(descr.action)
                    )}
                </IconButton>
            </WithTooltip>
        );
    }
);

export default ToolbarButton;
