/* eslint-disable react/no-unknown-property */
// th.align="left" правило не известно
import { useState, useEffect, FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography } from '@mui/material';

import { ResourceClass } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';

import useInheritor from 'hooks/useInheritor';

import Loading from 'components/utils/Loading/Loading';

import { EventType } from './ResInterfaces';

import resValidator from '../clientResValidator';
import ResGuidEditor from './ResGuidEditor';
import ResProp from './ResProp';

const stickyStyle = {
    background: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 10
};

interface ResPropsType {
    ResourceStore: ResourceClass;
}

const ResProps: FunctionComponent<ResPropsType> = observer(({ ResourceStore }) => {
    const { curCtrl, resPropsLoading, modifyCtrl, checkIsModified } = ResourceStore;
    const { resource } = ConfigurationStore.content;

    // any -> ClientDatasetType ?
    const [curDescr, setCurDescr] = useState<any>(null);
    const [fieldNames, setFieldNames] = useState<Array<string>>([]);
    const [formVals, setFormVals] = useState<any>({});

    const inheritor = useInheritor(ResourceStore);

    useEffect(() => {
        checkIsModified();
    });

    useEffect(() => {
        const descr = curCtrl ? resValidator.getTypeDescriptor(curCtrl.type) : null;
        const fields: { [key: string]: string } = {};
        if (descr?.fields) {
            for (const prop in descr.fields) {
                if (descr.fields[prop].type !== 'array') {
                    if (curCtrl) {
                        fields[prop] = curCtrl[prop];
                    }
                }
            }
        }

        setFieldNames(Object.keys(fields));
        setFormVals(fields);
        setCurDescr(descr);
    }, [curCtrl]);

    const applyValue = (name: string, newValue: unknown) => {
        const newVals = { ...formVals, [name]: newValue };
        modifyCtrl(fieldNames, newVals);
        setFormVals(newVals);
    };

    const inputChangeHandler = (event: EventType) => {
        const {
            target: { name, value }
        } = event;

        applyValue(name, value);
    };

    function getPropTable() {
        return curCtrl && curDescr
            ? fieldNames.map((prop, index) => (
                  <ResProp
                      key={index}
                      ResourceStore={ResourceStore}
                      curDescr={curDescr}
                      prop={prop}
                      propValue={formVals[prop]}
                      inheritor={inheritor}
                      inputChangeHandler={inputChangeHandler}
                      applyValue={applyValue}
                  />
              ))
            : null;
    }

    //--------------------------------------------------------

    return curCtrl ? (
        <>
            <Box sx={{ display: 'flex' }}>
                <Typography variant="h5" sx={{ mr: 'auto' }}>
                    {curCtrl?.name}
                </Typography>
                <ResGuidEditor ResourceStore={ResourceStore} onConfirm={inputChangeHandler} />
            </Box>
            <Box
                style={{
                    position: 'relative',
                    height: 'calc(100% - 30px)',
                    overflow: 'auto',
                    marginTop: 10
                }}
            >
                {resPropsLoading ? (
                    <Loading message={resource.resProps.saving} />
                ) : (
                    <form>
                        <table style={{ width: '99%' }}>
                            <thead>
                                <tr>
                                    <th style={stickyStyle as any} align="left">
                                        {resource.resProps.property}
                                    </th>
                                    <th style={stickyStyle as any} align="left">
                                        {resource.resProps.value}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{getPropTable()}</tbody>
                        </table>
                    </form>
                )}
            </Box>
        </>
    ) : null;
});

export default ResProps;
