import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import { v4 as uuidv4 } from 'uuid';

import CustomForm from 'forms/CustomForm';
import { EditModeType } from 'forms/interfaces';

import { ResourceClass } from 'store/resourceStore';
import ActionStore from 'store/actionStore';

import ResModal from './ResModal';

interface PreviewControlType {
    ResourceStore: ResourceClass;
}

const PreviewControl: FunctionComponent<PreviewControlType> = observer(({ ResourceStore }) => {
    const { previewResource, setPreviewResource } = ResourceStore;

    const [editMode, setEditMode] = useState<EditModeType>(null);

    const propContainerGuid = useMemo(
        () => (previewResource ? uuidv4() : undefined),
        [previewResource]
    );

    const formActions = ActionStore.getFormActions(propContainerGuid);

    useEffect(() => {
        formActions?.setEditMode(editMode);
    }, [formActions, editMode]);

    return previewResource ? (
        <ResModal
            content={
                <CustomForm
                    key={previewResource}
                    formGuid={previewResource}
                    propContainerGuid={propContainerGuid}
                />
            }
            open={!!previewResource}
            editMode={editMode}
            handleEditModeChange={setEditMode}
            onClose={() => setPreviewResource()}
        />
    ) : null;
});

export default PreviewControl;
