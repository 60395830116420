import { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import ActionStore from 'store/actionStore';
import DatasetActions from 'store/actionStore/dataset/DatasetActions';

import Editor from './Editor';
import Creator from './Creator';

const Editors: FunctionComponent = observer(() => {
    const dsActions = ActionStore.getActions('dataset') as DatasetActions[];

    const editors = useMemo(() => dsActions.filter(dsAction => dsAction.editor), [dsActions]);
    const creators = useMemo(() => dsActions.filter(dsAction => dsAction.creator), [dsActions]);

    return (
        <>
            {editors.map((dsAction, index) => (
                <Editor key={`editor-${index}`} dataset={dsAction.dataset} />
            ))}
            {creators.map((dsAction, index) => (
                <Creator key={`creator-${index}`} dataset={dsAction.dataset} />
            ))}
        </>
    );
});

export default Editors;
