import React, { FunctionComponent, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { Event, SchedulerData, ViewTypes } from 'react-big-scheduler';
import moment from 'moment';

import Popover from '@mui/material/Popover';

import PeriodCalendar from './PeriodCalendar';

interface PropsType {
    schedulerData: any;
    events: Event[];
    updateData: (data: SchedulerData) => void;
    setStartDate: (startDate: string) => void;
}

const getCurrentPeriod = (viewType: ViewTypes, startDate: string, endDate: string) => {
    switch (viewType) {
        case ViewTypes.Day:
            return moment(startDate).format('dd, DD MMMM');
        case ViewTypes.Week:
            return `${moment(startDate).format('DD MMMM')} - ${moment(endDate).format(
                'DD MMMM'
            )}, ${moment(startDate).format('YYYY')}`;
        case ViewTypes.Month:
            return moment(startDate).format('MMMM YYYY');
        case ViewTypes.Quarter:
            return `${moment(startDate).format('DD MMM')} - ${moment(endDate).format(
                'DD MMM'
            )}, ${moment(startDate).format('YYYY')}`;
        case ViewTypes.Year:
            return moment(startDate).format('YYYY');
        default:
            return startDate;
    }
};

const PeriodSwitcher: FunctionComponent<PropsType> = ({
    schedulerData,
    updateData,
    setStartDate,
    events
}) => {
    const { startDate, endDate, viewType } = schedulerData;

    const currentPeriod = getCurrentPeriod(viewType, startDate, endDate);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const setDate = (date: string) => {
        schedulerData.setDate(date);
        schedulerData.setEvents(events);
        setStartDate(schedulerData.startDate);

        updateData(schedulerData);
    };

    const prevClick = () => {
        schedulerData.prev();
        schedulerData.setEvents(events);
        setStartDate(schedulerData.startDate);

        updateData(schedulerData);
    };

    const nextClick = () => {
        schedulerData.next();
        schedulerData.setEvents(events);
        setStartDate(schedulerData.startDate);

        updateData(schedulerData);
    };

    return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center', fontSize: '14px' }}>
            <Box onClick={prevClick} sx={{ display: 'inline-flex', cursor: 'pointer' }}>
                <i
                    aria-label="icon: left"
                    tabIndex={-1}
                    style={{ display: 'inline-flex', marginRight: '8px' }}
                >
                    <svg
                        viewBox="64 64 896 896"
                        data-icon="left"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        focusable="false"
                    >
                        <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z" />
                    </svg>
                </i>
            </Box>

            <Typography fontSize={14} onClick={handleClick} sx={{ cursor: 'pointer' }}>
                {currentPeriod}
            </Typography>

            <Box onClick={nextClick} sx={{ display: 'inline-flex', cursor: 'pointer' }}>
                <i
                    aria-label="icon: right"
                    tabIndex={-1}
                    style={{ display: 'inline-flex', marginLeft: '8px' }}
                >
                    <svg
                        viewBox="64 64 896 896"
                        className=""
                        data-icon="right"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                        focusable="false"
                    >
                        <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z" />
                    </svg>
                </i>
            </Box>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <PeriodCalendar
                    date={startDate}
                    setDate={setDate}
                    schedulerData={schedulerData}
                    handleClose={handleClose}
                />
            </Popover>
        </Box>
    );
};

export default PeriodSwitcher;
