import AFContainer from 'dataObj/afContainer';
import Dataset from 'dataObj/customDataset';
import DataStock from 'dataObj/DataStock';
import PropContainer from 'dataObj/PropContainer';
import TaskQueue from 'dataObj/TaskQueue';
import FormulaCalculator from 'formulas/formula-calculator';

type DataCacheObj = { [key: string]: object[] };
export class DataStoreClass {
    readonly AF = new AFContainer();
    readonly taskQueue = new TaskQueue();
    readonly #setPropContainers = new Set<PropContainer>();
    readonly #setDataStocks = new Set<DataStock>();
    readonly #globalDataCache: DataCacheObj = {};

    registerForm(propContainer: PropContainer) {
        this.#setPropContainers.add(propContainer);
        this.#setDataStocks.add(propContainer.dataStock);
    }

    unregisterForm(propContainer: PropContainer) {
        // SubForm - не имеет собственного dataStock
        if (!propContainer.isSubForm) {
            propContainer.dataStock.unregisterDatasets();
            this.#setDataStocks.delete(propContainer.dataStock);
        }
        this.#setPropContainers.delete(propContainer);
        this.AF.unregisterLocalAF(propContainer.guid);
    }

    get dataStocks() {
        return Array.from(this.#setDataStocks);
    }

    get propContainers() {
        return Array.from(this.#setPropContainers);
    }

    get calculators() {
        return Array.from(this.#setPropContainers).map(pc => pc.formulaCalculator);
    }

    // Ищем все пропконтейнеры форм,
    // порожденных указанным пропконтейнером,
    // включая сам указанный пропконтейнер,
    // т.е. все родственные формы,
    // начиная с указанной
    #getDescendantsPropContainers(pC: PropContainer) {
        const all = this.propContainers;
        const decendants: PropContainer[] = [pC];

        const getDescendants = (cont: PropContainer) => {
            for (let i = 0; i < all.length; i++) {
                if (all[i].sourcePropContainer === cont) {
                    decendants.push(all[i]);
                    getDescendants(all[i]);
                }
            }
        };

        getDescendants(pC);

        return decendants;
    }

    getPropContainersByDataStock(dataStock: DataStock) {
        const res: PropContainer[] = [];
        this.#setPropContainers.forEach((pc: PropContainer) => {
            if (pc.dataStock === dataStock) {
                res.push(pc);
            }
        });
        return res;
    }

    getCalculatorsByDataStock(dataStock: DataStock): FormulaCalculator[] {
        const res: FormulaCalculator[] = [];
        this.#setPropContainers.forEach((pc: PropContainer) => {
            if (pc.dataStock === dataStock) {
                res.push(pc.formulaCalculator);
            }
        });
        return res;
    }

    // Найти все датасеты, зависящие от активного фильтра
    #getDependentDatasetsFromAF(afName: string, dataStoks?: DataStock[]): Dataset[] {
        const res: Dataset[] = [];

        if (!dataStoks?.length) dataStoks = this.dataStocks;

        dataStoks.forEach(dataStock => {
            for (const dsName in dataStock.data) {
                const ds: Dataset = dataStock.getDatasetObj(dsName);
                const found = ds.masterAFs.includes(afName);
                if (found && !res.includes(ds)) res.push(ds);
            }
        });
        return res;
    }

    #getPropContanerEnvironment(propContainer: PropContainer) {
        const descConts = this.#getDescendantsPropContainers(propContainer);
        const descCalculators = [...new Set(descConts.map(cont => cont.formulaCalculator))];
        const descDataStocks = [...new Set(descConts.map(cont => cont.dataStock))];
        return { descConts, descCalculators, descDataStocks };
    }

    distributeAF(afName: string, baseContainer?: PropContainer) {
        const recalcFormulas = (calculators?: FormulaCalculator[]) => {
            this.taskQueue
                .addFormulaTask({
                    taskType: 'onFieldModified',
                    datasetName: 'AF',
                    fieldName: afName,
                    calculators
                })
                .catch(err => console.error(err.message));
        };

        const baseContainerEnv = baseContainer
            ? this.#getPropContanerEnvironment(baseContainer)
            : undefined;

        const depDS = this.#getDependentDatasetsFromAF(afName, baseContainerEnv?.descDataStocks);

        if (depDS.length) {
            this.taskQueue
                .addLoadTask(depDS, () => recalcFormulas(baseContainerEnv?.descCalculators))
                .catch(err => console.error(err.message));
        } else {
            recalcFormulas(baseContainerEnv?.descCalculators);
        }
    }

    addDataToCache(key: string, data: object[]) {
        this.#globalDataCache[key] = data;
    }

    getDataFromCache(key: string): object[] {
        return this.#globalDataCache[key];
    }
}

const DataStore = new DataStoreClass();
export default DataStore;
