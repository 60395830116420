import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { jsonFetch } from 'utils/index';
import useBackdrop from 'hooks/backdrop';

import ControlTree from 'forms/ControlTree';

import { DataType } from './NodeFlow';

interface PropsType {
    data: DataType;
}

const stateList = [
    { state: 'R', availableState: ['W'], caption: 'Перевести в "Можно приступать"' },
    { state: 'S', availableState: ['R', 'I'], caption: 'Начать' },
    { state: 'I', availableState: ['S'], caption: 'Прервать' },
    { state: 'F', availableState: ['S'], caption: 'Завершить' },
    { state: 'C', availableState: ['S', 'R', 'I'], caption: 'Отменить' }
];

const StateMenu: FunctionComponent<PropsType> = observer(({ data }) => {
    const { taskId, state, dataRow, buttons } = data;
    const dataset = dataRow?.dataset;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [loading, setLoading] = useState(false);

    const open = Boolean(anchorEl);

    useBackdrop(loading);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeState = (newState: string) => {
        const body = {
            guid: '128bfcbb-ab2c-4e7f-91db-cf885b41b2d8',
            actionName: 'setTaskState',
            parObj: { tt_task_id: taskId, state: newState }
        };
        setLoading(true);

        jsonFetch('dataobject/execute', 'POST', body, {}, 'json')
            .then(() => {
                setLoading(false);

                if (dataset) {
                    const keepPosition = { info: { keepPosition: true } };
                    dataset.requestParams = { ...dataset.requestParams, ...keepPosition };

                    dataset.loadData();
                }
            })
            .catch(err => {
                console.error(err.message);
                setLoading(false);
            });

        handleClose();
    };

    if (buttons?.length) {
        return (
            <ControlTree
                key={buttons[0].guid}
                formDescr={buttons[0]}
                propContainer={data.propContainer}
                dataRow={dataRow}
            />
        );
    }

    return (
        <>
            <IconButton
                disabled={!stateList.filter(option => option.availableState.includes(state)).length}
                aria-label="settings"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {stateList
                    .filter(option => option.availableState.includes(state))
                    .map(option => (
                        <MenuItem
                            key={option.state}
                            onClick={() => handleChangeState(option.state)}
                        >
                            {option.caption}
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
});

export default StateMenu;
