import React, { FunctionComponent, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { Box, Grid, Typography, Backdrop, Tooltip } from '@mui/material';

import IconButton from '@mui/material/IconButton';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import useEvent from 'hooks/event';
import useCtrlProps from 'hooks/ctrlProps';

import ConfigurationStore from 'store/configurationStore';

import { getControlClass } from 'forms/form-utils';
import { ChildrenContainerPropsType, ControlDataRowType, PanelType } from 'forms/interfaces';

import PanelWrapper from './Wrapper';

import GroupBoxTitle from './GroupBoxTitle';

interface PropsType extends ChildrenContainerPropsType, ControlDataRowType {
    descr: PanelType;
}

const PanelControl: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, drawChildren, dataRow }) => {
        const { content } = ConfigurationStore;
        const { ctrlVisible, ctrlCaption, ctrlColorToken } = useCtrlProps(
            propContainer,
            descr.guid
        );

        const panelRef = useRef(null);

        useEvent(panelRef, descr, propContainer);

        const [fullScreen, setFullScreen] = useState(false);

        const styles = {
            panel: {
                position: 'relative',
                height: '100%',
                visibility: ctrlVisible ? 'inherit' : ('hidden' as any)
            },
            paper: {
                p: 4,
                margin: 2,
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                height: 'auto'
            },
            groupBox: {
                height: 'calc(100%)'
            },
            groupBoxContent: {
                pt: 2
            },
            buttons: {
                position: 'absolute',
                top: 0,
                right: 0,
                padding: 1,
                cursor: 'pointer',
                zIndex: (theme: any) => theme.zIndex.mobileStepper
            },
            modal: {
                boxSizing: 'border-box',
                position: 'fixed',
                zIndex: (theme: any) => theme.zIndex.modal,
                borderRadius: 1,
                backgroundColor: 'white',
                width: '90vw',
                height: '90vh',
                top: '5vh',
                left: '5vw',
                overflow: 'auto',
                padding: 1
            },
            backdrop: {
                zIndex: (theme: any) => theme.zIndex.modal - 1
            }
        } as const;

        const fullScreenButton = useMemo(
            () => Boolean(getControlClass(descr?.classes, 'fullscreenbutton')),
            [descr?.classes]
        );
        const isPaper = useMemo(
            () => Boolean(getControlClass(descr?.classes, 'paper')),
            [descr?.classes]
        );
        const isAccordion = useMemo(
            () =>
                Boolean(getControlClass(descr?.classes, 'accordion')) ||
                Boolean(getControlClass(descr?.classes, 'expanded-accordion')),
            [descr?.classes]
        );
        const isAccordionExpanded = useMemo(
            () => Boolean(getControlClass(descr?.classes, 'expanded-accordion')),
            [descr?.classes]
        );
        const isGroupBox = useMemo(
            () => Boolean(getControlClass(descr?.classes, 'groupbox')),
            [descr?.classes]
        );

        const switchFullScreen = () => {
            setFullScreen(prev => !prev);
        };

        function renderTopMenu() {
            return fullScreenButton ? (
                <Box sx={styles.buttons}>
                    <Tooltip
                        title={
                            fullScreen
                                ? content.controls.panel.fullscreenClose
                                : content.controls.panel.fullscreenOpen
                        }
                    >
                        <IconButton onClick={switchFullScreen} size="small">
                            {fullScreen ? (
                                <FullscreenExitIcon fontSize="small" />
                            ) : (
                                <FullscreenIcon fontSize="small" />
                            )}
                        </IconButton>
                    </Tooltip>
                </Box>
            ) : null;
        }

        const getPanelStyle = () => {
            if (fullScreen) return styles.modal;

            let style = { ...styles.panel } as { [key: string]: string | number };

            if (isPaper) {
                style = { ...style, ...styles.paper };
            }

            if (isGroupBox) {
                style = { ...style, ...styles.groupBox };
            }

            if (ctrlColorToken) {
                style = { ...style, border: 1, borderColor: ctrlColorToken, borderRadius: 4 };
            }

            return style;
        };

        const getPanelContentStyle = () => {
            if (isGroupBox) {
                return styles.groupBoxContent;
            }

            return {};
        };

        const renderPanel = () => (
            <Grid height="100%" container direction="column">
                {renderTopMenu()}
                {!isGroupBox && !isAccordion && (
                    <Grid item width="100%" sx={{ pl: 5 }}>
                        <Typography
                            id={`panel-caption-${descr.guid}`}
                            className={`caption-${descr.captionVariant || 'empty'}`}
                            variant="h6"
                            align="center"
                            sx={{ color: ctrlColorToken ? `${ctrlColorToken}` : 'inherit' }}
                        >
                            {ctrlCaption || descr?.caption}
                        </Typography>
                    </Grid>
                )}
                <Grid
                    ref={panelRef}
                    item
                    height="100%"
                    width="100%"
                    flex="1 1"
                    sx={{ ...{ overflow: 'auto' }, ...getPanelContentStyle() }}
                >
                    {drawChildren ? drawChildren(descr, dataRow) : null}
                </Grid>
            </Grid>
        );

        return (
            <>
                {isGroupBox && !isAccordion && (
                    <GroupBoxTitle guid={descr.guid} caption={descr?.caption} />
                )}
                <PanelWrapper
                    caption={descr?.caption}
                    paper={isPaper}
                    accordion={isAccordion}
                    defaultExpandedAccordion={isAccordionExpanded}
                    groupBox={isGroupBox}
                    style={getPanelStyle()}
                    descr={descr}
                    propContainer={propContainer}
                >
                    {renderPanel()}
                </PanelWrapper>
                <Backdrop open={fullScreen} sx={styles.backdrop} onClick={switchFullScreen} />
            </>
        );
    }
);

export default PanelControl;
