import React, {
    forwardRef,
    useImperativeHandle,
    FunctionComponent,
    useEffect,
    useState,
    useMemo,
    SyntheticEvent
} from 'react';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import Loading from 'components/utils/Loading/Loading';
import CustomDataset from 'dataObj/customDataset';

import LookupStore from 'store/lookupStore';
import ConfigurationStore from 'store/configurationStore';
import ActionStore from 'store/actionStore';

import { jsonFetch } from 'utils/index';

import { ControlPropsType, DatasetType, DBLookupType } from 'forms/interfaces';
import useCtrlData from 'hooks/ctrlData';

import LookupGeneratedForm from './LookupGeneratedForm';
import LookupForm from './LookupForm';

interface LookupDialogProps extends ControlPropsType {
    ref: any;
    descr: DBLookupType;
    dataVal: string[] | number[] | null;
    index?: number;
}

const LookupDialog: FunctionComponent<LookupDialogProps> = forwardRef(
    ({ descr, propContainer, dataVal, index }, ref) => {
        const { datasetName, fieldName } = descr;

        const { dataset, dataType } = useCtrlData(propContainer, datasetName, fieldName);

        const { content } = ConfigurationStore;

        const [dsLookup, setDSLookup] = useState<CustomDataset | null>(null);
        const [lookupDataset, setLookupDataset] = useState<any | null>(null);
        const [formDescr, setFormDescr] = useState<any | null>(null);
        const [generatedFormGuid, setGeneratedFrmGuid] = useState<any | null>(null);
        const [error, setError] = useState<boolean>(false); // Отметка о корректности ссылки на ресурс
        const [open, setOpen] = useState(false);
        const [callback, setCallback] = useState<(state: boolean) => unknown>();

        const propContainerGuid = useMemo(() => uuidv4(), []);
        const formActions = ActionStore.getFormActions(propContainerGuid);

        useImperativeHandle(
            ref,
            () =>
                ({
                    setOpen: (value: boolean, cb?: () => (state: boolean) => unknown) => {
                        setOpen(value);
                        value && setCallback(cb);
                    }
                } as any)
        );

        const readResource = async (resourceGuid: string) =>
            jsonFetch(`views/${resourceGuid}`, 'GET');

        const getResourceLinkDataset = async (formGuid: string, linkGuid: string) =>
            jsonFetch('generate/linkDataset', 'POST', { formGuid, linkGuid });

        const renderLookupForm = () => <LookupForm descr={formDescr} />;

        const renderGeneratedForm = () => (
            <LookupGeneratedForm
                dataset={lookupDataset}
                propContainer={propContainer}
                setGeneratedFrmGuid={setGeneratedFrmGuid}
                parentPropContainer={propContainer}
                propContainerGuid={propContainerGuid}
            />
        );

        const getDialogContent = useMemo(() => {
            if (error)
                return <Loading message={content.controls.lookup.incorrectLink} type="error" />;

            if (!lookupDataset) {
                return <Loading message={content.controls.data.loading} />;
            }

            return formDescr ? renderLookupForm() : renderGeneratedForm();
        }, [error, formDescr, lookupDataset]);

        const handleClose = (state = false) => {
            setOpen(false);
            setError(false);
            callback && callback(state);
        };

        /*
         * Для корректной работы выбора значения в диалоговом окне должно быть корректно настроено
         * соответствие multiselect: если у dbLookup это свойство выставлено в true, то и у датасета
         * формы диалогового окна тоже это свойство должно быть true.
         * Для динамически генерируемой из стат. запроса формы данное свойство наследуется в датасет
         * от дескриптора лукапа
         * Кроме того, имеется прямая зависимость в типом поля, которое редактирует лукап: для мультиселекта
         * тип редактируемого поля должен быть KRN_ARRAY для хранения в виде массива или KRN_MEMO,
         * для хранения значений через запятую
         * */
        const handleOkClick = () => {
            let values = descr.flMultiSelect ? [] : null;

            if (formActions?.getDataStock) {
                const ds = formActions.getDataStock().getMainDataset() as any;

                if (descr.flMultiSelect) {
                    if (dataType === 'KRN_ARRAY') {
                        values = ds ? ds.selectedIDs : [];
                    } else {
                        values = ds ? ds.selectedIDs.join(',') : '';
                    }
                } else {
                    values = ds ? ds.getFieldValue(ds.keyField) : null;
                }

                LookupStore.setValue(
                    descr,
                    datasetName ? values : null,
                    ds.data.filter((record: { [field: string]: string | number | null }) => {
                        if (descr.flMultiSelect)
                            return ds.selectedIDs.includes(record[ds.keyField]);

                        return values === record[ds.keyField];
                    })
                );
            }

            dataset
                ?.trySetFieldValue(fieldName, values, index)
                .catch(err => console.error(err.message));

            callback && callback(true);
            handleClose(true);
        };

        const preparePopupControl = async (resourceLinkName: string, lookupFormName?: string) => {
            const resourceLink = propContainer.getLookupResourceLink(resourceLinkName);
            const formLink = lookupFormName ? propContainer.getResourceLink(lookupFormName) : null;

            if (resourceLink) {
                try {
                    try {
                        if (formLink) setFormDescr(await readResource(formLink.resource?.code));

                        const lookupDatasetDescr: DatasetType = (await getResourceLinkDataset(
                            resourceLink?.formGuid,
                            resourceLink?.link.guid
                        )) as DatasetType;

                        if (lookupDatasetDescr) {
                            // Обогащаем описатель параметрами лукапа
                            if (descr.params?.length) {
                                lookupDatasetDescr.params = descr.params.map(param => {
                                    if (param.paramType === 'field') {
                                        const value = propContainer.dataStock
                                            .getDatasetObj(param.sourceDataset)
                                            ?.getFieldValue(param.sourceField)
                                            ?.toString();

                                        return {
                                            ...param,
                                            paramType: 'const',
                                            value
                                        };
                                    }

                                    if (param.paramType === 'extern') {
                                        const value =
                                            propContainer.dataStock.extParamVals[
                                                param.paramName
                                            ]?.toString();

                                        return {
                                            ...param,
                                            paramType: 'const',
                                            value
                                        };
                                    }

                                    return param;
                                });
                            } else {
                                lookupDatasetDescr.params = [];
                            }

                            if (descr.flMultiSelect) {
                                lookupDatasetDescr.multiSelect = true;
                            }

                            // Обогащаем описатель фиктивными данными request корректного для формирования запроса данных
                            lookupDatasetDescr.request = { code: resourceLink?.link.guid };

                            // Включить pagination
                            lookupDatasetDescr.pageSize = 25;

                            setLookupDataset(lookupDatasetDescr);
                        }
                    } catch (err: any) {
                        console.error(err.message);
                        setError(true);
                    }
                } catch (err: any) {
                    console.error(err.message);
                }
            } else setError(true);
        };

        // Считываем событие открытия/закрытия диалога
        useEffect(() => {
            if (open) {
                if (descr.lookupResourceName) {
                    preparePopupControl(descr.lookupResourceName, descr.lookupFormName).catch(err =>
                        console.error(err.message)
                    );
                } else {
                    setError(true);
                }
            }
            if (!open) {
                setOpen(false);
                setError(false);
                setLookupDataset(null);
            }
        }, [open, descr.lookupResourceName]);

        useEffect(() => {
            formActions?.getDataStock &&
                setDSLookup(formActions.getDataStock().getMainDataset() as any);
        }, [formActions]);

        useEffect(() => {
            if (dsLookup && dsLookup.hasData() && dataVal?.length) {
                if (descr.flMultiSelect) {
                    const recordIdx = dsLookup.data
                        .map((rec: any) => rec[dsLookup.keyField])
                        .filter((rec: any) => dataVal?.indexOf(rec as never) !== -1);

                    if (recordIdx?.length) {
                        recordIdx.forEach((idx: any) => dsLookup.setSelectedField(idx, true));

                        dsLookup.setActiveRec(null).catch(err => console.error(err.message));
                    }
                } else {
                    const recordIdx = dsLookup.data
                        .map((rec: any) => rec[dsLookup.keyField])
                        .indexOf(dataVal[0]);

                    if (recordIdx !== -1)
                        dsLookup.setActiveRec(recordIdx).catch(err => console.error(err.message));
                }
            }
        }, [dsLookup?.hasData(), dataVal, dsLookup]);

        return (
            <Dialog open={open} onClose={() => handleClose()} maxWidth="lg" fullWidth>
                <DialogTitle>{descr.label}</DialogTitle>
                <DialogContent
                    style={{ height: '100vh' }}
                    // Если лукап без мультиселекта, то включаем double-click
                    onDoubleClick={(event: SyntheticEvent) => {
                        const target = event.target as HTMLElement;

                        if (
                            !descr.flMultiSelect &&
                            target.className?.search &&
                            target.className?.search('MuiTableCell-root') !== -1
                        ) {
                            handleOkClick();
                        }
                    }}
                >
                    {open && getDialogContent}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>{content.controls.buttons.cancel}</Button>
                    <Button onClick={handleOkClick}>{content.controls.buttons.ok}</Button>
                </DialogActions>
            </Dialog>
        );
    }
);

export default observer(LookupDialog);
