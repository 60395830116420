import { FunctionComponent } from 'react';

import moment from 'moment';

import { SchedulerRowType, ColumnType } from '../../interfaces';

import SchedulerCell from './SchedulerCell';
import HeaderCell from './HeaderCell';

const SchedulerRow: FunctionComponent<SchedulerRowType> = ({
    row,
    columns,
    newEventHandler,
    header = false
}) => {
    const cellClickHandler = (column: ColumnType, slot: any) => {
        if (newEventHandler) {
            const start = moment(column.date).hour(Number(row.hour)).minutes(Number(row.minutes));
            const end = moment(column.date)
                .hour(Number(row.hour))
                .minutes(Number(row.minutes))
                .add(Number(row.duration), 'minutes');

            newEventHandler(
                slot.id,
                column.id,
                slot.title,
                start.format('YYYY-MM-DD HH:mm'),
                column.duration
                    ? start.add(column.duration, 'minutes').format('YYYY-MM-DD HH:mm')
                    : end.format('YYYY-MM-DD HH:mm')
            );
        }
    };

    return (
        <div className="scheduler-row">
            {columns.map((column, i) => {
                if (column.type === 'time' || column.type === 'duration') {
                    return (
                        <HeaderCell
                            className="side-cell"
                            key={column.id}
                            slotId={`${column.id}-${header ? 'header' : row.id}`}
                            header={header}
                        >
                            {row[column.key]}
                        </HeaderCell>
                    );
                }

                return (
                    <SchedulerCell
                        key={i}
                        slotId={`${column.id}-${moment(column.date).format('YYYY-MM-DD')}-${
                            header ? 'header' : row.id
                        }`}
                        className="scheduler-cell"
                        columnId={`${column.id}`}
                        onDoubleClick={(slot: any) => cellClickHandler(column, slot)}
                        header={header}
                        disabled={row.subject === 'disabled'}
                        dateStart={moment(column.date)
                            .hour(Number(row.hour))
                            .minutes(Number(row.minutes))}
                        dateEnd={moment(column.date)
                            .hour(Number(row.hour))
                            .minutes(Number(row.minutes))
                            .add(Number(row.duration), 'minutes')}
                    >
                        {row[column.key]}
                    </SchedulerCell>
                );
            })}
        </div>
    );
};

export default SchedulerRow;
