import React, { FunctionComponent, useRef } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Grid } from '@mui/material';

import { getClasses, styleStringToStyleObject } from 'forms/form-utils';

import { ControlPropsType, FormType } from 'forms/interfaces';
import PropContainer from 'dataObj/PropContainer';

import useEvent from 'hooks/event';

interface PropsType extends ControlPropsType {
    descr: FormType;
}

const Form: FunctionComponent<PropsType> = observer(({ descr, propContainer, children }) => {
    const frmRef = useRef(null);

    const { onScroll } = useEvent(frmRef, descr, propContainer);

    return (
        <Grid
            key={descr.guid}
            ref={frmRef}
            item
            className={classNames(getClasses(descr))}
            style={{
                position: 'relative',
                overflow: 'hidden',
                height: '100%',
                width: '100%',
                ...styleStringToStyleObject(descr.styles as any)
            }}
            onScroll={onScroll}
        >
            {children}
        </Grid>
    );
});

export default Form;
