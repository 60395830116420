import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import useDBEdit from 'hooks/dbEdit';

import { ControlPropsType } from 'forms/interfaces';

interface PropsType extends ControlPropsType {
    descr: any;
}

const DBRadioButton: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { guid, fieldName, value: btnValue, caption, labelPlacement } = descr;

    const { dataVal, ctrlVisible, ctrlEnabled, ctrlCaption, dataset } = useDBEdit(
        descr,
        propContainer
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (dataset?.inEdit) {
            dataset
                .trySetFieldValue(fieldName, event.target.value)
                .catch(err => console.error(err.message));
        }
    };

    return (
        <FormControlLabel
            key={guid}
            value={btnValue}
            control={
                <Radio
                    checked={String(dataVal) === String(btnValue)}
                    onChange={handleChange}
                    sx={{ m: 1, p: 2 }}
                />
            }
            label={ctrlCaption || caption}
            labelPlacement={labelPlacement}
            sx={{ visibility: ctrlVisible ? 'visible' : 'hidden' }}
            disabled={!ctrlEnabled}
        />
    );
});

export default DBRadioButton;
