import React, {
    FunctionComponent,
    Children,
    useMemo,
    useState,
    useEffect,
    useCallback
} from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import MUIToolbar from '@mui/material/Toolbar';
import { Typography, Stack, Box } from '@mui/material';

import CustomDataset from 'dataObj/customDataset';

import { ControlPropsType, ToolButtonType } from 'forms/interfaces';
import { TCustomToolbarButton } from './interface';

import { toolbarStyles } from './styles';

import Buttons from './Buttons';

interface PropsType extends ControlPropsType {
    initContainerWidth?: number;
    caption?: string;
    captionVariant?: string;
    buttons?: ToolButtonType[];
    customButtons?: TCustomToolbarButton[];
    breakpoint?: number;
    dataset?: CustomDataset;
    controlElements?: any; // для кнопки фильтра
    setButtonWidth?: (width: number) => unknown;
    visibility?: boolean;
    setIconsFullWidth?: (fullWidth: boolean) => unknown;
}

const Toolbar: FunctionComponent<PropsType> = observer(
    ({
        caption,
        captionVariant,
        buttons,
        customButtons,
        initContainerWidth,
        breakpoint,
        dataset,
        children,
        controlElements,
        setButtonWidth,
        visibility = true,
        propContainer,
        setIconsFullWidth
    }) => {
        const [containerWidth, setContainerWidth] = useState(initContainerWidth);
        const [fullWidth, setFullWidth] = useState(true);

        const breakpointWidth = breakpoint || 600;

        const styles = useMemo(
            () => toolbarStyles({ containerWidth, breakpointWidth, visibility }),
            [containerWidth, breakpointWidth, visibility]
        );

        const checkChildren = useMemo(() => {
            if (children) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return Children.map(children, child => !!child).reduce(
                    (acc, child) => acc || !!child
                );
            }

            return false;
        }, [children]);

        const handleFullWidth = (full: boolean) => {
            setIconsFullWidth && setIconsFullWidth(full);
            setFullWidth(full);
        };

        useEffect(() => {
            if (initContainerWidth && !containerWidth) {
                setContainerWidth(initContainerWidth - 40);
            }
        }, [initContainerWidth]);

        const handleCtrlBreakpoint = useCallback(
            (node: HTMLDivElement) => {
                if (initContainerWidth) {
                    setContainerWidth(initContainerWidth - 40 - (node?.scrollWidth || 0));
                }
            },
            [initContainerWidth]
        );

        return (
            <>
                {caption ? (
                    <MUIToolbar sx={styles.toolbar} id="caption-toolbar" style={{ minHeight: 50 }}>
                        <Typography
                            variant="h6"
                            className={classNames([
                                'title',
                                `caption-${captionVariant || 'empty'}`
                            ])}
                            component="div"
                            sx={{ pl: 5, pr: 5 }}
                        >
                            {caption}
                        </Typography>
                    </MUIToolbar>
                ) : null}
                <>
                    {buttons?.length || customButtons?.length || controlElements ? (
                        <MUIToolbar id="controls-toolbar" sx={styles.toolbar}>
                            <Stack
                                spacing={2}
                                direction="row"
                                justifyContent="left"
                                sx={{ width: '100%', pl: 5, pr: 5 }}
                            >
                                <Buttons
                                    custom={!buttons?.length && !!customButtons?.length}
                                    buttons={
                                        !buttons?.length && !!customButtons?.length
                                            ? customButtons
                                            : buttons
                                    }
                                    propContainer={propContainer}
                                    fullWidth={fullWidth}
                                    containerWidth={containerWidth}
                                    dataset={dataset}
                                    setFullWidth={handleFullWidth}
                                    setButtonWidth={setButtonWidth}
                                />
                                <Box
                                    ref={handleCtrlBreakpoint}
                                    display="flex"
                                    className={fullWidth ? '' : 'icon-buttons'}
                                >
                                    {controlElements || null}
                                </Box>
                            </Stack>
                        </MUIToolbar>
                    ) : null}
                    {checkChildren ? (
                        <MUIToolbar id="controls-toolbar" sx={styles.toolbar}>
                            {children}
                        </MUIToolbar>
                    ) : null}
                </>
            </>
        );
    }
);

export default Toolbar;
