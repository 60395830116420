import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import isEqual from 'lodash/isEqual';

import { useLocation } from 'react-router-dom';

import { Breadcrumbs, Typography, Link, Button, Grid, Stack } from '@mui/material';

import useCtrlProps from 'hooks/ctrlProps';
import useEditorCustomButtons from 'hooks/editorCustomButtons';

import { ControlPropsType, EditModeType, RouterItemType, MainFormType } from 'forms/interfaces';
import CustomDataset from 'dataObj/customDataset';
import CustomForm from 'forms/CustomForm';

import ConfigurationStore from 'store/configurationStore';
import MainFormStore from 'store/mainFormStore';
import DataStore from 'store/dataStore';
import ActionStore from 'store/actionStore';
import RoutingStore, { getRouteParams } from 'store/routingStore';

import { jsonFetch, checkGuid } from 'utils/index';
import { v4 as uuidv4 } from 'uuid';

interface PropsType extends ControlPropsType {
    descr: MainFormType;
}

const IterableContainer: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { content } = ConfigurationStore;
    const { setCurrentItem } = MainFormStore;

    const { guid, params, sourceNameType, sourceName } = descr;

    const [extParamVals, setExtParamVals] = useState({});
    const [editMode, setEditMode] = useState<EditModeType | undefined>();

    const { ctrlVisible } = useCtrlProps(propContainer, guid);

    const location = useLocation();

    const [formGuid, setFormGuid] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState<RouterItemType[]>([]);

    const setFormParams = async (route?: RouterItemType) => {
        // eslint-disable-next-line
        const { cdotype_id, values } = getRouteParams(
            route?.route || '',
            RoutingStore.getCurrentPath()
        );

        const code =
            cdotype_id === undefined
                ? route?.formLink?.code
                : (
                      (await jsonFetch(
                          'dataobject/form',
                          'POST',
                          { cdotype_id: Number(cdotype_id), formType: 'editor' },
                          {},
                          'json'
                      )) as any
                  )?.code;

        setFormGuid(code || '');
        setExtParamVals({ ...values, ...{ cdotype_id } });
        setEditMode(route?.editMode || 'view');
    };

    useEffect(() => {
        const pathArr = RoutingStore.getPathArr();
        const routeList = pathArr.map(path => RoutingStore.getRoute(path)).filter(route => !!route);

        if (!isEqual(routeList, breadcrumbs)) setBreadcrumbs(routeList);
    }, [location]);

    useEffect(() => {
        const route = [...breadcrumbs].pop();

        setFormParams(route).catch(err => console.error(err.message));
    }, [breadcrumbs]);

    useEffect(() => {
        setCurrentItem(RoutingStore.getPathArr()[0]);
    }, [breadcrumbs, location]);

    const [dependDS, setDependDS] = useState<CustomDataset[]>([]);

    const propContainerGuid = useMemo(() => uuidv4(), []);
    const formActions = ActionStore.getFormActions(propContainerGuid);

    const handleBreadcrumbClick = (index: number) => {
        const pathArr = RoutingStore.getPathArr();
        RoutingStore.push(`/${pathArr.slice(0, index + 1).join('/')}`);
        setBreadcrumbs([...breadcrumbs].slice(0, index + 1));
    };

    const checkSourceType = (source: string) => {
        if (checkGuid(source)) {
            return source;
        }

        const resLink = propContainer.getResourceLink(source);

        if (resLink) return resLink.resource.code;

        return source;
    };

    const handleCancel = () => {
        formActions?.setForceReload();
    };

    useEffect(() => {
        if (sourceNameType === 'af') {
            setFormGuid(DataStore.AF.getAF(sourceName));
        }
        if (['guid', 'name'].includes(sourceNameType)) {
            setFormGuid(checkSourceType(sourceName));
        }
    }, [sourceName, DataStore.AF.getAF(sourceName)]);

    useEffect(() => {
        if (params?.length) {
            const paramDsList: CustomDataset[] = [];
            params.forEach(param => {
                if (param.paramType === 'field') {
                    const ds = propContainer.dataStock.getDatasetObj(param.sourceDataset);
                    if (ds) paramDsList.push(ds);
                }
            });

            if (paramDsList.length && !isEqual(paramDsList, dependDS)) {
                setDependDS(paramDsList);
            }
        }
    }, [JSON.stringify(propContainer.dataStock.getParams(params))]);

    useEffect(() => {
        if (dependDS.reduce((states: number, ds) => states + ds.state, 0) === dependDS.length)
            formActions?.setForceReload();
    }, [dependDS.reduce((states: number, ds) => states + ds.state, 0)]);

    const customButtons = useEditorCustomButtons(formActions, content);

    if (formGuid) {
        return ctrlVisible ? (
            <>
                {breadcrumbs.filter(
                    item => !!item.caption && (!item.viewMode || item.viewMode === 'container')
                )?.length ? (
                    <Grid item>
                        <Breadcrumbs aria-label="breadcrumb" sx={{ display: 'flex', pl: 5, pr: 5 }}>
                            {breadcrumbs
                                .filter(item => !item.viewMode || item.viewMode === 'container')
                                .map((item, index) =>
                                    index === breadcrumbs.length - 1 ? (
                                        <Typography
                                            key={index}
                                            color="text.primary"
                                            className="caption-h1"
                                        >
                                            {item.caption || item.name}
                                        </Typography>
                                    ) : (
                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <Link
                                            className="caption-h1"
                                            key={index}
                                            underline="hover"
                                            color="inherit"
                                            onClick={() => handleBreadcrumbClick(index)}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            {item.caption || item.name}
                                        </Link>
                                    )
                                )}
                        </Breadcrumbs>
                    </Grid>
                ) : null}
                <Grid
                    item
                    position="relative"
                    height={`calc(100% - ${editMode === 'edit' ? 180 : 120}px)`}
                    xs
                >
                    <CustomForm
                        key={formGuid}
                        formGuid={formGuid}
                        extParamVals={extParamVals}
                        initEditMode={editMode}
                        // cdoData={editor?.datasets}
                        parentDataStock={propContainer.dataStock}
                        parentPropContainer={propContainer}
                        propContainerGuid={propContainerGuid}
                    />
                </Grid>
                {formActions?.isReady && editMode === 'edit' ? (
                    <Grid item ml="auto" p={4}>
                        <Stack spacing={4} direction="row">
                            <Button
                                key="cancel"
                                autoFocus
                                onClick={() => handleCancel()}
                                color="primary"
                            >
                                {content.controls.buttons.cancel}
                            </Button>
                            {customButtons()}
                        </Stack>
                    </Grid>
                ) : null}
            </>
        ) : (
            <div />
        );
    }

    return <div />;
});

export default IterableContainer;
