import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import Grid from '@mui/material/Grid';

import Loading from 'components/utils/Loading/Loading';
import ModalForm from 'hoc/ModalForm';

import ActionStore from 'store/actionStore';
import ConfigurationStore from 'store/configurationStore';
import MainFormStore from 'store/mainFormStore';

import DataStock from 'dataObj/DataStock';
import PropContainer from 'dataObj/PropContainer';

import CustomForm from 'forms/CustomForm';
import { getControlClass } from 'forms/form-utils';

import useMaxWidth from 'hooks/maxWidth';
import useBackdrop from 'hooks/backdrop';
import useEditorCustomButtons from 'hooks/editorCustomButtons';

interface ResultType {
    warn?: never[];
    err?: never[];
    noReload?: boolean;
    isValid?: boolean;
    result?: any[];
}

interface PropsType {
    parentDataStock: DataStock;
    parentPropContainer: PropContainer;
    formGuid?: any;
    formDescr?: any;
    pathname?: string;
    setForm: any;
}

const MainFormModal: FunctionComponent<PropsType> = observer(
    ({ formGuid, formDescr, parentDataStock, parentPropContainer, setForm }) => {
        const { content } = ConfigurationStore;
        const { modalFormValues, formEditMode } = MainFormStore;

        const [editorName, setEditorName] = useState('Редактор записи');
        const [error, setError] = useState(false);

        const propContainerGuid = useMemo(() => uuidv4(), []);
        const formActions = ActionStore.getFormActions(propContainerGuid);

        useEffect(() => {
            if (formActions?.getName) {
                setEditorName(formActions.getName());
            }
        }, [formActions]);

        const hideForm = useCallback(() => {
            setForm(null);
        }, [setForm]);

        useEffect(() => {
            if (error) {
                hideForm();
            }
        }, [error]);

        const handleFormClose = async (state: boolean | object, payout?: any) => {
            if (state && typeof state === 'boolean' && formActions && formEditMode === 'edit') {
                formActions
                    .save()
                    .then((result?: ResultType | false) => {
                        if (result === false || !result?.isValid) return;

                        if (!result?.err?.length) {
                            hideForm();
                        }
                    })
                    .catch(err => console.error(err.message));
            } else if (state) {
                hideForm();
            } else {
                hideForm();
            }
        };

        const settled = useMemo(
            () => !!formActions && formActions?.isReady,
            [formActions, formActions?.isReady, error]
        );

        const maxWidth = useMaxWidth(formActions);

        const minHeight = useMemo(() => {
            if (formActions) {
                const height = getControlClass(
                    formActions.getFormDescr().classes,
                    'minHeight'
                )?.split('-')?.[1] as string;

                return height ? `calc(${height}vh - 148px)` : undefined;
            }

            return;
        }, [formActions]);

        useBackdrop(!settled && (formGuid || formDescr?.guid) && !error);

        const customButtons = useEditorCustomButtons(formActions, content);

        return formGuid || formDescr ? (
            <ModalForm
                formGuid={formGuid}
                maxWidth={maxWidth as false | 'xl' | 'md' | 'sm' | 'xs' | 'lg'}
                handleClose={handleFormClose}
                editorName={editorName}
                sx={{
                    visibility: settled ? 'visible' : 'hidden',
                    '& .MuiPaper-root': {
                        overflow: 'hidden'
                    },
                    '& .MuiDialogContent-root ': {
                        position: 'relative',
                        minHeight: minHeight ?? (!formActions?.isReady ? 300 : null)
                    }
                }}
                customActions={customButtons}
                disabled={!formActions?.validated}
                propContainerGuid={propContainerGuid}
            >
                <Grid
                    item
                    sx={
                        minHeight
                            ? {
                                  position: 'absolute',
                                  height: '100%',
                                  width: 'calc(100% - 48px)'
                              }
                            : {}
                    }
                >
                    {formGuid || formDescr ? (
                        <CustomForm
                            key={propContainerGuid}
                            formGuid={formGuid || formDescr?.guid}
                            formConfig={formDescr}
                            extParamVals={modalFormValues}
                            initEditMode={formEditMode}
                            parentDataStock={parentDataStock}
                            parentPropContainer={parentPropContainer}
                            propContainerGuid={propContainerGuid}
                            handleClose={hideForm}
                            handleError={(err: boolean) => setError(err)}
                            modal
                        />
                    ) : (
                        <Loading type="empty" message={content.controls.data.noEditor} />
                    )}
                </Grid>
            </ModalForm>
        ) : null;
    }
);

export default MainFormModal;
