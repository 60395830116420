import React, { FunctionComponent, memo, useCallback } from 'react';

import { Handle, Position } from 'reactflow';
import { Box, Grid, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import PropContainer from 'dataObj/PropContainer';
import CustomDataset from 'dataObj/customDataset';
import { IQueryRow } from 'dataObj/dataInterfaces';

import { ScriptButtonType } from 'forms/interfaces';

import State from './State';
import StateMenu from './StateMenu';

export type DataType = {
    taskId: number;
    number: string;
    deadline: string;
    title: string;
    dataRow: { dataset: CustomDataset; data: IQueryRow };
    state: string;
    user?: string;
    name?: string;
    iconSet?: string;
    buttons: ScriptButtonType[];
    propContainer: PropContainer;
};

interface NodeFlowTypes {
    data: DataType;
    isConnectable: boolean;
    selected: boolean;
    sourcePosition?: Position;
    targetPosition?: Position;
}

const NodeFlow: FunctionComponent<NodeFlowTypes> = ({
    data,
    isConnectable,
    selected,
    sourcePosition,
    targetPosition
}) => {
    const { number, title, deadline, state, user, name, iconSet, propContainer } = data;

    const handleStyle = {
        zIndex: -1,
        border: 0,
        background: 'grey'
    };

    const getStateColor = useCallback((border = false) => {
        switch (state) {
            case 'R':
                return border ? '#1B5DAA' : '#1B5DAA';

            case 'S':
                return '#1B5DAA';

            case 'I':
                return '#F3A505';

            case 'F':
                return '#1F8B24';

            case 'C':
                return '#aa0000';

            case 'W':
                return border ? '#BDBDBD' : '#555';

            default:
                return border ? '#BDBDBD' : '#555';
        }
    }, []);

    const getBGColor = useCallback(() => {
        switch (state) {
            case 'R':
                return 'rgba(189,189,189,0.01)';

            case 'S':
                return 'rgba(27,93,170,0.05)';

            case 'I':
                return 'rgba(243,165,5,0.1)';

            case 'F':
                return 'rgba(31,139,36,0.05)';

            case 'C':
                return 'rgba(189,189,189,0.01)';

            case 'W':
                return 'rgba(189,189,189,0.2)';

            default:
                return 'rgba(189,189,189,0.01)';
        }
    }, []);

    return (
        <>
            <Handle
                type="target"
                position={targetPosition || Position.Top}
                isConnectable={isConnectable}
                style={{ visibility: 'hidden' }}
            />
            <Card
                key={number}
                variant="outlined"
                sx={{
                    position: 'relative',
                    width: 300,
                    border: `${selected ? 2 : 1}px solid ${getStateColor(true)}`,
                    borderRadius: '12px',
                    backgroundColor: getBGColor() // '#eeffee'
                }}
            >
                <Box
                    sx={{
                        backgroundColor: getStateColor(true),
                        height: 40,
                        width: 40,
                        position: 'absolute',
                        rotate: '45deg',
                        top: -20,
                        left: -20
                    }}
                />
                <CardHeader
                    sx={{ pb: 0 }}
                    action={<StateMenu data={data} />}
                    title={
                        <Grid container sx={{ pr: 2 }}>
                            <Grid item xs={6} sx={{ alignSelf: 'center' }}>
                                <Typography
                                    variant="subtitle1"
                                    color="text.secondary"
                                    fontSize="1.4rem"
                                >
                                    {number}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right', alignSelf: 'center' }}>
                                <Typography variant="subtitle2" fontSize="1.4rem">
                                    {deadline}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                />

                <CardContent sx={{ height: 60, pt: 0, pb: 0, overflow: 'hidden' }}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ fontSize: '1.5rem', fontWeight: 600 }}
                    >
                        {title}
                    </Typography>
                </CardContent>

                <CardActions sx={{ p: 4 }}>
                    <Typography
                        gutterBottom
                        variant="body2"
                        component="div"
                        color="text.secondary"
                        sx={{
                            fontSize: '1.2rem',
                            fontWeight: 400,
                            m: 0,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                        }}
                    >
                        {name}
                    </Typography>

                    <State
                        iconSet={iconSet}
                        state={state}
                        propContainer={propContainer}
                        stateColor={getStateColor}
                    />
                </CardActions>
            </Card>
            <Handle
                type="source"
                position={sourcePosition || Position.Bottom}
                id="a"
                style={handleStyle}
                isConnectable={isConnectable}
            />
        </>
    );
};

export default memo(NodeFlow);
