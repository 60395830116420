import { FunctionComponent, ReactElement } from 'react';

import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

interface PropsType extends Omit<TooltipProps, 'title'> {
    title?: string;
    wrap?: boolean;
    wrapperStyle?: object;
    children: ReactElement;
}

const WithTooltip: FunctionComponent<PropsType> = ({
    title,
    wrap = false,
    wrapperStyle = {},
    children,
    ...props
}) => {
    if (title) {
        const renderChildren = () =>
            wrap ? <span style={wrapperStyle}>{children}</span> : children;

        return (
            <Tooltip title={title} {...props}>
                {renderChildren()}
            </Tooltip>
        );
    }

    return children;
};

export default WithTooltip;
