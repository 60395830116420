import { action, makeAutoObservable, observable, set, get } from 'mobx';

import { EditModeType, RouterItemType } from 'forms/interfaces';

import { initRouting } from 'utils/index';

const validateResultString = (template: string, resultString: string): boolean => {
    const variableNames = template.match(/{\s*(\w+)\s*}/g)?.map(match => match.slice(1, -1)) || [];

    let validationRegex = template;
    variableNames.forEach(variableName => {
        validationRegex = validationRegex.replace(
            new RegExp(`{\\s*${variableName}\\s*}`, 'g'),
            '([a-zA-Z0-9_.]+)'
        );
    });

    return new RegExp(`^${validationRegex}$`).test(resultString);
};

export const getRouteParams = (template: string, url: string) => {
    // Извлекаем названия переменных из шаблона
    const variableNames = template.match(/{\s*(\w+)\s*}/g)?.map(match => match.slice(1, -1)) || [];

    // Создаём объект для хранения значений
    const extractedValues: Record<string, string> = {};

    let validationRegex = template;
    // Генерируем регулярное выражение для извлечения значений
    variableNames.forEach((variableName, index) => {
        validationRegex = validationRegex.replace(
            new RegExp(`{\\s*${variableName}\\s*}`, 'g'),
            '([a-zA-Z0-9_.]+)'
        );
    });

    // Извлекаем значения
    const decompose = new RegExp(`^${validationRegex}$`).exec(url);

    // Заполняем значения переменных
    if (decompose?.length && decompose?.length > 1) {
        decompose.forEach((value, index) => {
            if (index > 0) {
                extractedValues[variableNames[index - 1]] = value;
            }
        });
    }

    // eslint-disable-next-line
    const { cdotype_id, ...values } = extractedValues;

    return {
        cdotype_id,
        values
    };
};

class Routing {
    @observable routes: { [key: string]: RouterItemType } = {};

    private history: any | null = null;

    constructor() {
        makeAutoObservable(this);
        initRouting(this);
    }

    @observable path: RouterItemType[] = [];

    @action setHistory(history: any) {
        this.history = history;
    }

    @action setRoute(route: RouterItemType) {
        set(this.routes, route.route, route);
    }

    @action push(route: string, stack = false) {
        this.history?.push(
            !stack
                ? `/${route.replace(/^\/|\/$/g, '')}`
                : `/${this.getPathArr().join('/')}/${route}`
        );
    }

    @action replace(route: string) {
        this.history?.replace(route);
    }

    getRoute(name: string) {
        let route = get(this.routes, name);

        if (route) {
            return route;
        }

        Object.keys(this.routes).forEach(key => {
            if (validateResultString(key, name)) {
                route = get(this.routes, key);
            }
        });

        return route;
    }

    getRouteByResource(
        guid?: string,
        editMode?: EditModeType,
        viewMode?: 'modal' | 'container'
    ): RouterItemType | null {
        let route = null;

        if (!guid) {
            return route;
        }

        Object.keys(this.routes).forEach(key => {
            const tmp = get(this.routes, key);
            const resourceCode = tmp?.resource?.code;

            if (
                resourceCode === guid &&
                (!editMode || (editMode && tmp?.editMode === editMode)) &&
                (!viewMode || (viewMode && tmp?.viewMode === viewMode))
            ) {
                route = tmp;
            }
        });

        return route;
    }

    getHistory() {
        return this.history;
    }

    getPathArr(): string[] {
        return this.history?.location.pathname.split('/').filter((item: string) => !!item) || [];
    }

    getCurrentPath(): string {
        return (
            this.history?.location.pathname
                .split('/')
                .filter((item: string) => !!item)
                .pop() || ''
        );
    }
}

const RoutingStore = new Routing();
export default RoutingStore;
