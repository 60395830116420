import { FunctionComponent } from 'react';

import { HeaderCellType } from '../../interfaces';

const SchedulerCell: FunctionComponent<HeaderCellType> = ({
    disabled = false,
    children,
    slotId,
    className
}) => (
    <div
        id={slotId}
        role="gridcell"
        tabIndex={0}
        className={`scheduler-cell heading-cell${disabled ? ' disabled' : ''}${
            className ? ` ${className}` : ''
        }`}
    >
        {children}
    </div>
);

export default SchedulerCell;
