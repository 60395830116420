import { FunctionComponent, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import NotificationStore from 'store/notificationStore';

import TreeRootItem from '../../TreeItem/RootItem';

import { TreeItemRoot } from '../../../interfaces';

const RootNode: FunctionComponent<TreeItemRoot> = observer(props => {
    const {
        labelText,
        index,
        dataset,
        parentId,
        appendLoading,
        setAppendLoading,
        addRootNode,
        ...other
    } = props;

    const dsOptions = useMemo(
        () => (dataset.descr.options ? JSON.parse(dataset.descr.options) : {}),
        [dataset]
    );

    const currentDataChunk = useMemo(() => dataset.getChunkByKey(parentId), [dataset]);

    useEffect(() => {
        if (index === 0 && !dsOptions.archive && !props.disabled) {
            dataset.setCurrentDataChunk(currentDataChunk);
            dataset.edit();
        }
    }, []);

    const handleOpen = (open: boolean) => {
        if (!dsOptions.archive && !props.disabled && open) {
            dataset.post();
            dataset.setCurrentDataChunk(currentDataChunk);

            if (!currentDataChunk?.data?.length && !props.disabled) {
                setAppendLoading(true);

                dataset.cdo
                    ?.append(dataset)
                    .then(() => {
                        dataset.edit();
                    })
                    .catch((err: { message: string }) => {
                        console.log(err.message);
                        NotificationStore.enqueueSnackbar({
                            message: err.message,
                            options: { variant: 'error' }
                        });
                    })
                    .finally(() => setAppendLoading(false));
            } else {
                dataset
                    .setActiveRec(index, { keepInEdit: true, skipCheck: true })
                    .then(() => {
                        dataset.edit();
                    })
                    .catch((err: { message: string }) => console.error(err.message));
            }
        }
    };

    return (
        <TreeRootItem
            labelText={labelText}
            addRootNode={addRootNode}
            handleOpen={handleOpen}
            archive={dsOptions.archive}
            defaultExpanded={dataset.editType === 'root'}
            appendLoading={appendLoading}
            {...other}
        />
    );
});

export default RootNode;
