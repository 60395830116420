import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import DataObjectIcon from '@mui/icons-material/DataObject';

import Tooltip from '@mui/material/Tooltip';

import IconButton from 'components/Inputs/IconButton';
import MUIButton from 'components/Inputs/Button';

import { TCustomToolbarButton } from '../interface';

interface PropsType {
    button: TCustomToolbarButton;
    fullWidth?: boolean;
}

const CustomToolbarButton: FunctionComponent<PropsType> = observer(({ button, fullWidth }) => {
    const { caption, variant, icon, iconBtn, iconPosition, color, disabled, onClick } = button;

    return fullWidth ? (
        <MUIButton
            variant={variant || 'contained'}
            onClick={() => onClick()}
            disabled={disabled}
            sx={{ whiteSpace: 'nowrap', height: 'fit-content', alignSelf: 'center' }}
            startIcon={!iconPosition || iconPosition === 'start' ? icon : null}
            endIcon={iconPosition === 'end' ? icon : null}
            color={color}
        >
            {caption}
        </MUIButton>
    ) : (
        <Tooltip title={caption}>
            <span style={{ alignSelf: 'center' }}>
                <IconButton onClick={() => onClick()} disabled={disabled} color={color}>
                    {iconBtn || icon ? iconBtn || icon : <DataObjectIcon />}
                </IconButton>
            </span>
        </Tooltip>
    );
});

export default CustomToolbarButton;
