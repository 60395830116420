import React, { FunctionComponent } from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

type CustomSvgIconProps = SvgIconProps & {
    icon: string;
};

const CustomSvgIcon: FunctionComponent<CustomSvgIconProps> = ({ icon, ...props }) => {
    if (icon) {
        const viewBox = (/viewBox="([^"]+)"/.exec(icon) || '')[1];

        return (
            <SvgIcon viewBox={viewBox ?? '0 0 24 24'} sx={{ verticalAlign: 'middle' }} {...props}>
                <g dangerouslySetInnerHTML={{ __html: icon }} />
            </SvgIcon>
        );
    }

    return null;
};

export default CustomSvgIcon;
