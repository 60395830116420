import { ResourceEventType } from 'forms/interfaces';

import {
    afterDelete,
    beforeScroll,
    afterScroll,
    beforeDelete,
    onClose,
    onDataChange,
    afterDataChange,
    onError,
    onHide,
    afterOpen,
    onNewRecord,
    onSave,
    onShow,
    afterDataLoad
} from './events';

export const triggers = (resourceType: string) => {
    switch (resourceType) {
        case 'formEvent':
            return [onShow, afterDataLoad, onHide, onSave, onClose, onError];

        case 'wuiForm':
            return [onShow, afterDataLoad, onHide, onSave, onClose, onError];

        case 'panel':
        case 'controlEvent':
            return [onShow, onHide];

        case 'dataset':
        case 'clientDataset':
            return [
                afterOpen,
                onNewRecord,
                onDataChange,
                afterDataChange,
                beforeScroll,
                afterScroll,
                beforeDelete,
                afterDelete
            ];

        default:
            return [];
    }
};

export const filterEventType = (resourceType: string, event: ResourceEventType) => {
    const availableTriggers = triggers(resourceType);

    return availableTriggers.includes(event.trigger);
};
