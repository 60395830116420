import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';

import {
    Dialog,
    IconButton,
    Tooltip,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from '@mui/icons-material/WarningAmber';

import { ResourceClass } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';

import Loading from 'components/utils/Loading/Loading';

import ResCtrlList from '../ResCtrlList';

interface SelectResourcePropsType {
    loading: boolean;
    error?: boolean;
    notFound?: boolean;
    resource?: ResourceClass;
    onSelect: any;
}

const SelectOverlap: FunctionComponent<SelectResourcePropsType> = observer(props => {
    const { loading, resource, onSelect, error = false, notFound = false } = props;

    const { content } = ConfigurationStore;

    const [editOpen, setEditOpen] = useState(false);

    const handleEditOpen = () => {
        if (resource) {
            setEditOpen(true);
        }
    };

    const handleEditClose = () => {
        setEditOpen(false);
    };

    const handleSaveResource = () => {
        onSelect(resource?.curCtrl?.guid);
        setEditOpen(false);
    };

    const getIcon = () => {
        if (error)
            return (
                <IconButton disabled>
                    <WarningIcon fontSize="small" color="error" />
                </IconButton>
            );

        if (notFound) {
            return (
                <>
                    <IconButton disabled>
                        <WarningIcon fontSize="small" color="warning" />
                    </IconButton>
                    <IconButton onClick={handleEditOpen}>
                        <SearchIcon fontSize="small" />
                    </IconButton>
                </>
            );
        }

        return loading ? (
            <IconButton disabled>
                <CircularProgress size={20} />
            </IconButton>
        ) : (
            <IconButton onClick={handleEditOpen}>
                <SearchIcon fontSize="small" />
            </IconButton>
        );
    };

    return (
        <>
            <Tooltip title={content.resource.buttons.search}>{getIcon()}</Tooltip>
            <Dialog
                fullWidth
                open={editOpen}
                maxWidth="sm"
                sx={{ '& .MuiDialog-paper': { height: '90vh' } }}
                onClose={handleEditClose}
            >
                <DialogContent>
                    {resource && resource.initRes ? (
                        <ResCtrlList
                            ResourceStore={resource}
                            parent={null}
                            arr={[resource.initRes]}
                            readOnly
                        />
                    ) : (
                        <Loading />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose}>{content.application.buttons.cancel}</Button>
                    <Button onClick={handleSaveResource}>{content.application.buttons.ok}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default SelectOverlap;
