import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { observable, action, set, get, remove, makeObservable } from 'mobx';

import { jsonFetch, saveFile, confirmDialog } from 'utils/index';
import HttpError from 'utils/HttpError';

import resValidator from 'components/resources/clientResValidator';
import { Field, Resource } from 'components/resources/ResEditor/interfaces';
import ImportDetails from 'components/resources/ResEditor/ImportDetails';

import scriptClientInfo from 'scripts/script-client-jsdoc';

import { collectDataset } from 'forms/form-utils';

import {
    DatasetFieldType,
    DatasetType,
    QueryType,
    SimpleObject,
    ScriptGlossaryType,
    ResourceType,
    ResourceItem,
    ResourceLinkType
} from 'forms/interfaces';

import ConfigurationStore, { parseStorage } from 'store/configurationStore';
import NotificationStore from 'store/notificationStore';
import DescriptorStore from 'store/descriptorStore';
import { triggers } from 'store/eventsStore/eventTriggers';

import { UserResource } from './index';

type ResourceListItem = {
    code: string;
    type: string;
    name: string;
    createDate: string;
    updateDate: string;
    guid?: string;
};

type ConfigType = {
    type?: string;
    datasetName: string;
    fieldName: string;
    label: string;
    lookupData?: any;
};

export type SaveOptions = 'overwrite' | 'merge';

export enum SettingType {
    global = 'global',
    user = 'user',
    allUsers = 'allUsers'
}

const findCtrl = (ctrl: any, key: string, value: string) => {
    if (ctrl[key] === value) {
        return ctrl;
    }

    const keys = Object.keys(ctrl);

    for (let i = 0; i < keys.length; i++) {
        const k = keys[i];

        if (ctrl[k] && Array.isArray(ctrl[k])) {
            for (let j = 0; j < ctrl[k].length; j++) {
                const found: any = findCtrl(ctrl[k][j], key, value);

                if (found) return found;
            }
        }
    }
};

export const getAvailableCtrlList = (ctrl: any, parentSection?: string): string[] | null =>
    parentSection
        ? resValidator.getTypeDescriptor(ctrl?.type || '')?.fields?.[parentSection]?.children ||
          null
        : null;

const findCtrlPath = (
    ctrl: any,
    key: string,
    value: string,
    openedTree: { [key: string]: boolean } = {},
    parentSection?: string
) => {
    if (ctrl[key] === value) {
        openedTree = { ...openedTree, ...{ [`${ctrl.guid as string}${key}`]: true } };

        return { ctrl, openedTree, parentSection };
    }

    const keys = Object.keys(ctrl);

    for (let i = 0; i < keys.length; i++) {
        const k = keys[i];

        if (ctrl[k] && Array.isArray(ctrl[k])) {
            for (let j = 0; j < ctrl[k].length; j++) {
                const tempTree = { ...openedTree, ...{ [`${ctrl.guid as string}${k}`]: true } };

                const found: any = findCtrlPath(ctrl[k][j], key, value, tempTree, k);

                if (found) {
                    return {
                        ...found,
                        ...{ availableCtrl: getAvailableCtrlList(ctrl[k][j], found.parentSection) }
                    };
                }
            }
        }
    }
};

// Рекурсивно обходим дочерние датасеты
const getDetailDS = (list: DatasetType[], dataset: DatasetType) => {
    if (dataset?.datasets?.length) {
        list.push(...dataset.datasets);
        dataset.datasets.forEach(ds => getDetailDS(list, ds));
    }
};

// Рекурсивно обходим ресурс по контролам в поисках датасетов
const getNestedDS = (list: DatasetType[], resource: Resource | { [key: string]: any } | null) => {
    if (!resource) {
        return;
    }

    const { datasets, controls } = resource;
    if (datasets) {
        list.push(...resource.datasets);
        resource.datasets.forEach((ds: DatasetType) => getDetailDS(list, ds));
    }

    controls?.forEach((control: any) => getNestedDS(list, control));
};

const getPropList = () => [
    { key: 'enabled' },
    { key: 'visible' },
    { key: 'hidden' },
    { key: 'required' },
    { key: 'caption' },
    { key: 'tooltip' },
    { key: 'badge' },
    { key: 'icon' },
    { key: 'mask' },
    { key: 'colorToken' },
    { key: 'gridWidth' }
];

type CalypsoError = {
    code: string;
    message: string;
    detail?: {
        code: string;
        message: string;
        details: string[];
    }[];
};

const externalResources = ['wuiExtQuery', 'table'];

/**
 * Класс для каскадной работы с ресурсом
 * @class ResourceClass
 */
export class ResourceClass {
    @observable parentResource?: ResourceClass;
    @observable resList: Resource | { [key: string]: any } | null = {};
    @observable curGrp = '';
    @observable filterName = '';
    @observable tags: string[] = [];
    @observable fromDate: Date | null = null;

    // Ресурс
    @observable curResCode: string | null = null; // Код текущего ресурса
    @observable curRes: Resource | SimpleObject | null = null; // Текущий редактируемый ресурс
    @observable initRes: null | Resource | SimpleObject = null; // Изначальный ресурс
    @observable editRes: null | Resource | SimpleObject = null; // Изменяемый ресурс
    @observable ancestorRes: null | Resource | SimpleObject = null; // Ресурс-донор

    // Контрол
    @observable curCtrl: null | SimpleObject = null; // Текущий редактируемый контрол
    @observable initCtrl: null | Resource | Field | SimpleObject = null; // Изначальный контрол
    @observable editCtrl: null | Resource | Field | SimpleObject = null; // Изменяемый контрол

    @observable copiedCtrl: null | Resource | Field = null; // Скопированный контрол

    @observable alertMessage: string | null = null;
    @observable resListLoading = false;
    @observable resLoading = false;
    @observable resPropsLoading = false;
    @observable curResLoading = false;
    @observable modified = false;
    @observable newRes = false;
    @observable previewResource: string | null = null;
    @observable subForms: { guid: string; name: string; type: string }[] = [];

    // Вложенные ресурсы
    @observable resources: { [key: string]: ResourceClass } = {};

    // Состояние раскрытия дерева вложенного ресурса
    @observable openedTree = {};

    @observable lastError: CalypsoError | undefined;
    @observable treeWidth = 450;

    // Ширина для разделителя
    @observable propsWidth = 850;

    // Вкладка страницы ресурсов
    @observable tab = '';
    @observable prevTab = '';

    @observable availableCtrl: string[] | null = null;

    private readonly onSave?: () => void;

    constructor(parentResource?: ResourceClass, onSave?: () => void) {
        this.parentResource = parentResource;
        this.onSave = onSave;
        makeObservable(this);
    }

    @action cacheChildResourceList = (current?: string) => {
        localStorage.setItem(
            'resources',
            JSON.stringify({
                current,
                list: Object.keys(this.resources).map(key => {
                    const type = key.split(':')[0];
                    const code = key.split(':')[1];

                    return {
                        key,
                        type,
                        code,
                        selected: this.resources[key].curCtrl?.guid,
                        openedTree: this.resources[key].openedTree
                    };
                })
            })
        );
    };

    @action updateCachedCurCtrl = () => {
        const storageContent = localStorage.getItem('resources') as string;

        if (storageContent) {
            const resource = parseStorage(storageContent) as UserResource;
            const type = this.curRes?.type || '';
            const code = this.curRes?.guid || '';
            const curResKey = `${type as string}:${code as string}`;

            localStorage.setItem(
                'resources',
                JSON.stringify({
                    current: resource.current,
                    list: [
                        ...resource.list.filter(({ key }) => key !== curResKey),
                        ...[
                            {
                                key: curResKey,
                                type,
                                code,
                                selected: this.curCtrl?.guid,
                                openedTree: this.openedTree
                            }
                        ]
                    ]
                })
            );
        }
    };

    @action prepareChildResourceList = async () => {
        const storageContent = localStorage.getItem('resources') as string;

        if (storageContent) {
            const resource = parseStorage(storageContent) as UserResource;

            if (resource?.list?.length) {
                await Promise.all(
                    resource.list.map(async resItem => {
                        const { key, selected, openedTree } = resItem;
                        const childRS = new ResourceClass();

                        try {
                            await childRS.selectResource(resItem as any);
                            childRS.selectCtrlBy('guid', selected);
                            childRS.setOpenedTree(openedTree);
                            this.setChildResource(key, childRS, false);
                        } catch (err: any) {
                            console.error(err.message);
                        }
                    })
                );

                return resource?.current || resource.list[0].key;
            }

            return null;
        }

        return null;
    };

    initializeStructure = () => {
        resValidator.getStructDescriptor().catch((err: any) => console.error(err.message));
    };

    @action setCurCtrl = (data: { [key: string]: any } | null) => {
        this.curCtrl = data;
    };

    @action setNewRes = (newRes: boolean) => {
        this.newRes = newRes;
    };

    @action setCurGrp = (grp: string) => {
        this.curGrp = grp ?? '';
    };

    @action setFilterName = (name: string) => {
        this.filterName = name ?? '';
    };

    @action setTags = (list: string[]) => {
        this.tags = list ?? [];
    };

    @action setFromDate = (date: string) => {
        this.fromDate = date ? new Date(date) : null;
    };

    @action setResLoading = (loading: boolean) => {
        this.resLoading = loading;
    };

    @action setResPropsLoading = (loading: boolean) => {
        this.resPropsLoading = loading;
    };

    @action setCurResLoading = (loading: boolean) => {
        this.curResLoading = loading;
    };

    @action setResList = (data: Resource | { [key: string]: any } | null) => {
        this.resList = data;
    };

    @action setCurRes = (data: Resource | { [key: string]: any } | null) => {
        this.curRes = data;
    };

    @action getSubForms = (
        resource = this.initRes,
        subForms: { guid: string; name: string; type: string }[] = []
    ) => {
        const resSubForms: Resource[] = resource?.subForms;

        if (resSubForms) {
            subForms = [
                ...subForms,
                ...resSubForms.map(subForm => ({
                    key: subForm.guid,
                    guid: subForm.guid || '',
                    name: subForm.name || '',
                    type: subForm.type as ResourceType
                }))
            ];

            resSubForms?.forEach((subForm: Resource) => this.getSubForms(subForm, subForms));
        }

        return subForms as ResourceItem[];
    };

    @action setAlertMessage = (message: string | null) => {
        this.alertMessage = message;
    };

    @action setResListLoading = (loading: boolean) => {
        this.resListLoading = loading;
    };

    @action checkIsModified = () => {
        this.getFormData();
        this.modified = !!this.needSave();
    };

    @action getResourceList = async (
        types: Array<any> = [],
        filter = '',
        fromDate: null | Date = null
    ) => {
        this.setResListLoading(true);

        try {
            const params = [];

            if (types) {
                params.push(`types=${encodeURIComponent(JSON.stringify(types))}`);
            }
            if (filter) {
                params.push(`filter=${encodeURIComponent(filter)}`);
            }
            if (fromDate) {
                params.push(`fromDate=${encodeURIComponent(fromDate.toISOString())}`);
            }

            const data: ResourceListItem[] = await jsonFetch(
                `resources/${params ? `?${params.join('&')}` : ''}`,
                'get'
            );

            return this.setResList(
                data.map(item => ({ ...item, key: `${item.type}:${item.code}` }))
            );
        } catch (err) {
            console.error(err);
            this.setResList([]);

            if (err instanceof HttpError && err.code === 401) ConfigurationStore.logout();
        } finally {
            this.setResListLoading(false);
            this.setNewRes(false);
        }
    };

    @action getTaglist = async (): Promise<string[]> => jsonFetch('restags', 'get');

    @action initResource = (
        res: Resource | { [key: string]: any } | null,
        selectedCtrl?: string
    ) => {
        this.initRes = res;
        this.editRes = cloneDeep(res);

        this.initCtrl = this.initRes;
        this.editCtrl = this.editRes;

        if (res?.type === 'inheritor') {
            this.readResource(res.ancestor.type, res.ancestor.code, true)
                .then(ancestorRes => {
                    this.ancestorRes = ancestorRes as Resource;
                })
                .catch(err => {
                    console.error(err.message);
                });
        }

        this.setCurRes(this.editRes);
        if (selectedCtrl) {
            selectedCtrl && this.selectCtrl(findCtrl(this.editCtrl, 'guid', selectedCtrl));
        } else this.selectCtrl(this.editCtrl as any);
    };

    @action getResource = async (type: ResourceType, code: string, clearCache?: boolean) => {
        const editResGuid = this.curCtrl?.guid as string;
        const loadedRes = (await this.readResource(type, code, clearCache)) as Resource;
        this.initResource(loadedRes as any, editResGuid);
    };

    @action readResource = async (type: ResourceType, code: string, clearCache?: boolean) => {
        try {
            return await jsonFetch(`resources/${type}/${code}`, 'GET', { clearCache });
        } catch (err: any) {
            NotificationStore.showAlert(err.message);
        }
    };

    @action resetCache = async () => {
        await jsonFetch(`resetCache`, 'GET');
        NotificationStore.enqueueSnackbar({
            message: ConfigurationStore.content.resource.snackbars.operation.success,
            options: {
                variant: 'success'
            }
        });
    };

    @action saveResource = async (options?: SaveOptions) => {
        this.getFormData();
        this.setResPropsLoading(true);

        const editResGuid = this.curCtrl?.guid as string;
        const data = this.editRes;
        const method = data?.guid ? 'PUT' : 'POST';

        try {
            const { taskId } = ConfigurationStore;

            const savedRes = await jsonFetch('resources', method, {
                data,
                options,
                taskId
            });

            this.newRes && (await this.getResourceList([data?.type ?? 'all']));

            this.initResource(savedRes as any);
            this.selectCtrl(findCtrl(this.editRes, 'guid', editResGuid));

            NotificationStore.enqueueSnackbar({
                message: ConfigurationStore.content.resource.snackbars.save.success,
                options: {
                    variant: 'success'
                }
            });

            if (this.newRes) this.setNewRes(false);

            if (savedRes) {
                const res = savedRes as Resource;
                if (res.guid) {
                    DescriptorStore.removeDescriptor(res.guid);
                }
            }

            this.onSave && this.onSave();
        } catch (err: any) {
            const message = err.message
                ? err.message
                : ConfigurationStore.content.resource.snackbars.save.error;

            NotificationStore.enqueueSnackbar({
                message,
                options: {
                    variant: 'error'
                }
            });

            this.setLastError(err.content);
        } finally {
            this.setResPropsLoading(false);
        }
    };

    @action setLastError = (lastError: CalypsoError | undefined) => {
        this.lastError = lastError;
    };

    @action importStatQuery = async () => {
        const requestName = this.editRes?.name;
        if (requestName) {
            try {
                const url =
                    this.editRes?.type === 'wuiStatQuery' ? 'import/statquery' : 'import/extquery';

                const newRes = await jsonFetch(url, 'POST', { requestName });

                this.editRes = newRes as any;
                this.setCurRes(this.editRes);
                this.setCurCtrl(this.editRes);
            } catch (e) {
                NotificationStore.showAlert(
                    ConfigurationStore.content.resource.alert.statReNotExist(requestName)
                );
            }
        } else {
            NotificationStore.showAlert(ConfigurationStore.content.resource.alert.nameNotSpec);
        }
    };

    @action reloadResource = async (type: string) => {
        const queryList = ['wuiStatQuery'];

        if (queryList.includes(type)) {
            const queryType = queryList;

            if (queryType.includes(this.editRes?.type)) {
                this.importStatQuery().catch(err => console.error(err.message));
            } else {
                NotificationStore.showAlert('Resource cannot be imported');
            }
        } else if (type === ResourceType.cdo && this.editRes) {
            const { guid } = this.editRes;
            try {
                const newRes = await jsonFetch('dataobject/import', 'POST', { guid });

                this.editRes = newRes as Resource;
                this.setCurRes(this.editRes);
                this.setCurCtrl(this.editRes);
            } catch (e: any) {
                NotificationStore.showAlert(
                    ConfigurationStore.content.resource.alert.statReNotExist(e.message)
                );
            }
        } else {
            const editResGuid = this.curCtrl?.guid as string;
            const data = this.editRes;

            if (data?.type && data?.guid) {
                const updatedRes = await this.readResource(data.type, data.guid);

                this.initResource(updatedRes as any);
                this.selectCtrl(findCtrl(this.editRes, 'guid', editResGuid));
            }
        }
    };

    @action updateResource = async (type: string, code: string) => {
        this.setCurResLoading(true);
        await this.getResource(type as ResourceType, code, true);
        this.setCurResLoading(false);
    };

    @action exportResource = async () => {
        if (this.needSave()) {
            return NotificationStore.showAlert(ConfigurationStore.content.resource.alert.needSave);
        }

        const editRes = this.editRes as Resource;

        if (!editRes) {
            return;
        }

        if (editRes.type === ResourceType.group && editRes.guid) {
            try {
                const data = await jsonFetch(
                    `resources/${editRes.guid}/archive`,
                    'GET',
                    undefined,
                    undefined,
                    'blob'
                );
                // jsonFetch не отдает headers, имя подставляю руками
                saveFile(data, `${this.editRes?.name as string}.zip`, 'application/zip');
            } catch (err: any) {
                NotificationStore.showAlert(err.message);
            }
        } else {
            this.getFormData();

            const resource = { ...this.editRes };
            const resourceList = [resource];

            const confirm = await confirmDialog(
                ConfigurationStore.content.resource.dialog.exportLinkedResource
            );

            if (confirm) await this.getLinkedResource(resource, resourceList);

            saveFile(JSON.stringify(resourceList, null, 2), `${this.editRes?.name as string}.json`);
        }
    };

    @action importResource = async (
        file: File,
        showResource: (item: ResourceItem) => void,
        overwrite?: boolean
    ) => {
        if (file) {
            try {
                await jsonFetch('import/resource', 'POST', file, {}, 'json', {
                    file: true,
                    overwrite
                });

                NotificationStore.enqueueSnackbar({
                    message: ConfigurationStore.content.resource.snackbars.import.success,
                    options: {
                        variant: 'success'
                    }
                });

                let content: any;
                const reader = new FileReader();

                reader.addEventListener('load', async e => {
                    content = e?.target?.result;
                    const descr = JSON.parse(content);
                    if (descr) {
                        showResource(descr[descr.length - 1]);
                    }
                });
                reader.readAsBinaryString(file);
            } catch (err: any) {
                NotificationStore.enqueueSnackbar({
                    message: ConfigurationStore.content.resource.snackbars.import.error,
                    options: {
                        variant: 'error',
                        action: () =>
                            ImportDetails({
                                title: ConfigurationStore.content.resource.snackbars.details,
                                details: err?.content,
                                importResource: async () =>
                                    this.importResource(file, showResource, true)
                            })
                    }
                });
            }
        } else {
            NotificationStore.showAlert(ConfigurationStore.content.resource.alert.cantImport);
        }
    };

    @action getLinkedResource = async (resource: any, resourceList: Array<any>) => {
        if (resource.resourceLinks?.length) {
            const resLinkList: ResourceLinkType[] = resource.resourceLinks;

            for (let i = 0; i < resLinkList.length; i++) {
                const linkedResource = resLinkList[i].resource;

                if (linkedResource && !externalResources.includes(linkedResource.type)) {
                    const index = resourceList
                        .map((res: any) => res.guid)
                        .indexOf(linkedResource.code);

                    if (index === -1) {
                        // eslint-disable-next-line no-await-in-loop
                        const resLink = await this.readResource(
                            linkedResource.type,
                            linkedResource.code
                        );

                        resourceList.unshift(resLink);
                        // eslint-disable-next-line no-await-in-loop
                        await this.getLinkedResource(resLink, resourceList);
                    } else {
                        resourceList.unshift(resourceList.splice(index, 1)[0]);
                    }
                }
            }
        }
    };

    @action cancelEdit = () => {
        this.initResource(this.initRes);
    };

    needSave = () => this.initRes && this.editRes && !isEqual(this.initRes, this.editRes);

    @action selectResource = async (resItem: ResourceItem) => {
        this.curResCode = resItem.code;
        return this.getResource(resItem.type, resItem.code);
    };

    // Селектор в дереве контролов
    @action selectCtrlBy = (key: string, value: string) => {
        const find = findCtrlPath(this.editRes, key, value);

        if (find) {
            const { ctrl, openedTree, availableCtrl } = find;

            this.selectCtrl(ctrl, availableCtrl);

            return openedTree;
        }
    };

    @action addResource = () => {
        if (this.needSave()) {
            NotificationStore.showAlert(ConfigurationStore.content.resource.alert.needSave);
        } else {
            const newRes = this.createResource(ResourceType.form);
            this.initResource(newRes);
            return newRes;
        }
    };

    @action getFormData = () => {
        const { initCtrl, editCtrl } = this;

        if (initCtrl && editCtrl) {
            // Перебор свойств редактируемого контрола
            for (const key in editCtrl) {
                // Получение объекта свойства из инициализирующего контрола
                let obj = initCtrl[key as keyof typeof initCtrl];

                // Исключаем массивы
                if (!Array.isArray(obj)) {
                    if (typeof obj === 'object' && obj !== null) {
                        if (editCtrl[key as keyof typeof editCtrl]) {
                            // Объединение инициирующего объекта с редактируемым
                            obj = { ...editCtrl[key as keyof typeof editCtrl] };
                        } else obj = undefined;
                        // Если не объект, то меняется значение целиком
                    } else obj = editCtrl[key as keyof typeof editCtrl];

                    // Присваиваем значение обратно принудительно
                    initCtrl[key as keyof typeof initCtrl] = obj;
                }
            }
        }
    };

    @action changeCtrlType = (newType: string) => {
        const oldType = this.editCtrl?.type;
        if (this.editCtrl) this.editCtrl.type = newType;
        const newCtrl: SimpleObject = resValidator.validate(this.editCtrl || {});
        newCtrl.name = newCtrl.name.replace(oldType, newType);

        const { initCtrl } = this;

        // удаляем все свойста из объекта,
        // но ссылка this.initCtrl не меняется
        for (const key in initCtrl) {
            delete initCtrl[key as keyof typeof initCtrl];
        }

        for (const key in newCtrl) {
            if (initCtrl) initCtrl[key as keyof typeof initCtrl] = newCtrl[key];
        }

        this.editCtrl = cloneDeep(initCtrl);
        this.setCurCtrl(this.editCtrl);
    };

    @action selectCtrl = (
        ctrl: Resource | Field | { [key: string]: any },
        availableCtrl?: string[] | null
    ) => {
        this.getFormData();
        this.initCtrl = ctrl;
        this.editCtrl = cloneDeep(ctrl);
        this.setCurCtrl(ctrl);
        this.availableCtrl = availableCtrl || null;
    };

    @action deleteCtrl = (arr: Array<any>, ind: number, ctrl: Resource | Field) => {
        if (this.editRes && arr[ind] && arr[ind].guid === ctrl?.guid) {
            arr.splice(ind, 1);
            this.editRes = cloneDeep(this.editRes);
            this.setCurRes(this.editRes);

            this.selectCtrl(this.editRes);
        }
    };

    @action _clearGuids = (ctrl: Resource | Field) => {
        if (ctrl.guid) {
            ctrl.guid = undefined;
        }
        for (const key in ctrl) {
            if (Array.isArray(ctrl[key as keyof typeof ctrl])) {
                ctrl[key as keyof typeof ctrl]?.forEach((c: any) => this._clearGuids(c));
            }
        }
    };

    @action copyCtrl = (arr: Array<any>, ind: number, ctrl: Resource | Field) => {
        if (arr[ind] && arr[ind].guid === ctrl.guid) {
            this.copiedCtrl = cloneDeep(ctrl);
            // новые guid присвоятся при валидации во время вставки
            this._clearGuids(this.copiedCtrl);
        }
    };

    @action updateCurRes = (data: Resource | object | null) => {
        this.setCurRes(cloneDeep(data));
        this.editRes = cloneDeep(data);
    };

    @action upCtrl = (arr: Array<any>, ind: number, ctrl: Resource | Field) => {
        if (this.editRes && ind > 0 && arr[ind] && arr[ind].guid === ctrl.guid) {
            const temp = arr[ind];
            arr[ind] = arr[ind - 1];
            arr[ind - 1] = temp;
            this.editRes = cloneDeep(this.editRes);
            this.setCurRes(this.editRes);
        }
    };

    @action downCtrl = (arr: Array<any>, ind: number, ctrl: Resource | Field) => {
        if (this.editRes && ind < arr.length - 1 && arr[ind] && arr[ind].guid === ctrl.guid) {
            const temp = arr[ind];
            arr[ind] = arr[ind + 1];
            arr[ind + 1] = temp;
            this.editRes = cloneDeep(this.editRes);
            this.setCurRes(this.editRes);
        }
    };

    @action topCtrl = (arr: Array<any>, ind: number, ctrl: Resource | Field) => {
        if (this.editRes && ind > 0 && arr[ind] && arr[ind].guid === ctrl.guid) {
            const temp = arr.splice(ind, 1);
            arr.unshift(temp[0]);
            this.editRes = cloneDeep(this.editRes);
        }
    };

    @action bottomCtrl = (arr: Array<any>, ind: number, ctrl: Resource | Field) => {
        if (this.editRes && ind < arr.length - 1 && arr[ind] && arr[ind].guid === ctrl.guid) {
            const temp = arr.splice(ind, 1);
            arr.push(temp[0]);
            this.editRes = cloneDeep(this.editRes);
        }
    };

    // вызывается из формы редактирования для сохранения изменений формы в resController
    @action modifyCtrl = (fieldNames: Array<string>, formVals: { [key: string]: any }) => {
        const { editCtrl } = this;

        if (editCtrl && fieldNames && formVals)
            fieldNames.forEach(fld => {
                editCtrl[fld as keyof typeof editCtrl] = formVals[fld];
            });
    };

    @action addCtrl = (parentCtrl: any, collectionName: string) => {
        const descr = resValidator.getTypeDescriptor(parentCtrl.type);
        if (descr?.fields) {
            const collectionDescr = descr.fields[collectionName];

            let type = 'control';
            if (collectionDescr.children && collectionDescr.children.length > 0)
                type = collectionDescr.children[0];

            const collection = parentCtrl[collectionName as keyof typeof parentCtrl];

            if (collection || (collectionDescr.children && collectionDescr.children.length > 0)) {
                const newCtrl = this.createResource(type);

                if (collection) {
                    collection?.push(newCtrl);
                } else {
                    parentCtrl[collectionName as keyof typeof parentCtrl] = [newCtrl];
                }

                this.editRes = cloneDeep(this.editRes);
                this.setCurRes(this.editRes);
            }
        }
    };

    @action getList = (listName: string): SimpleObject[] | Promise<SimpleObject[]> => {
        const { editRes, curCtrl } = this;

        if (!editRes || !curCtrl) {
            return [];
        }

        const prepareList = () => {
            const dsList: DatasetType[] = [];
            switch (listName) {
                case 'selectScript':
                    return (editRes.scripts || []).map((it: any) => ({ key: it.name }));
                case 'getChildName':
                    return ((curCtrl as any)?.controls || []).map((it: any) => ({ key: it.name }));
                case 'getDatasetName':
                    collectDataset(editRes, dsList);
                    return dsList.map(ds => ({ key: ds.name }));
                case 'getDatasetFieldName':
                    collectDataset(editRes, dsList);
                    return (
                        dsList
                            .find(
                                ds =>
                                    (curCtrl as any).datasetName === ds.name ||
                                    (curCtrl as any).sourceDataset === ds.name ||
                                    (curCtrl as any).dataset === ds.name
                            )
                            ?.fields.map(f => ({ key: f.name })) ?? []
                    );
                case 'selectResourceLink':
                    return (editRes.resourceLinks || []).map((it: any) => ({ key: it.name }));
                case 'selectQueryLink':
                    return (editRes.resourceLinks || [])
                        .filter((it: any) =>
                            ['wuiExtQuery', 'wuiStatQuery'].includes(it?.resource?.type)
                        )
                        .map((it: any) => ({ key: it.name }));
                case 'selectFormLink':
                    return (editRes.resourceLinks || [])
                        .filter((it: any) => it?.resource?.type === 'wuiForm')
                        .map((it: any) => ({ key: it.name }));
                case 'selectIconSetLink':
                    return (editRes.resourceLinks || [])
                        .filter((it: any) => it?.resource.type === 'wuiIconSet')
                        .map((it: any) => ({ key: it.name }));
                case 'test':
                    return [{ key: 'test' }, { key: 'test2' }];
                // Таблицы датасета, достаем из запроса
                case 'getDatasetTables':
                    // eslint-disable-next-line no-case-declarations
                    const { request } = curCtrl as DatasetType;
                    return this.readDatasetTables(request).then(list =>
                        (list || []).map(item => ({ key: item }))
                    );
                // Список полей датасета
                case 'getFields':
                    return ((curCtrl as DatasetType).fields || []).map(field => ({
                        key: field.name
                    }));
                // Список полей родительского датасета для поля датасета
                case 'getMasterField':
                    collectDataset(editRes, dsList);
                    // Для начала опеределим чьё поле, датасет
                    // eslint-disable-next-line no-case-declarations
                    const fieldDs = dsList.find(ds =>
                        ds.fields.find(field => field.guid === curCtrl.guid)
                    );
                    if (!fieldDs) {
                        return [];
                    }
                    // Найти датасет которому принадлежит датасет поля
                    // eslint-disable-next-line no-case-declarations
                    const resDs = dsList.find(ds =>
                        ds.datasets?.find(child => child.guid === fieldDs.guid)
                    );
                    return (resDs?.fields || []).map(field => ({ key: field.name }));
                // Список типов событий формы
                case 'selectFormEvents':
                    // eslint-disable-next-line no-case-declarations
                    return triggers(this.curCtrl?.dataset ? 'dataset' : this.curCtrl?.type).map(
                        event => ({
                            key: event
                        })
                    );
                case 'getResourceDataset':
                    if (this.curCtrl?.type === 'cdoLink') {
                        return this.readCdoDatasets(this.curCtrl as ResourceLinkType);
                    }
                    break;
                case 'getPropList':
                    return getPropList();

                default:
                    return [];
            }
        };

        return prepareList();
    };

    async readCdoDatasets(link: ResourceLinkType) {
        const cdo = await this.readResource(link.resource.type, link.resource.code);
        if (cdo) {
            const datasets: DatasetType[] = [];
            getNestedDS(datasets, cdo);
            return datasets.map(ds => ({ key: ds.name }));
        }
    }

    // Прочитать список таблиц датасета
    async readDatasetTables(resItem: ResourceItem) {
        if (!resItem?.code) {
            return [];
        }
        const resRequest = (await this.readResource(resItem.type, resItem.code)) as QueryType;
        return (resRequest?.tables || []).map(table => table.name);
    }

    @action getDatasetList = () => {
        const datasets: DatasetType[] = [];
        getNestedDS(datasets, this.curRes);

        this.curRes?.dataObjects?.forEach((cdo: Resource) => getNestedDS(datasets, cdo));

        return datasets;
    };

    @action getDatasetsFields = (datasets: DatasetType[]) => {
        const fields: { [key: string]: DatasetFieldType[] } = {};

        datasets.map(ds => {
            fields[ds.name] = ds.fields;
            return;
        });

        return fields;
    };

    @action getDatasetFields = (datasetName: string) => {
        const dataset = this.getDatasetList().find(ds => ds.name === datasetName);

        if (dataset) return dataset.fields;

        return [];
    };

    @action addCtrlFieldControl = (parentCtrl: any, dataset: any, field: any) => {
        const collectionName = 'controls';
        const descr = resValidator.getTypeDescriptor(parentCtrl.type);
        if (descr?.fields) {
            const collectionDescr = descr.fields[collectionName];

            let type = 'control';
            const config = {
                name: field.name,
                datasetName: dataset.name,
                fieldName: field.name,
                label: field.name,
                type: ''
            } as ConfigType;

            if (field.lookupData) {
                type = 'dbComboBox';
                config.lookupData = field.lookupData;
            } else
                switch (field.dataType) {
                    case 'KRN_INTEGER':
                    case 'KRN_NUMERIC':
                        type = 'dbNumEdit';
                        break;
                    case 'KRN_STRING':
                        type = 'dbEdit';
                        break;
                    case 'KRN_DATE':
                        type = 'dbDateEdit';
                        break;
                    case 'KRN_DATETIME':
                        type = 'dbDateTimeEdit';
                        break;
                    case 'KRN_LOGICAL':
                        type = 'dbCheckBox';
                        break;
                    default:
                        type = 'control';
                }

            config.type = type;

            const collection = parentCtrl[collectionName as keyof typeof parentCtrl];
            /*  if (collection) { временно отключил */
            const newCtrl = this.createResource(config);
            collection?.push(newCtrl);
            this.editRes = cloneDeep(this.editRes);
            this.setCurRes(this.editRes);
            // }
        }
    };

    @action pasteCtrl = (
        parentCtrl: any,
        collectionName: string,
        clipboardData: Resource | Field | null
    ) => {
        if (clipboardData) {
            this.copiedCtrl = clipboardData;
            this._clearGuids(this.copiedCtrl);
        }
        if (this.copiedCtrl?.type) {
            const descr = resValidator.getTypeDescriptor(parentCtrl.type);
            if (descr?.fields) {
                const collectionDescr = descr.fields[collectionName];

                const { type } = this.copiedCtrl;
                let canPaste = true;

                if (collectionDescr.children && collectionDescr.children.length > 0) {
                    if (collectionDescr.children.indexOf(type) === -1) {
                        canPaste = false;
                    }
                }
                if (canPaste) {
                    const collection = parentCtrl[collectionName as keyof typeof parentCtrl];
                    if (collection) {
                        const newCtrl = resValidator.validate(this.copiedCtrl);
                        collection?.push(newCtrl);
                        this.editRes = cloneDeep(this.editRes);
                        this.setCurRes(this.editRes);
                    }
                }
            }
        }
    };

    // Получить описание методов для использования в скрипте
    getJsDocInfo = async (guid: string, serverServer = true): Promise<ScriptGlossaryType> =>
        serverServer ? jsonFetch('jsScriptInfo', 'POST', { guid }) : scriptClientInfo;

    @action saveSettings = async (
        formGuid: string,
        ctrlGuid: string | undefined,
        type: SettingType,
        content: string
    ) =>
        jsonFetch('settings/', 'post', {
            formGuid,
            ctrlGuid,
            type,
            content
        });

    @action loadSettings = async (
        formGuid: string,
        ctrlGuid?: string,
        type: SettingType = SettingType.user
    ): Promise<object | null> => jsonFetch('settings/', 'GET', { formGuid, ctrlGuid, type });

    @action dropSettings = async (
        formGuid: string,
        ctrlGuid?: string,
        flag: SettingType = SettingType.user
    ) => jsonFetch('settings/', 'delete', { formGuid, ctrlGuid, flag });

    @action saveFilter = async (
        formGuid: string,
        datasetName: string,
        type: SettingType,
        content: string
    ) =>
        jsonFetch('filter/', 'post', {
            formGuid,
            datasetName,
            type,
            content
        });

    @action loadFilter = async (
        formGuid: string,
        datasetName: string,
        type: SettingType = SettingType.user
    ): Promise<string | null> => jsonFetch('filter', 'GET', { formGuid, datasetName, type });

    @action dropFilter = async (
        formGuid: string,
        datasetName: string,
        flag: SettingType = SettingType.user
    ) => jsonFetch('filter', 'delete', { formGuid, datasetName, flag });

    @action setChildResource = (key: string, child: ResourceClass, cache = true) => {
        const resource = { [key]: child };

        set(this.resources, resource);
    };

    @action getChildResource = (key: string) => get(this.resources, key);

    @action removeChildResource = (key: string) => {
        remove(this.resources, key);
        this.cacheChildResourceList();
    };

    @action setOpenedTree = (element: { [name: string]: boolean }) => {
        set(this.openedTree, element);
    };

    @action getOpenedTree = (name: string) => get(this.openedTree, name);

    @action setTreeWidth = (width: number) => {
        this.treeWidth = width;
    };

    @action setPropsWidth = (width: number) => {
        this.propsWidth = width;
    };

    @action onSizeChange = (sizes: number[]) => {
        this.treeWidth = sizes[0];
        this.propsWidth = sizes[1];
    };

    @action setPreviewResource = (guid?: string) => {
        this.previewResource = guid ?? null;
    };

    @action setTab = (tab: string) => {
        if (tab !== this.tab) {
            this.prevTab = this.tab;

            this.tab = tab;
        }
    };

    getCtrlBy = (key: string, value: string) => findCtrlPath(this.editRes, key, value);

    createResource = <T extends Resource>(config: string | Partial<T>) =>
        resValidator.validate(typeof config === 'string' ? { type: config } : config);
}

const ResourceStore = new ResourceClass();
export default ResourceStore;
