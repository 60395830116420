import { MutableRefObject, useEffect, useMemo } from 'react';

import EventsStore from 'store/eventsStore';
import { onShow, onHide, onScroll } from 'store/eventsStore/events';

import { EventHandlerType } from 'forms/interfaces';
import PropContainer from 'dataObj/PropContainer';

import useOnScreen from './onScreen';

export default function useEvent(
    ref: MutableRefObject<any>,
    descr: EventHandlerType,
    propContainer: PropContainer
) {
    const guid = useMemo(() => `${propContainer.guid}-${descr.guid}`, [descr, propContainer]);

    const onScreen = useOnScreen(ref);

    const handleOnScroll = () => {
        EventsStore.getEvents(guid)
            ?.triggerEvent(onScroll)
            .catch(err => console.error(err.message));
    };

    useEffect(() => {
        EventsStore.addEvents(guid, descr, descr.events || [], propContainer);

        // Для сабформ проверяем наличие событий, зависимых от датасетов родительской формы
        if (descr?.isSubForm) {
            const datasetEvents = descr.events?.filter(event => !!event.dataset);

            datasetEvents?.forEach(event => {
                const dataset = propContainer.dataStock?.getDatasetObj(event.dataset || '');

                if (dataset) {
                    EventsStore.addEvents(
                        dataset.guid,
                        dataset.descr,
                        datasetEvents || [],
                        propContainer
                    );
                }
            });
        }
    }, []);

    useEffect(() => {
        if (onScreen) {
            EventsStore.getEvents(guid)
                ?.triggerEvent(onShow)
                .catch(err => console.error(err.message));
        } else {
            EventsStore.getEvents(guid)
                ?.triggerEvent(onHide)
                .catch(err => console.error(err.message));
        }
    }, [onScreen]);

    return {
        onScroll: handleOnScroll
    };
}
