import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { EMFJS, RTFJS, WMFJS } from 'rtf.js';
import { Box } from '@mui/material';

import useCtrlData from 'hooks/ctrlData';
import useCtrlProps from 'hooks/ctrlProps';
import useDatasetLoading from 'hooks/datasetLoading';

import Iframe from 'components/Iframe/Iframe';
import Loading from 'components/utils/Loading/Loading';

import { ControlPropsType, DBDocumentViewerType } from 'forms/interfaces';

interface PropsType extends ControlPropsType {
    descr: DBDocumentViewerType;
    content?: string;
    minWidth?: number;
}

function stringToArrayBuffer(string: string) {
    const buffer = new ArrayBuffer(string.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < string.length; i++) {
        bufferView[i] = string.charCodeAt(i);
    }
    return buffer;
}

RTFJS.loggingEnabled(false);
WMFJS.loggingEnabled(false);
EMFJS.loggingEnabled(false);

const DBRtfViewer: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, content, minWidth }) => {
        const { guid, datasetName, fieldName } = descr;
        const { dataVal, dataset } = useCtrlData(propContainer, datasetName, fieldName);
        const { ctrlVisible } = useCtrlProps(propContainer, guid);
        const { loading, loadingType, loadingMessage } = useDatasetLoading(dataset);

        const [htmlCont, setHtmlCont] = useState('<b>...</b>');

        useEffect(() => {
            if (dataVal || content) {
                try {
                    const doc = new RTFJS.Document(
                        stringToArrayBuffer(
                            (content || (dataVal as string))?.replace('ansicpg0', 'ansicpg1251')
                        ),
                        {}
                    );
                    doc.render()
                        .then(htmlElements => {
                            const div = document.createElement('div');
                            div.append(...htmlElements);

                            setHtmlCont(div.innerHTML);
                        })
                        .catch(err => {
                            console.log(err.message);
                            setHtmlCont(err.message);
                        });
                } catch (err: any) {
                    setHtmlCont(err.message);
                }
            } else setHtmlCont('<b>...</b>');
        }, [dataVal, content]);

        const paperStyle = {
            height: '100%'
        };

        const renderRtf = () => <Iframe content={htmlCont} minWidth={minWidth} />;

        return ctrlVisible ? (
            <Box sx={{ height: '100%' }}>
                <Box sx={paperStyle}>
                    {loading ? (
                        <Loading message={loadingMessage} type={loadingType} delay />
                    ) : (
                        renderRtf()
                    )}
                </Box>
            </Box>
        ) : null;
    }
);

export default DBRtfViewer;
