const fullHeight = { position: 'relative', height: '100%' };

const panel = { ...fullHeight, ...{ overflow: 'auto', flexShrink: 0 } };

export default function firmStyles(type: string, classes?: string): { [key: string]: any } {
    switch (type) {
        case 'panel':
            return classes?.match(/accordion/)?.length
                ? { ...panel, ...{ overflow: 'hidden' } }
                : panel;
        case 'container':
            return {
                ...panel,
                ...{ minHeight: 100 }
            };
        case 'editorContainer':
            return { ...panel, ...{ height: '100%' } };
        case 'grid':
            return { ...panel, ...{ flex: '1 1 !important' } };
        case 'pageControl':
            return {
                height: '100%',
                flex: 1,
                overflow: 'auto',
                minHeight: 150
            };
        case 'splitter':
            return fullHeight;
        case 'dbDocumentContainer':
        case 'dbHtmlViewer':
        case 'dbRtfViewer':
            return {
                height: '100%',
                flex: '1 1 !important',
                overflow: 'auto'
            };
        case 'dbPdfViewer':
            return { ...fullHeight, ...{ '&canvas': { m: 'auto' } } };
        case 'taskButton':
        case 'scriptButton':
        case 'svgIcon':
            return { p: 2 };
        case 'dbTreeEdit':
        case 'dbImage':
        case 'telemedChat':
        case 'dbScheduleChart':
        case 'dbScheduler':
            return { height: '100%' };
        default:
            return {};
    }
}
