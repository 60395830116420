import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@mui/material';

import { NestedMenuType } from '../interfaces';

import MenuItem from './MenuItem';

const NestedMenu: FunctionComponent<NestedMenuType> = observer(
    ({
        open,
        items,
        propContainer,
        handleClose,
        menuPlacement,
        dataRow,
        anchorRef,
        parentClose
    }) => (
        <Popper
            sx={theme => ({
                zIndex: theme.zIndex.modal + 1
            })}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            placement={menuPlacement}
            modifiers={[
                {
                    name: 'flip',
                    enabled: true,
                    options: {
                        altBoundary: false,
                        rootBoundary: 'document',
                        padding: 8
                    }
                },
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        altAxis: false,
                        altBoundary: false,
                        tether: false,
                        rootBoundary: 'document',
                        padding: 8
                    }
                }
            ]}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                    }}
                >
                    <Paper
                        sx={{
                            minWidth:
                                menuPlacement === 'bottom-start'
                                    ? anchorRef.current?.offsetWidth
                                    : 'initial',
                            overflow: 'visible !important'
                        }}
                    >
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                                {items?.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        descr={item}
                                        propContainer={propContainer}
                                        dataRow={dataRow}
                                        parentClose={parentClose}
                                    >
                                        {item.caption}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
);

export default NestedMenu;
