import React, {
    FunctionComponent,
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';

import { TableHead, TableRow } from '@mui/material';

import useNodeResize from 'hooks/nodeResize';

import SelectionCell from './SelectionCell';

import { HeaderProps, HeaderColumnType } from '../interface';
import HeaderCell from './HeaderCell';
import { getGridWidth } from '../index';

const MIN_COLUMN_WIDTH = 75;

const GridHeader: FunctionComponent<HeaderProps> = ({ dataset, columns, styles }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const headerRef = useRef(null) as MutableRefObject<any>;

    const [headerWidth, headerHeight] = useNodeResize(headerRef);

    const columnClickHandler = (col: HeaderColumnType) => {
        if (dataset && col.sortable) {
            dataset.changeOrderBy(col.fieldName);
            dataset.loadData();
        }
    };

    const mouseDown = (index: number) => {
        setActiveIndex(index);
    };

    const getPadding = (ref: MutableRefObject<any>) => {
        if (ref?.current)
            return (
                parseInt(window.getComputedStyle(ref.current).paddingLeft, 10) +
                parseInt(window.getComputedStyle(ref.current).paddingRight, 10)
            );

        return 0;
    };

    const mouseMove = useCallback(
        e => {
            columns.forEach((col: HeaderColumnType, index: number) => {
                const padding = getPadding(col.ref);
                const minWidth =
                    col.width && Number(col.width) < MIN_COLUMN_WIDTH
                        ? col.width
                        : MIN_COLUMN_WIDTH;

                // Всегда фиксируем ширину фиксированной колонки
                if (col.fixWidth && (col.gridWidth || col.width)) {
                    const width = col.width || getGridWidth(col.gridWidth as string);
                    col.ref.current.style.width = `${width || minWidth}px`;
                    return;
                }

                if (index === activeIndex) {
                    // Получаем ширину колонки из разности текущего положения курсора и левой границы столбика с отступами
                    const width = e.pageX - col.ref.current.getBoundingClientRect().x - padding;

                    // Фиксируем ширину, если она больше минимально допустимой
                    if (width >= minWidth) {
                        col.ref.current.style.width = `${width}px`;
                    }
                } else {
                    // Если размер колонки не менялся мануально, то проверяем достиг ли её размер минимальной ширины
                    if (col.ref.current.offsetWidth <= minWidth) {
                        col.ref.current.style.width = `${minWidth}px`;
                    }
                    // В противном случае фиксируем размер для всех колонок левее текущей
                    else if (activeIndex !== null && index < activeIndex)
                        col.ref.current.style.width = `${parseInt(
                            col.ref.current.style.width,
                            10
                        )}px`;

                    // Крайней правой колонке сбрасиываем ширину всякий раз,
                    // когда ширина таблицы возвращается к исходной
                    if (
                        e.movementX < 0 &&
                        headerRef.current.offsetWidth === headerWidth &&
                        index === columns.length - 1
                    ) {
                        col.ref.current.style.width = '';
                    }
                }
            });
        },
        [activeIndex, columns]
    );

    const removeListeners = useCallback(() => {
        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', removeListeners);
    }, [mouseMove]);

    const mouseUp = useCallback(() => {
        setActiveIndex(null);
        removeListeners();
    }, [setActiveIndex, removeListeners]);

    useEffect(() => {
        if (activeIndex !== null) {
            window.addEventListener('mousemove', mouseMove);
            window.addEventListener('mouseup', mouseUp);
        }

        return () => {
            removeListeners();
        };
    }, [activeIndex, mouseMove, mouseUp, removeListeners]);

    return (
        <TableHead
            ref={headerRef}
            sx={{
                visibility: 'inherit',
                userSelect: 'none',
                '-khtml-user-select': 'none',
                '-webkit-user-select': 'none',
                '-ms-user-select': 'none'
            }}
        >
            <TableRow>
                {!!dataset?.multiSelect && (
                    <SelectionCell headerHeight={headerHeight} sx={styles.headerCell} />
                )}
                {columns.map((col: HeaderColumnType, i: number) => (
                    <HeaderCell
                        key={i}
                        col={col}
                        dataset={dataset}
                        index={i}
                        headerHeight={headerHeight}
                        activeIndex={activeIndex}
                        styles={styles}
                        onMouseDown={mouseDown}
                        onClick={columnClickHandler}
                        lastCell={i === columns.length - 1}
                    />
                ))}
            </TableRow>
        </TableHead>
    );
};

export default GridHeader;
