import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled } from '@mui/material/styles';

import ResList from '../ResEditor/ResList/ResList';
import { SideBareType } from './interfaces';

const resTypeList = [
    'all',
    'wuiForm',
    'grid',
    'wuiStatQuery',
    'wuiExtQuery',
    'wuiQuery',
    'cdo',
    'wuiPackage',
    'wuiGroup',
    'table',
    'wuiSettings',
    'wuiIconSet',
    'wuiApplication',
    'inheritor',
    'tableStruct',
    'groupModificatorStruct',
    'cacheSet'
];

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
}));

const SideBar: FunctionComponent<SideBareType> = observer(
    ({ open, drawerWidth, ResourceStore, onNewForm, onSelect, onClose }) => (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box'
                }
            }}
            variant="temporary"
            anchor="left"
            open={open}
        >
            <DrawerHeader id="resource-side-bar-header">
                <Typography variant="h5" noWrap component="div" sx={{ mr: 'auto' }}>
                    Редактор ресурсов
                </Typography>
                <IconButton onClick={onClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <Box sx={{ p: 1, height: `calc(100% - ${64 + 17}px)` }}>
                <ResList
                    onResourceCreate={onNewForm}
                    onResourceSelect={onSelect}
                    types={resTypeList}
                    ResourceStore={ResourceStore}
                />
            </Box>
        </Drawer>
    )
);

export default SideBar;
