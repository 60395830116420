export const onNewRecord = 'onNewRecord';
export const onDataChange = 'onDataChange';
export const afterDataChange = 'afterDataChange';
export const beforeScroll = 'beforeScroll';
export const afterScroll = 'afterScroll';
export const onScroll = 'onScroll';
export const onShow = 'onShow';
export const afterDataLoad = 'afterDataLoad';
export const onHide = 'onHide';
export const afterOpen = 'afterOpen';
export const onSave = 'onSave';
export const beforeDelete = 'beforeDelete';
export const afterDelete = 'afterDelete';
export const onClose = 'onClose';
export const onError = 'onError';
