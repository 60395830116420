import CustomDataset from 'dataObj/customDataset';
import PropContainer from 'dataObj/PropContainer';
import { GridDataRow } from 'forms/interfaces';

import DataStore from 'store/dataStore';

type UseCtrlDataResult = {
    dataset?: CustomDataset;
    keyField?: string;
    fieldName: string;
    dataVal: any;
    dataType: string | null;
    fieldEnabled: boolean;
    fieldRequired: boolean;
    internationalField: boolean;
    fieldSize?: number;
    fieldScale?: number;
};

export default function useCtrlData(
    propContainer: PropContainer,
    datasetName: string,
    fieldName: string,
    dataRow?: GridDataRow
): UseCtrlDataResult {
    let dataset;
    let dataVal;
    let dataType;
    let fieldEnabled;
    let fieldRequired = false;
    let internationalField = false;
    let fieldSize;
    let fieldScale;

    const getDataVal = (ds: any) => {
        if (dataRow) {
            return dataRow.data[fieldName];
        }

        if (ds) {
            return ds.getFieldValue(fieldName);
        }

        return null;
    };

    const getDataType = (ds: any) => {
        if (ds) {
            const datasetField = ds.getFieldDescr(fieldName);

            return datasetField?.dataType ?? null;
        }

        return null;
    };

    if (datasetName.toUpperCase() === 'AF') {
        dataVal = DataStore.AF.getAF(fieldName, propContainer);
        fieldEnabled = false;
    } else if (propContainer.dataStock) {
        dataset = propContainer.dataStock.getDatasetObj(datasetName);

        dataVal = getDataVal(dataset);

        if (dataset) {
            dataType = getDataType(dataset);
            fieldEnabled =
                propContainer.getProperty(`${datasetName}.${fieldName}`, 'enabled') ?? false;
            fieldRequired = propContainer.getFieldRequirement(fieldName, datasetName);
            fieldSize = propContainer.getFieldSize(fieldName, datasetName);
            fieldScale = propContainer.getFieldScale(fieldName, datasetName);
            internationalField =
                typeof dataType === 'string' &&
                ['KRN_STRING_INTERNATIONAL', 'KRN_MEMO_INTERNATIONAL'].includes(dataType);
        }
    }
    return {
        dataset,
        keyField: dataset?.keyField,
        fieldName,
        dataVal,
        dataType,
        fieldEnabled,
        fieldRequired,
        internationalField,
        fieldSize,
        fieldScale
    };
}
