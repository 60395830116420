import React from 'react';

import { Button } from '@mui/material';

import FormActions from 'store/actionStore/form/FormActions';

const useEditorCustomButtons = (
    formActions: FormActions,
    content: any,
    onSave?: (result: any) => void,
    editType?: string // Тип редактирования из датасета
) => {
    const customButtons = (onClose?: (state: boolean) => void) => (
        <>
            {['view'].includes(formActions?.editMode as string) && (
                <Button
                    key="edit"
                    onClick={() => formActions.setEditMode('edit')}
                    variant="outlined"
                >
                    {content.controls.buttons.change}
                </Button>
            )}
            {['edit', 'create', 'cdoCreate'].includes(formActions?.editMode as string) && (
                <Button
                    key="save"
                    onClick={async () => {
                        if (
                            ['create', 'cdoCreate'].includes(formActions?.editMode as string) &&
                            editType === 'detail' &&
                            onClose
                        ) {
                            return onClose(true);
                        }

                        const result = await formActions.save();

                        if (result === false) return;

                        if (result?.err.length === 0) {
                            if (onSave) {
                                onSave(result);
                            } else formActions.setForceReload();
                        }
                    }}
                    variant="outlined"
                    disabled={!formActions.validated}
                >
                    {content.controls.buttons.save}
                </Button>
            )}
        </>
    );

    return customButtons;
};

export default useEditorCustomButtons;
