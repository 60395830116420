import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Paper, Box, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';

import ResourceStore, { ResourceClass } from 'store/resourceStore';
import useBackdrop from 'hooks/backdrop';

import { ResourceItem } from 'forms/interfaces';

import { getApiVersion } from 'utils/index';

import TabPanel from './TabPanel';

import ResEditor from '../ResEditor';

import ResPreviewControl from '../ResEditor/ResPreviewControl';
import Header from './Header';
import SideBar from './SideBar';

const DRAWER_WIDTH = 450;

const Main = styled('main')(({ theme }) => ({
    height: `100vh`,
    flexGrow: 1,
    padding: theme.spacing('80px', 3, 10)
}));

const ResPage: FunctionComponent = observer(() => {
    const {
        resources,
        tab,
        prevTab,
        resLoading,
        setTab,
        setChildResource,
        getChildResource,
        cacheChildResourceList,
        removeChildResource,
        prepareChildResourceList
    } = ResourceStore;

    const [open, setOpen] = useState(true);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = (resItem: ResourceItem) => {
        handleClose();
        const childRS = new ResourceClass();
        childRS
            .selectResource(resItem)
            .then(() => {
                setChildResource(resItem.key, childRS);
                cacheChildResourceList(resItem.key);
                setTab(resItem.key);
            })
            .catch((err: any) => {
                console.error(err.message);
            });
    };

    const handleNewForm = () => {
        handleClose();
        const childRS = new ResourceClass();
        const newForm = childRS.addResource();

        if (newForm) {
            childRS.setNewRes(true);
            setChildResource(newForm.guid, childRS);
            setTab(newForm.guid);
        }
    };

    const selectTab = (newTab: string) => {
        if (getChildResource(newTab)) {
            setTab(newTab);
            cacheChildResourceList(newTab);
        } else setTab('');
    };

    const switchTab = (event: React.SyntheticEvent, newValue: string) => {
        selectTab(newValue);
    };

    const closeTab = (guid: string) => {
        if (guid === tab) {
            const resourceIdList = Object.keys(resources);

            if (guid !== prevTab && resourceIdList.includes(prevTab)) {
                setTab(prevTab);
            } else {
                const curTabIndex = resourceIdList.indexOf(guid);

                if (curTabIndex !== -1) {
                    const newTab =
                        curTabIndex === 0 && resourceIdList.length > 0
                            ? resourceIdList[1]
                            : resourceIdList[0];
                    selectTab(newTab);
                }
            }
        }
        removeChildResource(guid);
    };

    useEffect(() => {
        prepareChildResourceList()
            .then(firstTab => {
                if (firstTab) {
                    setTab(firstTab);
                    setOpen(false);
                }
            })
            .catch(err => console.error(err.message));
    }, []);

    useBackdrop(resLoading);

    return (
        <>
            <Box>
                <Header open={open} onOpen={handleOpen} />
                <SideBar
                    open={open}
                    drawerWidth={DRAWER_WIDTH}
                    ResourceStore={ResourceStore}
                    onClose={handleClose}
                    onNewForm={handleNewForm}
                    onSelect={handleSelect}
                />
                {Boolean(Object.keys(resources).length) && (
                    <Main>
                        <Paper elevation={3} sx={{ height: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    value={tab || false}
                                    onChange={switchTab}
                                    aria-label="resource tabs"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    {Object.keys(resources).map((guid: string) => {
                                        const RS = getChildResource(guid);
                                        const name = RS.curRes?.name;

                                        return (
                                            <Tab
                                                key={guid}
                                                label={name}
                                                value={guid}
                                                icon={
                                                    <Box
                                                        sx={{
                                                            opacity: 0,
                                                            border: '0px solid #000',
                                                            borderRadius: '50%',
                                                            width: '1.25rem',
                                                            height: '1.25rem',
                                                            p: 1,
                                                            boxSizing: 'initial',
                                                            '&:hover': {
                                                                background: '#0000000a'
                                                            }
                                                        }}
                                                        onClick={event => {
                                                            event.stopPropagation();
                                                            closeTab(guid);
                                                        }}
                                                    >
                                                        <CloseIcon fontSize="small" />
                                                    </Box>
                                                }
                                                iconPosition="end"
                                                sx={{
                                                    '&.MuiTab-root': {
                                                        padding: '12px 0 12px 16px',
                                                        transition: 'all .2s'
                                                    },
                                                    '&.Mui-selected': {
                                                        '& .MuiBox-root': {
                                                            opacity: 1
                                                        }
                                                    },
                                                    '& .MuiTab-iconWrapper': {
                                                        color: 'divider',
                                                        transition: 'all .2s',
                                                        '&:hover': {
                                                            color: '#0000008a'
                                                        }
                                                    },
                                                    '&:hover': {
                                                        '& .MuiBox-root': {
                                                            opacity: 1
                                                        }
                                                    }
                                                }}
                                            />
                                        );
                                    })}
                                </Tabs>
                            </Box>

                            {Object.keys(resources).map((guid: string) => {
                                const RS = getChildResource(guid);

                                return (
                                    <TabPanel key={guid} value={tab} index={guid}>
                                        <ResEditor ResourceStore={RS} />
                                    </TabPanel>
                                );
                            })}
                        </Paper>
                        <Box
                            sx={theme => ({
                                position: 'fixed',
                                bottom: theme.spacing(2),
                                right: theme.spacing(3)
                            })}
                        >
                            <Typography variant="caption">{`version: ${
                                process.env.REACT_APP_VERSION as string
                            }${getApiVersion()}`}</Typography>
                        </Box>
                    </Main>
                )}
            </Box>
            <ResPreviewControl ResourceStore={ResourceStore} />
        </>
    );
});

export default ResPage;
