import React, { FunctionComponent, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import moment, { Moment } from 'moment';

import { styled } from '@mui/material/styles';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

interface PropsType {
    date: any;
    setDate: any;
    handleClose: any;
}

interface CustomPickerDayProps extends PickersDayProps<Moment> {
    isSelected: boolean;
    isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: prop => prop !== 'isSelected' && prop !== 'isHovered'
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
    borderRadius: 0,
    ...(isSelected && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main
        }
    }),
    ...(isHovered && {
        backgroundColor: theme.palette.primary[theme.palette.mode],
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary[theme.palette.mode]
        }
    }),
    ...(day.day() === 1 && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%'
    }),
    ...(day.day() === 0 && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%'
    })
})) as React.ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Moment, dayB: Moment | null | undefined) => {
    if (dayB == null) {
        return false;
    }

    return dayA.isSame(dayB, 'week');
};

function Day(
    props: PickersDayProps<Moment> & {
        selectedDay?: Moment | null;
        hoveredDay?: Moment | null;
    }
) {
    const { day, selectedDay, hoveredDay, ...other } = props;

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
        />
    );
}

const Week: FunctionComponent<PropsType> = observer(({ date, setDate, handleClose }) => {
    const [hoveredDay, setHoveredDay] = useState<Moment | null>(null);
    const value = useMemo(() => moment(date), [date]) as Moment | null;

    return (
        <DateCalendar
            value={value}
            onChange={newValue => {
                setDate(newValue.format('YYYY-MM-DD'));
                handleClose();
            }}
            showDaysOutsideCurrentMonth
            slots={{ day: Day }}
            slotProps={{
                day: ownerState =>
                    ({
                        selectedDay: value,
                        hoveredDay,
                        onPointerEnter: () => setHoveredDay(ownerState.day),
                        onPointerLeave: () => setHoveredDay(null)
                    } as any)
            }}
            sx={{
                '& .MuiButtonBase-root': { border: 'none !important' }
            }}
        />
    );
});

export default Week;
