import React, { FunctionComponent } from 'react';
import { Box, Typography, TypographyVariant } from '@mui/material';

import WithTooltip from 'components/utils/withTooltip';

import useCtrlProps from 'hooks/ctrlProps';

import { ControlPropsType, GridDataRow, LabelType } from 'forms/interfaces';

interface PropsType extends ControlPropsType {
    descr: LabelType;
    dataRow?: GridDataRow;
}

const CustomLabel: FunctionComponent<PropsType> = ({ descr, propContainer, dataRow }) => {
    const { guid, variant, tooltip } = descr;
    const { ctrlVisible, ctrlCaption, ctrlTooltip } = useCtrlProps(
        propContainer,
        descr.guid,
        dataRow
    );

    const renderLabel = (value: string) => {
        if (variant === 'pure-html') return <div dangerouslySetInnerHTML={{ __html: value }} />;

        if (variant)
            return (
                <Typography id={guid} variant={variant as TypographyVariant}>
                    {value}
                </Typography>
            );
        return <span id={descr.guid}>{value}</span>;
    };

    return (
        <Box
            sx={{
                width: '100%',
                p: 2,
                boxSizing: 'border-box',
                visibility: ctrlVisible ? 'inherit' : 'hidden'
            }}
        >
            <WithTooltip title={ctrlTooltip || tooltip}>
                {renderLabel(ctrlCaption || descr?.text || '')}
            </WithTooltip>
        </Box>
    );
};

export default CustomLabel;
