import React, {
    FunctionComponent,
    MutableRefObject,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

import { FormType, ResourceType } from 'forms/interfaces';

import ActionStore from 'store/actionStore';
import { ResourceClass } from 'store/resourceStore';

import ResEditor from 'components/resources/ResEditor';

interface PropsType {
    helperGuid: string;
    formDescr: FormType;
}

const SHIFT_STEP = 30;

const getShift = (
    guid: string,
    helpers: HTMLCollection,
    x: number,
    y: number,
    shift = 0
): number => {
    for (let i = 0; i < helpers.length; i++) {
        const xPosition = Math.floor(
            (window.innerWidth - helpers[i].getBoundingClientRect().x) / 10
        );
        const yPosition = Math.floor(helpers[i].getBoundingClientRect().y);

        if (
            xPosition === Math.floor((window.innerWidth - x) / 10) &&
            yPosition === Math.floor(y) &&
            helpers[i].id !== guid
        ) {
            shift += SHIFT_STEP;

            return getShift(guid, helpers, x + shift, y, shift);
        }
    }

    return shift;
};

const FormHelper: FunctionComponent<PropsType> = observer(({ helperGuid, formDescr }) => {
    const { guid, name } = formDescr;

    const helperRef = useRef(null) as MutableRefObject<any>;

    const [updated, setUpdated] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [resEditor, setResEditor] = useState(false);
    const [resStore, setResStore] = useState<ResourceClass | null>(null);

    const [shift, setShift] = useState(0);
    const [show, setShow] = useState(false);

    const marginRight = useMemo(() => `-${shift}px`, [shift]);

    useEffect(() => {
        if (helperRef.current && !shift) {
            const helpers = document.getElementsByClassName('form-helper');

            const { x, y } = helperRef.current.getBoundingClientRect();

            setShift(getShift(helperGuid, helpers, x, y));
            setShow(true);
        }
    }, [helperRef?.current]);

    const handleClick = () => {
        const RS = new ResourceClass(undefined, () => {
            setUpdated(true);
        });
        RS.getResource(ResourceType.form, guid).catch((err: any) => {
            console.error(err.message);
        });
        setResStore(RS);
        setResEditor(true);
    };

    const handleClose = () => {
        setResStore(null);
        setResEditor(false);
        if (updated) ActionStore.getFormActions(helperGuid)?.setForceReload();
    };

    const handleHover = () => {
        setOpacity(0.2);
    };

    const handleUnhover = () => {
        setOpacity(0);
    };

    return (
        <>
            {resStore && (
                <Dialog
                    open={resEditor}
                    fullWidth
                    maxWidth="xl"
                    sx={{ '& .MuiDialog-paper': { height: '90vh' } }}
                >
                    <DialogContent>
                        <ResEditor ResourceStore={resStore} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            )}
            {!!opacity && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'red',
                        opacity,
                        zIndex: 100,
                        border: 'dotted 2px black',
                        borderRadius: 1
                    }}
                />
            )}

            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    display: 'flex',
                    right: '25%',
                    visibility: show ? 'visible' : 'hidden',
                    marginRight
                }}
            >
                <Box
                    id={helperGuid}
                    ref={helperRef}
                    className="form-helper"
                    sx={theme => ({
                        zIndex: theme.zIndex.modal,
                        color: 'black',
                        backgroundColor: '#e7e7e7',
                        border: 'solid 1px grey',
                        borderRadius: 1,
                        p: 1,
                        cursor: 'pointer',
                        '&:hover': { zIndex: theme.zIndex.modal + 1 }
                    })}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleUnhover}
                    onClick={handleClick}
                >
                    <Typography variant="subtitle2" sx={{ fontSize: '0.7rem' }}>
                        {name}
                    </Typography>
                </Box>
            </Box>
        </>
    );
});

export default FormHelper;
