import React from 'react';
import moment from 'moment';

import { getExecRatio, getRelativeMinutes, getStateColor } from '../utils';

const EventTemplate = (
    schedulerData: any,
    event: any,
    bgColor: string,
    isStart: boolean,
    isEnd: boolean,
    mustAddCssClass: string,
    mustBeHeight: number,
    agendaMaxEventWidth: number
) => {
    const { id, state, start, end } = event;
    const { behaviors, startDate, endDate } = schedulerData;

    const titleText = behaviors.getEventTextFunc(schedulerData, event);

    const duration = moment(moment(end).diff(endDate) < 0 ? end : endDate).diff(
        moment(moment(start).diff(startDate) > 0 ? start : startDate),
        'minutes'
    );
    const minutes = getRelativeMinutes(event?.props?.exec_time);
    const execRatio = getExecRatio(duration, minutes);

    return (
        <div
            key={id}
            className={mustAddCssClass}
            style={{
                position: 'relative',
                borderRadius: `${isStart ? '8px' : '0'} ${isEnd ? '8px' : '0'} ${
                    isEnd ? '8px' : '0'
                } ${isStart ? '8px' : '0'}`,
                height: mustBeHeight
            }}
        >
            <span style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px`, zIndex: 1 }}>
                {titleText}
            </span>
            <div
                style={{ position: 'absolute', height: '100%', width: '100%', top: 0, zIndex: -1 }}
            >
                <div
                    style={{
                        position: 'absolute',
                        backgroundColor: getStateColor(state),
                        height: '100%',
                        // width: `calc(100% - ${isEnd ? 39 : 0}px)`
                        width: `${isEnd ? execRatio : 0}%`
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        backgroundColor: getStateColor(state),
                        opacity: '60%',
                        height: '100%',
                        width: '100%'
                    }}
                />
            </div>
        </div>
    );
};

export default EventTemplate;
