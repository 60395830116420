import React, { FunctionComponent, useMemo, useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Add, AddCircle, Delete, Edit, Visibility } from '@mui/icons-material';

import Toolbar from 'forms/controls/Toolbar/Toolbar';
import { TCustomToolbarButton } from 'forms/controls/Toolbar/interface';
import { ToolbarButtonType } from 'components/resources/ResEditor/gridSettings';
import ControlTree from 'forms/ControlTree';

import ConfigurationStore from 'store/configurationStore';
import NotificationStore from 'store/notificationStore';
import ActionStore from 'store/actionStore';

import useCtrlProps from 'hooks/ctrlProps';

import { getResourceGuid } from 'utils/index';

import FastFilters from './FastFilters';
import UserFilter from './UserFilter';

import { GridToolbarType } from '../interface';

const GridToolbar: FunctionComponent<GridToolbarType> = observer(
    ({ gridDescr, dataset, fastFilters, gridWidth, propContainer, owner, gridFocus }) => {
        const { ctrlVisible } = useCtrlProps(propContainer, gridDescr.guid);

        const breakpointWidth = 600;

        const [fullWidth, setFullWidth] = useState<boolean>();
        const [buttonWidth, setButtonWidth] = useState(0);
        const [inlineFF, setInlineFF] = useState(false);

        useEffect(() => {
            // setInlineFF(gridWidth - (buttonWidth || 1100) > (fastFilters?.length || 0) * 170 + 40);
        }, [gridWidth, buttonWidth]);

        const configButtons = useMemo(
            () => (owner?.getOptions()?.toolbar?.length ? owner.getOptions().toolbar : []),
            [owner]
        );

        const formActions = ActionStore.getFormActions(propContainer?.guid);

        const { content } = ConfigurationStore;
        const { showConfirmation } = NotificationStore;

        const datasetActions = ActionStore.getDatasetActions(dataset.guid);

        const filters =
            dataset && fastFilters?.length ? (
                <FastFilters
                    filters={fastFilters}
                    gridDescr={gridDescr}
                    dataset={dataset}
                    gridFocus={gridFocus}
                />
            ) : null;

        const userFilter = dataset?.descr?.flUserFilter ? (
            <UserFilter
                dataset={dataset}
                fullWidth={fullWidth}
                gridWidth={gridWidth}
                breakpointWidth={breakpointWidth}
            />
        ) : null;

        const defaultButtons: TCustomToolbarButton[] | undefined = useMemo(() => {
            if (datasetActions && dataset.checkMasterDSSaved())
                return [
                    {
                        type: 'add',
                        variant: 'contained',
                        color: 'primary',
                        caption: content.controls.buttons.add,
                        icon: <Add />,
                        iconBtn: <AddCircle />,
                        iconPosition: 'end',
                        onClick: () => datasetActions.addRecord(propContainer)
                    },
                    {
                        type: 'view',
                        variant: 'outlined',
                        caption: content.controls.buttons.view,
                        disabled: dataset.state !== 1 || !dataset.activeRec,
                        icon: <Visibility />,
                        onClick: () => datasetActions.viewRecord(propContainer)
                    },
                    {
                        type: 'edit',
                        variant: 'outlined',
                        caption: content.controls.buttons.change,
                        disabled: dataset.state !== 1 || !dataset.activeRec,
                        icon: <Edit />,
                        onClick: () => datasetActions.editRecord(propContainer)
                    },
                    {
                        type: 'delete',
                        variant: 'outlined',
                        color: 'warning',
                        caption: content.controls.buttons.delete,
                        disabled: dataset.state !== 1 || !dataset.activeRec,
                        icon: <Delete />,
                        onClick: () => {
                            showConfirmation(
                                content.controls.grid.delConfirmText,
                                datasetActions.deleteRecord(propContainer)
                            );
                        }
                    }
                ];
        }, [
            datasetActions,
            dataset.state,
            dataset.guid,
            dataset.activeRec,
            dataset.masterDS?.activeRec,
            dataset.masterDS?.cloneRec
        ]);

        const controlElements =
            !owner?.getOptions()?.configuration?.hideToolbar && formActions?.editMode !== 'view'
                ? gridDescr.buttons?.filter(
                      btn => !['toolbarButton', 'scriptButton'].includes(btn.type)
                  )
                : undefined;

        return (
            <Toolbar
                caption={gridDescr.caption}
                captionVariant={gridDescr.captionVariant}
                dataset={dataset}
                customButtons={
                    !owner?.getOptions()?.configuration?.hideToolbar &&
                    formActions?.editMode !== 'view' &&
                    !!getResourceGuid(dataset.descr.editForm)
                        ? defaultButtons?.filter(
                              button =>
                                  configButtons.find(
                                      (conf: ToolbarButtonType) => conf.name === button.type
                                  )?.visible ?? true
                          )
                        : undefined
                }
                buttons={
                    !owner?.getOptions()?.configuration?.hideToolbar &&
                    formActions?.editMode !== 'view'
                        ? gridDescr.buttons
                              ?.filter(button =>
                                  ['toolbarButton', 'scriptButton'].includes(button.type)
                              )
                              .filter(
                                  button =>
                                      configButtons.find(
                                          (conf: ToolbarButtonType) => conf.name === button.name
                                      )?.visible ?? true
                              )
                        : undefined
                }
                propContainer={propContainer}
                initContainerWidth={gridWidth}
                controlElements={
                    controlElements?.length || (inlineFF && filters) || userFilter ? (
                        <>
                            {controlElements?.map(button => (
                                <ControlTree
                                    key={button.guid}
                                    formDescr={button}
                                    propContainer={propContainer}
                                />
                            ))}
                            {inlineFF && filters}
                            {userFilter}
                        </>
                    ) : null
                }
                setButtonWidth={setButtonWidth}
                visibility={ctrlVisible}
                setIconsFullWidth={setFullWidth}
            >
                {inlineFF ? null : filters}
            </Toolbar>
        );
    }
);

export default GridToolbar;
