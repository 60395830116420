import { useEffect, useState } from 'react';

import ConfigurationStore from 'store/configurationStore';
import { jsonFetch } from 'utils/index';

type InfoResponseType = {
    basename?: string;
    version: string;
    title: string;
};

export default function useAppReady() {
    const { isAuthenticated, setBasename } = ConfigurationStore;

    const [isReady, setIsReady] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);

    const themeName = queryParams.get('theme');
    const accessToken = queryParams.get('token');
    const refreshToken = queryParams.get('refreshToken');
    const returnUrl = queryParams.get('returnUrl');

    useEffect(() => {
        jsonFetch<InfoResponseType>('info')
            .then(info => {
                setBasename(info?.basename || process.env.PUBLIC_URL);
                ConfigurationStore.setApiVersion(info.version);
                ConfigurationStore.setTitle(info.title);
            })
            .catch(err => {
                console.error(err.message);
                setBasename(process.env.PUBLIC_URL);
            })
            .finally(() => setIsReady(true));

        document.addEventListener('medialog', (event: any) =>
            console.log(JSON.stringify(event.detail))
        );
    }, []);

    useEffect(() => {
        if (isAuthenticated) ConfigurationStore.setReturnUrl(returnUrl || '');
    }, [isAuthenticated]);

    return { isReady, themeName, accessToken, refreshToken };
}
