import React, { FunctionComponent, useMemo } from 'react';

import useDBEdit from 'hooks/dbEdit';

import {
    DBEditType,
    DBTextEditType,
    DBComboBoxType,
    DBCheckBoxType,
    DBDateTimeType,
    ControlDataRowType
} from 'forms/interfaces';

import DBCheckBox from './DBCheckBox';
import DBComboBox from './DBComboBox';
import DBSimpleEdit from './DBSimpleEdit';
import DBDate from './DBDate';

interface PropsType extends ControlDataRowType {
    descr: DBEditType;
    index?: number;
}

const DBEdit: FunctionComponent<PropsType> = ({ descr, propContainer, dataRow, index }) => {
    const { ctrlVisible, fieldDescr } = useDBEdit(descr, propContainer);

    const lookupData = useMemo(() => {
        if (!fieldDescr?.lookupData) return null;

        return typeof fieldDescr.lookupData === 'string'
            ? JSON.parse(fieldDescr.lookupData)
            : fieldDescr.lookupData;
    }, [fieldDescr]);

    const editCtrl = useMemo(() => {
        function getEditType(datafieldDescr: any) {
            switch (datafieldDescr.dataType) {
                case 'KRN_INTEGER':
                case 'KRN_NUMERIC':
                    return 'number';
                default:
                    return;
            }
        }

        if (fieldDescr) {
            const { dataType } = fieldDescr;

            if (dataType === 'KRN_LOGICAL')
                return (
                    <DBCheckBox
                        descr={descr as DBCheckBoxType}
                        propContainer={propContainer}
                        dataRow={dataRow}
                        index={index}
                    />
                );
            if (['KRN_DATE', 'KRN_DATETIME', 'KRN_TIME'].includes(dataType))
                return (
                    <DBDate
                        descr={descr as DBDateTimeType}
                        propContainer={propContainer}
                        dataRow={dataRow}
                        index={index}
                    />
                );
            if (lookupData)
                return (
                    <DBComboBox
                        descr={descr as DBComboBoxType}
                        propContainer={propContainer}
                        dataRow={dataRow}
                        index={index}
                    />
                );

            return (
                <DBSimpleEdit
                    descr={descr as DBTextEditType}
                    propContainer={propContainer}
                    type={getEditType(fieldDescr)}
                    dataRow={dataRow}
                    index={index}
                />
            );
        }

        return (
            <DBSimpleEdit
                descr={descr as DBTextEditType}
                propContainer={propContainer}
                type="text"
                dataRow={dataRow}
                index={index}
            />
        );
    }, [descr, fieldDescr, propContainer, lookupData]);

    return ctrlVisible ? editCtrl : null;
};

export default DBEdit;
