import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Box, Typography } from '@mui/material';

import ConfigurationStore from 'store/configurationStore';

interface PropType {
    color?: string;
    position: 'left' | 'right';
    padding?: number;
    hide?: boolean;
}

const AppVersion: FunctionComponent<PropType> = ({
    color,
    position = 'right',
    padding = 5,
    hide = false
}) => (
    <Box
        sx={{
            position: 'fixed',
            pb: 2,
            pr: padding,
            pl: padding,
            bottom: 0,
            [position]: 0,
            visibility: hide ? 'hidden' : 'visible'
        }}
    >
        <Typography color={color ?? 'initial'} variant="caption">{`version: ${
            process.env.REACT_APP_VERSION as string
        }${
            ConfigurationStore?.apiVersion ? ` / ${ConfigurationStore.apiVersion}` : ''
        }`}</Typography>
    </Box>
);

export default observer(AppVersion);
