import React, { CSSProperties, forwardRef, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import { SelectChangeEvent } from '@mui/material/Select';

import FormControl from '@mui/material/FormControl';

import Autocomplete from './Editable';
import ComboBox from './ComboBox';
import ListBox from './ListBox';

type ObjectType = { [key: string]: string | number };

interface ComboBoxProps {
    id?: string;
    value: string | string[] | undefined;
    store: Array<ObjectType>;
    onChange: (event: SelectChangeEvent) => void;
    onConfirm?: (event: { target: { name?: string; value?: string[] } }) => void;
    nameField?: string;
    valueField?: string;
    name?: string;
    label?: string;
    variant?: 'filled' | 'standard' | 'outlined' | undefined;
    disabled?: boolean;
    filter?: Array<string>;
    style?: CSSProperties | undefined;
    size?: string;
    clearable?: boolean;
    required?: boolean;
    editable?: boolean;
    multiselect?: boolean;
    freeSolo?: boolean;
}

const ComboBoxContainer = forwardRef<HTMLLIElement | null, ComboBoxProps>((props, ref) => {
    const {
        id,
        disabled,
        variant,
        editable,
        multiselect,
        name,
        store,
        filter,
        size,
        style,
        clearable,
        required,
        freeSolo,
        onChange,
        onConfirm
    } = props;

    const valueField = props.valueField || 'key';
    const nameField = props.nameField || 'val';
    const label = props.label || '';

    // Выявляем тип данных ключевого поля - строка или число
    const valType = useMemo(() => {
        if (store?.length && typeof store[0][valueField] === 'number') return 'number';

        return 'string';
    }, [store, valueField]);

    const value = useMemo(() => {
        if (valType === 'string') {
            return Array.isArray(props.value)
                ? props.value.map(v => `${v}`)
                : `${props.value !== undefined ? props.value : ''}`;
        }

        return props.value;
    }, [props.value, valType]);

    const [newValue, setNewValue] = useState<{ [key: string]: string } | null>(null);

    const filterStore = (
        sStore: Array<{ [key: string]: string | number }>,
        locFilter?: Array<string>
    ) => {
        if (!sStore) return [];

        // Приводим ключи к строке
        let fStore =
            valType === 'string'
                ? sStore.map(el =>
                      valueField !== nameField
                          ? {
                                [valueField]: `${el[valueField]}`,
                                [nameField]: el[nameField]
                            }
                          : { [valueField]: `${el[valueField]}` }
                  )
                : sStore;

        if (newValue) fStore = [...fStore, ...[newValue]];
        if (value && !Array.isArray(value) && !fStore.map(el => el[valueField]).includes(value)) {
            fStore = [...fStore, ...[{ [valueField]: value }]];
        }

        if (locFilter) {
            return fStore.filter(item => locFilter.indexOf(`${item[valueField]}`) !== -1);
        }

        return fStore;
    };

    const bindingProps = {
        id,
        label,
        name,
        nameField,
        valueField,
        disabled,
        style,
        clearable,
        required,
        onChange,
        store: filterStore(store, filter)
    };

    const renderCombobox = () => {
        if (multiselect) {
            return (
                <ListBox ref={ref} {...bindingProps} onConfirm={onConfirm} defaultValue={value} />
            );
        }

        if (!props.editable)
            return (
                <ComboBox
                    ref={ref}
                    variant={variant}
                    size={size}
                    value={value as string}
                    {...bindingProps}
                />
            );

        return (
            <Autocomplete
                ref={ref}
                setNewValue={setNewValue}
                editable={editable}
                freeSolo={freeSolo}
                value={value as string}
                {...bindingProps}
            />
        );
    };

    return (
        <FormControl
            fullWidth
            variant={props.variant ?? 'outlined'}
            size="small"
            sx={{
                '& .MuiOutlinedInput-root': {
                    height: '40px'
                }
            }}
        >
            {renderCombobox()}
        </FormControl>
    );
});

export default observer(ComboBoxContainer);
