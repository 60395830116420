import { styled } from '@mui/material/styles';
import CheckBox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const baseBlue = '#6157ff';

export const CssSelectFormControl = styled(FormControl)(({ theme }) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            height: '40px',
            '& fieldset': {
                borderColor: '#D9DEEC'
            },
            '&:hover fieldset': {
                borderColor: '#D9DEEC'
            },
            '&.Mui-focused fieldset': {
                borderColor: baseBlue,
                borderWidth: '1px'
            }
        }
    }
}));

export const CssCheckBox = styled(CheckBox)(({ theme }) => ({
    root: {
        '&$checked': {
            color: baseBlue,

            '&.Mui-disabled': {
                color: '#00000042'
            }
        }
    },
    checked: {}
}));

export const CssFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    root: {
        color: 'black'
    }
}));
