import React, { FunctionComponent, MutableRefObject, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import useNodeResize from 'hooks/nodeResize';
import useOnScreen from 'hooks/onScreen';

import ControlTree from 'forms/ControlTree';
import { ControlPropsType, ToolbarType } from 'forms/interfaces';

import Toolbar from './Toolbar';

interface PropsType extends ControlPropsType {
    descr: ToolbarType;
}

const ToolbarControl: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { caption, buttons, breakpoint } = descr;

    const dataset = useMemo(
        () => (descr.dataset ? propContainer.dataStock.getDatasetObj(descr.dataset) : undefined),
        [descr?.dataset]
    );

    const ref = useRef(null) as MutableRefObject<any>;

    const [width] = useNodeResize(ref);
    const onScreen = useOnScreen(ref);

    const controlElements = buttons?.filter(
        btn => !['toolbarButton', 'scriptButton'].includes(btn.type)
    );

    return (
        <Box sx={{ pl: 2 }} ref={ref}>
            <Toolbar
                caption={caption}
                buttons={buttons?.filter(btn =>
                    ['toolbarButton', 'scriptButton'].includes(btn.type)
                )}
                controlElements={
                    controlElements?.length
                        ? controlElements.map(button => (
                              <ControlTree
                                  key={button.guid}
                                  formDescr={button}
                                  propContainer={propContainer}
                              />
                          ))
                        : null
                }
                propContainer={propContainer}
                breakpoint={breakpoint}
                initContainerWidth={onScreen ? width : undefined}
                dataset={dataset}
            />
        </Box>
    );
});

export default ToolbarControl;
