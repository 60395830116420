import { forwardRef } from 'react';

import { TreeItem, TreeItemProps } from '@mui/x-tree-view';

import CustomContent from './CustomContent';

const CustomTreeItem = forwardRef((props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
    <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />
));

export default CustomTreeItem;
