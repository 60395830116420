import React, { FunctionComponent, useCallback, useRef } from 'react';
import { observer } from 'mobx-react';

import { Code } from '@mui/icons-material';

import IconButton from 'components/Inputs/IconButton';
import Button from 'components/Inputs/Button';

import useCtrlProps from 'hooks/ctrlProps';
import useScriptHandler from 'hooks/scriptHandler';

import { ButtonType } from './interfaces';

import SVGIcon from '../SVGIcon';
import MenuButton from './MenuButton';

const ScriptButton: FunctionComponent<ButtonType> = observer(
    ({
        buttonRef,
        variant,
        descr,
        propContainer,
        dataRow,
        style,
        icon = false,
        fullWidth = true
    }) => {
        const { guid, tooltip, caption, params, onClickScript, items, iconButton } = descr;

        const menuButtonRef = useRef<{ open: any }>(null);

        const { ctrlEnabled, ctrlVisible, ctrlCaption, ctrlTooltip, ctrlColorToken } = useCtrlProps(
            propContainer,
            guid,
            dataRow
        );

        const handleOnClick = useScriptHandler(onClickScript, propContainer, params);

        const renderButton = useCallback(() => {
            if (icon || !!iconButton) {
                return fullWidth && !iconButton ? (
                    <Button
                        size="small"
                        variant={variant ?? descr.variant ? descr.variant : 'outlined'}
                        onClick={() =>
                            onClickScript ? handleOnClick() : menuButtonRef?.current?.open()
                        }
                        disabled={!ctrlEnabled}
                        sx={{
                            height: '100%',
                            visibility: ctrlVisible ? 'inherit' : 'hidden'
                        }}
                        color={ctrlColorToken}
                        title={ctrlTooltip || tooltip}
                    >
                        {descr.iconSet && descr.icon ? (
                            <SVGIcon
                                descr={{ iconSet: descr.iconSet, icon: descr.icon }}
                                propContainer={propContainer}
                            />
                        ) : (
                            <Code />
                        )}
                    </Button>
                ) : (
                    <IconButton
                        title={ctrlTooltip || tooltip || ctrlCaption || caption}
                        onClick={() =>
                            onClickScript ? handleOnClick() : menuButtonRef?.current?.open()
                        }
                        onMouseEnter={() => onClickScript && menuButtonRef?.current?.open()}
                        disabled={!ctrlEnabled}
                        color={ctrlColorToken}
                        sx={{ visibility: ctrlVisible ? 'inherit' : 'hidden' }}
                    >
                        {descr.iconSet && descr.icon ? (
                            <SVGIcon
                                descr={{ iconSet: descr.iconSet, icon: descr.icon }}
                                propContainer={propContainer}
                            />
                        ) : (
                            <Code />
                        )}
                    </IconButton>
                );
            }

            return (
                <Button
                    ref={buttonRef}
                    variant={variant ?? descr.variant ? descr.variant : 'contained'}
                    color={ctrlColorToken}
                    onClick={() =>
                        !onClickScript && descr.items?.length
                            ? menuButtonRef?.current?.open()
                            : handleOnClick()
                    }
                    disabled={!ctrlEnabled}
                    sx={{
                        height: '100%',
                        width: '100%',
                        visibility: ctrlVisible ? 'inherit' : 'hidden',
                        whiteSpace: 'nowrap',
                        ...style
                    }}
                    startIcon={
                        <SVGIcon
                            descr={{ iconSet: descr.iconSet, icon: descr.icon }}
                            propContainer={propContainer}
                        />
                    }
                    title={ctrlTooltip || tooltip}
                >
                    {ctrlCaption || caption}
                </Button>
            );
        }, [
            ctrlEnabled,
            ctrlVisible,
            ctrlCaption,
            ctrlTooltip,
            ctrlColorToken,
            variant,
            descr?.variant,
            caption,
            propContainer,
            icon,
            menuButtonRef?.current
        ]);

        return items?.length ? (
            <MenuButton
                buttonRef={menuButtonRef}
                descr={descr}
                propContainer={propContainer}
                style={style}
                icon={icon}
                disabled={!ctrlEnabled}
                empty={!onClickScript || !fullWidth}
                dataRow={dataRow}
            >
                {renderButton()}
            </MenuButton>
        ) : (
            renderButton()
        );
    }
);

export default ScriptButton;
