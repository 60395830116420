import React, { useState, useEffect, useMemo, FunctionComponent, useRef, useCallback } from 'react';
import { observer } from 'mobx-react';

import { Grid } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import isEqual from 'lodash/isEqual';

import useRouting from 'hooks/routing';

import ConfigurationStore from 'store/configurationStore';
import DescriptorStore from 'store/descriptorStore';
import DataStore from 'store/dataStore';
import ActionStore from 'store/actionStore';
import FormActions from 'store/actionStore/form/FormActions';
import EventsStore from 'store/eventsStore';
import { afterDataLoad } from 'store/eventsStore/events';
import NotificationStore from 'store/notificationStore';

import { getResourceDescriptor, applyFormExtParams } from 'forms/form-utils';
import Translation from 'localization/translation';

import PropContainer from 'dataObj/PropContainer';

import Loading from 'components/utils/Loading/Loading';
import FormHelper from 'components/utils/FormHelper';

import { CustomFormType } from 'forms/interfaces';
import Output from 'forms/controls/ScriptButton/Output';
import Glossary from 'forms/controls/glossary/Glossary';
import ControlTree from 'forms/ControlTree';

const CustomForm: FunctionComponent<CustomFormType> = observer(
    ({
        formGuid,
        tableId,
        formConfig,
        extParamVals,
        initEditMode,
        parentDataStock,
        parentPropContainer,
        cdoData,
        propContainerGuid,
        handleClose,
        handleError,
        editor = false,
        modal = false
    }) => {
        const { locale, content, isReady, devMode } = ConfigurationStore;
        const [dataLoaded, setDataLoaded] = useState(false);
        const [formReady, setFormReady] = useState(false);
        const [initFormDescr, setInitFormDescr] = useState<null | any>(null);
        const [formDescr, setFormDescr] = useState<null | any>(null);
        const [dataStock, setDataStock] = useState<null | any>(null);
        const [error, setError] = useState<null | string>(null);

        const propContainerRef = useRef<null | any>();

        const formActions: FormActions | null = useMemo(() => {
            if (formDescr && propContainerRef?.current) {
                // Основные действия формы
                // Для внешнего управления формой складываем их в стор
                return ActionStore.addFormActions(
                    formDescr,
                    propContainerRef.current,
                    handleClose,
                    initEditMode,
                    propContainerGuid
                );
            }

            return null;
        }, [formDescr, propContainerRef?.current]);

        useEffect(() => {
            if (initFormDescr) {
                const rebuildDescr = applyFormExtParams(initFormDescr, extParamVals);

                // Только если описатель действительно изменился
                if (!isEqual(formDescr, rebuildDescr)) {
                    setFormReady(false);

                    // Проверка текущей локали
                    if (rebuildDescr.lang && rebuildDescr.lang !== locale) {
                        setFormDescr(
                            new Translation(locale, rebuildDescr.translation).run(rebuildDescr)
                        );
                    } else {
                        setFormDescr({ ...rebuildDescr });
                    }
                }
            }
        }, [initFormDescr, locale, formActions?.isReady, JSON.stringify(extParamVals)]);

        // создание propContainer при получении дескриптора
        useEffect(() => {
            if (formDescr) {
                const pC = new PropContainer(
                    formDescr,
                    parentDataStock,
                    parentPropContainer,
                    propContainerGuid,
                    editor
                );

                propContainerRef.current = pC;
                setDataStock(pC.dataStock);

                DataStore.registerForm(pC);

                return () => {
                    if (propContainerRef.current) {
                        DataStore.unregisterForm(propContainerRef.current);
                        ActionStore.removeActions(formDescr.guid);
                    }
                };
            }
        }, [formDescr]);

        // Имеет ли форма Глоссарий
        const [withGloss, setWithGloss] = useState(false);

        const loadForm = useCallback(() => {
            if (formConfig) {
                setInitFormDescr(formConfig);
            } else {
                setError(null);
                getResourceDescriptor(formGuid, tableId, setInitFormDescr, DescriptorStore, {
                    logout: ConfigurationStore.logout
                }).catch((err: any) => {
                    console.error(err.message);
                    setError(err?.message);
                    handleError && handleError(true);
                });
            }
        }, [formGuid, tableId, formConfig]);

        // Загрузка ресурса
        useEffect(() => {
            loadForm();
        }, [formGuid, tableId, formConfig]);

        const loadFormData = useCallback(
            (force = false) => {
                if (formDescr?.isSubForm) {
                    setDataLoaded(true);
                } else if (formDescr && formDescr.datasets && dataStock) {
                    dataStock
                        .loadData(
                            extParamVals,
                            formActions?.editMode &&
                                ['create', 'cdoCreate'].includes(formActions.editMode),
                            !force ? cdoData : undefined
                        )
                        .then(() => {
                            setDataLoaded(true);
                        })
                        .catch((err: Error) => {
                            console.error(err.message);
                            setError(err.message);
                        });
                }
            },
            [formDescr, dataStock, JSON.stringify(extParamVals), formActions?.editMode]
        );

        // Принудительная перезагрузка формы
        useEffect(() => {
            if (formActions?.forceReload) {
                formActions?.setForceReload(false);
                loadFormData(true);
            }
        }, [formActions?.forceReload]);

        // Загрузка данных
        useEffect(() => {
            loadFormData();
        }, [formDescr, dataStock, JSON.stringify(extParamVals), formActions?.editMode]);

        useEffect(() => {
            if (formReady && dataStock && propContainerRef.current.formulaCalculator) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                DataStore.taskQueue.addFormulaTask({
                    taskType: 'onFormShow',
                    calculators: [propContainerRef.current.formulaCalculator]
                });
            }
        }, [formReady, JSON.stringify(extParamVals)]);

        useEffect(() => {
            // Включаем режим редактирования
            if (formActions && dataLoaded) {
                setDataLoaded(false);

                if (formActions.editMode) {
                    setWithGloss(formDescr.withGloss);
                    formActions
                        .edit()
                        .then(() => {
                            setFormReady(true);
                            formActions?.setIsReady();
                        })
                        .catch(err => console.error(err.message));
                } else {
                    setFormReady(true);
                    formActions?.setIsReady();
                }
            }
        }, [formDescr, dataLoaded, formActions, formActions?.editMode]);

        useEffect(() => {
            if (propContainerGuid && formReady) {
                EventsStore.getEvents(`${propContainerGuid}-${formDescr.guid as string}`)
                    ?.triggerEvent(afterDataLoad)
                    .catch(err => console.error(err.message));
            }
        }, [propContainerGuid, formReady]);

        useRouting(formActions, modal);

        const renderLoading = useCallback(() => {
            if (error) {
                NotificationStore.showAlert(error, content.resource.alert.errorTitle);
                handleError && handleError(true);

                return null;
            }

            return <Loading delay />;
        }, [error, isReady, formDescr]);

        const renderControl = useCallback(() => {
            if (withGloss)
                return (
                    <Grid container sx={{ height: '100%' }} spacing={4}>
                        <Grid item xs={9} sx={{ height: '100%' }}>
                            <ControlTree
                                key={formGuid}
                                formDescr={formDescr}
                                propContainer={propContainerRef.current}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            sx={{
                                height: '100%',
                                position: 'absolute',
                                right: 16,
                                width: '100%'
                            }}
                        >
                            <Glossary
                                formDescr={formDescr}
                                propContainer={propContainerRef.current}
                            />
                        </Grid>
                    </Grid>
                );

            return (
                <ControlTree
                    key={formGuid}
                    formDescr={formDescr}
                    propContainer={propContainerRef.current}
                />
            );
        }, [withGloss, formGuid, formDescr, propContainerRef]);

        return (
            <StyledEngineProvider injectFirst>
                {formReady && (process.env.REACT_APP_FORM_DEV_MODE || devMode) && (
                    <FormHelper helperGuid={propContainerRef.current.guid} formDescr={formDescr} />
                )}
                {formReady ? (
                    <>
                        {renderControl()}
                        {
                            // отображение скриптовых форм
                            Object.keys(propContainerRef.current?.scripts).map(scriptName => (
                                <Output
                                    key={scriptName}
                                    scriptButtonStore={propContainerRef.current.scripts[scriptName]}
                                    propContainer={propContainerRef.current}
                                />
                            ))
                        }
                    </>
                ) : (
                    renderLoading()
                )}
            </StyledEngineProvider>
        );
    }
);

export default CustomForm;
