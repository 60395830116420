import React, { FunctionComponent, useCallback, useMemo } from 'react';

import { TableCell } from '@mui/material';

import SVGIcon from 'forms/controls/SVGIcon';
import ControlTree from 'forms/ControlTree';
import { styleStringToStyleObject } from 'forms/form-utils';

import { ControlPropsType } from 'forms/interfaces';

import { DataLineCellType } from '../interface';

interface PropsType extends DataLineCellType, ControlPropsType {
    getCellColor: (fieldName: string) => any | undefined;
    isColored: boolean;
    dataRow: any;
}

const Cell: FunctionComponent<PropsType> = ({
    col,
    isColored,
    getCellColor,
    renderCellText,
    dataRow,
    propContainer
}) => {
    const { iconSet, iconFieldName, fieldName, align, controls, styles = '' } = col;

    const label = useMemo(() => renderCellText(fieldName), [fieldName, renderCellText]);
    const icon = useMemo(
        () => (iconSet ? renderCellText(iconFieldName || fieldName, true) : null),
        [fieldName, renderCellText, iconSet]
    );
    const cellColors = useMemo(() => getCellColor(fieldName), [fieldName, getCellColor]);

    const cellContent = useCallback(() => {
        if (controls?.length) {
            return (
                <ControlTree
                    formDescr={controls[0]}
                    propContainer={propContainer}
                    dataRow={dataRow}
                />
            );
        }

        if (iconSet && icon)
            return (
                <>
                    <SVGIcon
                        descr={{
                            iconSet,
                            icon,
                            tooltip: fieldName && iconFieldName ? undefined : label
                        }}
                        propContainer={propContainer}
                    />
                    {fieldName && iconFieldName ? (
                        <span style={{ paddingLeft: 8 }}>{label}</span>
                    ) : null}
                </>
            );

        return label;
    }, [controls, label]);

    return (
        <TableCell
            key={fieldName}
            align={align || 'left'}
            style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}
            sx={{
                ...{
                    backgroundColor: cellColors?.bgColor || 'inherit',
                    color: cellColors?.textColor || 'inherit'
                },
                ...styleStringToStyleObject(styles)
            }}
            padding={controls?.length ? 'none' : 'normal'}
            height={30}
        >
            {cellContent()}
        </TableCell>
    );
};

export default Cell;
