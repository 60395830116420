import React, { FunctionComponent } from 'react';

import clsx from 'clsx';

import {
    Box,
    IconButton,
    OutlinedInput,
    InputLabel,
    InputAdornment,
    FormControl,
    FormHelperText,
    Divider,
    Tooltip,
    CircularProgress
} from '@mui/material';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';

import MenuBookIcon from '@mui/icons-material/MenuBook';
import ClearIcon from '@mui/icons-material/Clear';

import useLookupStyles from './styles';

interface LookupProps extends BaseTextFieldProps {
    onClear: any;
    onOpenClick: any;
    loading?: boolean;
}

const Lookup: FunctionComponent<LookupProps> = props => {
    const {
        onClear,
        onOpenClick,
        disabled,
        label,
        value,
        helperText,
        error,
        loading = false,
        style
    } = props;

    const { classes } = useLookupStyles();

    return (
        <FormControl
            className={classes.form}
            variant="outlined"
            size="small"
            error={error}
            disabled={loading || disabled}
            sx={theme => ({
                '& .Mui-disabled': {
                    '&.MuiInputBase-root': {
                        overflow: 'hidden',
                        backgroundColor: theme.palette.mode === 'light' ? '#f1f1f1' : '#1A2027'
                    },
                    '& .MuiInputBase-input': {
                        overflow: 'hidden',
                        '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.6)'
                    }
                }
            })}
            style={style}
        >
            {label && (
                <InputLabel
                    variant="outlined"
                    sx={{
                        background: 'none !important',
                        '&.MuiFormLabel-root': {
                            maxWidth: 'calc(100% - 64px)'
                        },
                        '&.Mui-focused': { maxWidth: 'calc(133% - 24px)' }
                    }}
                >
                    {label}
                </InputLabel>
            )}
            <OutlinedInput
                readOnly
                label={label || undefined}
                value={value}
                className={classes?.root}
                classes={{
                    input: clsx(classes.input, helperText && classes.helperTextInput)
                }}
                endAdornment={
                    <InputAdornment
                        position="end"
                        sx={{
                            width: helperText ? '64%' : 'initial',
                            marginLeft: 'auto'
                        }}
                    >
                        <Box
                            className={classes.helperWrapper}
                            sx={{ visibility: helperText ? 'visible' : 'hidden' }}
                        >
                            <Divider className={classes.divider} orientation="vertical" />
                            <Tooltip title={helperText as string}>
                                <Box className={classes.helperTextWrapper}>
                                    <FormHelperText className={classes.helperText}>
                                        {helperText}
                                    </FormHelperText>
                                    <Box className={classes.helperFade} />
                                </Box>
                            </Tooltip>
                        </Box>
                        {!!value && (
                            <IconButton
                                onClick={onClear}
                                edge="end"
                                size="small"
                                disabled={disabled}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        )}
                        <Divider className={classes.divider} orientation="vertical" />

                        <IconButton
                            onClick={onOpenClick}
                            className={classes.iconButton}
                            edge="end"
                            size="small"
                            disabled={disabled || loading}
                        >
                            {loading ? (
                                <CircularProgress size={20} />
                            ) : (
                                <MenuBookIcon fontSize="small" />
                            )}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

export default Lookup;
