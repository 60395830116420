import { action, get, makeAutoObservable, observable, remove, set } from 'mobx';

import PropContainer from 'dataObj/PropContainer';

import ResourceEvents from './ResourceEvents';
import { filterEventType } from './eventTriggers';

export class Events {
    @observable events: { [key: string]: ResourceEvents } = {};

    constructor() {
        makeAutoObservable(this);
    }

    @action addEvents = (
        guid: string,
        descr: any,
        events: Array<any>,
        propContainer: PropContainer
    ) => {
        const availableEvents = events.filter(event => filterEventType(descr.type, event));

        if (availableEvents?.length) {
            const currentEvents = this.getEvents(guid);

            if (currentEvents) {
                currentEvents.addEvents(availableEvents, propContainer);
            } else set(this.events, { [guid]: new ResourceEvents(descr, events, propContainer) });
        }

        return this.getEvents(guid);
    };

    @action removeEvents = (guid: string) => {
        for (const key in this.events) {
            if (key.includes(guid)) {
                remove(this.events, key);
            }
        }
    };

    /**
     * Получает события ресурса на основе указанных параметров.
     *
     * @param {string} guid - Опционально. GUID ресурса, для которого необходимо получить события.
     * @return {ResourceEvents} События ресурса. Если не указан GUID, возвращается null.
     */
    getEvents = (guid?: string): ResourceEvents => (guid ? get(this.events, guid) : null);
}

const EventsStore = new Events();
export default EventsStore;
