import ELK from 'elkjs/lib/elk.bundled';

const elk = new ELK();

const getLayoutedElements = async (nodes: any[], edges: any[], options: any = {}) => {
    const isHorizontal = options?.['elk.direction'] === 'RIGHT';
    const graph = {
        id: 'root',
        layoutOptions: options,
        children: nodes.map(node => ({
            ...node,
            // Adjust the target and source handle positions based on the layout
            // direction.
            targetPosition: isHorizontal ? 'left' : 'top',
            sourcePosition: isHorizontal ? 'right' : 'bottom',

            // Hardcode a width and height for elk to use when layouting.
            width: 300,
            height: 200
        })),
        edges: edges.filter(
            edge =>
                !!nodes.find(node => node.id === edge.source) &&
                !!nodes.find(node => node.id === edge.target)
        )
    };

    try {
        const layoutedGraph = await elk.layout(graph);

        return {
            layoutedNodes: layoutedGraph.children?.map(node_1 => ({
                ...node_1,
                // React Flow expects a position property on the node instead of `x`
                // and `y` fields.
                position: { x: node_1.x, y: node_1.y }
            })),

            layoutedEdges: layoutedGraph.edges
        };
    } catch (message) {
        console.error(message);

        return {};
    }
};

export default getLayoutedElements;
