import { useEffect, useMemo, useState } from 'react';

import { DatasetType, ResourceInfo } from 'forms/interfaces';

import { generateTempDataset, generateForm } from 'forms/form-utils';

import PropContainer from 'dataObj/PropContainer';

export default function useGenerateForm(
    resource: any,
    propContainer: PropContainer,
    generateControl: (guid: string, dataset: DatasetType, formGuid?: string) => Promise<any>,
    formName?: string
) {
    const [controls, setControls] = useState<any[]>([]);
    const [form, setForm] = useState<ResourceInfo | null>(null);

    const dataset = useMemo(
        () =>
            resource.type === 'dataset' ? resource : (generateTempDataset(resource) as DatasetType),
        [resource?.guid]
    );

    useEffect(() => {
        if (dataset) {
            const resourceLink = propContainer.getLookupResourceLink(resource.name);

            generateControl(
                resourceLink?.link ? resourceLink.link.guid : resource.guid,
                dataset,
                resourceLink?.formGuid
            )
                .then(control => setControls([control]))
                .catch(err => console.error(err.message));
        }
    }, [dataset?.guid]);

    useEffect(() => {
        if (resource && controls?.length) {
            generateForm(
                dataset,
                propContainer.getCurrentForm()?.descr,
                controls,
                resource?.name,
                formName,
                propContainer.formDescr.ownerGuid
                    ? propContainer.getMainForm()?.descr.guid
                    : undefined
            )
                .then(resDescr => setForm(resDescr))
                .catch(err => console.error(err.message));
        }
    }, [resource, controls]);

    return form;
}
