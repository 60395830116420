import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { CSSObject, Theme } from '@mui/material/styles';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';

const openedMixin = (theme: Theme, width: number): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden',
    width
});

const closedMixin = (theme: Theme, width: number): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(11)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(13)} + 1px)`
    }
});

interface DrawerPropsType extends Omit<DrawerProps, 'width'> {
    width: number;
}

const Drawer: FunctionComponent<DrawerPropsType> = observer(({ width, ...otherProps }) => {
    const { open, variant } = otherProps;

    return (
        <MuiDrawer
            {...otherProps}
            sx={theme => ({
                flexShrink: 0,
                whiteSpace: 'nowrap',
                boxSizing: 'border-box',
                '& .MuiPaper-root': { borderRight: 'none', overflow: 'hidden' },
                ...(open && {
                    ...openedMixin(theme, width),
                    '& .MuiDrawer-paper': openedMixin(theme, width)
                }),
                ...(!open &&
                    variant === 'permanent' && {
                        ...closedMixin(theme, width),
                        '& .MuiDrawer-paper': closedMixin(theme, width)
                    }),
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                })
            })}
        />
    );
});

export default Drawer;
