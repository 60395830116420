import React, { useEffect, FC, Suspense, useCallback } from 'react';
import { Route, RouteChildrenProps, Switch, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Container } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import ConfigurationStore from 'store/configurationStore';
import MainMenuStore from 'store/mainMenuStore';
import RoutingStore from 'store/routingStore';

import CustomForm from 'forms/CustomForm';

import TestPage from 'pages/TestPage/TestPage';

import Loading from 'components/utils/Loading/Loading';
import AppVersion from 'components/Feedback/AppVersion';

import ResPage from 'components/resources/ResPage/ResPage';
import ExtRefsPage from 'components/resources/ResEditor/ExtRefsPage';

const MainPage: FC = observer(() => {
    const { mainFormGuid, demoFormGuid, readMainFormGuid, isReady, content } = ConfigurationStore;
    const { menuPosition } = MainMenuStore;

    const history = useHistory();

    RoutingStore.setHistory(history);

    const routePropsWrapper = useCallback(
        (guid: string | null) => (props: RouteChildrenProps) => {
            const { match } = props;

            // Фиксируем папраметры от роутера
            ConfigurationStore.setCurrentRouterPath(match?.params as { [key: string]: string });

            return guid && isReady ? <CustomForm key={guid} formGuid={guid} /> : null;
        },
        [isReady]
    );

    useEffect(() => {
        if (!mainFormGuid) {
            readMainFormGuid().catch(err => console.error(err.message));
        }
    }, [mainFormGuid, readMainFormGuid]);

    const links = [
        {
            name: 'Test-page',
            link: '/test',
            component: process.env.REACT_APP_TEST_PAGE ? <TestPage /> : null
        },
        {
            name: 'Ресурсы',
            link: '/resource',
            exact: true,
            component: <ResPage />
        },
        {
            name: 'Справочники',
            link: '/extref',
            component: <ExtRefsPage />
        },
        {
            name: 'Справочник(ADR_OBLAST)',
            link: '/extref/ADR_OBLAST'
        },
        {
            name: 'Демо',
            link: '/demo(.*)/',
            exact: true,
            component: routePropsWrapper(demoFormGuid)
        },
        {
            name: 'Главная',
            link: '/(.*)/',
            exact: true,
            component: routePropsWrapper(mainFormGuid)
        }
    ];

    return (
        <StyledEngineProvider injectFirst>
            <div
                style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    position: 'absolute'
                }}
            >
                <Container
                    maxWidth="xl"
                    disableGutters
                    style={{
                        ...{ margin: 'auto', height: '100%' },
                        ...menuPosition,
                        transition:
                            'padding-top 0.3s ease-in-out 0s, padding-left 0.3s ease-in-out 0s'
                    }}
                >
                    <Suspense fallback={<Loading message={content.controls.data.lazyLoading} />}>
                        <Switch>
                            {links
                                .filter(link => link.component)
                                .map((link, idx) => (
                                    <Route
                                        key={`${idx}-${link.link}`}
                                        path={link.link}
                                        exact={link.exact}
                                    >
                                        {link.component}
                                    </Route>
                                ))}
                        </Switch>
                    </Suspense>
                </Container>
            </div>
            <AppVersion position="right" hide />
        </StyledEngineProvider>
    );
});

export default MainPage;
