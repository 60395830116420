import { makeObservable, observable, action } from 'mobx';
import DataAccum from './DataAccum';
import DataChunkContainer, { TChunkKeyType } from './DataChunkContainer';
import Dataset from './customDataset';
import { IQueryData } from './dataInterfaces';

export default class DataChunk {
    container: DataChunkContainer;
    ds: Dataset;
    index: number;
    key: TChunkKeyType;
    data: IQueryData;
    hash: any;
    active: boolean;
    accum: DataAccum;
    lastLoadTime?: Date;
    forceRefresh: boolean;

    constructor(container: DataChunkContainer, key: TChunkKeyType) {
        this.container = container;
        this.ds = container.ds;
        this.index = container.getNewElemIndex();
        this.key = key;
        this.data = [];
        this.hash = {};
        this.active = false;
        this.accum = new DataAccum(this.ds, this);
        this.lastLoadTime = undefined;
        this.forceRefresh = false;
        this.container.chunks[key] = this;
        makeObservable(this, {
            data: observable,
            addData: action
        });
    }

    addData(data: IQueryData, hash: any) {
        this.data = data;
        this.hash = hash || {};
        this.accum.init();
        this.lastLoadTime = new Date();
        this.active = true;
        this.ds.dataStock.ownerPropContainer.formulaCalculator.onDataLoaded(this.ds, data);
    }

    joinData(data: IQueryData) {
        this.data = this.data.concat(data);
        this.accum.init();
        this.lastLoadTime = new Date();
        this.active = true;
        this.ds.dataStock.ownerPropContainer.formulaCalculator.onDataLoaded(this.ds, data);
    }

    close() {
        this.accum.clear();
        this.data = [];
        this.active = false;
    }
}
