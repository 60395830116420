import { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PropContainer from 'dataObj/PropContainer';
import CustomDataset from 'dataObj/customDataset';

import { DBSelfHierarchyTreeType } from 'forms/interfaces';

import { SelfTreeStore } from './SelfTreeStore';
import NodeList from './NodeList';

interface DBSelfHierarchyTreeProps {
    descr: DBSelfHierarchyTreeType;
    propContainer: PropContainer;
}

const DBSelfHierarchyTree = observer(({ descr, propContainer }: DBSelfHierarchyTreeProps) => {
    const dsParent: CustomDataset = useMemo(() => {
        const ds: CustomDataset | undefined = propContainer.dataStock.getDatasetObj(
            descr.datasetName
        );
        if (ds) ds.noSelectAfterOpen = true;
        return ds;
    }, [propContainer, descr.datasetName]);

    const dsChild: CustomDataset = useMemo(
        () => (dsParent.detailDatasets as Record<string, CustomDataset[]>)[dsParent.keyField][0],
        [dsParent]
    );

    const store = useMemo(
        () =>
            new SelfTreeStore(
                propContainer,
                dsParent,
                descr.parentFieldName,
                descr.captionFieldName,
                descr.afName,
                descr.caption
            ),
        [propContainer, dsParent, descr]
    );

    const [expanded, setExpanded] = useState<string[]>(['0']);
    const [selected, setSelected] = useState<string>('');

    useEffect(() => {
        store && store.selectNode(0);
        setSelected('0');
    }, [store]);

    useEffect(() => {
        store.rebuildTree(dsParent);
        dsParent.findById(store.activeNode.id);
    }, [dsParent.data]);

    useEffect(() => {
        store.rebuildTree(dsChild);
    }, [dsChild.data]);

    useEffect(() => {
        if (dsParent?.activeRec) {
            const id = +(dsParent.activeRec as Record<string, any>)[dsParent.keyField];
            store.selectNode(id);
            setSelected(id.toString());
        }
    }, [dsParent.activeRec]);

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (event: React.SyntheticEvent, nodeId: string) => {
        // setExpanded(Array.from(new Set([...expanded, nodeId])));
        store.selectNode(+nodeId);
        setSelected(nodeId);
    };

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
        >
            <NodeList store={store} list={[store.root]} />
        </TreeView>
    );
});

export default DBSelfHierarchyTree;
