import { useState, FC } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { FilterAlt, FilterAltOff } from '@mui/icons-material';

import CustomDataset from 'dataObj/customDataset';
import { FilterValues } from 'dataObj/CustomFilter';

import FilterDialog from 'forms/controls/Filter/FilterDialog';

import ConfigurationStore from 'store/configurationStore';

interface PropTypes {
    dataset: CustomDataset;
    breakpointWidth: number;
    fullWidth?: boolean;
    gridWidth?: number;
}

const UserFilter: FC<PropTypes> = observer(
    ({ dataset, fullWidth, gridWidth, breakpointWidth }: PropTypes) => {
        const { content, log } = ConfigurationStore;

        const [openDialog, setOpenDialog] = useState<boolean>(false);

        const openUserFilter = () => setOpenDialog(true);

        const handleClose = (state: boolean, result?: FilterValues) => {
            setOpenDialog(false);
            if (result) {
                log(`Пользователь собрал фильтр: ${JSON.stringify(result)}`, 'log');
                dataset.filter.values = result;
                dataset.loadData();
            }
        };

        if (gridWidth)
            return (
                <Box display="flex" minWidth={106}>
                    {fullWidth ? (
                        <Button
                            variant="outlined"
                            aria-label="filter"
                            onClick={openUserFilter}
                            // startIcon={dataset.filter.isEmpty() ? <FilterAltOff /> : <FilterAlt />}
                            startIcon={<FilterAlt />}
                            sx={theme => ({
                                position: 'absolute',
                                right: theme.spacing(5),
                                minWidth: 'auto',
                                height: 'fit-content',
                                alignSelf: 'center'
                            })}
                        >
                            {content.controls.grid.filter}
                        </Button>
                    ) : (
                        <Tooltip title={content.controls.grid.filter}>
                            <IconButton
                                onClick={openUserFilter}
                                sx={theme => ({
                                    ml: 'auto !important',
                                    padding: '6px',
                                    position: 'absolute',
                                    right: theme.spacing(5),
                                    alignSelf: 'center'
                                })}
                            >
                                {/* dataset.filter.isEmpty() ? <FilterAltOff /> : <FilterAlt /> */}
                                <FilterAlt />
                            </IconButton>
                        </Tooltip>
                    )}
                    {openDialog ? (
                        <FilterDialog handleClose={handleClose} dataset={dataset} />
                    ) : null}
                </Box>
            );

        return null;
    }
);

export default UserFilter;
