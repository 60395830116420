import tinycolor from 'tinycolor2';

import { Property } from 'csstype';

const primary = '#386EFA';
const secondary = '#73D7F5';
const error = '#F57069';
const warning = '#F57069';
const success = '#34DFA2';
const info = '#FF9690';
const hero = '#6157FF';
const greyBorder = '#D9DEEC';
const disabled = '#DFDEDE';

const lightenRate = 7.5;
const darkenRate = 15;

const defaults = {
    palette: {
        hero,
        bgLight: {
            one: '#F1F4FC',
            two: 'rgba(241, 244, 252, 0.6)',
            disabled: 'rgb(97, 87, 255, 0.3)'
        },

        avatarLinearGradient: {
            one: '#7385FF',
            two: '#584DFF'
        },
        appBar: {
            color: '#7C7D80',
            colorHover: '#6157FF',
            colorActive: '#000',
            bg: '#fff'
        },
        border: {
            main: greyBorder,
            light: tinycolor(greyBorder).lighten(lightenRate).toHexString(),
            dark: tinycolor(greyBorder).darken(darkenRate).toHexString()
        },
        primary: {
            main: primary,
            light: tinycolor(primary).lighten(lightenRate).toHexString(),
            dark: tinycolor(primary).darken(darkenRate).toHexString()
        },
        secondary: {
            main: secondary,
            light: tinycolor(secondary).lighten(lightenRate).toHexString(),
            dark: tinycolor(secondary).darken(darkenRate).toHexString(),
            contrastText: '#ccc'
        },
        warning: {
            main: warning,
            light: tinycolor(warning).lighten(lightenRate).toHexString(),
            dark: tinycolor(warning).darken(darkenRate).toHexString()
        },
        error: {
            main: error,
            light: tinycolor(error).lighten(lightenRate).toHexString(),
            dark: tinycolor(error).darken(darkenRate).toHexString()
        },
        success: {
            main: success,
            light: tinycolor(success).lighten(lightenRate).toHexString(),
            dark: tinycolor(success).darken(darkenRate).toHexString()
        },
        disabled: {
            main: disabled,
            light: tinycolor(disabled).lighten(lightenRate).toHexString(),
            dark: tinycolor(disabled).darken(darkenRate).toHexString()
        },
        info: {
            main: info,
            light: tinycolor(info).lighten(lightenRate).toHexString(),
            dark: tinycolor(info).darken(darkenRate).toHexString()
        },
        text: {
            primary: '#353638',
            secondary: '#B2B6C2',
            hint: '#B2B6C2'
        },
        background: {
            default: '#fff',
            light: '#e8ebf100'
        }
    },

    customShadows: {
        widget: '0px 2px 35px rgba(78, 72, 190, 0.08)',
        widgetDark: '0px 2px 35px rgba(78, 72, 190, 0.08)',
        widgetWide: '0px 2px 35px rgba(78, 72, 190, 0.08)'
    },

    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 2560
        }
    },

    spacing: 4,

    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    textTransform: 'none' as Property.TextTransform,
                    borderRadius: 6,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                        '@media (hover: none)': {
                            boxShadow: 'none'
                        }
                    },
                    '&$focusVisible': {
                        boxShadow: 'none'
                    },
                    '&:active': {
                        boxShadow: 'none'
                    },
                    '&$disabled': {
                        boxShadow: 'none'
                    }
                },
                contained: {
                    color: '#fff'
                },
                outlined: {
                    // color: '#6157FF', отключить принудительный цвет шрифта для outlined-кнопки
                    border: `1px solid ${greyBorder}`,
                    '&:hover': {
                        background: 'rgba(0, 0, 0, 0.02)',
                        border: `1px solid ${greyBorder}`
                    },
                    '&:active': {
                        background: 'rgba(99, 102, 114, 0.1)'
                    },
                    '&:disabled': {
                        color: 'rgba(194, 196, 203, 1)',
                        background: '#FFFFFF',
                        border: '1px solid #EDEDED'
                    }
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#304368',
                    // Блок для элементов AppBar
                    // Общий отступ
                    '& .toolbar': {
                        // marginLeft: 84
                    },
                    // Центрирование
                    '& .MuiGrid-item': {
                        '&.ctrl': {
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center'
                        }
                    },
                    // Поля ввода
                    '& .MuiFormControl-root': {
                        '& .MuiFormLabel-root': {
                            color: '#fff',
                            '&.Mui-focused': {
                                color: '#fff'
                            }
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#fff',
                                backgroundColor: '#ffffff21'
                            },
                            '&:hover fieldset': {
                                borderColor: '#fff'
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#fff !important',
                                backgroundColor: '#ffffff42'
                            },
                            '&.Mui-disabled': {
                                color: '#fff',
                                borderColor: disabled,

                                '& fieldset': {
                                    borderColor: disabled
                                },
                                '&:hover fieldset': {
                                    borderColor: disabled
                                }
                            }
                        },
                        '& input': {
                            color: '#fff !important'
                        }
                    },
                    // Лукапы
                    '& .MuiDivider-root': {
                        borderColor: '#fff'
                    },
                    '&.Mui-disabled': {
                        '& .MuiDivider-root': {
                            borderColor: '#0000001E'
                        }
                    },
                    '& .MuiFormHelperText-root': {
                        color: '#fff'
                    },
                    // Кнопки
                    '& .MuiButton-root': {
                        color: '#fff',
                        backgroundColor: '#304368',
                        '&:hover': {
                            backgroundColor: '#ffffff42'
                        }
                    },
                    '& .MuiIconButton-root': {
                        color: '#fff',
                        backgroundColor: '#304368',
                        '&:hover': {
                            backgroundColor: '#ffffff42'
                        }
                    },
                    // Комбобоксы
                    '& .MuiSelect-standard': {
                        color: '#fff',
                        '&:hover': {
                            '& .MuiInput-underline': {
                                '&:before': {
                                    borderBottom: '1px solid rgb(255 255 255 / 80%)'
                                }
                            }
                        }
                    },
                    '& .MuiInput-underline': {
                        '&:before': {
                            borderBottom: '1px solid rgb(255 255 255 / 69%)'
                        },
                        '&:after': {
                            borderBottom: '2px solid #fff'
                        }
                    },
                    '& .MuiSelect-icon': {
                        color: '#fff'
                    }
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '& .MuiPaper-root': {
                        backgroundColor: '#F6F6F6'
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#808FAB'
                    },
                    '& .MuiTypography-body1': {
                        color: '#616161',
                        fontWeight: 700
                    },
                    '& .Mui-disabled': {
                        '.MuiTypography-body1': {
                            color: '#808FAB'
                        }
                    },
                    '& .MuiMenuItem-root': {
                        '& .MuiTypography-root': {
                            fontSize: 16,
                            '& b': {
                                fontSize: '12px !important'
                            }
                        }
                    }
                }
            }
        },
        MuiButtonGroup: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                },
                contained: {
                    button: { color: '#fff' },
                    background: 'linear-gradient(139.78deg, #7385FF 3.01%, #584DFF 95.95%)',
                    '&:hover': {
                        background:
                            'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(139.78deg, #7385FF 3.01%, #584DFF 95.95%)'
                    },
                    '&:active': {
                        background:
                            'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(139.78deg, #7385FF 3.01%, #584DFF 95.95%)'
                    },
                    '&:disabled': {
                        color: '#fff',
                        opacity: 0.25
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& input': {
                        color: '#353638'
                    },
                    '& .MuiFormLabel-root': {
                        '&.Mui-error': {
                            color: error,
                            /* На случай, если захочется не выделять красным label незаполненного поля
                            color: '#888888FF',

                            '&.MuiFormLabel-filled': {
                                color: error
                            },
                            '&.Mui-focused': {
                                color: error
                            }, */
                            '&.Mui-disabled': {
                                // color: disabled
                                color: '#B2B6C2'
                            }
                        },
                        '&.Mui-disabled': {
                            // color: disabled
                            color: '#B2B6C2'
                        }
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: greyBorder
                        },
                        '&:hover fieldset': {
                            borderColor: greyBorder
                        },
                        '&.Mui-focused fieldset': {
                            borderWidth: '1px',
                            borderColor: primary
                        },
                        '&.Mui-error': {
                            '& fieldset': {
                                borderColor: error
                            },
                            '&:hover fieldset': {
                                borderColor: error
                            }
                        },
                        '&.Mui-disabled': {
                            color: '#00000042',
                            borderColor: disabled,

                            '& fieldset': {
                                borderColor: disabled
                            },
                            '&:hover fieldset': {
                                borderColor: disabled
                            }
                        }
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: { paddingTop: 0 },
                input: { paddingTop: 0 }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.MuiTypography-subtitle1': {
                        fontWeight: 500,
                        fontSize: '0.875rem',
                        lineHeight: '1.57',
                        letterSpacing: '0.00714em'
                    },
                    '&.MuiTypography-subtitle2': {
                        color: '#7C7D80',
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        lineHeight: 1.66
                    },
                    '&.caption-h1': {
                        fontSize: 24,
                        fontWeight: 700,
                        lineHeight: '32px',
                        textAlign: 'left'
                    },
                    '&.caption-h2': {
                        fontSize: 20,
                        fontWeight: 500,
                        lineHeight: '28px',
                        textAlign: 'left'
                    },
                    '&.caption-h3': {
                        fontSize: 14,
                        fontWeight: 700,
                        lineHeight: '21px',
                        textAlign: 'left'
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.caption-h1': {
                        '& .MuiBadge-root': {
                            fontSize: 24,
                            fontWeight: 700,
                            lineHeight: '32px',
                            textAlign: 'left'
                        }
                    },
                    '&.caption-h2': {
                        fontSize: 20,
                        fontWeight: 500,
                        lineHeight: '28px',
                        textAlign: 'left'
                    },
                    '&.caption-h3': {
                        fontSize: 14,
                        fontWeight: 700,
                        textAlign: 'left'
                    }
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    paddingTop: 0
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#000000'
                },
                head: {
                    color: '#000',
                    fontSize: 12,
                    lineHeight: '15.6px',
                    fontWeight: 400,
                    paddingTop: '24px !important',
                    paddingBottom: '8px !important',
                    borderBottom: '1px solid #A1A1A1',
                    '& .MuiSvgIcon-root': {
                        fontSize: 12
                    },
                    '& .table-splitter': {
                        height: 12,
                        margin: '25px 0 10px',

                        '&:hover': {
                            height: 18,
                            margin: '23px 0 8px'
                        }
                    }
                },
                body: {
                    fontSize: 13,
                    borderBottom: '1px solid #E1E1E1',
                    '&.borderless': {
                        borderBottom: 'none'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 6
                }
            }
        }
    },

    typography: { fontFamily: 'Onest' },

    props: {
        MuiButtonBase: { disableRipple: true, disableFocusRipple: true, disableTouchRipple: true }
    }
};

export default defaults;
