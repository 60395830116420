import React, { FunctionComponent, useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import {
    Box,
    List,
    ListItemIcon,
    ListItemText,
    Collapse,
    Avatar,
    Divider,
    Tooltip
} from '@mui/material';
import { MenuItemProps } from '@mui/material/MenuItem';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { ControlPropsType, MenuItemType } from 'forms/interfaces';
import MainFormStore from 'store/mainFormStore';

import SVGIcon from 'forms/controls/SVGIcon';

import MenuItem from '../MenuItem';

const ExpandArrow = (open: boolean) => (open ? <ExpandLess /> : <ExpandMore />);

const stringAvatar = (name: string) => ({
    sx: {
        bgcolor: 'transparent',
        color: '#353638FF',
        width: 24,
        height: 24,
        fontSize: '1.15rem'
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1]?.[0] ?? ''}`
});

interface PropsType extends ControlPropsType, Omit<MenuItemProps, 'button'> {
    descr: MenuItemType;
    padding?: number;
}

const SideBarListItem: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, padding = 4 }) => {
        const { open, selections } = MainFormStore;

        const [openGroup, setOpenGroup] = useState(selections.includes(descr.name));

        useEffect(() => {
            setOpenGroup(selections.includes(descr.name));
        }, [selections]);

        const handleOpen = () => {
            if (descr?.items?.length) {
                setOpenGroup(!openGroup);
            }
        };

        return (
            <>
                <Box onClick={handleOpen}>
                    <MenuItem descr={descr} propContainer={propContainer} sx={{ pl: padding }}>
                        {descr.icon ? (
                            <Tooltip
                                title={descr.caption}
                                arrow
                                placement="right"
                                disableHoverListener={open}
                            >
                                <ListItemIcon style={{ color: 'inherit' }}>
                                    <SVGIcon
                                        descr={descr}
                                        propContainer={propContainer}
                                        fontSize="small"
                                    />
                                </ListItemIcon>
                            </Tooltip>
                        ) : null}
                        {!descr.icon && !open ? (
                            <Tooltip
                                title={descr.caption}
                                arrow
                                placement="right"
                                disableHoverListener={open}
                            >
                                <Avatar
                                    variant="square"
                                    {...stringAvatar(descr.caption.toUpperCase())}
                                />
                            </Tooltip>
                        ) : null}
                        <ListItemText
                            primary={descr.caption}
                            sx={{
                                '& span': { textOverflow: 'ellipsis', overflow: 'hidden' },
                                opacity: open ? 1 : 0
                            }}
                        />
                        <Box
                            sx={{
                                p: 2,
                                display: 'inherit',
                                visibility: descr?.items?.length ? 'visible' : 'hidden'
                            }}
                        >
                            {ExpandArrow(openGroup)}
                        </Box>
                    </MenuItem>
                </Box>
                {descr?.items?.length ? (
                    <Collapse in={openGroup} timeout="auto" unmountOnExit>
                        <Divider />
                        <List>
                            {descr.items.map(item => (
                                <SideBarListItem
                                    key={item.guid}
                                    descr={item}
                                    propContainer={propContainer}
                                    padding={!open ? padding : padding + 4}
                                />
                            ))}
                        </List>
                        <Divider />
                    </Collapse>
                ) : null}
            </>
        );
    }
);

export default SideBarListItem;
