import { FormEventType } from 'forms/interfaces';

import PropContainer from 'dataObj/PropContainer';
import executeScript from 'scripts/script-client';

/**
 * Класс события
 */
export default class Event {
    private descr;
    private sript;
    private propContainer;
    private params;

    public guid;

    /**
     *
     * @param descr - Описатель события
     * @param propContainer - целевой propContainer
     */
    constructor(descr: FormEventType, propContainer: PropContainer) {
        this.guid = descr.guid;
        this.descr = descr;
        this.sript = descr.script;
        this.params = descr.params;
        this.propContainer = propContainer;
    }

    checkFieldName = (fieldName: string) =>
        !this.descr?.fieldName || this.descr?.fieldName === fieldName;

    execute = (event: { [key: string]: any } = {}) =>
        executeScript(this.propContainer, this.sript, this.params, null, null, {
            ...{
                descr: this.descr,
                params: this.propContainer.dataStock.getParams(this.descr.params)
            },
            ...event
        }).catch(err => console.error(err.message));
}
