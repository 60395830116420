import React, { LegacyRef, useCallback } from 'react';

const { PUBLIC_URL } = process.env;

interface PropsType {
    content: string;
    minWidth?: number;
    styleSheet?: string;
}

const Iframe = (props: PropsType) => {
    const { styleSheet, minWidth, content } = props;

    const defaultStyle =
        '<style type="text/css">html { height: min-content; }</style><style type="text/css">body { height: min-content; }</style>';

    const getWidth = useCallback(
        (width?: number) => {
            if (minWidth && width && minWidth < width) return `${width}px`;

            return '100%';
        },
        [minWidth]
    );

    const writeHTML: LegacyRef<HTMLDivElement> = (frame: HTMLIFrameElement | null) => {
        if (!frame) {
            return;
        }
        const doc = frame.contentDocument;

        const newHtml = document.createElement('html');
        const body = document.createElement('body');
        const head = document.createElement('head');

        const cssLink = document.createElement('link');
        cssLink.href = `${PUBLIC_URL || ''}/iframe.css`;
        cssLink.rel = 'stylesheet';
        cssLink.type = 'text/css';
        head.appendChild(cssLink);
        newHtml.appendChild(head);

        body.innerHTML = `${content} ${defaultStyle} ${
            styleSheet ? `<style>${styleSheet}</style>` : ''
        }`;
        newHtml.appendChild(body);

        doc?.open();
        doc?.write(newHtml.outerHTML);
        doc?.close();

        frame.style.width = getWidth(frame.contentWindow?.document.body.scrollWidth);
        frame.style.height = frame.contentWindow?.document.body.scrollHeight
            ? `${frame.contentWindow.document.body.scrollHeight + 24}px`
            : '100%';
    };

    return (
        <iframe title="iframe" src="about:blank" scrolling="no" frameBorder="0" ref={writeHTML} />
    );
};

export default Iframe;
