import { FunctionComponent, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import MainMenuStore from 'store/mainMenuStore';

interface SplitterType {
    active?: boolean;
    setWidth: (width: number) => void;
}

const Splitter: FunctionComponent<SplitterType> = ({ active = false, setWidth }) => {
    const { open, defaultWidth, setOpen, setSideBarWidth } = MainMenuStore;

    const [resize, setResize] = useState(false);

    const mouseDown = (event: any) => {
        setResize(true);
    };

    const mouseMove = (event: MouseEvent) => {
        if (event.pageX <= 130) {
            setOpen(false);
            setWidth(defaultWidth);
        } else {
            setWidth(event.pageX);
            setSideBarWidth(event.pageX);
        }
    };

    const removeListeners = () => {
        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', removeListeners);
    };

    const mouseUp = () => {
        removeListeners();
    };

    useEffect(() => {
        if (resize) {
            setResize(false);
            window.addEventListener('mousemove', mouseMove);
            window.addEventListener('mouseup', mouseUp);
        }
    }, [resize]);

    return (
        <Box
            sx={{
                height: '100%',
                display: 'block',
                position: 'absolute',
                cursor: open ? 'col-resize' : 'initial',
                width: '7px',
                right: 0,
                top: 0,
                zIndex: 1,
                borderRight: '1px solid transparent',
                margin: '12px 0',
                borderColor: '#e0e0e0',
                KhtmlUserSelect: 'none',
                WebkitUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',

                '&:hover': {
                    borderRight: open ? '2px solid' : '1px solid transparent',
                    borderColor: open ? '#ccc' : '#e0e0e0'
                },

                '&.active': {
                    borderColor: '#517ea5'
                }
            }}
            onMouseDown={mouseDown}
            className={active ? 'active' : 'idle'}
        />
    );
};

export default Splitter;
