import { action, get, makeAutoObservable, observable, set } from 'mobx';

import { ReportType, SimpleObject } from 'forms/interfaces';
import PropContainer from 'dataObj/PropContainer';

import { downloadBlobFile } from 'utils/index';

import NotificationStore from 'store/notificationStore';
import ConfigurationStore from 'store/configurationStore';

import Report from './Report';

class ReportStore {
    @observable reports: { [name: string]: Report } = {};

    constructor() {
        makeAutoObservable(this);
    }

    findReport(reportName: string) {
        return get(this.reports, reportName) as Report;
    }

    @action setFormReports = (formDescr: SimpleObject, propContainer: PropContainer) => {
        formDescr.reports?.forEach((report: ReportType) => {
            this.addReport(report, propContainer);
        });
    };

    @action addReport = (report: ReportType, propContainer: PropContainer) => {
        set(this.reports, { [report.name]: new Report(report, propContainer) });
    };

    @action runReport = async (
        reportName: string,
        parObj?: SimpleObject | null,
        filters?: SimpleObject
    ) => {
        const report = get(this.reports, reportName) as Report;

        if (report) {
            return report.run(parObj, filters);
        }

        return false;
    };

    @action getReportResultList = (reportName: string) => {
        const report = get(this.reports, reportName) as Report;

        if (report?.results) return report.results;

        return [];
    };

    @action getReportResult = (reportName: string, index = 0) => {
        const report = get(this.reports, reportName) as Report;

        if (report?.results) return report.results[index];

        return false;
    };

    @action saveReport = (reportName: string, index = 0) => {
        const report = this.getReportResult(reportName, index);

        if (report) {
            NotificationStore.showConfirmation(
                `${ConfigurationStore.content.application.reports.saveReport as string} «${
                    report.filename
                }»?`,
                (confirm: boolean) => {
                    confirm && downloadBlobFile(report.file, report.filename);
                }
            );
        } else NotificationStore.showAlert(ConfigurationStore.content.application.reports.notExist);
    };
}

const ReportGlobalStore = new ReportStore();
export default ReportGlobalStore;
