import { FunctionComponent } from 'react';

import { Button, DialogActions } from '@mui/material';

import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';

import ConfigurationStore from 'store/configurationStore';

const CustomActionBar: FunctionComponent<PickersActionBarProps> = ({
    onCancel,
    onSetToday,
    className
}) => {
    const { content } = ConfigurationStore;

    return (
        <DialogActions className={className}>
            <Button id="picker-actions-today" onClick={() => onSetToday()}>
                {content.components.inputs.date.today}
            </Button>
            <Button id="picker-actions-cancel" onClick={() => onCancel()}>
                {content.components.inputs.date.cancel}
            </Button>
        </DialogActions>
    );
};

export default CustomActionBar;
