import { FunctionComponent, MouseEvent } from 'react';

import { Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import DBLookup from 'forms/controls/DBLookup';
import { DBEditType, DBLookupType, DBMultipleEditType, GridDataRow } from 'forms/interfaces';
import DBEdit from 'forms/controls/DBEdit';
import PropContainer from 'dataObj/PropContainer';

interface PropsType {
    index: number;
    descr: DBMultipleEditType;
    dataRow: GridDataRow;
    propContainer: PropContainer;
    addField?: (event: MouseEvent) => unknown;
    removeField?: (event: MouseEvent, index: number) => unknown;
    disabled?: boolean;
}

const MultipleEditWrapper: FunctionComponent<PropsType> = ({
    index,
    descr,
    dataRow,
    propContainer,
    addField,
    removeField,
    disabled
}) => {
    const handleAdd = (event: MouseEvent) => {
        addField && addField(event);
    };

    const handleRemove = (event: MouseEvent) => {
        removeField && removeField(event, index);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                pl: !disabled ? 2 : 0,
                pr: 0,
                width: '100%'
            }}
        >
            {!disabled && (
                <Box color="inherit" sx={{ mr: 1 }} fontSize="medium">
                    <IconButton size="small" disabled={!addField}>
                        <AddIcon fontSize="inherit" onClick={handleAdd} />
                    </IconButton>
                </Box>
            )}

            {descr?.ctrlType === 'dbLookup' ? (
                <DBLookup
                    descr={descr as unknown as DBLookupType}
                    dataRow={dataRow}
                    propContainer={propContainer}
                    index={index}
                />
            ) : (
                <DBEdit
                    descr={descr as DBEditType}
                    dataRow={dataRow}
                    propContainer={propContainer}
                    index={index}
                />
            )}

            {removeField && !disabled && (
                <Box color="inherit" sx={{ mr: 4 }} fontSize="medium">
                    <IconButton size="small" onClick={handleRemove}>
                        <RemoveIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default MultipleEditWrapper;
