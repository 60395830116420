import { useState, useEffect, FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';

import { TreeView, TreeItem } from '@mui/x-tree-view';
import { Typography, Toolbar, IconButton, Grid, Paper, Menu, MenuItem } from '@mui/material';
import { ExpandMore, ChevronRight, MoreVert } from '@mui/icons-material';

import Alert from '@mui/material/Alert';

import styles from 'forms/form.module.scss';

import CustomForm from 'forms/CustomForm';
import Loading from 'components/utils/Loading/Loading';

import { jsonFetch } from 'utils/index';

import ConfigurationStore from 'store/configurationStore';

interface ErrorMessagePropsType {
    type?: 'success' | 'info' | 'warning' | 'error';
    message?: string | null;
}

export const ErrorMessage: FunctionComponent<ErrorMessagePropsType> = ({ type, message }) =>
    message ? <Alert severity={type || 'error'}>{message}</Alert> : null;

interface ExtRefPropsType {
    id?: string | null;
    guid?: string; // guid формы
}

export const ExtRef: FunctionComponent<ExtRefPropsType> = ({ id, guid }) => {
    const params: any = useParams();
    const [formGuid, setFormGuid] = useState<string | null>(guid ?? null);
    const [alertMessage, setAlertMessage] = useState<string | null>();

    const idref = id || params.extRefId;

    useEffect(() => {
        (async () => {
            if (!idref) return;
            try {
                setAlertMessage(null);
                const data: any = await jsonFetch(`extRefs/${idref as string}/list`);
                setFormGuid(null);
                setFormGuid(data.formGuid);
            } catch (err: any) {
                setAlertMessage(err.message);
            }
        })().catch(err => console.error(err.message));
    }, [idref, setAlertMessage]);

    return (
        <>
            <ErrorMessage message={alertMessage} />
            {formGuid ? <CustomForm key={formGuid} formGuid={formGuid} /> : null}
        </>
    );
};

const ExtRefsPage: FunctionComponent = observer(() => {
    const { resource } = ConfigurationStore.content;
    const isAdmin = true;

    const [refList, setRefList] = useState<any>(null);
    const [curNode, setCurNode] = useState(null);
    const [loading, setLoading] = useState(false);

    const paperStyle = {
        position: 'relative',
        padding: '24px',
        height: '100%',
        overflow: 'hidden'
    };
    const [alertMessage, setAlertMessage] = useState(null);

    useEffect(() => {
        (async () => {
            if (refList) return;
            setLoading(true);
            try {
                const data = await jsonFetch('extRefs');
                setRefList(data as any);
            } catch (err: any) {
                setAlertMessage(err.message);
            } finally {
                setLoading(false);
            }
        })().catch(err => console.error(err.message));
    }, [setRefList, refList]);

    const { path } = useRouteMatch();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const refreshList = () => {
        handleClose();
        setCurNode(null);
        setRefList(null);
    };

    const importView = async () => {
        const tmp = curNode;
        handleClose();
        try {
            await jsonFetch(`extRefs/${curNode ?? ''}/edit`);
            setCurNode(null);
            setCurNode(tmp);
        } catch (err: any) {
            setAlertMessage(err.message);
        }
    };

    const onNodeSelect = (e: any, id: string | string[]) => {
        setAlertMessage(null);

        if (typeof id !== 'string') {
            return;
        }
        const [GOUP_ID, EF_ID] = id.split('.');
        const groupId = parseInt(GOUP_ID, 10);
        const refId = parseInt(EF_ID, 10);

        const group = refList?.find((g: any) => g.id === groupId);
        setCurNode(group ? group.refs.find((r: any) => r.id === refId)?.tableName : null);
    };

    const RefTree = (items: Array<any>) => (
        <div style={{ height: 'calc(100% - 50px)', overflow: 'auto' }}>
            <TreeView
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                onNodeSelect={onNodeSelect}
            >
                {(items || []).map(g => (
                    <TreeItem
                        key={g.id.toString()}
                        nodeId={typeof g.id === 'number' ? g.id.toString() : g.id}
                        label={g.name}
                    >
                        {(g.refs || []).map((item: { id: string; name: string }) => (
                            <TreeItem
                                key={`${g.id as string}.${item.id}`}
                                nodeId={`${g.id as string}.${item.id}`}
                                label={item.name}
                            />
                        ))}
                    </TreeItem>
                ))}
            </TreeView>
        </div>
    );

    return (
        <Switch>
            <Route exact path={`${path}/:extRefId`}>
                <ExtRef />
            </Route>
            <Route exact path={path}>
                <Grid container style={{ height: '80vh' }} spacing={3}>
                    <Grid item xs={12}>
                        <ErrorMessage message={alertMessage} />
                    </Grid>
                    <Grid item xs={5} style={{ height: '100%' }} className={styles.clearfix}>
                        <Paper sx={paperStyle} elevation={3}>
                            <Toolbar className={styles.toolbar} style={{ minHeight: 50 }}>
                                <Typography variant="h6" className={styles.title} component="div">
                                    Оглавление
                                </Typography>
                                {isAdmin && (
                                    <div>
                                        <IconButton
                                            size="large"
                                            aria-label="refspage-menu"
                                            aria-haspopup="true"
                                            onClick={handleMenu}
                                            color="primary"
                                        >
                                            <MoreVert />
                                        </IconButton>
                                        <Menu
                                            id="refspage-menu"
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={refreshList}>
                                                {resource.refreshList}
                                            </MenuItem>
                                            <MenuItem
                                                disabled={curNode === null}
                                                onClick={importView}
                                            >
                                                {resource.importEditor}
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                )}
                            </Toolbar>
                            {!loading ? RefTree(refList) : <Loading message={resource.loading} />}
                        </Paper>
                    </Grid>
                    <Grid item xs={7} style={{ height: '100%' }} className={styles.clearfix}>
                        <Paper sx={paperStyle} elevation={3} className={styles.clearfix}>
                            <ExtRef id={curNode} />
                        </Paper>
                    </Grid>
                </Grid>
            </Route>
        </Switch>
    );
});

export default ExtRefsPage;
