import React, { useEffect, useCallback, useState, FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Box, Typography, TypographyVariant } from '@mui/material';

import WithTooltip from 'components/utils/withTooltip';

import useCtrlData from 'hooks/ctrlData';
import useCtrlProps from 'hooks/ctrlProps';

import { ControlDataRowType, DBLabelType } from 'forms/interfaces';
import { getLocalizedString, dateToString } from 'utils/index';

interface BDLabelProps extends ControlDataRowType {
    descr: DBLabelType;
}

const DBLabel: FunctionComponent<BDLabelProps> = observer(({ descr, propContainer, dataRow }) => {
    const { guid, variant, tooltip } = descr;

    const { dataVal, dataType, internationalField, fieldEnabled } = useCtrlData(
        propContainer,
        descr.datasetName,
        descr.fieldName,
        dataRow
    );
    const { ctrlEnabled, ctrlVisible, ctrlColorToken, ctrlCaption, ctrlTooltip } = useCtrlProps(
        propContainer,
        guid,
        dataRow
    );

    const enabled = fieldEnabled && (ctrlEnabled === null ? true : ctrlEnabled);

    const isDate = dataType === 'KRN_DATE' || dataType === 'KRN_DATETIME';
    const dateFormat = dataType === 'KRN_DATE' ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm:ss';

    const addCaption = (caption: string, value: string) => {
        if (caption.includes('%s')) return caption.replace('%s', value);
        return caption + value;
    };

    const renderLabel = (value: string) => {
        if (variant === 'pure-html')
            return (
                <div
                    style={{ color: ctrlColorToken ? `${ctrlColorToken}.main` : 'inherit' }}
                    dangerouslySetInnerHTML={{ __html: value }}
                />
            );

        if (variant)
            return (
                <Typography
                    id={guid}
                    variant={variant as TypographyVariant}
                    sx={{ color: ctrlColorToken ? `${ctrlColorToken}.main` : 'inherit' }}
                >
                    {value}
                </Typography>
            );
        return (
            <span
                id={descr.guid}
                style={{ color: ctrlColorToken ? `${ctrlColorToken}.main` : 'inherit' }}
            >
                {value}
            </span>
        );
    };

    const valueToText = useCallback(
        fldVal => {
            let val = '';
            if (fldVal === undefined || fldVal === null) val = '';
            else if (typeof fldVal === 'number') val = fldVal.toString();
            else if (typeof fldVal === 'string') val = fldVal;

            if (internationalField) val = getLocalizedString(fldVal ?? '');
            if (isDate) val = dateToString(fldVal ?? '', dateFormat);
            return addCaption(ctrlCaption || '', val);
        },
        [dataVal, ctrlCaption]
    );

    const [val, setVal] = useState(() => valueToText(dataVal));

    useEffect(() => {
        setVal(valueToText(dataVal));
    }, [dataVal, valueToText]);

    return (
        <Box
            sx={{
                width: '100%',
                p: 2,
                boxSizing: 'border-box',
                visibility: ctrlVisible ? 'inherit' : 'hidden',
                color: enabled ? 'inherit' : '#888'
            }}
        >
            <WithTooltip title={ctrlTooltip || tooltip}>{renderLabel(val || '')}</WithTooltip>
        </Box>
    );
});

export default DBLabel;
