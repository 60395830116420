import { FunctionComponent, useCallback, useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';

import { TreeView } from '@mui/x-tree-view';
import {
    FolderOpen,
    Report,
    Clear,
    CheckBox,
    ArrowDropDown,
    ArrowRight,
    SmartDisplay,
    CheckBoxOutlineBlank,
    FiberManualRecordOutlined
} from '@mui/icons-material';

import Loading from 'components/utils/Loading/Loading';
import SVGIcon from 'forms/controls/SVGIcon';

import TreeItem from './TreeItem';

import { StateType, TaskTreePropsType, TTNodeType } from './interface';

const TaskTree: FunctionComponent<TaskTreePropsType> = observer(
    ({ value, dataset, parentTask, tree, iconSet, propContainer }) => {
        const [selected, setSelected] = useState<string>(`${(value as string | number) || ''}`);

        const nodeSelect = (nodeId: string, recId?: number) => {
            setSelected(nodeId);
            dataset?.findById(recId || nodeId, true);
        };

        useEffect(() => {
            `${(value as string | number) || ''}` !== selected &&
                setSelected(`${(value as string | number) || ''}`);
        }, [value]);

        const calcIcon = useCallback((state: StateType): any => {
            if (iconSet && state) {
                return () => (
                    <SVGIcon
                        descr={{ iconSet, icon: state }}
                        propContainer={propContainer}
                        sx={{ mr: 1, fontSize: 'medium' }}
                    />
                );
            }

            switch (state) {
                case 'A':
                    return SmartDisplay;

                case 'P':
                    return FolderOpen;

                case 'C':
                    return Clear;

                case 'F':
                    return CheckBox;

                case 'I':
                    return Report;

                case 'W':
                    return FiberManualRecordOutlined;

                case 'R':
                    return CheckBoxOutlineBlank;

                default:
                    return CheckBoxOutlineBlank;
            }
        }, []);

        const defaultExpanded = useMemo(() => {
            const opened = ['0', '1'];

            const checkChild = (node: TTNodeType, arr: string[]) => {
                if (node.child_nodes) {
                    arr.push(String(node.tt_node_id));

                    node.child_nodes.forEach(childNode => checkChild(childNode, arr));
                }
            };

            tree.forEach(node => checkChild(node, opened));

            return opened;
        }, [tree, tree?.length]);

        const Tree = useMemo(() => {
            const renderTree = (nodeList: TTNodeType[], chldIdx = 0) =>
                nodeList
                    .slice()
                    .sort((n1, n2) => {
                        if (n1?.exec_order > n2?.exec_order) return 1;

                        return n1?.exec_order < n2?.exec_order ? -1 : 0;
                    })
                    .map((node, index) => {
                        const nodeId = String(node.tt_node_id);

                        const handleNodeSelect = () => {
                            nodeSelect && nodeSelect(nodeId, node.tt_node_id);
                        };

                        if (index === 0 && chldIdx === 0 && !selected && selected !== nodeId) {
                            handleNodeSelect();
                            chldIdx++;
                        }

                        return (
                            <TreeItem
                                key={index}
                                node={node}
                                nodeId={nodeId}
                                onClick={handleNodeSelect}
                                calcIcon={calcIcon}
                            >
                                {node?.child_nodes ? renderTree(node?.child_nodes, chldIdx) : null}
                            </TreeItem>
                        );
                    });

            return renderTree(tree);
        }, [tree?.length]);

        return tree?.length ? (
            <TreeView
                selected={selected}
                aria-label="taskTree"
                defaultExpanded={defaultExpanded}
                defaultCollapseIcon={<ArrowDropDown />}
                defaultExpandIcon={<ArrowRight />}
                defaultEndIcon={<div style={{ width: 24 }} />}
                sx={{ flexGrow: 1, padding: 2 }}
            >
                {parentTask ? (
                    <TreeItem
                        parent
                        nodeId="1"
                        node={parentTask}
                        onClick={() =>
                            nodeSelect &&
                            nodeSelect(String(parentTask.tt_node_id), parentTask.tt_node_id)
                        }
                        calcIcon={calcIcon}
                    >
                        {Tree}
                    </TreeItem>
                ) : (
                    Tree
                )}
            </TreeView>
        ) : (
            <Loading type="empty" message="Нет данных для построения" />
        );
    }
);

export default TaskTree;
