import { forwardRef, useMemo } from 'react';
import { observer } from 'mobx-react';

import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDateTimePicker as DateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopTimePicker as TimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import ConfigurationStore from 'store/configurationStore';

import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';

import CustomActionBar from './CustomActionBar';

const localeMap = {
    eng: enLocale,
    fra: frLocale,
    esp: esLocale,
    rus: ruLocale
};

interface DateTimeProps {
    type?: 'KRN_DATE' | 'KRN_DATETIME' | 'KRN_TIME';
    withTime?: boolean;
    label?: string;
    value: Date | null;
    error?: string | null;
    required?: boolean;
    disabled?: boolean;
    handleChange: (event: any) => unknown;
    handleError?: (event: any) => unknown;
    sx?: any;
}

const DateTimePickerContainer = forwardRef<HTMLLIElement | null, DateTimeProps>(
    (
        { type, withTime, label, value, error, required, disabled, handleChange, handleError, sx },
        ref
    ) => {
        const { content } = ConfigurationStore;

        const inputFormat = useMemo(() => {
            switch (type) {
                case 'KRN_DATETIME':
                    return withTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy';

                case 'KRN_TIME':
                    return 'HH:mm';

                case 'KRN_DATE':
                default:
                    return 'dd.MM.yyyy';
            }
        }, [type]);

        const props = {
            label: label || '',
            value: value || null,
            disabled,
            onChange: handleChange,
            onError: handleError,
            closeOnSelect: true,
            inputFormat,
            size: 'small',
            error: (required && !value) || !!error,
            helperText: error ? content.controls.date.incorrect : '',
            sx: (theme: any) => ({
                ...{
                    '& .Mui-disabled': {
                        backgroundColor: theme.palette.mode === 'light' ? '#f1f1f1' : '#1A2027',
                        '& .MuiInputBase-input': {
                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.6)'
                        }
                    },
                    '& .MuiInputLabel-root': {
                        backgroundColor: 'transparent'
                    },
                    '& .MuiPickersArrowSwitcher': { width: 4 }
                },
                ...(sx || {})
            }),
            slots: {
                actionBar: CustomActionBar
            },
            slotProps: {
                textField: { size: 'small', fullWidth: true }
            } as any
        };

        const getPicker = () => {
            switch (type) {
                case 'KRN_DATETIME':
                    return withTime ? <DateTimePicker {...props} /> : <DatePicker {...props} />;

                case 'KRN_TIME':
                    return <TimePicker {...props} />;

                case 'KRN_DATE':
                default:
                    return <DatePicker {...props} />;
            }
        };

        return (
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={localeMap[ConfigurationStore?.locale]}
            >
                {getPicker()}
            </LocalizationProvider>
        );
    }
);

export default observer(DateTimePickerContainer);
