/// FRONT-END MODULE!!!
import { v4 as uuidv4 } from 'uuid';

import { ComponentType, Descriptor, ResourceInfo } from 'forms/interfaces';
import { jsonFetch } from 'utils/index';

const defaultFunc = {
    newGuid: () => uuidv4(),
    nowDate: () => new Date().toISOString(),
    newName: (descr: ComponentType) =>
        `${descr.type}${Math.floor(Math.random() * 1000000).toString()}`,
    newCaption: (descr: ComponentType) => descr.name,
    setTrue: () => true,
    setFalse: () => false
} as { [key: string]: any };

const defaultVal = {
    string: '',
    integer: 0,
    boolean: false,
    array: [],
    resource: { guid: '', name: 'unknown', type: 'unknown' }
} as { [key: string]: any };

// Тип для текста подсказок дескриптора
type InfoType = { short: string; long: string };
type PropType = { [key: string]: string | string[] | InfoType };
type StructInfoType = { [key: string]: PropType };

class ResourceValidator {
    private structDescriptor?: Descriptor;

    private structInfo?: StructInfoType;

    public ctrlTypes: string[] = [];

    async getStructDescriptor(): Promise<Descriptor | undefined> {
        if (!this.structDescriptor) {
            this.structDescriptor = await jsonFetch('structdescr');
            if (this.structDescriptor) {
                this.ctrlTypes = Object.keys(this.structDescriptor as object);
            }
        }
        return this.structDescriptor;
    }

    async getStructInfo(): Promise<StructInfoType | undefined> {
        if (!this.structInfo) {
            this.structInfo = {}; // быстрое обращение может второй вызов сгенерировать, защита
            this.structInfo = await jsonFetch('structinfo');
        }
        return this.structInfo;
    }

    /**
     * Получить описание
     * @param type Тип обьекта
     * @param prop Свойство
     */
    findStructInfo(type: string, prop: string): string | undefined {
        if (!this.structInfo || !this.structInfo[type]) {
            return;
        }
        const info = this.structInfo[type][prop];
        if (!info) {
            return;
        }
        if (typeof info === 'string') {
            return info;
        }
        if (Array.isArray(info)) {
            return info.join('\n');
        }
        if ('short' in info) {
            return info.short;
        }
    }

    getTypeDescriptor(type: string) {
        return this.structDescriptor ? this.structDescriptor[type] : undefined;
    }

    getStructDescriptorData() {
        return this.structDescriptor;
    }

    validate<T = ResourceInfo>(resource: Record<string, any>): T {
        const resourceType = (resource.type || '') as string;
        const result: Record<string, any> = {
            guid: '00000000-0000-0000-0000-000000000000',
            type: 'unknown',
            name: `Unknown Resource Type ${resourceType}`
        };

        if (!resourceType) {
            return result as T;
        }

        const fields = this.getTypeDescriptor(resourceType)?.fields;
        if (!fields) {
            return result as T;
        }

        for (const fld in fields) {
            const rule = fields[fld];

            result[fld] = resource[fld];

            if (result[fld] === undefined && rule.defaultFunc) {
                const f = defaultFunc[rule.defaultFunc];
                if (f) {
                    result[fld] = f(resource);
                }
            }

            if (result[fld] === undefined) {
                result[fld] = rule.defVal || defaultVal[rule.type] || '';
            }

            const value = result[fld];
            switch (rule.type) {
                case 'array':
                    result[fld] = Array.isArray(value)
                        ? value.map((c: ResourceInfo) => this.validate(c))
                        : [];
                    break;
                case 'string':
                    result[fld] = typeof value === 'string' ? String(value) : '';
                    break;
                case 'integer':
                    result[fld] = typeof value === 'number' ? +value : 0;
                    break;
                case 'boolean':
                    result[fld] = Boolean(value);
                    break;
                default:
                    if (this.getTypeDescriptor(rule.type) && value) {
                        result[fld] = typeof value === 'object' ? this.validate(value) : null; // null?
                    }
            }
        }

        return result as T;
    }
}

export default new ResourceValidator();
