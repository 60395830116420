import { FunctionComponent, useEffect, useMemo, useState, useRef } from 'react';

import moment from 'moment';

import SchedulerRow from './SchedulerRow';

import { ColumnType, EventDataType, SchedulerLayoutType } from '../../interfaces';
import Event from './Event';

import {
    getCurrentWeek,
    makeHour,
    addStartDateCells,
    addEndDateCells,
    addEmptyCells,
    makeEventList,
    allocateElementsEqually,
    getEventPosition,
    getSelectedArea
} from './utils';

const SchedulerLayout: FunctionComponent<SchedulerLayoutType> = ({
    type,
    model,
    dayStartFrom,
    dayStopTo,
    subject,
    data,
    currentDate,
    eventClickedHandler,
    newEventHandler,
    setSubject,
    controls = true,
    fitSlots = false,
    eventItemTemplateResolver
}) => {
    const bodyRef = useRef<HTMLDivElement>(null);

    const [events, setEvents] = useState<any[]>([]);
    const [allocatedEvents, setAllocatedEvents] = useState<any[]>([]);
    const [renderedEvents, setRenderedEvents] = useState<HTMLElement[]>([]);

    const [selected, setSelected] = useState<{
        start: HTMLElement | null;
        end: HTMLElement | null;
    }>({ start: null, end: null });

    const duration = useMemo(() => {
        if (type === 'day') {
            const minDuration = Math.min(
                ...model.subjects.filter(subj => !!subj.duration).map(subj => subj.duration || 0)
            );

            return minDuration < model.duration ? minDuration : model.duration;
        }

        return subject?.duration || model.duration;
    }, [type, subject]);

    const columns = useMemo(() => {
        const defaultColumns = [
            { id: 'time', key: 'time', type: 'time', title: 'Время' },
            { id: 'duration', key: 'duration', type: 'duration', title: 'Длительность' }
        ];

        if (type === 'day' && model.subjects.length) {
            return [
                ...defaultColumns,
                ...model.subjects.map(subj => ({
                    id: subj.id,
                    key: `${subj.id}`,
                    date: currentDate,
                    type: 'subject',
                    title: subj.name,
                    duration: subj.duration || model.duration
                }))
            ];
        }

        const dateColumns = getCurrentWeek(currentDate).map(date => ({
            id: subject.id,
            key: date,
            date: new Date(date),
            type: 'day',
            title: moment(date).format('DD.MM.YYYY')
        }));

        return [...defaultColumns, ...dateColumns];
    }, [type, model, currentDate, subject.id]);

    const generateHeaderRow = () => {
        const row: { [key: string]: string } = {};

        columns.forEach(col => {
            row[col.key] = col.title;
        });

        return row;
    };

    const rows = useMemo(() => {
        const { start, end } = model;

        const tmpRows: {
            id: string;
            time: string;
            hour: string;
            minutes: string;
            duration: number;
            subject: string;
        }[] = [];

        const dayStart = makeHour(dayStartFrom);
        const dayEnd = makeHour(dayStopTo);

        const modelStartTime = makeHour(start);
        const modelEndTime = makeHour(end);

        addEmptyCells(model, duration, tmpRows, data, fitSlots);

        addStartDateCells(dayStart, modelStartTime, duration, tmpRows);
        addEndDateCells(dayEnd, modelEndTime, duration, tmpRows);

        return tmpRows;
    }, [model?.id, duration]);

    const handleRendered = (event: HTMLElement, rendered: boolean) => {
        if (rendered) {
            setRenderedEvents(prevState => {
                if (!prevState.includes(event)) {
                    return [...prevState, event];
                }

                return prevState;
            });
        } else {
            setRenderedEvents(prevState => {
                const tmpArr = [...prevState];
                tmpArr.splice(prevState.indexOf(event), 1);

                return tmpArr;
            });
        }
    };

    const allocateEvents = () => {
        if (events.length && renderedEvents?.length === events.length) {
            const locatedEvents = events.map((event: EventDataType) => {
                const renderedEvent = Array.from(renderedEvents).find(
                    el => el.id === `event-${type}-${event.key}`
                );
                const slotId = `${event.ownerId}-${event?.dateStart.format(
                    'YYYY-MM-DD'
                )}-${event?.dateStart.format('HH:mm')}`;

                const getBottomBorder = () => {
                    const slots: HTMLElement[] = Array.from(
                        document.getElementsByClassName('scheduler-cell')
                    ) as HTMLElement[];

                    if (slots.length > 0) {
                        return (
                            slots[slots.length - 1].offsetTop + slots[slots.length - 1].offsetHeight
                        );
                    }

                    return 0;
                };

                const bottomBorder = getBottomBorder();

                const position = getEventPosition(
                    slotId,
                    bottomBorder,
                    event.ownerId,
                    event.dateStart.format('HH:mm'),
                    event.duration,
                    fitSlots ? 1 : event.duration / duration,
                    event.dateStart
                );

                if (position && renderedEvent) {
                    renderedEvent.style.top = `${position.top}px`;
                    renderedEvent.style.height = `${position.height}px`;
                    renderedEvent.style.left = `${position.left}px`;
                    renderedEvent.style.width = `${position.width}px`;
                    renderedEvent.className = `${renderedEvent.className}${
                        position.continuing ? ' continuing' : ''
                    }${position.prolongation ? ' prolongation' : ''}`;
                    renderedEvent.setAttribute('is-start', String(!position.prolongation));
                    renderedEvent.setAttribute('is-end', String(!position.continuing));
                }

                return { ...event, isStart: !position?.prolongation, isEnd: !position?.continuing };
            });

            setAllocatedEvents(locatedEvents);

            columns.forEach((col: ColumnType) => {
                const columnId = `${col.id}-${moment(col.date).format('YYYY-MM-DD')}`;
                const column = document.querySelectorAll(`[id^="${columnId}"]`)[0] as HTMLElement;

                if (column) {
                    const columnEvents = Array.from(
                        document.querySelectorAll(`[column-index^="${columnId}"]`)
                    ).sort((a: any, b: any) => a.offsetTop - b.offsetTop);

                    allocateElementsEqually(columnEvents as HTMLElement[], column);
                }
            });
        }
    };

    useEffect(() => {
        const eventsList = makeEventList(data, type, model, subject, currentDate);

        setEvents(eventsList);
        setAllocatedEvents(eventsList);
    }, [type, model, subject, currentDate, data, data?.length]);

    useEffect(() => {
        allocateEvents();
    }, [events, renderedEvents, events.length]);

    const handleWindowSizeChange = () => {
        allocateEvents();
    };

    useEffect(() => {
        if (renderedEvents) window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, [renderedEvents]);

    const handleMouseDown = (e: MouseEvent) => {
        if (
            (e.target as HTMLElement)?.classList.contains('scheduler-cell') &&
            !(e.target as HTMLElement)?.classList.contains('side-cell')
        ) {
            const columnId = (e.target as HTMLElement)?.getAttribute('column-id') as string;
            setSubject(columnId);

            setSelected({ start: e.target as HTMLElement, end: e.target as HTMLElement });
        }
    };

    const handleMouseMove = (e: any) => {
        if (
            selected.start &&
            (e.target as HTMLElement)?.classList.contains('scheduler-cell') &&
            !(e.target as HTMLElement)?.classList.contains('side-cell') &&
            (type === 'week' ||
                (type === 'day' &&
                    e.target.offsetLeft <= selected.start.offsetLeft &&
                    e.target.offsetLeft >= selected.start.offsetLeft)) &&
            (selected.end?.offsetTop !== e.target.offsetTop ||
                selected.end?.offsetLeft !== e.target.offsetLeft)
        ) {
            setSelected(prev => ({ ...prev, ...{ end: e.target as HTMLElement } }));
        }
    };

    const handleMouseUp = () => {
        if (selected?.start && selected?.end && selected?.start !== selected?.end) {
            newEventHandler(
                'test',
                subject.id,
                subject.name,
                selected.start.getAttribute('slot-start') as string,
                selected.end.getAttribute('slot-end') as string,
                'exceptional'
            );
        }
        setSelected({ start: null, end: null });
    };

    useEffect(() => {
        if (bodyRef.current) {
            bodyRef.current.addEventListener('mousedown', handleMouseDown);
            bodyRef.current.addEventListener('mousemove', handleMouseMove);
            bodyRef.current.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            bodyRef.current?.removeEventListener('mousedown', handleMouseDown);
            bodyRef.current?.removeEventListener('mousemove', handleMouseMove);
            bodyRef.current?.removeEventListener('mouseup', handleMouseUp);
        };
    }, [type, bodyRef, selected?.start, selected?.end]);

    return (
        <div className={`scheduler-layout ${controls ? 'with-controls' : ''}`}>
            <div className="scheduler-header">
                <SchedulerRow row={generateHeaderRow()} columns={columns} header />
            </div>
            <div ref={bodyRef} className="scheduler-body">
                {rows.map((row, i) => (
                    <SchedulerRow
                        key={i}
                        row={row}
                        columns={columns}
                        newEventHandler={newEventHandler}
                    />
                ))}
                {selected?.start && selected?.end && selected?.start !== selected?.end && (
                    <div
                        className="selected-area"
                        style={getSelectedArea(selected.start, selected.end)}
                    />
                )}
                {allocatedEvents.map((event: EventDataType) => (
                    <Event
                        key={`${type}-${event.key}`}
                        eventKey={`${type}-${event.key}`}
                        eventId={event.id}
                        ownerId={event.ownerId}
                        date={event?.dateStart}
                        start={event?.dateStart}
                        end={event?.dateEnd}
                        type={event.type}
                        exceptional={event.type === 'exceptional'}
                        title={event.title}
                        color={event.color}
                        exactPosition={fitSlots}
                        heightIndex={fitSlots ? 1 : event.duration / duration}
                        eventClickedHandler={eventClickedHandler}
                        maxWidth={event?.maxWidth}
                        onRender={handleRendered}
                        eventItemTemplateResolver={eventItemTemplateResolver}
                        event={event}
                        data={data.map(d => ({
                            ...d,
                            date: moment(d.date),
                            start: makeHour(d.hour, new Date(d.date)),
                            end: makeHour(d.hour, new Date(d.date)).add(d.duration, 'minutes')
                        }))}
                    />
                ))}
            </div>
        </div>
    );
};

export default SchedulerLayout;
