import { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { ControlPropsType } from 'forms/interfaces';

import useTaskTree from 'hooks/useTaskTree';

import Loading from 'components/utils/Loading/Loading';

import TaskTree from './TaskTree';

import { TTNodeType } from './interface';

interface PropsType extends ControlPropsType {
    descr: any;
}

const TaskTreeControl: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { dataset, parentTask, value, loading, loadingType, loadingMessage } = useTaskTree(
        descr,
        propContainer
    );

    const [tree, setTree] = useState<TTNodeType[]>([]);

    const makeDataTree = (
        data: Array<TTNodeType>,
        dataTree = data.filter(item => item.child_level === 1)
    ) => {
        dataTree.map(item => {
            const childNodes = data.filter(
                childItem => childItem.parent_tt_node_id === item.tt_node_id
            );

            if (childNodes?.length) {
                item.child_nodes = childNodes;
                makeDataTree(data, item.child_nodes);
            }

            return item;
        });

        return dataTree;
    };

    useEffect(() => {
        if (dataset?.recCount && !loading) {
            setTree(makeDataTree(dataset.data));
        } else if (tree.length) setTree([]);
    }, [dataset?.recCount, loading]);

    return loading ? (
        <Loading delay type={loadingType} message={loadingMessage} />
    ) : (
        <TaskTree
            value={value}
            dataset={dataset}
            parentTask={parentTask as TTNodeType}
            tree={tree}
            iconSet={descr.iconSet}
            propContainer={propContainer}
        />
    );
});

export default TaskTreeControl;
