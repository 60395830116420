import { action, get, makeAutoObservable, observable, set } from 'mobx';

import { jsonFetch } from 'utils/index';
import { DBLookupType } from 'forms/interfaces';

type ValueType = string[] | number[] | null;

type LookupSetRecord = {
    descr: DBLookupType;
    cache: {
        [idList: string]: { value: ValueType; data: { [field: string]: string | number | null }[] };
    };
    callback?: (state: boolean) => unknown;
    actions: { setOpen: (open: boolean, callback?: (state: boolean) => unknown) => unknown };
};

class LookupSet {
    public descr: DBLookupType;

    @observable actions: {
        setOpen: (open: boolean, callback?: (state: boolean) => unknown) => unknown;
    };

    @observable cache: {
        [idList: string]: { value: ValueType; data: { [field: string]: string | number | null }[] };
    } = {};

    @observable callback?: (state: boolean) => unknown;

    constructor(descr: DBLookupType, actions: { setOpen: any } = { setOpen: () => true }) {
        this.descr = descr;
        this.cache = {};
        this.actions = actions;
    }

    @action setCache = (value: ValueType, data: { [field: string]: string | number | null }[]) => {
        this.cache[Array.isArray(value) ? value?.join(',') : value || '#'] = {
            value,
            data
        };
    };

    @action setActions = (actions: { setOpen: any }) => {
        this.actions = actions;
    };

    getCache = (value: ValueType) =>
        this.cache[Array.isArray(value) ? value?.join(',') : value || '#']?.data || [];
}

class Lookup {
    @observable lookupSet: { [lookupName: string]: LookupSet } = {};

    constructor() {
        makeAutoObservable(this);
    }

    @action setLookup = (descr: DBLookupType, actions?: { setOpen: any }) => {
        const lookup = this.getLookup(descr.name);

        if (lookup) {
            if (actions) {
                lookup.setActions(actions);
            }

            return lookup;
        }

        const newLookup = new LookupSet(descr, actions);
        set(this.lookupSet, {
            [descr.name]: newLookup
        });

        return newLookup;
    };

    @action setValue = (
        descr: DBLookupType,
        value: ValueType,
        data: { [field: string]: string | number | null }[]
    ) => {
        const lookup = this.setLookup(descr);

        if (lookup) {
            lookup.setCache(value, data);
        }
    };

    @action getValue = async (
        descr: DBLookupType,
        value: ValueType,
        formGuid: string,
        linkGuid: string,
        parObj: { [key: string]: string | number } = {}
    ) => {
        const data = this.getLookupData(descr.name, value);
        if (data?.length) return data;

        const answer: { data: { [field: string]: string | number | null }[] } = await jsonFetch(
            'query',
            'POST',
            {
                formGuid,
                linkGuid,
                idList: value,
                parObj
            }
        );

        this.setValue(descr, value, answer?.data || []);

        return answer?.data;
    };

    getLookup = (lookupName: string): LookupSet => get(this.lookupSet, lookupName);

    getLookupData = (lookupName: string, idList: ValueType) => {
        const lookup: LookupSet = get(this.lookupSet, lookupName);

        return lookup?.getCache(idList) || [];
    };

    getData = (lookupName: string, idList: ValueType, recNo?: number) => {
        const lookup: LookupSet = get(this.lookupSet, lookupName);
        const data = lookup?.getCache(idList);

        if (data?.length) return recNo === undefined ? data : data[recNo];

        return null;
    };
}

const LookupStore = new Lookup();
export default LookupStore;
