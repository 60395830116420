import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';

import { Tooltip, IconButton, Typography } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { getObjectFromClipboard } from 'utils/index';

import ConfigurationStore from 'store/configurationStore';
import GlobalResourceStore, { ResourceClass } from 'store/resourceStore';

import ResCtrlList from './ResCtrlList';

import ResCtrlImportFields from './ResCtrlImportFields';
import ResCtrlListClear from './ResCtrlListClear';

interface PropsType {
    ctrl: any;
    colName: string;
    fields: { [key: string]: any };
    ResourceStore: ResourceClass;
    readOnly?: boolean;
}

const ResCollection: FunctionComponent<PropsType> = observer(
    ({ ctrl, colName, fields, ResourceStore, readOnly }) => {
        const { content } = ConfigurationStore;
        const { addCtrl, setOpenedTree, getOpenedTree, updateCachedCurCtrl } = ResourceStore;
        const { pasteCtrl } = GlobalResourceStore;
        const [isOpened, setIsOpened] = useState(getOpenedTree(`${ctrl.guid as string}${colName}`));

        const openHandler = () => {
            setOpenedTree({ [`${ctrl.guid as string}${colName}`]: !isOpened });
            setIsOpened(!isOpened);
            updateCachedCurCtrl();
        };

        const handlePaste = async () => {
            const clipboardData = await getObjectFromClipboard();
            pasteCtrl(ctrl, colName, clipboardData);
        };

        const buttons = () =>
            readOnly ? null : (
                <>
                    &nbsp;
                    <Tooltip title={content.resource.buttons.add}>
                        <span>
                            <IconButton
                                disabled={fields[colName]?.maxCount <= ctrl[colName]?.length}
                                size="small"
                                onClick={() => {
                                    addCtrl(ctrl, colName);
                                }}
                            >
                                <AddCircleRoundedIcon fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={content.resource.buttons.paste}>
                        <span>
                            <IconButton size="small" onClick={handlePaste}>
                                <AssignmentIcon fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                    {colName === 'controls' ? (
                        <ResCtrlImportFields ctrl={ctrl} ResourceStore={ResourceStore} />
                    ) : null}
                    {['controls', 'fields', 'columns', 'filters'].includes(colName) ? (
                        <ResCtrlListClear
                            ctrl={ctrl}
                            listType={colName}
                            ResourceStore={ResourceStore}
                        />
                    ) : null}
                </>
            );

        return (
            <li key={colName}>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <Typography
                        onClick={openHandler}
                        style={{ cursor: 'pointer', fontSize: 14 }}
                        component="span"
                    >
                        {fields[colName].caption}&nbsp;({ctrl[colName]?.length ?? 0})
                    </Typography>

                    {isOpened ? (
                        <>
                            {buttons()}
                            <ResCtrlList
                                ResourceStore={ResourceStore}
                                parent={ctrl}
                                parentSection={colName}
                                arr={ctrl[colName]}
                                readOnly={readOnly}
                            />
                        </>
                    ) : null}
                </div>
            </li>
        );
    }
);

export default ResCollection;
