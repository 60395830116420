import { FunctionComponent } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

import { SchedulerCalendarType } from '../../interfaces';

const Calendar: FunctionComponent<SchedulerCalendarType> = ({ currentDate, setDate }) => {
    const handleChange = (newValue: Moment) => {
        setDate(newValue.toDate());
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateCalendar value={moment(currentDate)} onChange={handleChange} />
        </LocalizationProvider>
    );
};

export default Calendar;
