import { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Grid, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import useCtrlData from 'hooks/ctrlData';

import ComboBox from 'components/Inputs/ComboBox';
import { conditions, Condition, FilterValue, intervalConditions } from 'dataObj/CustomFilter';

import configurationStore from 'store/configurationStore';

import {
    FilterControlType,
    DrawComponentType,
    FilterDataType,
    MedialogDataType,
    ControlPropsType
} from 'forms/interfaces';
import FilterControlItem from './FilterControlItem';
import { DrawResourceType } from './interfaces';

const FieldDataType: Record<string, FilterDataType | null> = {
    KRN_INTEGER: FilterDataType.other,
    KRN_AUTOINC: FilterDataType.other,
    KRN_STRING: FilterDataType.string,
    KRN_DATETIME: FilterDataType.other,
    KRN_DATE: FilterDataType.other,
    KRN_TIME: FilterDataType.other,
    KRN_MONEY: FilterDataType.other,
    KRN_NUMERIC: FilterDataType.other,
    KRN_MEMO: FilterDataType.memo,
    KRN_BLOB: FilterDataType.memo,
    KRN_LOGICAL: FilterDataType.bool,
    KRN_GRAPHIC: null,
    KRN_COURS: FilterDataType.other,
    KRN_TAUX: FilterDataType.other,
    KRN_STRING_INTERNATIONAL: FilterDataType.string,
    KRN_DURATION_MINUTES: FilterDataType.other,
    KRN_BIT: FilterDataType.bool,
    KRN_MEMO_INTERNATIONAL: FilterDataType.memo,
    KRN_RTF: null,
    KRN_JSON: null,
    KRN_ARRAY: null
};

const filterDataTypeConditions: Record<string, Condition[]> = {
    string: [
        conditions.inInterval,
        conditions.outInterval,
        conditions.startWith,
        conditions.contain,
        conditions.notContain,
        conditions.endWith,
        conditions.equal,
        conditions.notEqual,
        conditions.notEmpty,
        conditions.empty
    ],
    memo: [
        conditions.startWith,
        conditions.contain,
        conditions.notContain,
        conditions.endWith,
        conditions.equal,
        conditions.notEqual,
        conditions.notEmpty,
        conditions.empty
    ],
    lookup: [conditions.include, conditions.notInclude, conditions.notEmpty, conditions.empty],
    bool: [conditions.skipIt, conditions.yes, conditions.no],
    other: [
        conditions.inInterval,
        conditions.outInterval,
        conditions.equal,
        conditions.notEqual,
        conditions.notEmpty,
        conditions.empty
    ]
};

const DefaultCondition: Record<string, Condition> = {
    other: conditions.inInterval,
    string: conditions.startWith,
    memo: conditions.startWith,
    bool: conditions.skipIt,
    lookup: conditions.include
};

interface PropTypes extends ControlPropsType {
    descr: FilterControlType;
    drawComponent: DrawComponentType;
}

const { filterControl } = configurationStore.content.components.inputs;

function FilterControl(props: PropTypes) {
    const { descr, propContainer, drawComponent } = props;
    const { setActiveCtrlDescr } = propContainer;

    // Упаковка свойств
    const drawResource = {
        descr,
        propContainer,
        drawComponent,
        setActiveCtrlDescr
    } as DrawResourceType;

    const { filterType, fieldName, datasetName, type, inputType } = descr;
    const { dataset } = useCtrlData(propContainer, datasetName, fieldName);
    const filter = dataset?.filter;

    const dataType =
        dataset?.descr.fields.find(f => f.name === fieldName)?.dataType || MedialogDataType.unknown;

    const filterDataType =
        type === 'filterLookup' || inputType === 'deflist'
            ? FilterDataType.lookup
            : FieldDataType[dataType] || FilterDataType.unknown;

    const conditionList = filterDataTypeConditions[filterDataType] ?? [];
    const conditionsStore = conditionList.map(it => ({
        key: it.toString(),
        val: filterControl.conditions[it]
    }));

    const defaultFValue: FilterValue = useMemo(() => {
        const cond = DefaultCondition[filterDataType];
        return {
            id: descr.filterId,
            filterDataType,
            type: descr.filterType,
            condition: filterType === 'value' ? cond : conditions.inInterval,
            value: { value: null },
            value2: intervalConditions.includes(cond) ? { value: null } : undefined
        };
    }, [descr, filterType, filterDataType]);

    let initValue = filter.getValue(descr.filterId);
    if (!initValue) {
        filter.setValue(defaultFValue);
        initValue = filter.getValue(descr.filterId);
    }
    const [fValue, setFValue] = useState(initValue || defaultFValue);

    useEffect(() => {
        setFValue(filter.getValue(descr.filterId) || defaultFValue);
    }, [filter, filter.values, descr.filterId, defaultFValue]);

    return filterDataType ? (
        <Grid container direction="column" style={{ paddingBottom: 8 }}>
            <Grid item style={{ paddingLeft: 8 }}>
                <Typography variant="button">{descr.label}</Typography>
            </Grid>
            <Grid container item>
                <Grid item xs={2} style={{ padding: 8 }}>
                    <ComboBox
                        style={{
                            width: '100%',
                            boxSizing: 'border-box'
                        }}
                        size="small"
                        store={conditionsStore}
                        // label="условие"
                        value={fValue.condition.toString()}
                        onChange={(e: SelectChangeEvent) => {
                            filter.setProperty(
                                fValue.id,
                                'condition',
                                Number.parseInt(e.target.value, 10)
                            );
                        }}
                        variant="outlined"
                        clearable={false}
                    />
                </Grid>
                <Grid item container xs={10}>
                    <FilterControlItem
                        propName="value"
                        drawResource={drawResource}
                        fieldName={fieldName}
                    />
                    {intervalConditions.includes(fValue.condition) && fValue.value2 ? (
                        <FilterControlItem
                            drawResource={drawResource}
                            propName="value2"
                            fieldName={`${fieldName}_2`}
                        />
                    ) : null}
                </Grid>
            </Grid>
        </Grid>
    ) : null;
}

const OFilterControl: React.FC<PropTypes> = observer(FilterControl);
export default OFilterControl;
