import { FunctionComponent, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import Loading from 'components/utils/Loading/Loading';

import useCtrlProps from 'hooks/ctrlProps';

import ConfigurationStore from 'store/configurationStore';
import NotificationStore from 'store/notificationStore';

import Tree from './Tree';

import { TreeEditContainerProps } from './interfaces';

const PARENT_ID = '#';

const BDTreeEditContainer: FunctionComponent<TreeEditContainerProps> = observer(
    ({ descr, propContainer }) => {
        const { guid, datasetName } = descr;

        const { ctrlVisible, ctrlEnabled } = useCtrlProps(propContainer, guid as string);
        const dataset = propContainer.dataStock.getDatasetObj(datasetName);

        const enabled = useMemo(() => {
            console.log('enabled');
            return ctrlEnabled && !!dataset?.inEdit;
        }, [ctrlEnabled, dataset?.inEdit]);

        useEffect(() => {
            if (ConfigurationStore.applicationConfig?.tables) {
                try {
                    const reqTables = JSON.parse(ConfigurationStore.applicationConfig?.tables);
                    propContainer.setCustomResourceLinks(reqTables);
                } catch (err: any) {
                    NotificationStore.showAlert(err.message);
                }
            }
        }, []);

        if (dataset)
            return (
                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                        boxSizing: 'border-box',
                        alignItems: 'center',
                        pr: 4,
                        visibility: ctrlVisible ? 'inherit' : 'hidden'
                    }}
                >
                    <Box sx={{ flexGrow: 1, padding: 2 }}>
                        <Tree dataset={dataset} parentId={PARENT_ID} disabled={!enabled} />
                    </Box>
                </Box>
            );

        return <Loading type="empty" />;
    }
);

export default BDTreeEditContainer;
