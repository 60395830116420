import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import Inputmask from 'inputmask';

import { isRegExpString } from 'forms/form-utils';

export default function useInputMask(ref: MutableRefObject<any>, value: string, mask: string) {
    const [completed, setCompleted] = useState(!mask);
    const [valid, setValid] = useState(true);

    const makeOptions = useCallback(
        (options: any = {}) => {
            const isRegExp = isRegExpString(mask);
            const regex = isRegExp ? mask.replace(/^\/|\/$/g, '') : mask;

            if (isRegExp)
                return {
                    ...options,
                    ...{
                        regex
                    }
                };

            return { ...options, ...{ mask } };
        },
        [mask]
    );

    const handleOnComplete = () => {
        setCompleted(true);
    };

    const handleOnIncomplete = () => {
        setCompleted(false);
        setValid(true);
    };

    const maskOpt = useMemo(() => {
        if (!mask) return {};

        const options = {
            oncomplete: handleOnComplete,
            onincomplete: handleOnIncomplete
        };

        return makeOptions(options);
    }, [mask]);

    const inputMask = useMemo(() => new Inputmask(maskOpt), [mask, maskOpt]);

    useEffect(() => {
        if (ref?.current && mask) {
            inputMask.mask(ref?.current);
        }
    }, [ref?.current, mask, inputMask]);

    useEffect(() => {
        if (value && mask && completed)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setValid(inputMask.isValid(value));
    }, [value, completed]);

    return {
        completed,
        valid
    };
}
