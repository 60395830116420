import React, { FunctionComponent, useState } from 'react';

import { Box } from '@mui/material';

import { ControlDataRowType, DBTextEditType } from 'forms/interfaces';

import SimpleDialog from 'components/Feedback/SimpleDialog';
import DBSimpleEdit from './DBSimpleEdit';

interface PropsType extends ControlDataRowType {
    descr: DBTextEditType;
    type?: string;
    index?: number;
}

const DBSimpleEditContainer: FunctionComponent<PropsType> = ({ descr, type, ...other }) => {
    const [fullScreen, setFullScreen] = useState(false);

    const switchFullScreen = () => {
        setFullScreen(prev => !prev);
    };

    return (
        <>
            <DBSimpleEdit
                descr={descr}
                type={type}
                switchFullScreen={switchFullScreen}
                {...other}
            />
            {fullScreen && (
                <SimpleDialog handleClose={switchFullScreen}>
                    <Box sx={{ p: 2, position: 'relative' }}>
                        <DBSimpleEdit
                            descr={descr}
                            type={type}
                            fullScreen={fullScreen}
                            switchFullScreen={switchFullScreen}
                            {...other}
                        />
                    </Box>
                </SimpleDialog>
            )}
        </>
    );
};

export default DBSimpleEditContainer;
