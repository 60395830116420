const langEng = {
    auth: {
        signIn: 'Sign in',
        username: 'User Name',
        password: 'Password',
        enter: 'Enter',
        tokenAuth: 'Authorization by token',
        tokenInvalid: 'Invalid authorization token',
        post: 'Post'
    },
    external: {
        error: {
            formId: 'Form ID not specified',
            gridName: 'Grid name not specified',
            pageExists: 'The requested page does not exist',
            requestFailed: 'Request failed!'
        }
    },
    settings: {
        clear: 'Reset',
        save: 'Save',
        load: 'Load',
        delete: 'Delete',
        user: 'for current user',
        allUsers: 'for all users',
        global: 'global'
    },
    buttons: {
        ok: 'Ok',
        cancel: 'Cancel',
        add: 'Add',
        next: 'Next',
        back: 'Back',
        refresh: 'Refresh',
        close: 'Close',
        save: 'Save',
        undo: 'Cancel',
        copy: 'Copy',
        paste: 'Paste',
        delete: 'Delete',
        export: 'Export',
        import: 'Import',
        load: 'Load',
        open: 'Open',
        selectAll: 'Select all',
        clearAll: 'Clear all'
    },
    reports: {
        saveReport: 'Save the report file',
        done: 'The report has been generated',
        notExist: 'The requested report has not been generated'
    },
    messages: {
        saveAnyway: 'Save anyway?'
    }
};

export default langEng;
