import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { BackdropInstance } from '../store/notificationStore';

export default function useBackdrop(showBackdrop: boolean, timeout?: number) {
    const backdropRef: MutableRefObject<BackdropInstance | null> = useRef(null);

    useEffect(() => {
        const backdrop = backdropRef.current;

        if (showBackdrop) {
            if (backdrop) {
                backdrop.updateBackdrop(timeout);
            } else {
                backdropRef.current = new BackdropInstance(timeout);
            }
        } else {
            backdrop?.clearBackdrop();
        }

        return () => {
            backdrop?.clearBackdrop();
        };
    }, [showBackdrop]);

    return useMemo(() => backdropRef.current, [backdropRef.current]);
}
