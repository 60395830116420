import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Box, Grid } from '@mui/material';

import { ControlPropsType } from 'forms/interfaces';
import ControlTree from 'forms/ControlTree';

interface PropsType extends ControlPropsType {
    items?: any[];
    align?: 'left' | 'right';
}

const HeaderControls: FunctionComponent<PropsType> = observer(
    ({ items, align = 'left', propContainer }) => (
        <Box className="toolbar" display="flex" flexGrow={1} alignItems="center">
            {items?.map(item => (
                <Box display="flex" key={item.guid} flexGrow={item.gridWidth === 'auto' ? 1 : 0}>
                    <ControlTree formDescr={item} propContainer={propContainer} />
                </Box>
            ))}
        </Box>
    )
);

/* const HeaderControls: FunctionComponent<PropsType> = observer(
    ({ items, align = 'left', propContainer }) => (
        <Grid container flexGrow={1} alignItems="start" direction="row">
            {items?.map(item => (
                <Grid item key={item.guid}>
                    <ControlTree formDescr={item} propContainer={propContainer} />
                </Grid>
            ))}
        </Grid>
    )
); */

export default HeaderControls;
