import React, { FunctionComponent, ReactElement } from 'react';
import { observer } from 'mobx-react';

import {
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    DialogContent,
    Box,
    FormControl
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Close } from '@mui/icons-material';

import { CustomFormType, EditModeType } from 'forms/interfaces';

import ConfigurationStore from 'store/configurationStore';
import MenuItem from '@mui/material/MenuItem';

interface PropsType {
    open: boolean;
    content: ReactElement<CustomFormType>;
    editMode: EditModeType;
    handleEditModeChange: (editMode: EditModeType) => void;
    onClose: (event: any) => void;
}

const ResModal: FunctionComponent<PropsType> = observer(props => {
    const { open, content, editMode, handleEditModeChange, onClose } = props;

    const onEditModeChange = (event: SelectChangeEvent) => {
        handleEditModeChange((event.target.value as EditModeType) || null);
    };

    return (
        <Dialog fullScreen open={open} onClose={onClose}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        size="large"
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: '16px', flex: 1 }}>
                        {ConfigurationStore.content.resource.buttons.close}
                    </Typography>

                    <Box sx={{ p: 2, boxSizing: 'border-box' }}>
                        <FormControl
                            fullWidth
                            variant="standard"
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '40px'
                                }
                            }}
                        >
                            <Select
                                sx={{ width: 200 }}
                                value={editMode || 'view'}
                                onChange={onEditModeChange}
                            >
                                <MenuItem value="view">Просмотр</MenuItem>
                                <MenuItem value="edit">Редактор</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ height: '100vh' }}>{content}</DialogContent>
        </Dialog>
    );
});

export default ResModal;
