import React, { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';

import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';

import DataStore from 'store/dataStore';
import RoutingStore, { getRouteParams } from 'store/routingStore';
import MainFormStore from 'store/mainFormStore';

import { ControlPropsType, MainFormType, RouterItemType } from 'forms/interfaces';

import { jsonFetch } from 'utils/index';

import SideBar, { DrawerHeader } from './SideBar';
import Header from './Header';
import MainFormModal from './MainFormModal';
import MainFormContainer from './MainFormContainer';

interface PropsType extends ControlPropsType {
    descr: MainFormType;
}

const MainForm: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const {
        modalForm,
        initSelections,
        setCurrentItem,
        setModalForm,
        setModalFormValues,
        setFormEditMode
    } = MainFormStore;

    const setCurrentForm = (value: string, route: string) => {
        if (DataStore?.AF) {
            DataStore.AF.setAF('currentForm', value, {}, propContainer);
        }
        setCurrentItem(route);
        initSelections(route, descr.sideBar);
    };

    const history = RoutingStore.getHistory();
    const location = useLocation();

    const findListForm = (pathArr: string[]): RouterItemType | null => {
        const route = RoutingStore.getRoute(pathArr.pop() || '');

        if (route?.resource) {
            if (route.viewMode === 'modal') {
                return findListForm(pathArr);
            }

            return route;
        }

        return null;
    };

    const setFormParams = async (route?: RouterItemType) => {
        // eslint-disable-next-line
        const { cdotype_id, values } = getRouteParams(
            route?.route || '',
            RoutingStore.getCurrentPath()
        );

        const code =
            cdotype_id === undefined
                ? route?.formLink.code
                : (
                      (await jsonFetch(
                          'dataobject/form',
                          'POST',
                          { cdotype_id: Number(cdotype_id), formType: 'editor' },
                          {},
                          'json'
                      )) as any
                  )?.code;

        setModalFormValues({ ...values, ...{ cdotype_id } });
        setFormEditMode(route?.editMode || 'view');

        if (route?.viewMode === 'modal') {
            setModalForm(code);
            const listForm = findListForm(RoutingStore.getPathArr());
            listForm && setCurrentForm(listForm.formLink.code, listForm.name);
        } else {
            setModalForm();
            setCurrentForm(code, route?.name || '');
        }
    };

    useEffect(() => {
        // Инициализируем только при получении пути
        if (['POP', 'REPLACE'].includes(history.action)) {
            const currentPath = RoutingStore.getCurrentPath();

            const route = RoutingStore.getRoute(currentPath);

            setFormParams(route).catch(err => console.error(err));
        }
    }, [location, history]);

    return (
        <>
            <Grid display="flex" height="100vh">
                <CssBaseline />
                <Header descr={descr} propContainer={propContainer} />
                <SideBar descr={descr} propContainer={propContainer} />
                <Grid
                    container
                    component="main"
                    flexGrow={1}
                    flexWrap="nowrap"
                    direction="column"
                    position="relative"
                    // pt={4}
                >
                    <DrawerHeader />
                    <Grid item flexShrink={0} sx={theme => ({ height: theme.spacing(4) })} />
                    <MainFormContainer descr={descr} propContainer={propContainer} />
                </Grid>
            </Grid>
            <MainFormModal
                parentDataStock={propContainer.dataStock}
                parentPropContainer={propContainer}
                setForm={setModalForm}
                formGuid={modalForm}
            />
        </>
    );
});

export default MainForm;
