import React, {
    FunctionComponent,
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';

import { Stack } from '@mui/material';

interface PropsType {
    containerWidth?: number;
    setFullWidth: (fullWidth: boolean) => unknown;
    setButtonWidth?: (width: number) => unknown;
}

const ButtonsContainer: FunctionComponent<PropsType> = ({
    containerWidth,
    setFullWidth,
    setButtonWidth,
    children
}) => {
    const toolbarRef = useRef(null) as MutableRefObject<any>;

    const [breakpoint, setBreakpoint] = useState(0);
    const [settled, setSettled] = useState(false);

    useEffect(() => {
        if (containerWidth !== undefined) setFullWidth(containerWidth >= breakpoint);
    }, [containerWidth, breakpoint]);

    useEffect(() => {
        toolbarRef?.current && setBreakpoint(toolbarRef?.current?.offsetWidth);
        toolbarRef?.current && setButtonWidth && setButtonWidth(toolbarRef?.current?.offsetWidth);
    }, [toolbarRef.current]);

    useEffect(() => {
        if (breakpoint > 0) setSettled(true);
    }, [breakpoint]);

    const handleBreakpoint = useCallback((node: HTMLDivElement) => {
        setBreakpoint(node?.offsetWidth);
        setButtonWidth && setButtonWidth(node?.offsetWidth);
    }, []);

    return containerWidth !== undefined ? (
        <Stack
            ref={handleBreakpoint}
            spacing={2}
            direction="row"
            sx={{ visibility: settled ? 'visible' : 'hidden' }}
        >
            {children}
        </Stack>
    ) : null;
};

export default ButtonsContainer;
