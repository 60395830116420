import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import Lookup from 'forms/controls/DBLookup/Lookup';

import { getLocalizedString } from 'utils/index';

import LookupStore from 'store/lookupStore';
import ConfigurationStore from 'store/configurationStore';

interface PropsType {
    value: string;
    lookup: { resourceLinkName: string; valueField: string; nameField: string };
}

type DataValType = string[] | number[] | null;

const ResLookup: FunctionComponent<PropsType> = observer(({ value, lookup }) => {
    const { applicationConfig } = ConfigurationStore;

    const { resourceLinkName, valueField, nameField } = lookup;

    const label = '';

    const reqTables = useMemo(() => {
        if (applicationConfig?.tables) {
            try {
                return JSON.parse(applicationConfig?.tables);
            } catch (err: any) {
                console.error(err.message);
                return [];
            }
        }
    }, [ConfigurationStore.applicationConfig?.tables]);

    const [loading, setLoading] = useState(false);
    const [codeLabel, setCodeLabel] = useState({ code: '', label: '' });

    useEffect(() => {
        async function updateCodeLabel(val: DataValType) {
            const resourceLink = reqTables.find((RL: any) => RL.name === resourceLinkName);

            if (val?.length && resourceLink?.link?.resource?.name) {
                setLoading(true);

                try {
                    const data = await LookupStore.getValue(
                        { name: resourceLinkName } as any,
                        val,
                        resourceLink.formGuid,
                        resourceLink.link.guid || resourceLink.link.name,
                        {}
                    );

                    if (data?.length > 0) {
                        setCodeLabel({
                            code: '',
                            label: value
                        });
                    }
                } catch (err: any) {
                    console.error(err.message);
                } finally {
                    setLoading(false);
                }
            } else {
                setCodeLabel({ code: '', label: '' });
                setLoading(false);
            }
        }

        if (resourceLinkName && valueField && nameField) {
            updateCodeLabel([value]).catch(err => console.error(err.message));
        }
    }, [value, resourceLinkName, valueField, nameField]);

    const openLookup = () => {
        alert('open');
    };

    const clearValues = () => {
        alert('clear');
    };

    return (
        <Lookup
            variant="outlined"
            value={getLocalizedString(codeLabel.code)}
            helperText={getLocalizedString(codeLabel.label)}
            inputProps={{ style: { color: '#888' } }}
            onOpenClick={openLookup}
            onClear={clearValues}
            disabled={!resourceLinkName}
            loading={loading}
            style={{ margin: 0 }}
        />
    );
});

export default ResLookup;
