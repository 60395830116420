import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useEditType from './editType';
import useDBEdit from './dbEdit';
import PropContainer from '../dataObj/PropContainer';

import { checkInternationalField, getLocalizedString, updateInternationalContent } from '../utils';

export default function useSimpleEdit(
    descr: { [key: string]: any },
    propContainer: PropContainer,
    type?: string,
    dataRow?: any,
    index?: number
) {
    const {
        dataset,
        fieldName,
        dataVal,
        dataType,
        fieldRequired,
        internationalField,
        fieldSize,
        fieldScale,
        ctrlEnabled,
        ctrlVisible,
        ctrlCaption,
        ctrlMask,
        editRef,
        onScreen
    } = useDBEdit(descr, propContainer, dataRow);
    const editType = useEditType(type, descr?.type, descr?.password);

    const inputRef = useRef(undefined) as MutableRefObject<HTMLInputElement | undefined>;

    const size = useMemo(() => fieldSize || null, [fieldSize]);
    const scale = useMemo(() => {
        if (dataType === 'KRN_INTEGER') return 0;
        return fieldScale || fieldScale === 0 ? fieldScale : 2;
    }, [fieldScale]);

    const [count, setCount] = useState(0);
    const [invalid, setInvalid] = useState(false);
    const [selectionStart, setSelectionStart] = useState(0);

    const valueToText = useCallback(
        val => {
            if (val === undefined || val === null) return null;

            if (editType === 'date' || editType === 'datetime-local') {
                const ISO_8601_FULL =
                    /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;
                if (ISO_8601_FULL.test(val)) {
                    if (editType === 'date') {
                        return val.substring(0, 10);
                    }
                    return val.substring(0, 19);
                }
                setInvalid(true);
                return `Wrong format ${val as string}`;
            }

            if (editType === 'number') {
                return `${val as number}`;
            }

            if (internationalField) return getLocalizedString(val, false);

            return val;
        },
        [editType]
    );

    const textToValue = useCallback(
        text => {
            if (editType === 'date' || editType === 'datetime-local') {
                if (text) return new Date(text).toISOString();
                return null;
            }

            switch (dataType) {
                case 'KRN_INTEGER':
                case 'KRN_NUMERIC':
                    return text ? Number(text) : null;
                default:
                    return text;
            }
        },
        [editType]
    );

    const [value, setValue] = useState(() => valueToText(dataVal));

    const handleSetValue = (val: string) => {
        setValue(valueToText(val));
    };

    // Если валидируемое значение не проходит проверку, то возвращаем предыдущее
    const getValidated = (val: string, validateVal: string, replace = false): string =>
        validateVal === val || replace ? validateVal : value;

    // Проверка количества символов после запятой
    const validateScale = (val: string, replace = false) => {
        const scaleRegExp = new RegExp(scale ? `^-?\\d*(?:\\.|\\,)?\\d{0,${scale}}` : `^-?\\d*`);
        return getValidated(val, val.match(scaleRegExp)?.[0] ?? '', replace);
    };

    // Проверка общего количества символов
    const validateSize = (val: string, replace = false) => {
        if (val === null || val === undefined) return '';

        if (size) {
            if (editType === 'number') {
                const sizeRegExp = new RegExp(
                    `^-?(?:(?=.*[\\.].*)[0-9.]{0,${size + 1}}|[0-9]{0,${size}})`
                );
                return getValidated(val, val.match(sizeRegExp)?.[0] ?? '', replace);
            }

            return getValidated(val, val?.substring(0, size), replace);
        }

        return val;
    };

    // Валидация numeric-значений
    const validateNumber = (val: string, past?: boolean) => {
        if (editType === 'number') {
            // Валидация scale
            return validateScale(
                `${val ?? ''}`
                    // Проверяем строку - если присутствует что-то кроме цифр и разделителей, отсекаем
                    // Убираем все знаки "-", кроме первого в строке
                    .replace(/(?!^-)[^\d.,]+/g, '')
                    // Заменяем все запятые на точки для безболезненной конвертации в число
                    .replace(/,/, '.')
                    // Добавляем ноль, если есть разделитель, но нет целого
                    .replace(/(?<=^-|^)\./, '0.')
                    // Проверяем, что первый разделитель-точка - единственный, иначе убираем все разделители правее
                    .replace(/\..*/, c => `.${c.replace(/\./g, () => '')}`)
                    // Удаляем лишние нули в начале числа
                    .replace(/(?<=^-|^)0+?(?=(?:(?=.*[\\.].*)\\.|[^0]|$))/, '0'),
                past
            );
        }

        return val;
    };

    const getNewValue = (newVal: string) => {
        if (ctrlMask) return newVal;
        return validateSize(validateNumber(newVal));
    };

    const handleOnChange = (event: any) => {
        let val = getNewValue(event.target.value);

        // Проверяем - нужно ли сдвигать каретку
        /* При использовании маски ввода меняется набор данных
         * в event.target, отсутствует selectionStart. С учётом того,
         * что определение сдвига каретки необходимо только для
         * валидации численных значений при использовании макси ввода
         * этим можно пренебречь
         */
        if (!ctrlMask) {
            if (value !== val || event?.nativeEvent?.inputType === 'insertFromPaste') {
                setSelectionStart(event.target.selectionStart);
            } else {
                setSelectionStart(
                    event.target.selectionStart - (val.length <= event.target.value.length ? 1 : -1)
                );
            }

            // Счётчик для отлавливания нажатий
            setCount(count + 1);
        }

        if (ctrlEnabled && !invalid && event?.nativeEvent?.inputType !== 'insertFromPaste') {
            // Запоминаем старое значение
            const oldValue = value || '';

            // Обновляем значение
            handleSetValue(val);
            if (checkInternationalField(dataType)) val = updateInternationalContent(val, dataVal);

            dataset
                ?.trySetFieldValue(fieldName, textToValue(val), index)
                .then(allowed => {
                    if (!allowed) {
                        // Восстанавливаем старое значение, если изменение недопустимо
                        handleSetValue(oldValue);
                        if (checkInternationalField(dataType))
                            updateInternationalContent(oldValue, dataVal);
                    }
                })
                .catch(err => console.error(err.message));
        }
    };

    const handleOnInternationalChange = (val: string) => {
        if (ctrlEnabled && !invalid) {
            // Запоминаем старое значение
            const oldValue = value || '';

            // Обновляем значение
            handleSetValue(val);

            dataset
                ?.trySetFieldValue(fieldName, textToValue(val), index)
                .then(allowed => {
                    // Восстанавливаем старое значение, если изменение недопустимо
                    if (!allowed) handleSetValue(oldValue);
                })
                .catch(err => console.error(err.message));
        }
    };

    // Слушаем событие вставки значения
    useEffect(() => {
        const insertByIndex = (newVal: string, origVal: string, idx = 0) =>
            `${origVal.substring(0, idx)}${newVal}${origVal.substring(idx)}`;

        const getNotSelected = (val: string, start: number, end: number) => {
            if (start - end) {
                return `${val.substring(0, start)}${val.substring(end)}`;
            }

            return val;
        };

        const onPaste = (event: any) => {
            const paste = validateSize(
                validateNumber(event.clipboardData.getData('text'), true),
                true
            );
            const origVal = getNotSelected(
                value || '',
                event.currentTarget.selectionStart,
                event.currentTarget.selectionEnd
            );
            let val = validateSize(
                validateNumber(insertByIndex(paste, origVal, event.target.selectionStart)),
                true
            );

            if (ctrlEnabled && !invalid) {
                if (checkInternationalField(dataType))
                    val = updateInternationalContent(val, dataVal);

                dataset
                    ?.trySetFieldValue(fieldName, val, index)
                    .catch(err => console.error(err.message));
            }

            setSelectionStart((event.target.selectionStart as number) || 0);
            setCount(count + 1);
        };

        if (inputRef.current) {
            const elem = inputRef.current;
            elem.addEventListener('paste', onPaste);
            return () => {
                elem.removeEventListener('paste', onPaste);
            };
        }
    }, [value, count]);

    // Ручной сдвиг каретки
    useEffect(() => {
        if (
            inputRef.current &&
            (inputRef.current.selectionStart !== selectionStart ||
                inputRef.current.selectionEnd !== selectionStart)
        ) {
            inputRef.current.selectionStart = selectionStart;
            inputRef.current.selectionEnd = selectionStart;
        }
    }, [count]);

    useEffect(() => {
        const val = valueToText(dataVal);
        if (val !== value) setValue(val);
    }, [dataVal, valueToText]);

    useEffect(() => {
        propContainer.setPageRequirement(descr.name, fieldRequired && ctrlEnabled && !value);
    }, [value, fieldRequired, ctrlEnabled]);

    useEffect(() => {
        setValue(validateSize(validateNumber(value, true), true));
    }, []);

    return {
        value,
        dataVal,
        handleOnChange,
        handleOnInternationalChange,
        editType,
        valueToText,
        textToValue,
        invalid,
        fieldRequired,
        onScreen,
        internationalField,
        ctrlEnabled,
        ctrlVisible,
        ctrlCaption,
        ctrlMask,
        editRef,
        inputRef
    };
}
