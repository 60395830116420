import React, { FunctionComponent } from 'react';

import { observer } from 'mobx-react';

import CustomDataset from 'dataObj/customDataset';

import { ControlPropsType, ToolButtonType } from 'forms/interfaces';

import { TCustomToolbarButton } from '../interface';

import ButtonsContainer from './ButtonContainer';
import ToolbarButton from './ToolbarButton';
import CustomToolbarButton from './CustomToolbarButton';

interface PropsType extends ControlPropsType {
    custom?: boolean;
    buttons?: ToolButtonType[] | TCustomToolbarButton[];
    fullWidth: boolean;
    containerWidth?: number;
    dataset?: CustomDataset;
    setFullWidth: (fullWidth: boolean) => unknown;
    setButtonWidth?: (width: number) => unknown;
}

const Buttons: FunctionComponent<PropsType> = observer(
    ({
        custom = false,
        fullWidth,
        containerWidth,
        buttons = [],
        propContainer,
        setFullWidth,
        setButtonWidth,
        dataset
    }) => (
        <ButtonsContainer
            containerWidth={containerWidth}
            setFullWidth={setFullWidth}
            setButtonWidth={setButtonWidth}
        >
            {buttons.map((button, index) =>
                custom ? (
                    <CustomToolbarButton
                        key={index}
                        button={button as TCustomToolbarButton}
                        fullWidth={fullWidth}
                    />
                ) : (
                    <ToolbarButton
                        key={button.guid}
                        descr={button as ToolButtonType}
                        propContainer={propContainer}
                        fullWidth={fullWidth}
                        dataset={dataset}
                    />
                )
            )}
        </ButtonsContainer>
    )
);

export default Buttons;
