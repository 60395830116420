import { DatasetType } from 'forms/interfaces';
import { getResourceDescriptor } from 'forms/form-utils';

import DescriptorStore from 'store/descriptorStore';
import ConfigurationStore from 'store/configurationStore';

import { CDODataStruct, IQueryRow } from './dataInterfaces';

/**
 * Генерирует конфигурацию набора данных на основе описателя CDO и полученного набора данных.
 *
 * @param {DatasetType[]} cdoDatasets - структура данных CDO, в соответствии с описателем
 * @param {CDODataStruct} cdoData - набор данных CDO, полученный от сервера
 * @param {boolean} [makeInitRecord=false] - флаг для указания создания начальной записи
 * @param {string} [parentKey] - необязательный родительский ключ
 * @param {string | number | null} [parentVal] - необязательное родительское значение
 * @param {number} [count=-1] - необязательное значение счетчика
 */
const makeDatasetConfig = (
    cdoDatasets: DatasetType[],
    cdoData: CDODataStruct,
    makeInitRecord: boolean = false,
    parentKey?: string,
    parentVal?: string | number | null,
    count: number = -1
) => {
    const makeFields = (ds: DatasetType) => {
        const tmpFields: IQueryRow = {};

        ds.fields.forEach(fld => {
            tmpFields[fld.name] = null;
        });

        tmpFields[ds.keyField] = count;
        count--;

        if (parentKey) tmpFields[parentKey] = parentVal || null;

        return tmpFields;
    };

    cdoDatasets.map(ds => {
        // Проверяем, что в наборе данных от сервера существует и заполнен датасет
        if (cdoData[ds.name]?.length) {
            cdoData[ds.name].map(detDS => {
                if (!detDS.fields) detDS.fields = makeFields(ds);

                if (ds.datasets) {
                    makeDatasetConfig(
                        ds.datasets,
                        detDS.datasets || {},
                        makeInitRecord,
                        ds.keyField,
                        detDS.fields[ds.keyField],
                        count
                    );
                }
            });
        } else {
            const fields = makeInitRecord && ds.required ? makeFields(ds) : undefined;

            cdoData[ds.name] = [
                {
                    fields
                }
            ];

            if (ds.datasets) {
                makeDatasetConfig(
                    ds.datasets,
                    cdoData,
                    makeInitRecord,
                    ds.keyField,
                    fields?.[ds.keyField],
                    count
                );
            }
        }
    });
};

/**
 * Заполняет структуру CDO предоставленными данными и возвращает обновленную структуру CDO.
 *
 * @param {string} cdoGuid - Уникальный идентификатор CDO.
 * @param {CDODataStruct} cdoData - Данные для заполнения структуры CDO.
 * @param {boolean} makeInitRecord - Флаг, указывающий, нужно ли создавать начальную запись.
 * @return {CDODataStruct} Обновленная структура CDO.
 * */
export const fillCDOStruct = async (
    cdoGuid: string,
    cdoData: CDODataStruct,
    makeInitRecord: boolean = false
): Promise<CDODataStruct> => {
    const cdoDescr = await getResourceDescriptor(cdoGuid, undefined, undefined, DescriptorStore, {
        logout: ConfigurationStore.logout
    });

    const tmpDS = { ...cdoData };
    makeDatasetConfig(cdoDescr.datasets, tmpDS, makeInitRecord);

    return tmpDS;
};
