import React, { FunctionComponent, useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import { Box, IconButton, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import { ControlPropsType, MainFormType } from 'forms/interfaces';

import MainFormStore from 'store/mainFormStore';

import Splitter from './Splitter';
import CustomDrawer from './CustomDrawer';
import ListContent from './ListContent';

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 4, 0, 4),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
}));

const MenuButtonWrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 4, 0, 4),
    zIndex: theme.zIndex.drawer,
    ...theme.mixins.toolbar
}));

interface PropsType extends ControlPropsType {
    descr: MainFormType;
}

const SideBar: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { fixedSideBar: fixed } = descr;
    const { open, defaultWidth, setOpen } = MainFormStore;

    const [width, setWidth] = useState(defaultWidth);

    const items = useMemo(() => {
        if (descr.sideBar) {
            return descr.sideBar;
        }
        return [];
    }, [descr?.guid]);

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <CustomDrawer
                id={`drawer-${descr.guid}`}
                key={`drawer-${descr.guid}`}
                variant={fixed ? 'permanent' : 'temporary'}
                open={open}
                width={width}
            >
                <DrawerHeader />
                <Divider />
                <Box
                    className="scrollbar-hidden"
                    sx={{
                        width,
                        overflowY: 'auto'
                    }}
                    role="presentation"
                >
                    <ListContent items={items} propContainer={propContainer} />
                </Box>
                <Splitter setWidth={setWidth} />
            </CustomDrawer>
            {!fixed && (
                <MenuButtonWrapper id={`open-menu-btn-${descr.guid}`}>
                    <IconButton onClick={handleOpen}>
                        <MenuIcon />
                    </IconButton>
                </MenuButtonWrapper>
            )}
        </>
    );
});

export default SideBar;
