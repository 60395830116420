import React, { FunctionComponent } from 'react';
import ResCollection from './ResCollection';
import styles from './res.module.scss';

import { ResourceClass } from '../../../store/resourceStore';
import { ControlType } from '../../../forms/interfaces';

interface PropsType {
    ResourceStore: ResourceClass;
    ctrl: ControlType;
    fields: { [key: string]: any };
    readOnly?: boolean;
}

const ResCollectionList: FunctionComponent<PropsType> = ({
    ResourceStore,
    ctrl,
    fields,
    readOnly = false
}) => {
    const colNames = [];

    for (const fld in fields) {
        if (fields[fld].type === 'array') {
            colNames.push(fld);
        }
    }

    return colNames.length ? (
        <ul className={styles.list}>
            {colNames.map(colName => (
                <ResCollection
                    ResourceStore={ResourceStore}
                    key={colName}
                    ctrl={ctrl}
                    colName={colName}
                    fields={fields}
                    readOnly={readOnly}
                />
            ))}
        </ul>
    ) : null;
};

export default ResCollectionList;
