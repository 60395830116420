import { FunctionComponent } from 'react';

import { styled } from '@mui/material/styles';

import Toolbar from '@mui/material/Toolbar';
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PrintIcon from '@mui/icons-material/Print';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import { ToolbarType, TypeOfView } from './interfaces';

const types: { [key: string]: TypeOfView } = { day: 'day', week: 'week', month: 'month' };

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: theme.shape.borderRadius,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0
        }
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
        marginLeft: -1,
        borderLeft: '1px solid transparent'
    }
}));

const ScheduleToolbar: FunctionComponent<ToolbarType> = ({
    type,
    subject,
    subjects,
    model,
    models,
    setType,
    setSubject,
    setModel
}) => {
    const handleType = (event: React.MouseEvent<HTMLElement>, newType: string | null) => {
        setType(newType as TypeOfView);
    };

    const handleSubject = (event: SelectChangeEvent) => {
        setSubject(event.target.value);
    };

    const handleModel = (event: SelectChangeEvent) => {
        setModel(event.target.value);
    };

    return (
        <Toolbar variant="dense" disableGutters>
            <StyledToggleButtonGroup
                size="small"
                value={type}
                exclusive
                onChange={handleType}
                sx={{ flexGrow: 1 }}
            >
                <ToggleButton value={types.day} aria-label={types.day}>
                    <AccessTimeIcon />
                </ToggleButton>
                <ToggleButton value={types.week} aria-label={types.week}>
                    <CalendarMonthIcon />
                </ToggleButton>
                <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                <ToggleButton value={types.month} aria-label={types.day}>
                    <PrintIcon />
                </ToggleButton>
                <ToggleButton value={types.month} aria-label={types.week}>
                    <EventAvailableIcon />
                </ToggleButton>
            </StyledToggleButtonGroup>
            {type === 'week' && (
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="select-subject-label">Субъект</InputLabel>
                    <Select
                        labelId="select-subject-label"
                        id="select-subject"
                        value={`${subject?.id}` || ''}
                        label="Субъект"
                        onChange={handleSubject}
                    >
                        {subjects.map((subj, index) => (
                            <MenuItem key={index} value={subj.id}>
                                {subj.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="select-configuration-label">Модель</InputLabel>
                <Select
                    labelId="select-configuration-label"
                    id="select-configuration"
                    value={`${model?.id}` || ''}
                    label="Модель"
                    onChange={handleModel}
                >
                    {models.map((m, index) => (
                        <MenuItem key={index} value={m.id}>
                            {m.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Toolbar>
    );
};

export default ScheduleToolbar;
