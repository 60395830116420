import { makeObservable, observable, action, computed } from 'mobx';
import _ from 'lodash';

import {
    onNewRecord,
    afterOpen,
    onDataChange,
    afterDataChange,
    afterScroll,
    beforeScroll
} from '../store/eventsStore/events';

import ConfigurationStore from '../store/configurationStore';
import EventsStore from '../store/eventsStore';
import DataStore from '../store/dataStore';

import { dateToString, getLocalizedString } from '../utils';
import CustomFilter from './CustomFilter';
import DataChunkContainer from './DataChunkContainer';
import ActionStore from '../store/actionStore';
import { v4 as uuidv4 } from 'uuid';

export const dsStates = {
    dsInactive: 0, // не загружался
    dsBrowse: 1, // просмотр
    dsAppend: 2, // добавление записи
    dsEdit: 3, // редактироваине записи
    dsLoading: 4, // в процессе загрузки
    dsWaiting: 5, // поставлен в очередь загрузки
    dsError: 6 // ошибка загрузки
};

/** @typedef {import('./DataStock').default} DataStock */
/** @typedef {import('../forms/interfaces').DatasetType} DatasetType  */
/** @typedef {import('./CDO').default} CDOType  */
/** @typedef {import('./TaskQueue').default} taskQueue */

/**
 * @class CustomDataset
 * @property {DataStock} dataStock
 * @property {DatasetType} descr
 * @property {dataStock} dataStock
 * @property {object} DataStore
 * @property {descr} DatasetType
 * @property {TaskQueue} taskQueue
 * @property {CustomFilter} filter
 */
export default class CustomDataset {
    /**
     * @constructor
     * @param {{dataStock: DataStock, descr: DatasetType, cdo?: CDOType }} props
     */
    constructor({ dataStock, descr, cdo }) {
        this.index = undefined;
        this.dataStock = dataStock;
        this.taskQueue = DataStore.taskQueue;
        this.descr = descr;
        this.name = descr.name;
        this.guid = uuidv4();
        this.editable = descr.editable;
        this.editType = descr.editType;

        // Свойства multi-dataset (begin)
        this.currentDataChunk = undefined;
        this.chunkContainer = new DataChunkContainer(this);
        // Свойства multi-dataset (end)

        if (cdo) {
            this.cdo = cdo;
            this.options = cdo.options?.datasets[this.name];
            cdo.addDataset(this);
        }

        this.serverKeyFields =
            this.descr.fields?.filter(fld => fld.isKey).map(fld => fld.name) ?? [];
        // TODO: переименовать поле дескриптора в keyField !
        // это поле - уникальный идентификатор клиентского датасета,
        this.keyField = descr.keyField?.trim();
        if (!this.keyField)
            this.keyField = this.serverKeyFields?.length ? this.serverKeyFields[0] : '';
        this.activeFields = descr.activeFields;
        this.noSelectAfterOpen = descr.noSelectAfterOpen; // после открытия не активировать "текущую запись"
        this.recNo = undefined; // индекс текущей записи
        this.activeRec = undefined; // текущая запись (объект)
        this.cloneRec = undefined; // копия текущей записи (для редактирования)
        this.inEdit = false;
        this.delta = {};
        this.markForDelete = [];
        this.fastFilters = {};
        this.orderBy = [];
        this.state = dsStates.dsInactive;
        this.page = 0;
        this.pageSize = descr.pageSize;
        // массив имен активных фильтров, от которых зависит датасет
        this.masterAFs = [];
        // явно заданный по иерархии master-датасет, может быть только один.
        this.masterDS = undefined;
        // зависимые от полей датасеты-детали
        this.detailDatasets = {};
        this.requestParams = {}; // полный набор параметров, с которыми запрос был отправлен на сервер.
        this.requestCounter = 0;
        this.groupFields = [];
        this.aggrFields = [];

        this.multiSelect = descr.multiSelect;
        this.selectedIDs = [];
        this.filter = new CustomFilter(this);

        EventsStore.addEvents(
            this.guid,
            descr,
            dataStock.formDescr.events?.filter(event => event.dataset === descr.name) || [],
            this.dataStock.ownerPropContainer
        );

        this.actions = ActionStore.addDatasetActions(this);

        makeObservable(this, {
            currentDataChunk: observable,
            setCurrentDataChunk: action,
            activeRec: observable,
            cloneRec: observable,
            setFieldValue: action,
            setActiveRec: action,
            doLoadData: action,
            inEdit: observable,
            edit: action,
            append: action,
            cancelEdit: action,
            localSave: action,
            state: observable,
            setState: action,
            page: observable,
            doSetPage: action,
            selectedIDs: observable,
            setSelectedField: action,
            recCount: computed,
            setData: action,
            addRecord: action,
            setInEdit: action
        });
    }

    get data() {
        return this.currentDataChunk?.data;
    }

    get accum() {
        return this.currentDataChunk?.accum;
    }

    setCurrentDataChunk(chunk) {
        this.post();
        // Обнуляем номер текущей записи для корректного определения в setActiveRec
        this.recNo = undefined;
        this.currentDataChunk = chunk;

        if (this.masterDS) {
            const { masterDS } = this;

            const parentChunk = masterDS.getChunkByRecord(chunk?.key);
            if (parentChunk && parentChunk.key !== masterDS.currentDataChunk?.key)
                masterDS.setCurrentDataChunk(parentChunk);
        }
    }

    getChunkByKey(key) {
        return this.chunkContainer.chunks[key];
    }

    getChunkByRecord(recordId) {
        return Object.keys(this.chunkContainer.chunks)
            .filter(key =>
                this.chunkContainer.chunks[key]?.data.find(
                    record => String(record[this.keyField]) === String(recordId)
                )
            )
            .map(key => this.chunkContainer.chunks[key])[0];
    }

    addRecord(record) {
        return this.data.push(record) - 1;
    }

    async removeRecord(id) {
        if (this.currentDataChunk) {
            this.currentDataChunk.addData(this.data.filter(record => record[this.keyField] !== id));
        }
        if (this.activeRec?.[this.keyField] === id) {
            this.recNo = undefined;
            await this.setActiveRec(0);
        }
    }

    readOptions() {
        this.orderBy = [];
        this.groupFields = [];
        this.aggrFields = [];

        const propObj = this.dataStock.ownerPropContainer.find(this.descr.guid);
        if (!propObj?.options) {
            return;
        }
        const { group, sort, aggregation, configuration } = propObj.options;

        if (configuration?.pageSize) {
            this.pageSize = configuration.pageSize;
        }

        (Array.isArray(sort) ? sort : []).forEach(ord => {
            if (ord.direction) this.orderBy.push(ord);
        });
        (Array.isArray(group?.fields) ? group.fields : []).forEach(grp => {
            this.groupFields.push(grp);
        });
        (Array.isArray(aggregation) ? aggregation : []).forEach(aggr => {
            this.aggrFields.push(aggr);
        });
    }

    hasData() {
        return [dsStates.dsBrowse, dsStates.dsEdit, dsStates.dsAppend].includes(this.state);
    }

    get recCount() {
        return this.data?.length;
    }

    loadData(options) {
        if (options?.forceRefresh) this.currentDataChunk.forceRefresh = true;

        this.taskQueue.addLoadTask([this]).catch(err => console.error(err.message));
    }

    setInEdit(inEdit) {
        this.inEdit = inEdit;
    }

    // вызывается из очереди загрузок
    async doLoadData() {
        try {
            this.setState(dsStates.dsLoading);

            const newData = await this.getDataChunk();
            if (!newData) {
                this.setState(dsStates.dsInactive);
                return;
            }

            let ind = newData.length ? 0 : -1;

            if (this.noSelectAfterOpen) {
                ind = -1;
            } else if (this.requestParams.info?.keepPosition) {
                const id = this.activeRec ? this.#getKeyVal(this.activeRec) : null;
                ind = this.#findRecIndexByID(newData, id);
                if (ind === -1 && newData.length) ind = 0;
            }

            this.setInEdit(false);
            // Очищаем cloneRec и delta для исключения коллизий при повторном чтении данных
            this.cloneRec = undefined;
            this.delta = {};

            this.setActiveRec(ind, { loadDependencies: false }).catch(err =>
                console.error(err.message)
            );

            this.setState(dsStates.dsBrowse);
        } catch (e) {
            this.setState(dsStates.dsError);
            console.error(e);
        } finally {
            await EventsStore.getEvents(this.guid)?.triggerEvent(afterOpen, {
                state: this.state,
                error: this.state === dsStates.dsError,
                data: this.data
            });
        }
    }

    getPropContainer() {
        return this.dataStock.ownerPropContainer;
    }

    setProtectedFieldsHash(key, hash) {
        if (this.cdo?.descr.hashable && hash) {
            this.currentDataChunk.hash[key] = hash;
        }
    }

    getAppendFieldsHash() {
        if (this.cdo?.descr.hashable) {
            const parentID = this.masterDS.getFieldValue(this.masterDS.keyField);
            return this.masterDS.currentDataChunk.hash[parentID];
        }
    }

    getProtectedFieldsHash(hashId) {
        if (this.cdo?.descr.hashable) {
            return this.currentDataChunk.hash[hashId];
        }
    }

    #getMasterParams() {
        const masterParams = {};
        if (this.masterDS) {
            this.descr?.fields
                ?.filter(fldDescr => fldDescr.masterField)
                .forEach(fldDescr => {
                    masterParams[fldDescr.name] = this.masterDS.getFieldValue(fldDescr.masterField);
                });
        }
        return masterParams;
    }

    #getRequestParams(idList) {
        const fastFilters = this.getFastFiltersArray();
        const orderBy = this.getOrderByArray();
        const filters = this.filter.values;

        const parObj = {
            ...this.#getMasterParams(),
            ...this.dataStock.getParams(this.descr.params)
        };

        if (
            this.dataStock.formDescr.isEditor &&
            this.descr.guid === this.dataStock.formDescr.datasets[0]?.guid &&
            this.dataStock.formDescr.datasets[0]?.autoOpen &&
            this.dataStock.formDescr.datasets[0]?.editable
        ) {
            if (
                typeof this.dataStock.extParamVals === 'object' &&
                this.dataStock.extParamVals !== null
            ) {
                idList = this.dataStock.extParamVals[this.keyField]
                    ? [this.dataStock.extParamVals[this.keyField]]
                    : [];
            } else {
                idList = this.dataStock.extParamVals ? [this.dataStock.extParamVals] : [];
            }
        }

        const newParams = {
            formGuid: this.dataStock.formDescr.isGenerated
                ? this.dataStock.formDescr.parentForm?.guid
                : this.dataStock.formGuid,
            parObj,
            fastFilters,
            limit: this.pageSize,
            orderBy,
            idList,
            info: {},
            filters,
            ownerGuid: this.dataStock.formDescr?.ownerGuid // для сабформ
        };

        if (this.dataStock.formDescr.isGenerated || this.dataStock.formDescr.isModified) {
            newParams.linkGuid = this.descr.request?.code;
        } else {
            newParams.datasetName = this.descr.name;
        }

        const oldParams = _.cloneDeep(this.requestParams);
        // Из старых параметров запроса удаляем offset и info
        // для того, чтобы сравнить их с новыми параметрам запроса,
        // у которых эти свойства пока не присвоены,
        // но сохраняем старое значение offset в переменной
        const oldOffset = oldParams.offset;
        delete oldParams.offset;
        oldParams.info = {};

        // Сравниваем старые и новые параметры запроса без учета offset
        const areParamsEqual = _.isEqual(oldParams, newParams);
        if (this.pageSize) {
            // Если параметры не совпадают, то сбрасываем page в 0,
            // т.к. нет смысла сохранять страницу при изменившихся параметрах запроса
            if (!areParamsEqual) {
                this.doSetPage(0);
            }
            newParams.offset = this.pageSize * this.page;
        }

        // Будем пытаться восстановить позицию в датасете,
        // если параметры запроса не изменились, включая offset
        newParams.info.keepPosition =
            areParamsEqual && (oldOffset || 0) === (newParams.offset || 0);
        this.requestCounter++;
        newParams.info.requestCounter = this.requestCounter;

        this.requestParams = newParams;
        return newParams;
    }

    async getDataChunk(idList) {
        const params = this.#getRequestParams(idList);
        return this.chunkContainer.getData(params);
    }

    async getDataByIdList(idList) {
        try {
            return await this.getDataChunk(idList);
        } catch (e) {
            this.setState(dsStates.dsError);
            console.error(e);
        }
    }

    setActiveFilters(sourceFld, init = false) {
        this.activeFields.forEach(aFld => {
            if (aFld.afName) {
                // если поле источник не задано -> устанавливаем все активные фильтры
                // если поле задано - все активные фильтры этого поля (их может быть несколько)
                if (!sourceFld || sourceFld === aFld.fieldName) {
                    DataStore.AF.setAF(
                        aFld.afName,
                        this.activeRec ? this.getFieldValue(aFld.fieldName) : null,
                        { init },
                        this.dataStock.ownerPropContainer
                    );
                }
            }
        });
    }

    makeEmptyActiveRec() {
        if (this.activeRec) {
            const activeRec = { ...this.activeRec };
            if (activeRec?.[this.keyField] < 0) {
                for (let field in activeRec) {
                    if (activeRec.hasOwnProperty(field) && field !== this.keyField) {
                        activeRec[field] = null;
                    }
                }
            }

            return activeRec;
        }

        return undefined;
    }

    /**
     *
     * @param ind
     * @param [options]
     * @param [callback] - функция обратного вызова для выбора текущей записи в датасетах загруженных CDO
     * @returns {Promise<import('../forms/interfaces').SimpleObject | undefined>}
     */
    async setActiveRec(ind, options, callback) {
        if (this.recNo !== undefined && ind === this.recNo) {
            // Если выставлять текущую запись не нужно, то коллбэк тоже вызываем, чтобы исключить зависания функции
            callback && callback();
            return this.activeRec;
        }

        // Вызываем событие, предшествующее переключению текущей записи, если выбор записи не первичный
        if (this.activeRec && !options?.skipCheck) {
            // переделать на forceCheck
            const resultArr = await EventsStore.getEvents(this.guid)?.triggerEvent(beforeScroll);

            if (resultArr?.some(res => res?.block || res === false || res === 0)) return;
        }

        // Фиксируем изменения
        this.post(options?.keepInEdit);

        this.activeRec = this.data?.length ? this.data[ind] : undefined;
        this.recNo = this.activeRec ? ind : -1;

        DataStore.taskQueue.addFormulaTask({
            taskType: 'onRecordChanged',
            dataStock: this.dataStock,
            datasetName: this.name
        });

        this.setActiveFilters(undefined, options.init);

        if (options?.loadDependencies)
            try {
                // Если текущая запись - новая, то ожидаем асинхронный ответ загрузки зависимостей
                if (options?.append || this.recNo === -1) {
                    await this.taskQueue.loadDependencies(this, callback);
                } else {
                    this.taskQueue
                        .loadDependencies(this, callback)
                        .catch(err => console.error(err.message));
                }
            } catch (err) {
                console.error(err.message);
            }

        await EventsStore.getEvents(this.guid)?.triggerEvent(afterScroll);

        return this.activeRec;
    }

    /**
     * Перемещаю поля из массива в обьект
     * @returns {object}
     */
    getFields() {
        if (typeof this.fields === 'undefined') {
            this.fields = this.descr.fields.reduce((prev, f) => {
                prev[f.name] = f;
                return prev;
            }, {});
        }
        return this.fields;
    }

    /**
     * Получить формат для поля с датой
     *
     * @param {string} dataType
     * @returns {string}
     */
    getDateTimeFormat(dataType) {
        switch (dataType) {
            case 'KRN_DATE':
                return 'DD.MM.YYYY';

            case 'KRN_DATETIME':
                return 'DD.MM.YYYY HH:mm:ss';

            case 'KRN_TIME':
                return 'HH:mm';

            default:
                return 'DD.MM.YYYY HH:mm:ss';
        }
    }

    /**
     * Получить значение
     * @param {object} rec
     * @param {string} name
     * @returns {string|number|null}
     */
    getValue(rec, name) {
        let value = rec[name];
        if (!value) {
            return value;
        }

        const datasetField = this.getFieldDescr(name);
        // Поле отсутсвует в dataset
        if (!datasetField) {
            return value;
        }

        if (datasetField.hashable) {
            value = typeof value === 'object' ? value.val : value;
        }

        return value;
    }

    /**
     * Получить строковое значение
     * @param {object} rec
     * @param {string} name
     * @returns {string}
     */
    getValueStr(rec, name) {
        return String(this.getValue(rec, name));
    }

    /**
     * Отображение значения
     * @param {object} rec
     * @param {string} name
     * @param {boolean} raw - вернуть изначальное значение поля
     * @return {string}
     */
    displayValue(rec, name, raw = false) {
        const value = this.getValue(rec, name);
        if (typeof value === 'undefined' || value === null) {
            return '';
        }

        const datasetField = this.getFieldDescr(name);
        if (!datasetField || raw) {
            return String(value);
        }

        if (datasetField.lookupData) return this.getLookupDataVal(value, datasetField.lookupData);

        switch (datasetField.dataType) {
            case 'KRN_DATE':
            case 'KRN_DATETIME':
            case 'KRN_TIME':
                return dateToString(value, this.getDateTimeFormat(datasetField.dataType));
            case 'KRN_LOGICAL':
                return value ? ConfigurationStore.content.controls.grid.yes : '';
            case 'KRN_MONEY':
                return `${value} р.`;
            case 'KRN_STRING_INTERNATIONAL':
            case 'KRN_MEMO_INTERNATIONAL':
                return getLocalizedString(value);
            default:
                return String(value);
        }
    }

    getLookupDataVal(key, lookupData) {
        const data = JSON.parse(lookupData);
        const object = data?.find(obj => `${obj.key}` === `${key}`);

        return (object?.val || object?.value) ?? key;
    }

    // получаем строковoe значений ключевого поля, переданой записи Dataset'а
    // запись не обязательно активная, поэтому сюда передается ссылкка на нее.
    #getKeyVal(rec) {
        return this.getValueStr(rec, this.keyField);
    }

    #findRecIndexByID(data, id) {
        return data.findIndex(rec => String(id) === this.#getKeyVal(rec));
    }

    findById(id, forceDepsReload = false) {
        const ind = this.#findRecIndexByID(this.data, id);

        if (forceDepsReload || ind !== this.recNo) {
            this.setActiveRec(ind, { loadDependencies: true });
        }
    }

    nextRecord() {
        if (this.recNo < this.data.length - 1) {
            this.setActiveRec(this.recNo + 1, { loadDependencies: true });
        }
    }

    prevRecord() {
        if (this.recNo > 0) {
            this.setActiveRec(this.recNo - 1, { loadDependencies: true });
        }
    }

    /**
     * Зафиксировать изменения
     */
    post(keepInEdit = false) {
        if (this.inEdit) {
            this.saveDelta();
            this.localSave(keepInEdit);
        }
    }

    /** @typedef {{[record]: Object, [keepInEdit]: boolean, [loadDependencies]: boolean, [append]: boolean, [init]: boolean}} AppendOptions */

    /**
     * Добавить новую запись
     * @param options {AppendOptions} - параметры новой записи
     */
    async append(options = {}) {
        if (!this.descr?.fields) throw new Error('Нет описания полей dataset');

        const record =
            options.record ||
            this.descr.fields.reduce((res, fld) => {
                res[fld.name] = fld.isKey ? this.dataStock.getPhantomId() : null;
                return res;
            }, {});

        // record.phantom = true;
        const idx = this.addRecord(record);
        this.setState(dsStates.dsAppend);
        // append - флаг переключения активной записи на только что созданную
        await this.setActiveRec(idx, { ...{ loadDependencies: true, append: true }, ...options });

        const clone = _.cloneDeep(record);
        this.descr.fields
            .filter(fld => fld.masterField)
            .forEach(fld => (clone[fld.name] = this.masterDS?.getFieldValue(fld.masterField)));

        this.cloneRec = clone;

        this.setInEdit(true);

        await DataStore.taskQueue.addFormulaTask({
            taskType: 'setDefaults',
            dataStock: this.dataStock,
            datasetName: this.name
        });

        if (!options.record)
            await Promise.all(
                Object.keys(this.detailDatasets).map(async key => {
                    const dsList = this.detailDatasets[key];

                    await Promise.all(
                        dsList.map(async ds => {
                            if (
                                ds.descr.editType === 'extension' ||
                                ds.descr.editType === 'subform'
                            ) {
                                await ds.append();
                            }
                        })
                    );
                })
            );

        this.accum.init();

        await EventsStore.getEvents(this.guid)?.triggerEvent(onNewRecord);

        return idx;
    }

    view() {
        if (!this.activeRec) {
            this.setActiveRec(0, { loadDependencies: false }).catch(err =>
                console.error(err.message)
            );
        }
    }

    //  РЕДАКТИРОВАНИЕ ЗАПИСИ
    edit() {
        if (!this.activeRec) {
            this.setActiveRec(0, { loadDependencies: false }).catch(err =>
                console.error(err.message)
            );
        }

        if (this.activeRec && !this.inEdit && !this.cloneRec) {
            this.cloneRec = _.clone(this.activeRec);

            const enabledObj = {};

            for (const fld in this.cloneRec) {
                enabledObj[fld] = true;
            }

            this.setInEdit(true);
            this.setState(dsStates.dsEdit);

            DataStore.taskQueue.addFormulaTask({
                taskType: 'onBeginEdit',
                dataStock: this.dataStock,
                datasetName: this.name
            });
        }
    }

    async cancelEdit(remove = false) {
        if (this.inEdit) {
            // Отменяем добавление
            if (remove && this.cloneRec[this.keyField] < 0) {
                await this.delete();
            }
            // Обнуляем редактирование
            this.cloneRec = undefined;
            this.setInEdit(false);
            this.setState(dsStates.dsBrowse);
        }
    }

    saveDelta() {
        if (this.inEdit) {
            const keyFld = this.cloneRec?.[this.keyField];

            if (keyFld) {
                if (this.delta[keyFld]) {
                    this.delta[keyFld].clone = _.clone(this.cloneRec);
                } else {
                    this.delta[keyFld] = {
                        clone: _.clone(this.cloneRec),
                        source:
                            keyFld < 0 && this.editable
                                ? this.makeEmptyActiveRec()
                                : _.clone(this.activeRec)
                    };
                }
            }
        }
    }

    async delete() {
        if (this.activeRec) {
            await this.cancelEdit();

            // TODO: временное решение
            this.cloneRec = _.clone(this.activeRec);

            if (!this.masterDS) {
                this.markForDelete.push(this.cloneRec);
            } else {
                const delKey = this.cloneRec[this.keyField];

                if (delKey < 0 && delKey > -100000) {
                    if (this.delta[delKey]) delete this.delta[delKey];
                } else {
                    this.markForDelete.push(this.cloneRec);
                }
                await this.removeRecord(delKey);
            }
        }
    }

    getFieldValue(fldName) {
        const rec = this.inEdit ? this.cloneRec : this.activeRec;
        return rec ? rec[fldName] : null;
    }

    addRecordToMultipleField(fldName) {
        if (this.inEdit) {
            this.cloneRec[fldName] = this.cloneRec[fldName]
                ? [...this.cloneRec[fldName], { val: '' }]
                : [{ val: '' }, { val: '' }];
        } else {
            console.error(`Dataset ${this.name} not in edit mode`);
        }
    }

    removeRecordFromMultipleField(fldName, index) {
        if (this.inEdit && this.cloneRec[fldName]?.length) {
            const tmpCloneRec = _.cloneDeep(this.cloneRec[fldName]);
            tmpCloneRec.splice(index, 1);
            if (!tmpCloneRec.length) tmpCloneRec.push({ val: '' });
            this.cloneRec[fldName] = tmpCloneRec;
        } else {
            console.error(`Dataset ${this.name} not in edit mode`);
        }
    }

    setMultipleFieldValue(fldName, val, index = 0) {
        const tmpCloneRec = _.cloneDeep(this.cloneRec[fldName]) ?? [];
        tmpCloneRec[index] = { ...tmpCloneRec[index], ...{ val } };
        this.cloneRec[fldName] = tmpCloneRec;
    }

    /**
     * Устанавливает значение поля в наборе данных.
     *
     * @param {string} fieldName - Имя поля.
     * @param {*} [val=null] - Опционально. Значение поля. По умолчанию null.
     * @param {number} [index] - Опционально. Индекс поля, если оно является множественным.
     * @returns {boolean} - Возвращает true, если значение поля было успешно установлено, и false в противном случае.
     */
    setFieldValue(fieldName, val = null, index) {
        if (!this.inEdit) {
            console.error(`Dataset ${this.name} not in edit mode`);
            return false;
        }

        const fldDescr = this.getFieldDescr(fieldName);
        if (fldDescr?.multiple) {
            // Установка значения для множественного поля
            this.setMultipleFieldValue(fieldName, val, index);
        } else {
            // Установка значения для одиночного поля
            this.cloneRec[fieldName] = val;
            this.setActiveFilters(fieldName);
            DataStore.taskQueue
                .addFormulaTask({
                    taskType: 'onFieldModified',
                    dataStock: this.dataStock,
                    datasetName: this.name,
                    fieldName
                })
                .catch(err => console.error(err.message));
        }

        // Запускаем события afterDataChange
        EventsStore.getEvents(this.guid)?.triggerEvent(afterDataChange, {
            fieldName,
            value: val
        });

        return true;
    }

    /**
     * Асинхронная функция-обертка. Заполняет значения поля в датасете с учётом событий onDataChange
     *
     * @param {string} fieldName - Имя поля устанавливаемого значения.
     * @param {*} [value] - Опционально. Значение поля. По умолчанию null.
     * @param {number} [index] - Опционально. Индекс поля, если оно является множественным.
     * @returns {Promise<boolean>} - Промис, который разрешается в true, если триггер выполнен успешно, и в false в противном случае.
     */
    async trySetFieldValue(fieldName, value = null, index) {
        // Получаем события для текущего экземпляра датасета
        const events = EventsStore.getEvents(this.guid);

        // Запускаем события onDataChange, если они существуют
        if (events) {
            const result = await events?.triggerEvent(onDataChange, {
                fieldName,
                value
            });
            // Выявляем блокирующие события. Такими являются те события, выполнение скрипта которых возвращает false в явном виде
            const interrupted = result?.reduce(
                (interrupt, allow) => !(allow === true || allow === undefined) || interrupt,
                false
            );

            if (interrupted) {
                return false;
            }
        }

        return this.setFieldValue(fieldName, value, index);
    }

    /**
     *
     * @param name {string}
     * @return {boolean|*}
     */
    getEnabledStatus(name) {
        // поле, не определенное в дескрипторе датасета, не может редактироваться.
        const enabled =
            this.dataStock.ownerPropContainer.getProperty(`${this.name}.${name}`, 'enabled') ??
            false;
        return this.inEdit && enabled;
    }

    getOrderByDirection(fieldName) {
        const order = this.orderBy.find(ord => ord.fieldName === fieldName);
        return order ? order.direction : '';
    }

    changeDirection(direction) {
        if (direction === '') {
            return 'asc';
        }

        if (direction === 'asc') {
            return 'desc';
        }

        return '';
    }

    changeOrderBy(fieldName) {
        if (fieldName) {
            let ind = this.orderBy.findIndex(ord => ord.fieldName === fieldName);

            if (ind === -1) {
                this.orderBy.unshift({ fieldName, direction: '' });
                ind = 0;
            }

            const ord = this.orderBy[ind];

            ord.direction = this.changeDirection(ord.direction);

            // устанавливаем поле первым попорядку в массиве orderBy
            if (ind > 0) {
                const temp = ord;

                this.orderBy[ind] = this.orderBy[0];
                this.orderBy[0] = temp;
            }
        }
    }

    getOrderByArray() {
        const orderBy = this.orderBy.filter(ord => ord.direction);

        if (this.groupFields.length) {
            // Вставляем поля группировки в начало массива сортировки
            return [
                // Сначала те, которые есть в сортировке
                ...this.groupFields
                    .filter(gr => !!orderBy.find(ord => ord.fieldName === gr))
                    .map(gr => orderBy.find(ord => ord.fieldName === gr)),
                // Затем свободные поля группировки
                ...this.groupFields
                    .filter(gr => !orderBy.find(ord => ord.fieldName === gr))
                    .map(fieldName => ({ fieldName, direction: 'asc' })),
                // В конце остальные поля сортировки за исключением тех, которые задействованы в группировках
                ...orderBy.filter(ord => !this.groupFields.includes(ord.fieldName))
            ];
        }

        return orderBy;
    }

    getFastFiltersArray() {
        const arr = [];
        Object.values(this.fastFilters).forEach(ffObj => {
            if (ffObj.value) {
                const value = ['KRN_NUMERIC', 'KRN_INTEGER', 'KRN_AUTOINC'].includes(
                    this.fields[ffObj.fieldName]?.dataType
                )
                    ? +ffObj.value || 0
                    : ffObj.value;
                arr.push({ ...ffObj, value });
            }
        });

        return arr;
    }

    // запись измененных данных обратно в датасет (не в БД!)
    localSave(keepInEdit = false) {
        if (this.inEdit) {
            for (const fld in this.cloneRec) {
                this.activeRec[fld] = this.cloneRec[fld];
            }
            if (!keepInEdit) {
                this.setInEdit(false);
                this.cloneRec = undefined;
                this.setState(dsStates.dsBrowse);
            }
        }
    }

    doSetPage(page) {
        this.page = page;
    }

    nextPage(pageDir = 1) {
        this.doSetPage(this.page + pageDir);
        this.loadData();
    }

    setPage(pageNo) {
        this.doSetPage(pageNo);
        this.loadData();
    }

    setState(state) {
        this.state = state;
    }

    getMaster() {
        return this.dataStock && this.descr
            ? this.dataStock.getDatasetObj(this.descr.masterDatasetName)
            : null;
    }

    addDependentDataset(masterField, ds) {
        if (ds === this) return;

        const fieldDatasets = this.detailDatasets[masterField];
        if (fieldDatasets) {
            if (!fieldDatasets.includes(ds)) {
                fieldDatasets.push(ds);
            }
        } else {
            this.detailDatasets[masterField] = [ds];
        }
    }

    getFieldDescr(name) {
        return this.getFields()[name];
    }

    /**
     * Добавить ключи множественного выбора записей в гриде
     *
     * @param {Object} keyObj - ключи выделяемой записи
     * @param {boolean} checked - флаг выделения
     */
    setSelectedField(keyObj, checked) {
        if (checked && !this.getIsSelected(keyObj)) {
            this.selectedIDs.push(keyObj);
        } else {
            this.selectedIDs = this.selectedIDs.filter(obj => !_.isEqual(obj, keyObj));
        }
    }

    /**
     * Проверить выделение записи грида
     *
     * @param {Object} keyObj - проверяемые ключи записи
     * @returns {boolean}
     */
    getIsSelected(keyObj) {
        return this.selectedIDs.map(obj => JSON.stringify(obj)).includes(JSON.stringify(keyObj));
    }

    getServerKeyFields() {
        return this.descr.fields.filter(fld => fld.isKey).map(fld => fld.name);
    }

    getAppendKeys() {
        const parentId = this.currentDataChunk.key;
        const key = this.descr.fields.find(field => field.isKey)?.name || '__ID';
        const parentKey =
            this.descr.fields.find(field => field.isKey)?.masterField || '__PARENT_ID';

        return { key, parentId, parentKey };
    }

    /**
     * Подкинуть данные
     * @param {object[]} data
     */
    setData(data) {}

    /**
     * Проверка существования записи родительского датасета
     *
     * @returns {boolean}
     */
    checkMasterDSSaved = () => {
        if (!this.descr.saveMasterFirst) return true;

        const { masterDS } = this;

        if (!masterDS) return true;

        return masterDS.cloneRec?.[masterDS.keyField] > 0;
    };

    /**
     * Проверка заполнения несистемных полей
     *
     * @returns {boolean}
     */
    hasUsefulData = () => {
        if (this.getFieldValue(this.keyField) < 0) {
            return (
                this.descr.fields
                    // проверяются только несистемные поля с потенциально полезной нагрузкой
                    .filter(fld => !(fld.isKey || fld.masterField || fld.protected))
                    .reduce((acc, fld) => {
                        const val = this.getFieldValue(fld.name);

                        if (fld.dataType === 'KRN_ARRAY' || Array.isArray(val)) return !val?.length;

                        return acc || !(val === null || val === undefined || val === '');
                    }, false)
            );
        }

        return true;
    };

    /**
     * Вычисление необходимости сохранения данных датасета
     *
     * @returns {boolean}
     */
    needToSave = () => {
        // для главного датасета и датасета без явного указания типа данные всегда подлежат сохранению
        if (!this.editType || this.editType === 'root') return true;

        // для датасета, помеченного обязательным, данные всегда подлежат сохранению
        if (this.descr?.required) return true;

        // для датасета-расширителя проверяются заполнение несистемных полей
        if (this.editType === 'extension') {
            return this.hasUsefulData();
        }

        return true;
    };

    checkUnsavedChanges = () => {
        this.post(true);

        if (Object.keys(this.delta)?.length) {
            for (let keyVal in this.delta) {
                const delta = this.delta[keyVal];

                for (const fld in delta.clone) {
                    if (delta.clone[fld] !== delta.source[fld]) {
                        return true;
                    }
                }
            }
        }

        return false;
    };

    unregister = () => {
        ActionStore.removeActions(this.guid);
    };
}
