import React, { ReactElement } from 'react';

import FlowChart from './FlowChart';
import TextFields from './TextFields';
import Scheduler from './Scheduler';

type LinkType = {
    name: string;
    link: string;
    exact: boolean;
    component: ReactElement;
};

export default [
    {
        name: 'Флоучарт',
        link: 'flowchart',
        exact: true,
        component: <FlowChart />
    },
    {
        name: 'Текстовые поля',
        link: 'textfields',
        exact: true,
        component: <TextFields />
    },
    {
        name: 'Scheduler',
        link: 'scheduler',
        exact: true,
        component: <Scheduler />
    }
] as LinkType[];
