import React, { FunctionComponent, MutableRefObject, useMemo, useRef } from 'react';

import ReactFlow, { MiniMap, Controls, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';

import useLayout from './layout';
import DirectionSwitch from './DirectionSwitch';

const snapGrid = [20, 20];

interface FlowChartType {
    render?: 'elk' | 'dagre';
    initNodes: any[];
    initEdges: any[];
    selectedNode?: string;
    zoom?: number;
    fitChart?: boolean;
    initDirection?: 'top-bottom' | 'left-right';
    showMap?: boolean;
    showControls?: boolean;
    directionSwitcher?: boolean;
    onNodeClick?: any;
    onSelectionChange?: any;
    NodeFlow: any;
}

const FlowChart: FunctionComponent<FlowChartType> = props => {
    const {
        render = 'elk',
        initNodes,
        initEdges,
        selectedNode = '',
        initDirection = 'top-bottom',
        showMap = false,
        showControls = false,
        directionSwitcher = false,
        zoom = 1,
        fitChart = true,
        onNodeClick,
        onSelectionChange,
        NodeFlow
    } = props;
    const wrapperRef = useRef(null) as MutableRefObject<any>;
    const nodeTypes = useMemo(() => ({ selectorNode: NodeFlow }), [NodeFlow]);

    const {
        visible,
        nodes,
        onNodesChange,
        edges,
        onEdgesChange,
        defaultViewport,
        onConnect,
        direction,
        handleChangeDirection,
        handleInit
    } = useLayout(
        initNodes,
        initEdges,
        selectedNode,
        render,
        initDirection,
        zoom,
        wrapperRef.current
    );

    return (
        <div ref={wrapperRef} style={{ position: 'absolute', height: '100%', width: '100%' }}>
            {directionSwitcher ? (
                <DirectionSwitch
                    checked={direction === 'top-bottom'}
                    handleChange={handleChangeDirection}
                />
            ) : null}
            {nodes.length === 0 || !wrapperRef.current ? null : (
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    snapToGrid
                    snapGrid={snapGrid as [number, number]}
                    defaultViewport={defaultViewport}
                    attributionPosition="bottom-left"
                    // fitView={fitChart}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    elementsSelectable={false}
                    onNodeClick={onNodeClick}
                    onSelectionChange={onSelectionChange}
                    onInit={handleInit}
                    style={{ display: visible ? 'block' : 'none' }}
                >
                    {showMap ? <MiniMap /> : null}
                    {showControls ? <Controls showInteractive={false} /> : null}
                </ReactFlow>
            )}
        </div>
    );
};

const FlowWithProvider: FunctionComponent<FlowChartType> = props => (
    <ReactFlowProvider>
        <FlowChart {...props} />
    </ReactFlowProvider>
);

export default FlowWithProvider;
