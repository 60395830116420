const langEng = {
    descriptor: {
        get: 'Getting descriptor...',
        validator: 'Getting Validator...'
    },
    data: {
        lazyLoading: 'Loading...',
        loading: 'Loading data...',
        error: 'Loading error',
        noDataset: 'No dataset selected',
        noData: 'No data',
        choiceRecord: 'Choice record for edit',
        noEditor: 'There is no suitable form-editor',
        saveMasterFirst: 'For adding new record you need to save master record first'
    },
    buttons: {
        add: 'Add',
        view: 'View',
        edit: 'Edit',
        change: 'Change',
        delete: 'Delete',
        ok: 'Ok',
        cancel: 'Cancel',
        refresh: 'Refresh',
        options: 'Options',
        clear: 'Clear',
        sync: 'Sync',
        save: 'Save',
        editMode: 'Edit mode',
        viewMode: 'View mode',
        yes: 'Yes',
        no: 'No'
    },
    glossary: {
        public: 'Public',
        private: 'Private'
    },
    grid: {
        filter: 'Filter',
        editorName: 'Confirmation',
        delConfirmText: 'Delete record?',
        yes: 'Yes'
    },
    panel: {
        fullscreenOpen: 'Expand',
        fullscreenClose: 'Minimize'
    },
    pdf: {
        error: "Can't load PDF file",
        noData: 'No PDF file specified'
    },
    lookup: {
        incorrectLink: 'Incorrect resource link'
    },
    imageDraw: {
        setImage: 'Upload an image'
    },
    taskTree: {
        rootNode: 'Tasks and works',
        complexTask: 'Complex task',
        emptyTree: 'No data to build a tree'
    },
    internationalDialog: {
        translation: 'Translation',
        rus: 'Russian',
        fra: 'French',
        spa: 'Spanish',
        engh: 'English'
    },
    fileUploader: {
        select: 'Choice file',
        add: 'Add file',
        delete: 'Delete file'
    },
    date: {
        incorrect: 'Incorrect data'
    },
    fastFilter: {
        equals: 'equal to',
        notEquals: 'not equal',
        greater: 'greater than',
        less: 'less than',
        greaterEquals: 'greater than or equal to',
        lessEquals: 'less than or equal to',
        begins: 'begins with',
        contains: 'contains',
        ends: 'ends',
        notContains: 'does not contain'
    }
};

export default langEng;
