import { makeObservable, observable, action } from 'mobx';

import { dsStates } from './customDataset';

import DataStore from '../store/dataStore';
import Dataset from './customDataset';

import DataStock from './DataStock';
import FormulaCalculator from 'formulas/formula-calculator';

enum TaskLoadStates {
    waiting,
    loading,
    ready,
    error
}

export type FormulaTaskTypes = 'onRecordChanged' | 'onBeginEdit' | 'onFieldModified' | 'onFormShow';

export type FormulaTask = {
    taskType: FormulaTaskTypes;
    dataStock?: DataStock;
    calculators?: FormulaCalculator[];
    datasetName?: string;
    fieldName?: string;
};

// инкапсулирует датасет для его загрузки
class TaskLoadElem {
    ds: Dataset;
    state: TaskLoadStates;
    parents: TaskLoadElem[];

    constructor(ds: Dataset) {
        this.state = TaskLoadStates.waiting;
        this.ds = ds;
        this.ds.setState(dsStates.dsWaiting);

        // элементы, которые должны быть полностью загружены,
        // для того чтобы можно было загрузить данный  датасет,
        // в общем случае это не один "родитель"
        this.parents = [];
    }

    canLoad() {
        let res = true;
        for (let i = 0; i < this.parents.length; i++) {
            // не можем загружать, если хотя бы
            // один родительский датасет не готов к загрузке
            if (this.parents[i].state !== TaskLoadStates.ready) {
                res = false;
                break;
            }
        }
        return res;
    }

    isBlocked() {
        let res = false;
        for (let i = 0; i < this.parents.length; i++) {
            // загрузка заблокирована, если хотя бы
            // один родительский датасет загрузился с ошибкой
            if (this.parents[i].state === TaskLoadStates.error) {
                res = true;
                break;
            }
        }
        return res;
    }

    async load() {
        this.state = TaskLoadStates.loading;
        try {
            await this.ds.doLoadData();
            this.state =
                this.ds.state === dsStates.dsError ? TaskLoadStates.error : TaskLoadStates.ready;
        } catch (e) {
            this.state = TaskLoadStates.error;
        }
    }
}

// собирает и обрабатывает датасеты, которые должны быть загружены единой цепочкой
// например, мастер + детали + детали деталей
class LoadTaskContainer {
    #timerId?: number;
    #stopFlag: boolean;
    taskQueue: TaskQueue;
    callback?: Function;
    key: string;
    tasks: TaskLoadElem[];

    constructor(taskQueue: TaskQueue, key: string, callback?: Function) {
        this.taskQueue = taskQueue;
        this.key = key;
        this.callback = callback;
        this.#stopFlag = false;
        this.tasks = [];
    }

    initByDatasets(arrDS: Dataset[]) {
        this.#addLoadTasks(arrDS);
    }

    #findTaskElem(ds: Dataset) {
        return this.tasks.find(task => task.ds === ds);
    }

    #addLoadTasks(arrDS: Dataset[], parent?: TaskLoadElem) {
        arrDS.forEach(ds => {
            let el = this.#findTaskElem(ds);
            if (!el) {
                el = new TaskLoadElem(ds);
                this.tasks.push(el);
            }
            if (parent) {
                if (!el.parents.includes(parent)) el.parents.push(parent);
            }

            const deps = this.taskQueue.getDependentDatasets(ds);
            if (deps.length > 0) this.#addLoadTasks(deps, el);
        });
    }

    collectState() {
        const res: Record<string, TaskLoadStates> = {};
        this.tasks.forEach(task => (res[task.ds.name] = task.state));
        return res;
    }

    step() {
        let allLoaded = true;
        for (let i = 0; i < this.tasks.length; i++) {
            const task = this.tasks[i];
            if (![TaskLoadStates.ready, TaskLoadStates.error].includes(task.state))
                allLoaded = false;
            if (task.state === TaskLoadStates.waiting) {
                if (task.canLoad()) {
                    task.load();
                } else if (task.isBlocked()) task.state = TaskLoadStates.error;
            }
        }
        this.taskQueue.setState(this.collectState());

        return allLoaded;
    }

    run() {
        return new Promise((resolve, reject) => {
            let counter = 600;
            let interval = 100;
            this.step();
            this.#timerId = window.setInterval(() => {
                counter--;
                if (!counter) reject('timeout');
                if (this.#stopFlag) {
                    clearInterval(this.#timerId);
                    resolve('stopped');
                }
                if (this.step()) {
                    this.stop();
                    resolve('done');
                }
            }, interval);
        });
    }

    stop() {
        this.#stopFlag = true;
    }
}

class TaskQueue {
    inTask: boolean;
    state: Record<string, TaskLoadStates>;
    #arrLoadTasks: LoadTaskContainer[];
    #arrFormulaTasks: FormulaTask[];
    #isFormulaTasksBlocked: boolean;

    constructor() {
        this.inTask = false;
        this.state = {};
        this.#arrLoadTasks = [];
        this.#arrFormulaTasks = [];
        this.#isFormulaTasksBlocked = false;

        makeObservable(this, {
            inTask: observable,
            setInTask: action,
            state: observable,
            setState: action
        });
    }

    setInTask(val: boolean) {
        this.inTask = val;
    }

    setState(state: Record<string, TaskLoadStates>) {
        this.state = state;
    }

    getState() {
        return this.state;
    }

    blockFormulaTasks() {
        this.#isFormulaTasksBlocked = true;
    }

    unblockFormulaTasks() {
        this.#isFormulaTasksBlocked = false;
    }

    // ищем все зависимые датасеты
    getDependentDatasets(ds: Dataset) {
        // зависимые по полям
        let arrRes: Dataset[] = [];

        for (const fld in ds.detailDatasets) {
            arrRes = arrRes.concat((ds.detailDatasets as Record<string, Dataset[]>)[fld]);
        }

        return Array.from(new Set(arrRes));
    }

    #getLoadTaskKey(arrDS: Dataset[]): string {
        return arrDS.map(ds => ds.guid).join(',');
    }

    async addLoadTask(arrDS: Dataset[], callback?: Function) {
        const key = this.#getLoadTaskKey(arrDS);
        if (!this.#arrLoadTasks.find(t => t.key === key)) {
            const cont = new LoadTaskContainer(this, key, callback);
            cont.initByDatasets(arrDS);
            this.#arrLoadTasks.push(cont);
            return this.#doTasks();
        }
    }

    async loadDependencies(ds: Dataset, callback?: Function) {
        const arrDS = this.getDependentDatasets(ds);
        if (arrDS?.length > 0) {
            return this.addLoadTask(arrDS, callback);
        }

        // На случай отсутствия в описателе, содержащем CDO, свободных датасетов
        if (callback) return await callback();
    }

    addFormulaTask(task: FormulaTask) {
        if (this.#isFormulaTasksBlocked) return Promise.resolve();
        this.#arrFormulaTasks.push(task);
        return this.#doTasks();
    }

    async #doTasks() {
        if (this.inTask) {
            return;
        } else {
            this.setInTask(true);
            try {
                while (this.#arrLoadTasks.length || this.#arrFormulaTasks.length) {
                    while (this.#arrFormulaTasks.length) {
                        const task = this.#arrFormulaTasks[0];
                        let calculators: FormulaCalculator[];
                        if (task.calculators) {
                            calculators = task.calculators;
                        } else if (task.dataStock) {
                            calculators = DataStore.getCalculatorsByDataStock(task.dataStock);
                        } else {
                            calculators = DataStore.calculators;
                        }

                        if (!calculators.length) {
                            this.#arrFormulaTasks.shift();
                        } else {
                            for (let i = 0; i < calculators.length; i++) {
                                const calculator = calculators[i];

                                await calculator.doFormulaTask(task);
                            }
                            this.#arrFormulaTasks.shift();
                        }
                    }

                    if (this.#arrLoadTasks.length) {
                        const cont = this.#arrLoadTasks.shift();
                        if (cont) {
                            await cont.run();
                            if (cont.callback) await cont.callback();
                        }
                    }
                }
            } finally {
                this.setInTask(false);
            }
        }
    }
}

export default TaskQueue;
