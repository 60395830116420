import moment from 'moment';

const firstDay = moment().startOf('week').format('YYYY-MM-DD 00:00:00.000');
const secondDay = moment(firstDay).add(1, 'days').format('YYYY-MM-DD 00:00:00.000');
const thirdDay = moment(firstDay).add(2, 'days').format('YYYY-MM-DD 00:00:00.000');
const fourthDay = moment(firstDay).add(3, 'days').format('YYYY-MM-DD 00:00:00.000');
const fifthDay = moment(firstDay).add(4, 'days').format('YYYY-MM-DD 00:00:00.000');

const model = [
    {
        id: 1,
        name: 'Тест',
        start: 830,
        end: 1730,
        duration: 30,
        subjects: [
            { id: 6, name: 'Иванов Петр Сидорович', duration: 15 },
            { id: 77, name: 'Царева Елена Викторовна', duration: 15 },
            { id: 210, name: 'Лорие Зоя Викторовна' },
            { id: 237, name: 'Кастранова Эльмира Олеговна' }
        ]
    },
    {
        id: 2,
        name: 'Тест 2',
        start: 830,
        end: 1730,
        duration: 30,
        subjects: [
            { id: 210, name: 'Лорие Зоя Викторовна' },
            { id: 237, name: 'Кастранова Эльмира Олеговна' }
        ]
    }
];

const data = [
    // first day
    {
        id: 1,
        ownerId: 6,
        date: firstDay,
        hour: 1100,
        duration: 20,
        dateStart: `${moment(firstDay).format('YYYY-MM-DD')} 11:00:00.000`,
        dateEnd: `${moment(firstDay).format('YYYY-MM-DD')} 11:20:00.000`,
        title: 'Иванов П.И.'
    },
    {
        id: 2,
        ownerId: 6,
        date: firstDay,
        hour: 1100,
        duration: 20,
        title: 'Петров И.С.'
    },
    {
        id: 3,
        ownerId: 6,
        date: firstDay,
        hour: 830,
        duration: 10,
        title: 'Лиходеев В.П.'
    },
    {
        id: 4,
        ownerId: 6,
        date: firstDay,
        hour: 1600,
        duration: 20,
        title: 'Лоботрясов П.С.'
    },
    {
        id: 5,
        ownerId: 6,
        date: firstDay,
        hour: 1620,
        duration: 40,
        title: 'Подхалимов Ю.Ю.'
    },
    {
        id: 6,
        ownerId: 6,
        date: firstDay,
        hour: 1710,
        duration: 25,
        title: 'Измаилов Р.С.'
    },
    {
        id: 7,
        ownerId: 77,
        date: firstDay,
        hour: 1000,
        duration: 15,
        title: 'Рукосуев М.М.'
    },
    {
        id: 8,
        ownerId: 77,
        date: firstDay,
        hour: 1115,
        duration: 15,
        title: 'Ломакин П.П.'
    },
    {
        id: 9,
        ownerId: 77,
        date: firstDay,
        hour: 1130,
        duration: 15,
        title: 'Курочкин Р.С.'
    },
    // second day
    {
        id: 10,
        ownerId: 6,
        date: secondDay,
        hour: 1200,
        duration: 20,
        title: 'Нижнинов П.С.'
    },
    {
        id: 26,
        ownerId: 6,
        date: secondDay,
        hour: 1150,
        duration: 20,
        title: 'Серединин П.С.'
    },
    {
        id: 11,
        ownerId: 6,
        date: secondDay,
        hour: 1320,
        duration: 40,
        title: 'Подхалимов Ю.Ю.'
    },
    {
        id: 27,
        ownerId: 6,
        date: secondDay,
        hour: 1330,
        duration: 15,
        title: 'Справин Ю.Ю.'
    },
    {
        id: 28,
        ownerId: 6,
        date: secondDay,
        hour: 1130,
        duration: 30,
        title: 'Верховцев П.С.'
    },
    {
        id: 12,
        ownerId: 6,
        date: secondDay,
        hour: 1510,
        duration: 25,
        title: 'Измаилов Р.С.'
    },
    // third day
    {
        id: 13,
        ownerId: 6,
        date: thirdDay,
        hour: 1000,
        duration: 15,
        title: 'Измайлов П.С.'
    },
    {
        id: 14,
        ownerId: 6,
        date: thirdDay,
        hour: 1045,
        duration: 10,
        title: 'Михайлов Ю.Ю.'
    },
    {
        id: 15,
        ownerId: 6,
        date: thirdDay,
        hour: 1300,
        duration: 15,
        title: 'Петров Р.С.'
    },
    // fourth day
    {
        id: 16,
        ownerId: 6,
        date: fourthDay,
        hour: 830,
        duration: 15,
        title: 'Лугачёв П.С.'
    },
    {
        id: 17,
        ownerId: 6,
        date: fourthDay,
        hour: 845,
        duration: 15,
        title: 'Романов Ю.Ю.'
    },
    {
        id: 18,
        ownerId: 6,
        date: fourthDay,
        hour: 900,
        duration: 15,
        title: 'Сидоров Р.С.'
    },
    // fifth day
    {
        id: 19,
        ownerId: 6,
        date: fifthDay,
        hour: 910,
        duration: 23,
        title: 'Лоботрясов П.С.'
    },
    {
        id: 20,
        ownerId: 6,
        date: fifthDay,
        hour: 1100,
        duration: 15,
        title: 'Лоботрясов П.С.'
    },
    {
        id: 21,
        ownerId: 6,
        date: fifthDay,
        hour: 1200,
        duration: 15,
        title: 'Карапулькин Ю.Ю.'
    },
    {
        id: 22,
        ownerId: 6,
        date: fifthDay,
        hour: 1315,
        duration: 15,
        title: 'Ломоносов Р.С.'
    },
    {
        id: 23,
        ownerId: 77,
        date: fifthDay,
        hour: 1115,
        duration: 15,
        title: 'Лугачёв П.С.'
    },
    {
        id: 24,
        ownerId: 77,
        date: fifthDay,
        hour: 830,
        duration: 15,
        title: 'Романов Ю.Ю.'
    },
    {
        id: 25,
        ownerId: 77,
        date: fifthDay,
        hour: 1000,
        duration: 15,
        title: 'Сидоров Р.С.'
    },
    {
        id: 1000,
        ownerId: 210,
        type: 'exceptional',
        dateStart: `${moment(firstDay).format('YYYY-MM-DD')} 00:00:00.000`,
        dateEnd: `${moment(secondDay).format('YYYY-MM-DD')} 16:00:00.000`,
        color: '#b23131',
        title: 'Конференция',
        maxWidth: 20
    },
    {
        id: 1002,
        ownerId: 210,
        type: 'exceptional',
        dateStart: `${moment(firstDay).format('YYYY-MM-DD')} 09:00:00.000`,
        dateEnd: `${moment(firstDay).format('YYYY-MM-DD')} 16:00:00.000`,
        color: '#20912b',
        title: 'Совещание'
    },
    {
        id: 1001,
        ownerId: 237,
        type: 'exceptional',
        dateStart: `${moment(firstDay).format('YYYY-MM-DD')} 09:00:00.000`,
        dateEnd: `${moment(firstDay).format('YYYY-MM-DD')} 16:00:00.000`,
        color: '#4b53ce',
        title: 'Совещание'
    }
];

export { model, data };
