import {
    FunctionComponent,
    ChangeEvent,
    useEffect,
    useState,
    useRef,
    MutableRefObject
} from 'react';
import { observer } from 'mobx-react';

import { Box, Grid, FormControlLabel, Checkbox, Paper } from '@mui/material';

import useInputMask from 'hooks/inputMask';

import ConfigurationStore from 'store/configurationStore';

import SimpleEdit from 'forms/controls/DBEdit/DBSimpleEdit/SimpleEdit';

import { Configuration } from '../index';

interface PropsType {
    configuration: Configuration;
    setConfiguration: (configuration: { [key: string]: undefined | boolean | number }) => unknown;
}

const GridConfiguration: FunctionComponent<PropsType> = observer(
    ({ configuration, setConfiguration }) => {
        const content = ConfigurationStore.content.resource.gridProps.configuration;
        const { hideRefresh, hideToolbar, pageSize } = configuration;

        const inputRef = useRef(undefined) as MutableRefObject<HTMLInputElement | undefined>;

        const [refresh, setRefresh] = useState(!!hideRefresh);
        const [toolbar, setToolbar] = useState(!!hideToolbar);
        const [pSize, setPSize] = useState(pageSize?.toString());

        const handleRefreshChange = () => {
            setRefresh(!refresh);
        };

        const handleToolbarChange = () => {
            setToolbar(!toolbar);
        };

        const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
            setPSize(event.target.value);
        };

        useEffect(() => setRefresh(!!hideRefresh), [hideRefresh]);

        useEffect(() => setToolbar(!!hideToolbar), [hideToolbar]);

        useEffect(() => {
            setConfiguration({
                hideRefresh: refresh,
                hideToolbar: toolbar,
                pageSize: Number(pSize) || undefined
            });
        }, [setConfiguration, refresh, toolbar, pSize]);

        useInputMask(inputRef, pSize || '', '/\\d*/');

        return (
            <Grid container direction="column" sx={{ height: '100%' }}>
                <Grid item flex="1 1">
                    <Paper elevation={0} variant="outlined" sx={{ height: '100%' }}>
                        <Box sx={{ p: 4 }}>
                            <Grid container direction="column">
                                <Grid item pl={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={refresh}
                                                onChange={handleRefreshChange}
                                            />
                                        }
                                        label={content.hideRefresh}
                                    />
                                </Grid>
                                <Grid item pl={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={toolbar}
                                                onChange={handleToolbarChange}
                                            />
                                        }
                                        label={content.hideToolbar}
                                    />
                                </Grid>
                                <Grid item>
                                    <SimpleEdit
                                        visible
                                        inputRef={inputRef}
                                        label="Размер страницы"
                                        guid="page-size"
                                        defaultValue={pSize}
                                        handleOnChange={handlePageSizeChange}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
);

export default GridConfiguration;
