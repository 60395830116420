import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Grid } from '@mui/material';
import classNames from 'classnames';

// import CustomForm from './CustomForm';
import Form from 'forms/controls/Form';
import MainForm from 'forms/controls/MainForm';
import DataGrid from 'forms/controls/DataGrid';
import DBEdit from 'forms/controls/DBEdit';
import DBSimpleEdit from 'forms/controls/DBEdit/DBSimpleEdit';
import DBDate from 'forms/controls/DBEdit/DBDate';
import DBLookup from 'forms/controls/DBLookup';
import DBPdfViewer from 'forms/controls/DBPdfViewer';
import DBIframe from 'forms/controls/DBIframe';
import DBDocumentContainer from 'forms/controls/DBDocumentContainer';
import DBTinyMCE from 'forms/controls/DBTinyMCE';
import DBCheckBox from 'forms/controls/DBEdit/DBCheckBox';
import DBComboBox from 'forms/controls/DBEdit/DBComboBox';
import DBRadioGroup from 'forms/controls/DBRadioGroup';
import DBRadioGroupButton from 'forms/controls/DBRadioGroup/RadioGroupButton';
import DBRadioButton from 'forms/controls/DBRadioButton';
import DBTreeEdit from 'forms/controls/DBTreeEdit';
import DBLabel from 'forms/controls/DBLabel';
import Label from 'forms/controls/Label';
import SVGIcon from 'forms/controls/SVGIcon';
import FilterControl from 'forms/controls/Filter/FilterControl';
import DBImage from 'forms/controls/DBImage';
import DBImageDraw from 'forms/controls/DBImageDraw';
import FileUploader from 'forms/controls/FileUploader';
import DatasetTreeControl from 'forms/controls/DatasetTree/DatasetTreeControl';
import Menu from 'forms/controls/Menu';
import MenuItem from 'forms/controls/Menu/MenuItem';
import PageControl from 'forms/controls/PageControl/PageControl';
import Panel from 'forms/controls/Panel';
import Container from 'forms/controls/Container';
import IterableContainer from 'forms/controls/IterableContainer';
import EditorContainer from 'forms/controls/EditorContainer/EditorContainer';
import Chart from 'forms/controls/Chart';
import ScriptButton from 'forms/controls/ScriptButton/ScriptButton';
import Toolbar from 'forms/controls/Toolbar';
import ToolbarButton from 'forms/controls/Toolbar/Buttons/ToolbarButton';
import Splitter from 'forms/controls/Splitter';
import DBMultipleEdit from 'forms/controls/DBMultipleEdit';
import TelemedChat from 'forms/controls/TelemedChat';
import DBScheduler from 'forms/controls/DBScheduler';
import DBSelfHirarchyTree from 'forms/controls/DBSelfHierarchyTree';

// TeamTask controls
import TaskTree from 'forms/ttControls/TaskTree';
import DBFlowChart from 'forms/ttControls/FlowChart';
import ScheduleChart from 'forms/ttControls/ScheduleChart';
import DBExecTime from 'forms/ttControls/ExecTime';

import { getGridContainer, getClasses } from 'forms/form-utils';

import { ControlDataRowType, ControlType, GridDataRow, DrawComponentType } from 'forms/interfaces';

import GridWrapper from 'components/utils/GridWrapper/GridWrapper';

interface ControlTreeProps extends ControlDataRowType {
    formDescr: ControlType;
    dropBreakpoint?: boolean;
    dataRow?: GridDataRow;
}

const ControlTree: FunctionComponent<ControlTreeProps> = observer(
    ({ formDescr, propContainer, dataRow, dropBreakpoint = false }) => {
        // т.к. вызываю эту функцию в FilterControl там в контексте нет setActiveCtrlDescr, то передаю
        const drawComponent: DrawComponentType = (descr, componentDataRow, dropBP) => {
            //
            const drawChildren = (chldDescr: any, chldDataRow?: any) => {
                const gridContainer = getGridContainer(descr);

                return (
                    <Grid
                        id={chldDescr.guid}
                        key={chldDescr.guid}
                        item
                        container
                        height="100%"
                        alignContent="flex-start"
                        {...gridContainer}
                    >
                        {chldDescr?.controls
                            ? chldDescr?.controls.map((cld: ControlType) =>
                                  drawComponent(cld, chldDataRow)
                              )
                            : null}
                    </Grid>
                );
            };

            if (descr.type === 'wuiForm')
                return (
                    <Form descr={descr} propContainer={propContainer}>
                        {drawChildren(descr)}
                    </Form>
                );

            const getControl = () => {
                switch (descr.type) {
                    case 'mainForm': {
                        return <MainForm descr={descr} propContainer={propContainer} />;
                    }

                    case 'container': {
                        return descr.iterable ? (
                            <IterableContainer descr={descr} propContainer={propContainer} />
                        ) : (
                            <Container descr={descr} propContainer={propContainer} />
                        );
                    }

                    case 'editorContainer': {
                        return <EditorContainer descr={descr} propContainer={propContainer} />;
                    }

                    case 'panel': {
                        return (
                            <Panel
                                descr={descr}
                                drawChildren={drawChildren}
                                propContainer={propContainer}
                                dataRow={componentDataRow}
                            />
                        );
                    }

                    case 'splitter': {
                        return <Splitter descr={descr} propContainer={propContainer} />;
                    }

                    case 'pageControl':
                        return (
                            <PageControl
                                descr={descr}
                                propContainer={propContainer}
                                drawChildren={drawChildren}
                            />
                        );

                    case 'page':
                        return drawChildren(descr);
                    case 'taskButton':
                    case 'scriptButton':
                        return (
                            <ScriptButton
                                descr={descr}
                                propContainer={propContainer}
                                dataRow={componentDataRow}
                            />
                        );
                    case 'dbRadioGroup':
                        return (
                            <DBRadioGroup
                                descr={descr}
                                propContainer={propContainer}
                                drawChildren={drawChildren}
                            />
                        );
                    case 'radioGroupButton':
                        return <DBRadioGroupButton descr={descr} propContainer={propContainer} />;
                    case 'dbRadioButton':
                        return <DBRadioButton descr={descr} propContainer={propContainer} />;

                    case 'dbLabel':
                        return (
                            <DBLabel
                                descr={descr}
                                propContainer={propContainer}
                                dataRow={componentDataRow}
                            />
                        );
                    case 'dbImage':
                        return (
                            <DBImage
                                descr={descr}
                                propContainer={propContainer}
                                dataRow={componentDataRow}
                            />
                        );
                    case 'dbImageDraw':
                        return <DBImageDraw descr={descr} propContainer={propContainer} />;
                    case 'fileUploader':
                        return <FileUploader descr={descr} propContainer={propContainer} />;
                    case 'datasetTree':
                        return <DatasetTreeControl descr={descr} propContainer={propContainer} />;
                    case 'dbEdit':
                        return (
                            <DBEdit
                                descr={descr}
                                propContainer={propContainer}
                                dataRow={componentDataRow}
                            />
                        );
                    case 'dbMultipleEdit':
                        return (
                            <DBMultipleEdit
                                descr={descr}
                                dataRow={dataRow}
                                propContainer={propContainer}
                            />
                        );
                    case 'dbTextEdit':
                    case 'dbMemoEdit':
                    case 'dbNumEdit':
                        return <DBSimpleEdit descr={descr} propContainer={propContainer} />;
                    case 'dbExecTimeEdit':
                        return <DBExecTime descr={descr} propContainer={propContainer} />;
                    case 'dbDate':
                    case 'dbDateTime':
                        return (
                            <DBDate
                                descr={descr}
                                propContainer={propContainer}
                                dataRow={componentDataRow}
                            />
                        );
                    case 'dbLookup':
                        return (
                            <DBLookup
                                descr={descr}
                                propContainer={propContainer}
                                dataRow={componentDataRow}
                            />
                        );
                    case 'dbLookupCombobox':
                        return (
                            <DBComboBox
                                descr={descr}
                                propContainer={propContainer}
                                dataRow={componentDataRow}
                            />
                        );
                    case 'dbTreeEdit':
                        return <DBTreeEdit descr={descr} propContainer={propContainer} />;
                    case 'dbCheckBox':
                        return (
                            <DBCheckBox
                                descr={descr}
                                propContainer={propContainer}
                                dataRow={componentDataRow}
                            />
                        );
                    case 'dbComboBox':
                        return (
                            <DBComboBox
                                descr={descr}
                                propContainer={propContainer}
                                dataRow={componentDataRow}
                            />
                        );
                    case 'dbPdfViewer':
                        return <DBPdfViewer descr={descr} propContainer={propContainer} />;
                    case 'dbIframe':
                        return <DBIframe descr={descr} propContainer={propContainer} />;
                    case 'dbHtmlEditor':
                        return <DBTinyMCE descr={descr} propContainer={propContainer} />;
                    case 'dbDocumentContainer':
                    case 'dbHtmlViewer':
                    case 'dbRtfViewer':
                        return (
                            <DBDocumentContainer
                                descr={descr}
                                propContainer={propContainer}
                                dataRow={componentDataRow}
                            />
                        );
                    case 'breakLine':
                        return <hr />;
                    case 'label':
                        return (
                            <Label
                                descr={descr}
                                propContainer={propContainer}
                                dataRow={componentDataRow}
                            />
                        );
                    case 'svgIcon':
                        return <SVGIcon descr={descr} propContainer={propContainer} />;
                    case 'grid':
                        return <DataGrid descr={descr} propContainer={propContainer} />;
                    case 'filterControl':
                    case 'filterLookup':
                        return (
                            <FilterControl
                                descr={descr}
                                propContainer={propContainer}
                                drawComponent={drawComponent}
                            />
                        );
                    case 'menu':
                        return (
                            <Menu key={descr.guid} descr={descr} propContainer={propContainer} />
                        );
                    case 'menuItem':
                        return (
                            <MenuItem
                                key={descr.guid}
                                descr={descr}
                                propContainer={propContainer}
                            />
                        );
                    case 'chart':
                        return <Chart descr={descr} propContainer={propContainer} />;

                    case 'dbTaskTree':
                        return (
                            <TaskTree
                                key={descr.guid}
                                descr={descr}
                                propContainer={propContainer}
                            />
                        );

                    case 'dbFlowChart':
                        return <DBFlowChart descr={descr} propContainer={propContainer} />;

                    case 'dbScheduleChart':
                        return <ScheduleChart descr={descr} propContainer={propContainer} />;

                    case 'dbScheduler':
                        return <DBScheduler descr={descr} propContainer={propContainer} />;

                    case 'toolbar':
                        return (
                            <Toolbar key={descr.guid} descr={descr} propContainer={propContainer} />
                        );

                    case 'toolbarButton':
                        return <ToolbarButton descr={descr} propContainer={propContainer} />;

                    case 'telemedChat':
                        return <TelemedChat descr={descr} propContainer={propContainer} />;

                    case 'dbSelfHirarchyTree':
                        return <DBSelfHirarchyTree descr={descr} propContainer={propContainer} />;

                    default: {
                        return <span>Unknown component: {JSON.stringify(descr)}</span>;
                    }
                }
            };

            return (
                <GridWrapper
                    key={descr.guid}
                    descr={descr}
                    propContainer={propContainer}
                    className={classNames('ctrl', getClasses(descr))}
                    dropBreakpoint={dropBP}
                >
                    {getControl()}
                </GridWrapper>
            );
        };

        return drawComponent(formDescr, dataRow, dropBreakpoint);
    }
);

export default ControlTree;
