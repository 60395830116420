import React, { FunctionComponent, ChangeEvent, useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { TextField, InputAdornment, Stack, Select, FormControl } from '@mui/material';
import { Search } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';

import ConfigurationStore from 'store/configurationStore';

interface FastFilterPropsType {
    index: number;
    caption: string;
    value: string;
    dataType?: string;
    ffObj: {
        fieldName: string;
        value: string;
        operator: string;
    };
    setFFObj: (index: number, ffObj: { [key: string]: any }) => void;
    gridFocus?: () => unknown;
}

const FastFilter: FunctionComponent<FastFilterPropsType> = observer(
    ({ index, caption, value, dataType, ffObj, setFFObj, gridFocus }) => {
        const { content } = ConfigurationStore;
        const labels = content.controls.fastFilter as { [key: string]: string };

        const [filterVal, setFilterVal] = useState(value);
        const [filterOperator, setFilterOperator] = useState(ffObj.operator);

        const operatorList = useMemo(() => {
            switch (dataType) {
                case 'KRN_INTEGER':
                case 'KRN_NUMERIC':
                case 'KRN_AUTOINC':
                    return [
                        { value: '=', label: labels.equals },
                        { value: '<>', label: labels.notEquals },
                        { value: '>', label: labels.greater },
                        { value: '<', label: labels.less },
                        { value: '>=', label: labels.greaterEquals },
                        { value: '<=', label: labels.lessEquals }
                    ];

                default:
                    return [
                        { value: 'like *%', label: labels.begins },
                        { value: 'like %*%', label: labels.contains },
                        { value: 'like %*', label: labels.ends },
                        { value: '!like %*%', label: labels.notContains }
                    ];
            }
        }, [dataType]);

        const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setFilterVal(event.target.value);
            setFFObj(index, { ...ffObj, value: event.target.value });
        };

        const handleKeyDown = (event: any) => {
            if (event.key === 'ArrowDown') {
                gridFocus && gridFocus();
            }
        };

        const handleChangeOperator = (event: any) => {
            setFilterOperator(event.target.value);
            setFFObj(index, { ...ffObj, operator: event.target.value });
        };

        useEffect(() => {
            if (!value) setFilterVal('');
        }, [value]);

        return (
            <Stack width="100%" spacing={2} direction="row">
                <FormControl variant="outlined" sx={{ width: 150 }}>
                    <Select value={filterOperator} size="small" onChange={handleChangeOperator}>
                        {operatorList.map((operator, idx) => (
                            <MenuItem key={idx} value={operator.value}>
                                {operator.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    size="small"
                    label={caption}
                    variant="outlined"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    value={filterVal}
                    fullWidth
                    sx={{ minWidth: 170 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>
        );
    }
);

export default FastFilter;
