const langFra = {
    descriptor: {
        get: 'Chargement du descripteur...',
        validator: 'Obtenir un validateur...'
    },
    data: {
        Loading: 'Chargement',
        loading: 'Chargement des données...',
        error: 'Erreur du chargement',
        noDataset: 'Aucun jeu de données sélectionné',
        noData: 'Pas de données',
        choiceRecord: 'Sélectionnez une entrée à modifier',
        noEditor: "Pas de formulaire d'éditeur approprié",
        saveMasterFirst:
            'Pour ajouter un enregistrement, vous devez enregistrer les données du DataSet parent'
    },
    buttons: {
        add: 'Ajouter',
        view: 'Afficher',
        edit: 'Modifier',
        change: 'Modifier',
        delete: 'Supprimer',
        ok: 'Ok',
        cancel: 'Annuler',
        refresh: 'Rafraîchir',
        options: 'Choix',
        clear: 'Effacer',
        sync: 'Synchroniser',
        save: 'Enregistrer',
        editMode: "Mode d'édition",
        viewMode: "Mode d'affichage",
        yes: 'Oui',
        no: 'Pas'
    },
    glossary: {
        public: 'Général',
        private: 'Personnel'
    },
    grid: {
        filter: 'Filtre',
        editorName: 'Confirmation',
        delConfirmText: "Supprimer l'entrée?",
        yes: 'Oui'
    },
    panel: {
        fullscreenOpen: 'Déployer',
        fullscreenClose: 'Rouler'
    },
    pdf: {
        error: 'Impossible de charger le fichier PDF',
        noData: 'Aucun fichier PDF spécifié'
    },
    lookup: {
        incorrectLink: 'Référence de ressource incorrecte'
    },
    imageDraw: {
        setImage: "Télécharger l'image"
    },
    taskTree: {
        rootNode: 'Tâches et travaux',
        complexTask: 'Tâche complexe',
        emptyTree: "Pas de données pour construire l'arbre"
    },
    internationalDialog: {
        translation: 'Traduction',
        rus: 'Russe',
        fra: 'Français',
        spa: 'Espagnol',
        eng: 'Anglais'
    },
    fileUploader: {
        select: 'Sélectionnez un fichier',
        add: 'Ajouter un fichier',
        delete: 'Supprimer le fichier'
    },
    date: {
        incorrect: 'Données incorrectes'
    },
    fastFilter: {
        equals: 'égal à',
        notEquals: 'pas égal',
        greater: 'plus',
        less: 'moins',
        greaterEquals: 'supérieur ou égal à',
        lessEquals: 'inférieur ou égal à',
        begins: 'commence par',
        contains: 'contient',
        ends: 'se termine',
        notContains: 'ne contient pas'
    }
};

export default langFra;
