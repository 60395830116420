import { action, makeObservable, observable } from 'mobx';

import { CDOType, DatasetType, EditModeType, FormType } from 'forms/interfaces';

import DataStock from 'dataObj/DataStock';
import PropContainer from 'dataObj/PropContainer';
import Dataset from 'dataObj/customDataset';
import { ResAccum } from 'dataObj/dataInterfaces';

import { collectDatasetDescriptors } from 'forms/form-utils';
import { confirmDialog } from 'utils/index';

import NotificationStore, { BackdropInstance } from 'store/notificationStore';
import configurationStore from 'store/configurationStore';
import EventsStore from 'store/eventsStore';
import { onSave, onError } from 'store/eventsStore/events';

import { view, save, edit, cancel, getName, getDataStock } from './actions';

const calcEditMode = (formDescr: FormType, editMode?: EditModeType): EditModeType => {
    const { isEditor } = formDescr;

    if (isEditor) {
        return editMode || null;
    }

    if (isEditor && editMode) {
        console.error(`Form "${formDescr.name}" is not editor!`);
        return null;
    }

    return null;
};

const calcAvailableActions = (editMode?: EditModeType) =>
    ['edit', 'create', 'cdoCreate'].includes(editMode || '')
        ? [view, save, edit, cancel, getName, getDataStock]
        : [view, getName, getDataStock];

type RatherForm = {
    name: string;
    guid: string;
    extParamVals?: { [key: string]: string };
    editMode?: EditModeType;
};

const { content } = configurationStore;

/**
 * Класс действий формы
 */
export default class FormActions {
    public type = 'form';

    private formDescr: FormType;
    private isSubForm?: boolean;
    private propContainer: PropContainer;
    private dataStock: DataStock;
    private availableActions?: string[];
    private backdrop?: BackdropInstance | null;

    private handleClose?: () => void;

    @observable editMode: EditModeType;
    @observable validated: boolean;
    @observable isReady: boolean;
    @observable forceReload: boolean;

    @observable ratherForm?: RatherForm;

    constructor(
        formDescr: FormType,
        propContainer: PropContainer,
        handleClose?: () => void,
        editMode?: EditModeType,
        actions?: string[]
    ) {
        this.formDescr = formDescr;
        this.isSubForm = formDescr.isSubForm;
        this.propContainer = propContainer;
        this.dataStock = propContainer.dataStock;
        this.editMode = calcEditMode(formDescr, editMode);
        this.validated = true;
        this.isReady = false;
        this.forceReload = false;
        this.handleClose = handleClose;

        this.availableActions = actions || calcAvailableActions(this.editMode);

        makeObservable(this);
    }

    checkLocalData = async () => {
        const errAccum = { err: [], warn: [] };

        await this.dataStock.checkData(this.propContainer, errAccum);

        if (!errAccum.err.length && !errAccum.warn.length) return true;

        if (errAccum.err.length) {
            NotificationStore.showAlert(`${errAccum.err.concat(errAccum.warn).join('\n')}`);
            return false;
        }

        const confirm = await confirmDialog(
            `${errAccum.warn.join('\n')}\n${String(content.application.messages.saveAnyway)}`
        );
        return confirm;
    };

    @action setBackdrop = (showBackdrop: boolean, timeout?: number) => {
        if (showBackdrop) {
            if (this.backdrop) {
                this.backdrop.updateBackdrop(timeout);
            } else {
                this.backdrop = new BackdropInstance(timeout);
            }
        } else {
            this.backdrop?.clearBackdrop();
        }
    };

    @action setEditMode = (mode?: EditModeType) => {
        this.availableActions = calcAvailableActions(mode);

        this.editMode = mode || null;
    };

    @action save = async () => {
        const eventId = `${this.propContainer.guid ? `${this.propContainer.guid}-` : ''}${
            this.formDescr.guid
        }`;
        const resultArr = await EventsStore.getEvents(eventId)?.triggerEvent(onSave);

        if (resultArr?.some(result => result?.block || result === false || result === 0))
            return false;

        if (this.availableActions?.length && this.availableActions.includes(save)) {
            const resAccum: ResAccum = [];
            const errAccum = { err: [], warn: [] };

            await this.dataStock.checkData(this.propContainer, errAccum);

            let isValid = errAccum.err.length === 0;

            if (errAccum.err.length || errAccum.warn.length) {
                const onErrorResult = await EventsStore.getEvents(eventId)?.triggerEvent(
                    onError,
                    errAccum
                );

                // Если ошибки не были обработаны скриптами запускаем стандартный механизм вызова сообщений об ошибках
                if (!onErrorResult?.length) {
                    if (errAccum.err.length) {
                        NotificationStore.showAlert(
                            `${errAccum.err.concat(errAccum.warn).join('\n')}`
                        );
                    } else {
                        // есть только warn
                        isValid = await confirmDialog(
                            `${errAccum.warn.join('\n\r')}\n\r${String(
                                content.application.messages.saveAnyway
                            )}`
                        );
                    }
                }
            }

            this.setBackdrop(true);
            try {
                if (isValid) {
                    if (this.isSubForm) {
                        const arrDSDescr: DatasetType[] = [];

                        collectDatasetDescriptors(this.formDescr.datasets, arrDSDescr);

                        if (this.formDescr.editDatasetName) {
                            const editDataset = this.propContainer.dataStock.getDatasetObj(
                                this.formDescr.editDatasetName
                            )?.descr;

                            if (editDataset) {
                                collectDatasetDescriptors([editDataset], arrDSDescr);
                            }
                        }

                        arrDSDescr.forEach(dsDescr => {
                            this.dataStock.getDatasetObj(dsDescr.name)?.post();
                        });
                    } else {
                        await this.dataStock.saveData(errAccum, resAccum);
                    }
                }
            } finally {
                this.setBackdrop(false);
            }

            return {
                isValid,
                noReload: this.formDescr.isSubForm,
                resAccum,
                ...errAccum
            };
        }

        NotificationStore.showAlert('Недоступное действие сохранения');
    };

    @action cancel = () => {
        const cancelEdit = (datasets: Dataset[]) => {
            datasets?.forEach((ds: Dataset) => {
                if (ds) {
                    ds.cancelEdit(this.editMode === 'create').catch(err =>
                        console.error(err.message)
                    );
                }
            });
        };

        if (this.availableActions?.length && this.availableActions.includes(cancel)) {
            const checkDatasetEditor = (dsName?: string) => (ds: Dataset) => {
                if (dsName) {
                    return ds.name === dsName;
                }

                return true;
            };

            for (let i = 0; i < this.dataStock.CDOs.length; i++) {
                cancelEdit(
                    this.dataStock.CDOs[i].datasets.filter(
                        checkDatasetEditor(this.formDescr.editDatasetName)
                    )
                );
            }

            cancelEdit(
                this.dataStock
                    .getFreeDatasets()
                    .filter(checkDatasetEditor(this.formDescr.editDatasetName))
            );
        }
    };

    @action edit = async () => {
        const setDSStates = async (arrDS: Dataset[]) => {
            for (let i = 0; i < arrDS.length; i++) {
                const ds = arrDS[i];

                if (this.editMode === 'view') {
                    ds.view();
                } else if (ds.index === 0 || ds.editable) {
                    if (this.editMode === 'create') {
                        // добавление записей необходимо производить строго
                        // последовательно для учета зависимости по иерархии
                        if (ds.cdo) {
                            // В случае CDO добавляем только запись редактируемую сабформой
                            if (
                                ds.index === 0 ||
                                ['root', 'detail'].includes(ds.editType as string)
                            ) {
                                if (this.isSubForm && this.formDescr.editDatasetName === ds.name) {
                                    if (ds.currentDataChunk?.key >= 0) {
                                        return ds.cdo.append(ds);
                                    }
                                    await ds.append();
                                }
                            }
                        } else if (
                            ds.index === 0 ||
                            ['root', 'extension'].includes(ds.editType as string) ||
                            (this.isSubForm && this.formDescr.editDatasetName === ds.name)
                        ) {
                            await ds.append();
                        }
                    }
                    // editMode === 'edit'
                    else if (
                        ds.index === 0 ||
                        ds.editType === 'root' ||
                        (this.isSubForm && this.formDescr.editDatasetName === ds.name) // ?
                    ) {
                        ds.edit();
                    } else if (ds.editType === 'extension') {
                        if (ds.recCount === 0) {
                            await ds.append();
                        } else {
                            ds.edit();
                        }
                    }
                }
            }
        };

        if (
            this.availableActions?.length &&
            this.availableActions.some(avAct => [edit, view].includes(avAct))
        ) {
            // Для сабформы-редактора датасета
            const checkDatasetEditor = (dsName?: string) => (ds: Dataset) => {
                if (dsName) {
                    return ds.name === dsName;
                }

                return true;
            };

            for (let i = 0; i < this.dataStock.CDOs.length; i++) {
                await setDSStates(
                    this.dataStock.CDOs[i].datasets.filter(
                        checkDatasetEditor(this.formDescr.editDatasetName)
                    )
                );
            }
            await setDSStates(
                this.dataStock
                    .getFreeDatasets()
                    .filter(checkDatasetEditor(this.formDescr.editDatasetName))
            );
        }
    };

    @action clearDiff = () => {
        this.dataStock.datasets.forEach(ds => {
            ds.delta = {};
            ds.markForDelete = [];
        });
    };

    @action setValidation = (validate: boolean) => {
        this.validated = validate;
    };

    @action setIsReady = (isReady = true) => {
        this.isReady = isReady;
    };

    @action setForceReload = (reload = true) => {
        this.forceReload = reload;
    };

    @action swapForm = (form: RatherForm) => {
        this.ratherForm = form;
    };

    @action closeForm = () => {
        this.handleClose && this.handleClose();
    };

    getName = () => this.formDescr.caption ?? this.formDescr.name;

    getDataStock = () => this.dataStock;

    hasAction = (checkAction?: string | null) => this.availableActions?.includes(checkAction || '');

    getFormDescr = () => this.formDescr;
}
