import { useEffect } from 'react';

export default function useSaveHotKey(clientFunction: () => void) {
    const saveFunction = (e: KeyboardEvent) => {
        if (e.keyCode === 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
            e.preventDefault();
            clientFunction();
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', saveFunction, false);

        return () => {
            document.removeEventListener('keydown', saveFunction);
        };
    }, []);
}
