import { FunctionComponent, MutableRefObject, useMemo, useRef, useState } from 'react';

import { observer } from 'mobx-react';

import SimpleEdit from 'forms/controls/DBEdit/DBSimpleEdit/SimpleEdit';
import { ControlDataRowType } from 'forms/interfaces';

import useInputMask from 'hooks/inputMask';
import useDBEdit from 'hooks/dbEdit';

import ConfigurationStore from 'store/configurationStore';

import { getLocalizedString } from 'utils/index';

interface PropsType extends ControlDataRowType {
    descr: any;
}

const DBExecTime: FunctionComponent<PropsType> = observer(({ descr, dataRow, propContainer }) => {
    const { guid } = descr;
    const { locale } = ConfigurationStore;

    const {
        dataset,
        fieldName,
        dataVal,
        fieldRequired,
        ctrlEnabled,
        ctrlVisible,
        ctrlCaption,
        onScreen
    } = useDBEdit(descr, propContainer, dataRow);

    const inputRef = useRef(undefined) as MutableRefObject<HTMLInputElement | undefined>;

    const label = useMemo(() => {
        if (ctrlCaption) {
            return getLocalizedString(ctrlCaption);
        }
        return getLocalizedString(descr.label);
    }, [ctrlCaption, descr.label]);

    /**
     * Генерация маски в зависимости от локали
     */
    const ctrlMask = useMemo(() => {
        const makeRegExp = (d: string, h: string, m: string) =>
            `/([0]|[1-9][0-9]?)${d} [0-7]${h} ([0-9]|[0-5][0-9])${m}/`;

        switch (locale) {
            case 'rus':
                return makeRegExp('д', 'ч', 'мин');

            case 'fra':
                return makeRegExp('j', 'h', 'min');

            case 'eng':
            case 'esp':
            default:
                return makeRegExp('d', 'h', 'min');
        }
    }, [locale]);

    /**
     * Преобразование числового значения в текстовое для работы с маской
     *
     * @param val
     */
    const valueToText = (val: number) => {
        if (!val) return '0 _ _';

        const days = Math.floor(val / (8 * 60));
        const hours = Math.floor((val - days * 8 * 60) / 60);
        const minutes = val - days * 8 * 60 - hours * 60;

        return `${days} ${hours} ${minutes}`;
    };

    /**
     * Преобразование текстового значения с маской в числовое
     *
     * @param val
     */
    const textToValue = (val: string) => {
        const [days, hours, minutes] = val
            .replace(/_/g, '0') // заменяем подчеркивания на нули
            .replace(/\D/g, ' ') // убираем буквы
            .replace(/ +(?= )/g, '') // убираем повторяющиеся пробелы
            .split(' '); // разделяем

        return Number(days) * 8 * 60 + Number(hours) * 60 + Number(minutes);
    };

    const [value, setValue] = useState(valueToText(dataVal));
    const { valid } = useInputMask(inputRef, value || '', ctrlMask);

    const handleOnChange = (event: any) => {
        setValue(event.target.value);

        dataset
            ?.trySetFieldValue(fieldName, textToValue(event.target.value))
            .catch(err => console.error(err.message));
    };

    return (
        <SimpleEdit
            guid={guid}
            inputRef={inputRef}
            defaultValue={value || ''}
            label={label}
            disabled={!ctrlEnabled}
            visible={ctrlVisible}
            fieldRequired={fieldRequired}
            error={(fieldRequired && !value) || !valid}
            handleOnChange={handleOnChange}
            onScreen={onScreen}
            masked
        />
    );
});

export default DBExecTime;
