import React from 'react';

import EventsStore from 'store/eventsStore';
import { onClose } from 'store/eventsStore/events';
import PropContainer from 'dataObj/PropContainer';
import SimpleDialog, { SimpleDialogPropsType } from 'components/Feedback/SimpleDialog';

interface ModalPropsType extends SimpleDialogPropsType {
    formGuid: string;
    handleClose: (state: boolean, result?: any[]) => Promise<void>;
    propContainer?: PropContainer;
    propContainerGuid?: string;
}

const ModalForm = (props: ModalPropsType) => {
    const { formGuid, handleClose, children, propContainerGuid, ...otherProps } = props;

    const modalClose = async (state: boolean) => {
        const resultArr = await EventsStore.getEvents(
            `${propContainerGuid ? `${propContainerGuid}-` : ''}${formGuid}`
        )?.triggerEvent(onClose, {
            success: state
        });

        if (resultArr?.some(result => result?.block || result === false || result === 0)) return;

        await handleClose(state, resultArr);
    };

    const injProps = {
        handleClose: modalClose,
        children
    };

    return <SimpleDialog {...otherProps} {...injProps} />;
};

export default ModalForm;
