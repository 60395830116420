import React, { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Box, IconButton, Toolbar, Typography } from '@mui/material';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';

import MainFormStore from 'store/mainFormStore';

import SVGIcon from 'forms/controls/SVGIcon';

import { ControlPropsType, MainFormType } from 'forms/interfaces';

import HeaderControls from './HeaderControls';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

interface PropsType extends ControlPropsType {
    descr: MainFormType;
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    })
}));

const Header: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { open, defaultWidth, setOpen } = MainFormStore;

    const items = useMemo(() => {
        if (descr.appBar?.length) {
            return descr.appBar;
        }
        return [];
    }, [descr?.guid]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <AppBar key={`app-bar-${descr.guid}`} position="fixed">
            <Toolbar>
                <Box
                    display="flex"
                    alignItems="center"
                    flexGrow={1}
                    minWidth={defaultWidth - 12}
                    maxWidth={defaultWidth - 12}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={open ? handleClose : handleOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            marginLeft: -1.5
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h5"
                        noWrap
                        component="div"
                        sx={{ mr: 'auto', display: 'inline-flex' }}
                    >
                        {descr.icon ? (
                            <SVGIcon
                                descr={descr}
                                propContainer={propContainer}
                                sx={{ m: 'auto', mr: 2, ml: 2 }}
                                fontSize="inherit"
                            />
                        ) : null}
                        {descr?.caption}
                    </Typography>
                </Box>
                <HeaderControls items={items} propContainer={propContainer} />
            </Toolbar>
        </AppBar>
    );
});

export default Header;
