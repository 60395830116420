import { useEffect } from 'react';

import PropContainer from 'dataObj/PropContainer';
import executeScript from 'scripts/script-client';
import ScriptStore from 'store/scriptStore';
import { ParamType } from '../forms/interfaces';

export default function useScriptHandler(
    scriptName: string,
    propContainer: PropContainer,
    params?: ParamType[]
) {
    const { scripts } = propContainer;
    const scriptButtonStore = scripts[scriptName] || new ScriptStore();

    const {
        formActions,
        setForm,
        setFormDescr,
        setCallback,
        setFormName,
        setValues,
        setEditMode,
        setModalMessage,
        setCustomControl
    } = scriptButtonStore;

    useEffect(() => {
        if (formActions.getName) {
            setFormName(formActions.getName());
        }
    }, [formActions]);

    return async function clickHandler(event: { [key: string]: any } = {}) {
        const context = {
            setForm,
            setCallback,
            setValues,
            setEditMode,
            setModalMessage,
            setFormDescr,
            setCustomControl
        };
        return executeScript(propContainer, scriptName, params, null, context, event);
    };
}
