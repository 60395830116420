import { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import ComboBox from 'components/Inputs/ComboBox';

import { InheritHookType } from '../interfaces';

interface PropsType {
    name: string;
    value: string;
    parentGuid: string;
    collection?: boolean;
    inheritor: InheritHookType;
    onChange: any;
}

const PropertyList: FunctionComponent<PropsType> = observer(
    ({ name, value, inheritor, onChange, collection = false, parentGuid }) => {
        const { loading, error, notFound, overlapResource } = inheritor;

        const [options, setOptions] = useState<Array<{ key: string }>>([]);

        useEffect(() => {
            if (overlapResource) {
                const parent = overlapResource.getCtrlBy('guid', parentGuid);

                if (parent?.ctrl) {
                    const opt = Object.keys(parent.ctrl);
                    setOptions(
                        opt
                            .filter(key =>
                                collection
                                    ? Array.isArray(parent.ctrl[key])
                                    : !Array.isArray(parent.ctrl[key])
                            )
                            .map(key => ({ key }))
                    );
                } else setOptions([{ key: '' }]);
            }
        }, [overlapResource, name, value, error, notFound, loading, parentGuid]);

        return (
            <ComboBox
                name={name}
                value={value || ''}
                store={options}
                nameField="key"
                valueField="key"
                onChange={onChange}
                editable
                clearable
                freeSolo={false}
                disabled={error || notFound || loading}
            />
        );
    }
);

export default PropertyList;
