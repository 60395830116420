import { FunctionComponent, useState } from 'react';

import './styles.scss';

import SchedulerLayout from './SchedulerLayout';
import SchedulerControls from './SchedulerControls';

import { SchedulerContainerType } from '../interfaces';

const SchedulerContainer: FunctionComponent<SchedulerContainerType> = ({
    type,
    model,
    dayStartFrom,
    dayStopTo,
    subject,
    data,
    eventClickedHandler,
    newEventHandler,
    setSubject,
    controls,
    fitSlots,
    eventItemTemplateResolver
}) => {
    const [currentDate, setCurrentDate] = useState(new Date());

    return (
        <div className="scheduler-container">
            <SchedulerLayout
                type={type}
                model={model}
                dayStartFrom={dayStartFrom}
                dayStopTo={dayStopTo}
                subject={subject}
                data={data}
                eventClickedHandler={eventClickedHandler}
                newEventHandler={newEventHandler}
                currentDate={currentDate}
                controls={controls}
                fitSlots={fitSlots}
                setSubject={setSubject}
                eventItemTemplateResolver={eventItemTemplateResolver}
            />
            {controls ? (
                <SchedulerControls currentDate={currentDate} setDate={setCurrentDate} />
            ) : null}
        </div>
    );
};

export default SchedulerContainer;
