import React, { FunctionComponent, useMemo } from 'react';

import { Box } from '@mui/material';
import { Event, SchedulerData } from 'react-big-scheduler';

import CustomDataset from 'dataObj/customDataset';

import PeriodSwitcher from './PeriodSwitcher';
import ViewTypeSwitcher from './ViewTypeSwitcher';
import UserFilter from './UserFilter';

interface PropsType {
    dataset: CustomDataset;
    schedulerData: any;
    events: Event[];
    updateData: (data: SchedulerData) => void;
    setStartDate: (startDate: string) => void;
}

const Toolbar: FunctionComponent<PropsType> = ({
    dataset,
    schedulerData,
    updateData,
    setStartDate,
    events
}) => {
    const userFilter = useMemo(
        () => (dataset?.descr?.flUserFilter ? <UserFilter dataset={dataset} /> : null),
        [dataset?.descr?.flUserFilter]
    );
    return (
        <Box sx={{ p: 5, display: 'flex' }}>
            <PeriodSwitcher
                schedulerData={schedulerData}
                events={events}
                updateData={updateData}
                setStartDate={setStartDate}
            />
            {userFilter}
            <ViewTypeSwitcher
                schedulerData={schedulerData}
                events={events}
                updateData={updateData}
                filter={!!userFilter}
            />
        </Box>
    );
};

export default Toolbar;
