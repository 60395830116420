import { makeObservable, observable, action } from 'mobx';

import PropContainer from 'dataObj/PropContainer';

import DataStore from 'store/dataStore';
import ConfigurationStore from 'store/configurationStore';

const AF_LOCAL_PREFIX = '#';
const AF_STORED_PREFIX = '@';

export default class AFContainer {
    readonly data: any;

    constructor() {
        this.data = {};

        this.#initStoredAF();

        makeObservable(this, {
            data: observable,
            setAF: action
        });
    }

    unregisterLocalAF(guid: string): void {
        const keysToDelete: string[] = [];
        for (let key in this.data) {
            if (key.startsWith(guid)) {
                keysToDelete.push(key);
            }
        }
        keysToDelete.forEach(key => delete this.data[key]);
    }

    #getBasePropContainer(propContainer?: PropContainer): PropContainer | undefined {
        let pc = propContainer;
        while (pc) {
            if (pc.isLocalAFContainer) {
                return pc;
            }
            pc = pc.sourcePropContainer as PropContainer;
        }
    }

    #setStoredAF(afName: string, value: any) {
        const storedAF = JSON.parse(localStorage.getItem('storedAF') || '{}');
        const storedAFKey = `STORED_AF_BINDING_${ConfigurationStore.userInfo?.bindingId}_${afName}`;

        storedAF[storedAFKey] = value;

        localStorage.setItem('storedAF', JSON.stringify(storedAF));
    }

    #initStoredAF() {
        const storedAF = JSON.parse(localStorage.getItem('storedAF') || '{}');

        Object.keys(storedAF).forEach(key => {
            const [bindingId, afName] = key.split('@');
            if (bindingId === `STORED_AF_BINDING_${ConfigurationStore.userInfo?.bindingId}_`) {
                this.data[`@${afName}`] = storedAF[key];
            }
        });
    }

    setAF(afName: string, value: any, options: any = {}, propContainer?: PropContainer) {
        let realAFName = afName;
        let basePC;

        // Если вызывается в init-режиме, то пропускаем установку значений активных фильтров
        if (options.init) return;

        // Фиксируем локальный AF в propContainer формы-носителя AF
        if (afName.startsWith(AF_LOCAL_PREFIX)) {
            basePC = this.#getBasePropContainer(propContainer);
            if (basePC) {
                realAFName = `${basePC.guid}.${afName}`;
            } else
                throw new Error(
                    `AFContainer: No AFContainer-form found for local AF-filter in Form: ${propContainer?.formName}`
                );
        }

        // Фиксируем хранимый AF в localStorage
        if (afName.startsWith(AF_STORED_PREFIX)) {
            this.#setStoredAF(afName, value);
        }

        if (this.data[realAFName] !== value) {
            this.data[realAFName] = value;
            if (!options?.noDistribute) DataStore.distributeAF(afName, basePC);
        }
    }

    getAF(afName: string, propContainer?: PropContainer) {
        if (afName === 'null') return null;
        if (afName === 'undefined') return undefined;

        let realAFName = afName;

        // Получаем локальный AF
        if (afName.startsWith(AF_LOCAL_PREFIX)) {
            const basePC = this.#getBasePropContainer(propContainer);
            if (basePC) {
                realAFName = `${basePC.guid}.${afName}`;
            } else
                throw new Error(
                    `AFContainer: No AFContainer-form found for local AF-filter in Form: ${propContainer?.formName}`
                );
        }
        return realAFName ? this.data[realAFName] : undefined;
    }
}
