/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { TreeItem } from '@mui/x-tree-view';

import DataStock from 'dataObj/DataStock';
import { getLocalizedString } from 'utils/index';

// eslint-disable-next-line import/no-cycle
import DatasetTreeNode from './DatasetTreeNode';

interface PropsType {
    nodeDescr: any;
    parentKey?: number | string;
    dataStock: DataStock;
    setActiveFilter: (value: string) => unknown;
    handleNodeSelect: (value: string) => unknown;
}

const DatasetTreeItem: FunctionComponent<PropsType> = observer(
    ({ nodeDescr, parentKey, dataStock, setActiveFilter, handleNodeSelect }) => {
        const { labelField, datasetName, guid, nodes } = nodeDescr;
        const dataset = dataStock.getDatasetObj(datasetName);

        const { keyField, state, chunkContainer } = dataset;
        let { data } = dataset;

        if (chunkContainer.cacheType === 'master') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data = chunkContainer.chunks[parentKey]?.data;
        }

        const selectRec = (id: any, nodeId: any) => {
            if (dataset.descr.cacheType === 'master') {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const chunk = dataset.chunkContainer.chunks[parentKey];
                if (chunk !== dataset.currentDataChunk) {
                    dataset.setCurrentDataChunk(chunk);
                }
            }
            dataset.findById(id, true);

            setActiveFilter(dataset.name);
            handleNodeSelect(nodeId);
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return data
            ? data.map((rec: any) => {
                  const label = getLocalizedString(rec[labelField]);
                  return (
                      <TreeItem
                          key={`${guid}_${rec[keyField]}`}
                          nodeId={`${guid}_${rec[keyField]}`}
                          label={label}
                          onClick={() => selectRec(rec[keyField], `${guid}_${rec[keyField]}`)}
                      >
                          {nodes?.length > 0
                              ? nodes.map((node: any) => (
                                    <DatasetTreeNode
                                        key={`${node.guid}_${guid}_${rec[keyField]}`}
                                        node={node}
                                        parentKey={rec[keyField]}
                                        nodeKey={`${node.guid}_${guid}_${rec[keyField]}`}
                                        dataStock={dataStock}
                                        setActiveFilter={setActiveFilter}
                                        handleNodeSelect={handleNodeSelect}
                                    />
                                ))
                              : null}
                      </TreeItem>
                  );
              })
            : null;
    }
);

export default DatasetTreeItem;
