import { ElementType, useMemo } from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { TreeItemProps, treeItemClasses } from '@mui/x-tree-view';
import Typography from '@mui/material/Typography';
import { FormatListNumbered } from '@mui/icons-material';

import { SvgIconProps } from '@mui/material/SvgIcon';

import { calcWorkDays, getLocalizedString } from 'utils/index';

import CustomTreeItem from './CustomTreeItem';
import ParallelIcon from './ParallelIcon';

import { StateType, TTNodeType } from './interface';

type StyledTreeItemProps = TreeItemProps & {
    parent?: boolean;
    node?: TTNodeType;
    bgColor?: string;
    color?: string;
    labelIcon?: ElementType<SvgIconProps>;
    labelInfo?: string;
    labelText?: string;
    calcIcon?: (state: StateType) => any;
};

const TreeItemRoot = styled(CustomTreeItem)(({ theme }) => ({
    color: theme.palette.text.primary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightRegular,
        width: 'auto',
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightMedium
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)'
        },
        '&.Mui-selected': {
            p: { color: theme.palette.primary.main }
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: theme.palette.text.primary
        }
    }
}));

const StyledTreeItem = (props: StyledTreeItemProps) => {
    const {
        parent = false,
        node,
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        labelText,
        calcIcon,
        ...other
    } = props;

    const style: { [key: string]: string | undefined } = {};

    if (color) style['--tree-view-color'] = color;
    if (bgColor) style['--tree-view-bg-color'] = bgColor;

    const calcExecModeIcon = (exec_mode: 'P' | 'C'): any =>
        exec_mode === 'P' ? ParallelIcon : FormatListNumbered;

    const getWorkTime = (time: number) => {
        const workDays = calcWorkDays(time);
        const { days, hours, minutes } = workDays;

        const daysLabel = days ? `${days} дн. ` : '';
        const hoursLabel = hours ? `${hours} ч. ` : '';
        const minutesLabel = minutes ? `${minutes} мин. ` : '';

        return `${daysLabel}${hoursLabel}${minutesLabel}`;
    };

    const generateWorkDaysLabel = (plan: number, elapsed: number, state: string) => {
        if (!plan) return '';

        if (elapsed || elapsed === 0) {
            const remain = plan - elapsed;

            if (state === 'F') return `(потрачено: ${getWorkTime(elapsed)})`;

            return `(потрачено: ${getWorkTime(elapsed)}, осталось: ${getWorkTime(remain)})`;
        }

        return `(нужно: ${getWorkTime(plan)})`;
    };

    const generateParentTaskLabel = () => {
        if (node) {
            const taskType = getLocalizedString(node.label);
            const taskNumber = node.task_number ? `[№ ${node.task_number}]` : null;
            const taskName = getLocalizedString(node.task_name);

            return `${taskType}${taskNumber || taskName ? ':' : ''} ${taskNumber || ''} ${
                taskName || ''
            }`;
        }
    };

    const generateTaskLabel = () => {
        if (node) {
            const label = getLocalizedString(node.label ?? '');
            const taskNumber = node.task_number ? `[№ ${node.task_number}]` : '';
            const taskName = getLocalizedString(node.task_name ?? '');
            const executor =
                node.surname && node.name
                    ? `${getLocalizedString(node.surname)} ${getLocalizedString(node.name)}`
                    : null;

            return (
                `${label}${taskNumber || taskName ? `: ` : ''}${taskNumber} ${taskName} ${
                    executor ? `- ${executor}` : ''
                }` || ''
            );
        }

        return;
    };

    const generateWorkLabel = () => {
        if (node) {
            const label = getLocalizedString(node.label ?? '');
            const taskName = getLocalizedString(node.task_name ?? '');
            const executor = `${getLocalizedString(node.surname)} ${getLocalizedString(node.name)}`;
            const workTime = generateWorkDaysLabel(
                node.time_by_executor,
                node.elapsed_time,
                node.state
            );

            return `${node.node_num}. ${label} (${taskName}) - ${executor} ${workTime}`;
        }

        return;
    };

    const text = useMemo(() => {
        if (node) {
            if (parent) return generateParentTaskLabel();

            return node.node_type === 'T' ? generateTaskLabel() : generateWorkLabel();
        }

        return null;
    }, [node]);

    const Icon = useMemo(() => {
        if (node && calcIcon)
            return node.node_type === 'T' &&
                !node.label?.trim() &&
                !node.task_number &&
                !node.task_name
                ? calcExecModeIcon(node.exec_mode)
                : calcIcon(node.state);

        return null;
    }, []);

    return (
        <TreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
                    <Box
                        component={LabelIcon || Icon}
                        color="inherit"
                        sx={{ mr: 1 }}
                        fontSize="medium"
                    />
                    <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                        {labelText || text}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            style={style}
            {...other}
        />
    );
};

export default StyledTreeItem;
