import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import useOnScreen from 'hooks/onScreen';

interface TabPanelProps {
    children?: ReactNode;
    index: any;
    value: any;
    forceRender?: boolean;
}

const TabPanel: FunctionComponent<TabPanelProps> = ({
    children,
    value,
    index,
    forceRender = false,
    ...other
}) => {
    const [needRender, setNeedRender] = useState(forceRender);

    const tabRef = useRef(null);

    const onScreen = useOnScreen(tabRef);

    useEffect(() => {
        if (onScreen && !needRender) setNeedRender(value === index);
    }, [onScreen, value, index]);

    return (
        <div
            ref={tabRef}
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index as number}`}
            aria-labelledby={`wrapped-tab-${index as number}`}
            style={{
                height: '100%',
                boxSizing: 'border-box',
                paddingTop: '8px'
            }}
            {...other}
        >
            {needRender ? children : null}
        </div>
    );
};

export default TabPanel;
