import React, {
    FunctionComponent,
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import { Box } from '@mui/material';
import { Save, Clear, Edit } from '@mui/icons-material';

import ConfigurationStore from 'store/configurationStore';
import NotificationStore from 'store/notificationStore';
import Loading from 'components/utils/Loading/Loading';

import CustomForm from 'forms/CustomForm';
import Toolbar from 'forms/controls/Toolbar/Toolbar';

import useNodeResize from 'hooks/nodeResize';

import { ControlPropsType, EditModeType, EditorContainerType } from 'forms/interfaces';
import { TCustomToolbarButton } from 'forms/controls/Toolbar/interface';

import ActionStore from 'store/actionStore';

interface PropsType extends ControlPropsType {
    descr: EditorContainerType;
}

const EditorContainer: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { content } = ConfigurationStore;
    const { datasetName, params } = descr;

    const dataset = useMemo(
        () => propContainer.dataStock.getDatasetObj(datasetName),
        [datasetName]
    );

    const editorRef = useRef(null) as MutableRefObject<any>;

    const [formGuid, setFormGuid] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState<EditModeType>('view');
    const [selectedRec, setSelectedRec] = useState(dataset?.activeRec);
    const [editorExtParamVals, setEditorExtParamVals] = useState(null);

    const propContainerGuid = useMemo(() => uuidv4(), []);
    const formActions = formGuid ? ActionStore.getFormActions(propContainerGuid) : null;

    const [editorWidth] = useNodeResize(editorRef);

    const calcOperationFormula = async (formulaName: string) => {
        let allowed = true;
        const code = `ds.${dataset.name}.${formulaName}`;
        const errAccum = { err: [], warn: [] };
        const payload =
            await dataset.dataStock.ownerPropContainer.formulaCalculator.calcFormulaByCode(
                code,
                errAccum
            );

        if (errAccum.err.length) {
            allowed = false;
            NotificationStore.showAlert(errAccum.err.join('\n'));
        }
        return { allowed, payload };
    };

    const editRecord = async (record: any) => {
        if (record) {
            const res = await calcOperationFormula('editFormula');
            if (res.allowed) {
                const extParamVals =
                    res.payload && typeof res.payload === 'object' ? res.payload : {};

                if (res.payload?.__ID) {
                    extParamVals[dataset.keyField] = res.payload.__ID;
                } else extParamVals[dataset.keyField] = record[dataset.keyField];

                setFormGuid(res.payload?.__EDITOR ?? dataset?.descr.editForm?.code);
                setEditorExtParamVals({
                    ...extParamVals,
                    ...propContainer.dataStock.getParams(params)
                });
            }
        }
    };

    const selectRecord = useCallback(
        (record: any) => {
            setSelectedRec(record);
            setLoading(true);
            setFormGuid(null);
            editRecord(record)
                .then(() => {
                    setError(null);
                    setLoading(false);
                })
                .catch(err => console.error(err.message));
        },
        [datasetName]
    );

    useEffect(() => {
        // Предварительная фиксация актуальной текущей записи
        const nextRecord = dataset?.activeRec;

        if (formActions?.editMode === 'edit') {
            // Если есть несохранённые данные в текущем редактируемом датасете, то не переключаем запись
            if (!formActions.getDataStock()?.checkUnsavedDatasets()) {
                selectRecord(nextRecord);
            }
        } else selectRecord(nextRecord);
    }, [dataset?.activeRec]);

    useEffect(() => {
        if (formActions) setEditMode(formActions.editMode);
    }, [formActions?.editMode]);

    const buttons: TCustomToolbarButton[] | undefined = useMemo(() => {
        if (formActions)
            return (formActions?.editMode as string) === 'view'
                ? [
                      {
                          type: 'edit',
                          variant: 'outlined',
                          caption: content.controls.buttons.change,
                          icon: <Edit />,
                          onClick: () => formActions.setEditMode('edit')
                      }
                  ]
                : [
                      {
                          type: 'save',
                          variant: 'contained',
                          caption: content.resource.buttons.save,
                          icon: <Save />,
                          disabled: !formActions?.validated || !formActions?.isReady,
                          onClick: async () => {
                              const result = await formActions.save();

                              if (result === false) return;

                              if (result?.err.length === 0) {
                                  if (result.noReload) {
                                      dataset.accum.init();
                                  } else {
                                      dataset.loadData();
                                  }
                              }
                          }
                      },
                      {
                          type: 'cancel',
                          variant: 'outlined',
                          caption: content.resource.buttons.undo,
                          disabled: !selectedRec || !formActions?.isReady,
                          icon: <Clear />,
                          onClick: async () => {
                              selectRecord(selectedRec);
                              formActions.setEditMode('view');
                          }
                      }
                  ];
    }, [
        formActions,
        formActions?.validated,
        formActions?.editMode,
        formActions?.isReady,
        dataset?.state,
        dataset?.activeRec,
        formActions?.isReady
    ]);

    if (loading) return <Loading delay />;

    if (formGuid && selectedRec)
        return (
            <Box
                ref={editorRef}
                sx={{ height: formActions && buttons?.length ? 'calc(100% - 64px)' : '100%' }}
            >
                {formActions && buttons?.length && (
                    <Toolbar
                        key={`toolbar-${selectedRec[dataset.keyField] as number}`}
                        customButtons={buttons}
                        propContainer={propContainer}
                        initContainerWidth={editorWidth}
                    />
                )}
                <CustomForm
                    key={`form-${selectedRec[dataset.keyField] as number}`}
                    formGuid={formGuid}
                    extParamVals={editorExtParamVals}
                    initEditMode={editMode}
                    parentDataStock={dataset?.dataStock}
                    parentPropContainer={propContainer}
                    propContainerGuid={propContainerGuid}
                    editor
                />
            </Box>
        );

    if (!selectedRec) return <Loading type="empty" message={content.controls.data.choiceRecord} />;

    if (!formGuid) return <Loading type="warning" message={content.controls.data.noEditor} />;

    return null;
});

export default EditorContainer;
