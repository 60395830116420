const langRus = {
    loading: 'Загрузка',
    refreshList: 'Обновить список',
    importEditor: 'Импортировать редактор',
    loadReqFields: 'Загрузить поля и параметры запроса',
    loadDSColumns: 'Загрузить колонки из датасета',
    loadDSEdit: 'Загрузить поля из датасета',
    clearNestedControls: 'Удалить вложенные контролы',
    resSelect: 'Выбор ресурса',
    needSelect: 'Выберите ресурс',
    guidEditor: 'Редактирование GUID',
    alert: {
        idRequest: 'Не указан идентификатор request',
        missingDS: 'Указанный dataset не найден',
        specDS: 'Не указан исходный dataset',
        statReNotExist: (name: string): string =>
            `Ошибка импорта! Возможно, статзапрос ${name} не существует!` as any,
        nameNotSpec: 'Имя статзапроса не задано!',
        cantImport: 'Ресурс не может быть импортирован',
        needSave: 'Изменения нужно сохранить или отменить!',
        guidChange:
            'Внимание! Изменение guid элемента может повлечь за собой непоправимые последствия!',
        errorTitle: 'Ошибка!',
        guidErrorMsg: 'Неверный формат guid!'
    },
    dialog: {
        title: 'Мастер вставки полей',
        chooseDS: 'Укажите DataSet',
        chooseFields: 'Укажите поля',
        exportLinkedResource: 'Экспортировать связанные ресурсы?'
    },
    clearList: {
        title: 'Внимание!',
        content: 'Вы действительно хотите удалить все вложенные контролы?'
    },
    resList: {
        groupStore: {
            all: 'Все объекты',
            wuiForm: 'Формы',
            subForm: 'Дочерние формы',
            wuiQuery: 'SQL-запросы',
            wuiExtQuery: 'Внешние запросы',
            wuiStatQuery: 'Статзапросы',
            cdo: 'CDO',
            table: 'Таблицы',
            settings: 'Настройки',
            wuiRouter: 'Роутер',
            wuiIconSet: 'Набор иконок',
            wuiApplication: 'Настройки приложения',
            wuiGroup: 'Группа ресурсов',
            wuiPackage: 'Пакет скриптов',
            inheritor: 'Ресурс-наследник'
        },
        show: 'Показать',
        type: 'Тип',
        searchLabel: 'Имя / GUID',
        searchTag: '#Тэг',
        fromDate: 'Изменения с даты'
    },
    resProps: {
        property: 'Свойство',
        value: 'Значение',
        saving: 'Сохранение',
        refresh: 'Обновление'
    },
    buttons: {
        ok: 'Ok',
        cancel: 'Отмена',
        add: 'Добавить',
        next: 'Дальше',
        back: 'Назад',
        refresh: 'Обновить',
        close: 'Закрыть',
        create: 'Создать',
        save: 'Сохранить',
        undo: 'Отменить',
        copy: 'Копировать',
        cut: 'Вырезать',
        paste: 'Вставить',
        delete: 'Удалить',
        clear: 'Очистить',
        pasteFromDataset: 'Вставить поля из датасета',
        export: 'Экспорт',
        import: 'Импорт',
        load: 'Загрузить',
        open: 'Открыть',
        changeLocale: 'Язык',
        editor: 'Редактор',
        guid: 'Изменить GUID',
        generate: 'Сгенерировать новый guid',
        resetCache: 'Очистить кэш',
        getDescriptor: 'Экспорт описателя',
        homePage: 'На главную',
        search: 'Поиск',
        upward: 'Поднять',
        downward: 'Опустить',
        updateResource: 'Обновить ресурс',
        selectAll: 'Выделить всё',
        clearAll: 'Снять выделение'
    },
    scriptEditor: {
        title: 'Редактор кода',
        change: 'Изменить',
        apply: 'Принять',
        cancel: 'Отменить',
        options: 'Опции расчета',
        asDefault: 'значение по умолчанию',
        asValue: 'расчетное значение'
    },
    iconEditor: {
        title: 'Редактор иконки',
        preview: 'Предварительный просмотр',
        fontSize: 'Размер'
    },
    gridProps: {
        caption: 'Редактор свойств грида',
        selectFields: 'Выбор полей',
        tabs: {
            cols: 'Столбцы',
            fastFilter: 'Быстрый поиск',
            sort: 'Сортировка',
            group: 'Группировка',
            aggregation: 'Агрегирование',
            toolbar: 'Панель инструментов',
            settings: 'Настройка'
        },
        columns: {
            caption: { headerName: 'Наименование', description: 'Наименование поля' },
            resource: { headerName: 'Ресурс', description: 'Ресурс' },
            direction: { headerName: 'Сортировка', description: 'Настройки сортировки поля' },
            visible: { headerName: 'Видимость', description: 'Видимость поля' },
            align: { headerName: 'Выравнивание', description: 'Выравнивание по горизонтали' },
            width: { headerName: 'Ширина', description: 'Ширина указанного поля' },
            fixWidth: { headerName: 'Фиксация', description: 'Фиксация ширины указанного поля' },
            sortable: {
                headerName: 'Сортировка',
                description: 'Включение сортировки по указанному полю'
            },
            func: {
                headerName: 'Функция агрегирования',
                description: 'Настройки функции агрегирования'
            },
            buttons: {
                caption: { headerName: 'Наименование', description: 'Наименование поля' },
                type: {
                    headerName: 'Тип кнопки',
                    description: 'Выбор базового действия для кнопки'
                },
                variant: {
                    headerName: 'Вариант отображения',
                    description: 'Вариант отображения кнопки'
                },
                color: { headerName: 'Цвет иконки', description: 'Цвет кнопки в режиме иконки' },
                iconSet: {
                    headerName: 'Набор иконок',
                    description: 'Набор иконок, содержащий иконку для кнопки'
                },
                leftIcon: {
                    headerName: 'Имя иконки',
                    description: 'Наименование экземпляра иконки для кнопки'
                },
                script: {
                    headerName: 'Скрипт',
                    description: 'Скрипт, выполняемый при нажатии на кнопку'
                }
            }
        },
        alignText: {
            left: 'влево',
            right: 'вправо',
            center: 'по центру',
            justify: 'по ширине'
        },
        sortModeText: {
            asc: 'по возрастанию',
            desc: 'по убыванию',
            deny: 'запрещено менять',
            none: ''
        },
        aggMode: {
            sum: 'Сумма',
            avg: 'Среднее',
            min: 'Минимальное',
            max: 'Максимальное',
            count: 'Количество'
        },
        toolbarMode: {},
        configuration: {
            hideRefresh: 'Отключить кнопку "Обновить"',
            hideToolbar: 'Скрыть панель инструментов'
        }
    },
    cdoProps: {
        caption: 'Редактор свойств CDO',
        selectDataset: 'Датасет',
        selectFields: 'Выбор полей',
        tabs: {
            fields: 'Поля',
            groups: 'Группы полей'
        },
        columns: {
            caption: { headerName: 'Поле', description: 'Наименование поля' },
            dataset: { headerName: 'Датасет', description: 'Наименование датасета' },
            group: { headerName: 'Группа', description: 'Наименование группы' },
            groupCaption: { headerName: 'Заголовок', description: 'Отображаемый заголовок группы' },
            groupFields: { headerName: 'Поля группы', description: 'Список полей группы' },
            width: { headerName: 'Ширина', description: 'Ширина контрола' }
        }
    },
    routerProps: {
        caption: 'Роутинг приложения',
        columns: {
            caption: { headerName: 'Наименование', description: 'Наименование поля' },
            route: { headerName: 'Роут', description: 'Имя роута' },
            resource: { headerName: 'Ресурс', description: 'Ресурс' },
            viewMode: { headerName: 'Режим просмотра', description: 'Режим просмотра формы' },
            editMode: {
                headerName: 'Режим редактирования',
                description: 'Режим редактирования формы'
            }
        }
    },
    snackbars: {
        operation: {
            success: 'Операция выполнена успешно'
        },
        save: {
            success: 'Ресурс успешно сохранён',
            error: 'Ошибка при сохранении ресурса'
        },
        import: {
            success: 'Импорт ресурса выполнен успешно',
            error: 'Ошибка при импорте ресурса',
            fields: (count: number): string =>
                count ? `Добавлено полей: ${count}` : `Нет полей для добавления`,
            params: (count: number): string =>
                count > 0 ? `Добавлено параметров: ${count}` : `Удалено параметров: ${count * -1}`,
            columns: (count: number): string =>
                count ? `Добавлено колонок: ${count}` : `Нет колонок для добавления`,
            filters: (count: number) =>
                count > 0 ? `Добавлено фильтров: ${count}` : `Удалено фильтров: ${count * -1}`
        },
        details: 'Детали'
    },
    translation: {
        fieldName: 'Наименование поля',
        baseValue: 'Базовый перевод',
        exists: 'Поле с таким наименованием уже существует'
    }
};

export default langRus;
