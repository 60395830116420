import React, { useState } from 'react';
import moment, { Moment } from 'moment';

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogTitle,
    TextField
} from '@mui/material';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import Scheduler from 'components/Scheduler';
import { DataType } from 'components/Scheduler/interfaces';

import { model, data } from './testData';
import EventTemplate from './EventTemplate';

const SchedulerTest = () => {
    const [open, setOpen] = useState(false);
    const [schedulerData, setData] = useState(data);
    const [counter, setCounter] = useState(0);

    const [id, setId] = useState(100);
    const [newEvent, setNewEvent] = useState<any | null>(null);
    const [title, setTitle] = useState<string>('');
    const [startTime, setStartTime] = useState<Moment | null>(null);
    const [endTime, setEndTime] = useState<Moment | null>(null);

    const eventClicked = (curData: any, event: any) => {
        alert(event.title);
    };

    const onNewEvent = (
        curData: any,
        slotId: string | number,
        ownerId: string | number,
        slotName: string,
        start: string,
        end: string,
        type?: string
    ) => {
        setTitle('Новая запись');
        setStartTime(moment(start));
        setEndTime(moment(end));

        setNewEvent({
            title: model[0].subjects.find(subj => subj.id === Number(ownerId))?.name,
            slotId,
            ownerId,
            slotName,
            start,
            end,
            type
        });

        setOpen(true);
    };

    const handleNewEvent = () => {
        if (!newEvent || !startTime || !endTime) {
            return;
        }

        const { ownerId, start, end, type } = newEvent;

        const hour = startTime.hour() * 100 + startTime.minutes();

        const newData = {
            id,
            ownerId,
            date: startTime.format('YYYY-MM-DD'),
            dateStart: startTime.format('YYYY-MM-DD HH:mm'),
            dateEnd: endTime.format('YYYY-MM-DD HH:mm'),
            hour,
            duration: endTime.diff(startTime, 'minutes'),
            title,
            type
        };

        setId(prev => prev + 1);

        schedulerData.push(newData as any);

        setData(schedulerData);
        setCounter(counter + 1);

        setOpen(false);
    };

    return (
        <Box style={{ height: '100vh' }}>
            <Scheduler
                model={model}
                data={schedulerData as DataType[]}
                controls
                fitSlots={false}
                eventItemClick={eventClicked}
                newEvent={onNewEvent}
                eventItemTemplateResolver={EventTemplate}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Dialog open={open} fullWidth>
                    <DialogTitle>{newEvent?.title}</DialogTitle>
                    <DialogContent>
                        <Box sx={{ pt: 2, '& .MuiTextField-root': { m: 1 } }}>
                            <TextField
                                label="Наименование"
                                size="small"
                                fullWidth
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                            />
                            <TimeField
                                label="Время начала"
                                size="small"
                                fullWidth
                                value={startTime}
                                onChange={newValue => setStartTime(newValue)}
                            />
                            <TimeField
                                label="Время окончания"
                                size="small"
                                fullWidth
                                value={endTime}
                                onChange={newValue => setEndTime(newValue)}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button onClick={handleNewEvent}>Ok</Button>
                    </DialogActions>
                </Dialog>
            </LocalizationProvider>
        </Box>
    );
};

export default SchedulerTest;
