import React, {
    FunctionComponent,
    MutableRefObject,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { observer } from 'mobx-react';

import moment from 'moment';
import 'moment/locale/ru';

import { Box, Button } from '@mui/material';
import { Refresh } from '@mui/icons-material';

import onNodeResize from 'hooks/nodeResize';
import useDatasetLoading from 'hooks/datasetLoading';
import useScriptHandler from 'hooks/scriptHandler';
import useBackdrop from 'hooks/backdrop';

import Scheduler, { Event, Resource, SchedulerData, ViewTypes } from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';

import { ControlPropsType } from 'forms/interfaces';

import ConfigurationStore from 'store/configurationStore';
import ActionStore from 'store/actionStore';
import notificationStore from 'store/notificationStore';
import DataStore from 'store/dataStore';

import Loading from 'components/utils/Loading/Loading';

import withDragDropContext from './withDnDContext';

import Toolbar from './Toolbar';
import EventTemplate from './Event/EventTemplate';
import PopoverTemplate from './Event/PopoverTemplate';

import './Scheduler.scss';
import { generateSchedulerData, makeEvents, makeResources } from './utils';

interface PropsType extends ControlPropsType {
    descr: any;
}

const RESOURCE_WIDTH = 400;

const ScheduleChart: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { content } = ConfigurationStore;

    const wrapperRef = useRef(null) as MutableRefObject<any>;

    const {
        caption,
        resourceDatasetName,
        eventDatasetName,
        resourceFieldName,
        resourceIdField,
        labelField,
        resourceTitle,
        onClickScript,
        eventClickScript,
        eventChangeScript
    } = descr;

    const viewType = ViewTypes.Week;

    const handleClick = useScriptHandler(onClickScript, propContainer);
    const handleEventClick = useScriptHandler(eventClickScript, propContainer);
    const handleChangeScript = useScriptHandler(eventChangeScript, propContainer);

    const resourceDataset = useMemo(
        () => propContainer.dataStock.getDatasetObj(resourceDatasetName),
        [resourceDatasetName]
    );
    const eventDataset = useMemo(
        () => propContainer.dataStock.getDatasetObj(eventDatasetName),
        [eventDatasetName]
    );

    const { loading, loadingType, loadingMessage } = useDatasetLoading(resourceDataset);

    const datasetActions = ActionStore.getDatasetActions(eventDataset?.guid);

    const [ganttData, setGanttData] = useState<{ resources: Resource[]; events: Event[] }>({
        resources: [],
        events: []
    });
    const [startDate, setStartDate] = useState<string>();
    const [currentReqPeriod, setCurrentReqPeriod] = useState<string[]>([
        moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        moment().add(1, 'month').endOf('month').format('YYYY-MM-DD')
    ]);

    const [backdrop, setBackdrop] = useState(false);
    const [test, setTest] = useState(Math.random());
    const [schedulerData, setSchedulerData] = useState<any>();

    const [wrapperWidth, wrapperHeight] = onNodeResize(wrapperRef);

    useBackdrop(backdrop);

    const updateData = (data: any) => {
        setSchedulerData(data);
        setTest(Math.random());
    };

    useEffect(() => {
        DataStore.AF.setAF(`${descr.guid as string}-start`, currentReqPeriod[0], {}, propContainer);
        DataStore.AF.setAF(`${descr.guid as string}-end`, currentReqPeriod[1], {}, propContainer);
    }, [currentReqPeriod]);

    // При изменении даты периода сверяем её с текущим интервалом загрузки данных. При необходимости загружаем данные
    useEffect(() => {
        if (
            !moment(startDate).isBetween(moment(currentReqPeriod[0]), moment(currentReqPeriod[1]))
        ) {
            setCurrentReqPeriod([
                moment(startDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                moment(startDate).add(1, 'month').endOf('month').format('YYYY-MM-DD')
            ]);

            schedulerData.setEvents([]);
            eventDataset.loadData({ forceRefresh: true });
        }
    }, [startDate]);

    // Фиксируем загрузку ресурсов
    useEffect(() => {
        if (wrapperWidth && resourceDataset?.data?.length) {
            const resources = makeResources(
                resourceDataset.data,
                resourceFieldName,
                resourceIdField
            );

            if (schedulerData) {
                schedulerData.setResources(resources);
                setGanttData({
                    ...ganttData,
                    ...{
                        resources
                    }
                });

                updateData(schedulerData);
            } else {
                const events = eventDataset?.data?.length
                    ? makeEvents(
                          eventDataset.data.filter(
                              (event: any) => event.date_from && event.date_to
                          ),
                          datasetActions,
                          resourceFieldName,
                          resourceIdField,
                          labelField
                      )
                    : [];

                const data = { resources, events };

                setGanttData(data);

                const newSchedulerData = generateSchedulerData(
                    data,
                    resourceTitle,
                    wrapperWidth,
                    wrapperHeight,
                    RESOURCE_WIDTH,
                    viewType,
                    startDate
                );

                updateData(newSchedulerData);
            }
        }
    }, [wrapperWidth, resourceDataset, resourceDataset?.state]);

    // Фиксируем загрузку событий
    useEffect(() => {
        // Если статус относится к процессу
        if ([4, 5, 6].indexOf(eventDataset?.state) !== -1) {
            if (schedulerData) {
                // schedulerData.setEvents([]);
            }
        } else if (wrapperWidth && eventDataset?.data?.length) {
            const events = makeEvents(
                eventDataset.data.filter((event: any) => event.date_from && event.date_to),
                datasetActions,
                resourceFieldName,
                resourceIdField,
                labelField
            );

            if (schedulerData) {
                schedulerData.setEvents(events);
                setGanttData({
                    ...ganttData,
                    ...{
                        events
                    }
                });

                updateData(schedulerData);
            } else {
                const resources = ganttData.resources?.length
                    ? ganttData.resources
                    : makeResources(resourceDataset.data, resourceFieldName, resourceIdField);
                const data = { resources, events };

                setGanttData(data);

                const newSchedulerData = generateSchedulerData(
                    data,
                    resourceTitle,
                    wrapperWidth,
                    wrapperHeight,
                    RESOURCE_WIDTH,
                    viewType,
                    startDate
                );

                updateData(newSchedulerData);
            }
        }
    }, [wrapperWidth, eventDataset, eventDataset?.state, currentReqPeriod]);

    // Фиксируем изменения ширины контейнера для пересчёта ширины графика
    useEffect(() => {
        if (schedulerData) {
            if (wrapperWidth) {
                schedulerData.config.schedulerWidth = wrapperWidth;
                schedulerData.config.dayCellWidth = (wrapperWidth - RESOURCE_WIDTH) / 26;
                schedulerData.config.weekCellWidth = (wrapperWidth - RESOURCE_WIDTH) / 7;
                schedulerData.config.monthCellWidth = (wrapperWidth - RESOURCE_WIDTH) / 15;
                schedulerData.config.quarterCellWidth = (wrapperWidth - RESOURCE_WIDTH) / 15;
                schedulerData.config.yearCellWidth = (wrapperWidth - RESOURCE_WIDTH) / 15;
            }
            if (wrapperHeight) {
                schedulerData.config.schedulerMaxHeight = wrapperHeight;
            }

            updateData(schedulerData);
        }
    }, [wrapperWidth, wrapperHeight]);

    const onSelectDate = (data: SchedulerData, date: string) => {
        data.setDate(date);
        data.setEvents(ganttData.events);

        updateData(data);
    };

    const eventClicked = (data: SchedulerData, event: any) => {
        const { start, end, props } = event;

        handleEventClick({ start, end, props })
            .then(result => {
                result && eventDataset.loadData({ forceRefresh: true });
            })
            .catch(err => console.error(err.message));
    };

    const ops1 = (data: SchedulerData, event: any): any => {};

    const ops2 = (data: SchedulerData, event: any) => {};

    const updateEventStart = (data: any, event: any, newStart: string) => {
        const { start, end, props } = event;

        data.updateEventStart(event, newStart);
        updateData(data);

        handleChangeScript({ start, newStart, end, props })
            .then(result => {
                if (!result) {
                    data.updateEventStart(event, start);
                    updateData(data);

                    notificationStore.enqueueSnackbar({
                        message: 'Ошибка сохранения',
                        options: { variant: 'error' }
                    });
                }
            })
            .catch(err => console.error(err.message));
    };

    const updateEventEnd = (data: any, event: any, newEnd: string) => {
        const { start, end, props } = event;

        data.updateEventEnd(event, newEnd);
        updateData(data);

        handleChangeScript({ start, end, newEnd, props })
            .then(result => {
                if (!result) {
                    data.updateEventEnd(event, end);
                    updateData(data);

                    notificationStore.enqueueSnackbar({
                        message: 'Ошибка сохранения',
                        options: { variant: 'error' }
                    });
                }
            })
            .catch(err => console.error(err.message));
    };

    const moveEvent = (
        data: any,
        event: any,
        slotId: string,
        slotName: string,
        newStart: string,
        newEnd: string
    ) => {
        const { start, end, props } = event;

        data.moveEvent(event, slotId, slotName, newStart, newEnd);
        updateData(data);

        handleChangeScript({ start, newStart, end, newEnd, props })
            .then(result => {
                if (!result) {
                    data.moveEvent(event, slotId, slotName, start, end);
                    updateData(data);

                    notificationStore.enqueueSnackbar({
                        message: 'Ошибка сохранения',
                        options: { variant: 'error' }
                    });
                }
            })
            .catch(err => console.error(err.message));
    };

    const onNewEvent = (
        data: any,
        slotId: string,
        slotName: string,
        start: string,
        end: string
    ) => {
        const newStart = moment(start).format('YYYY-MM-DD');
        const newEnd = moment(end).format('YYYY-MM-DD');

        handleClick({ slotId, slotName, newStart, newEnd })
            .then(result => {
                if (result) {
                    eventDataset.loadData({ forceRefresh: true });
                }
            })
            .catch(err => console.error(err.message));
    };

    const onScrollRight = (data: any, schedulerContent: any, maxScrollLeft: number) => {
        if (data.ViewTypes === ViewTypes.Day) {
            data.next();
            data.setEvents(ganttData.events);

            updateData(data);

            schedulerContent.scrollLeft = maxScrollLeft - 10;
        }
    };

    const onScrollLeft = (data: any, schedulerContent: any, maxScrollLeft: number) => {
        if (data.ViewTypes === ViewTypes.Day) {
            data.prev();
            data.setEvents(ganttData.events);

            updateData(data);

            schedulerContent.scrollLeft = 10;
        }
    };

    const onScrollTop = (data: SchedulerData, schedulerContent: any, maxScrollTop: number) => {
        console.log('onScrollTop');
    };

    const onScrollBottom = (data: SchedulerData, schedulerContent: any, maxScrollTop: number) => {
        console.log('onScrollBottom');
    };

    const toggleExpandFunc = (data: any, slotId: string) => {
        data.toggleExpandStatus(slotId);

        updateData(data);
    };

    return (
        <Box ref={wrapperRef} sx={{ height: 'calc(100% - 130px)', width: '100%' }}>
            {loading ? <Loading delay type={loadingType} message={loadingMessage} /> : null}
            {schedulerData && !loading ? (
                <>
                    {caption ? <h3 style={{ textAlign: 'center' }}>{caption}</h3> : null}
                    <Toolbar
                        dataset={resourceDataset}
                        schedulerData={schedulerData}
                        events={ganttData.events}
                        updateData={updateData}
                        setStartDate={setStartDate}
                    />
                    <Scheduler
                        schedulerData={schedulerData}
                        onSelectDate={onSelectDate}
                        eventItemClick={eventClicked}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        viewEventClick={ops1}
                        viewEventText="Ops 1"
                        viewEvent2Text="Ops 2"
                        viewEvent2Click={ops2}
                        updateEventStart={updateEventStart}
                        updateEventEnd={updateEventEnd}
                        moveEvent={moveEvent}
                        newEvent={onNewEvent}
                        onScrollLeft={onScrollLeft}
                        onScrollRight={onScrollRight}
                        onScrollTop={onScrollTop}
                        onScrollBottom={onScrollBottom}
                        toggleExpandFunc={toggleExpandFunc}
                        eventItemTemplateResolver={EventTemplate}
                        eventItemPopoverTemplateResolver={PopoverTemplate(setBackdrop)}
                        nonWorkingTimeBodyBgColor="#ffffff"
                        nonWorkingTimeHeadBgColor="#ffffff"
                    />
                    <Box sx={{ pt: 2, pr: 5, pl: 5, display: 'flex' }}>
                        <Button
                            variant="text"
                            onClick={() => {
                                schedulerData.setEvents([]);
                                eventDataset.loadData({ forceRefresh: true });
                            }}
                            startIcon={<Refresh />}
                            // sx={{ ml: 'auto' }}
                        >
                            {content.controls.buttons.refresh}
                        </Button>
                    </Box>
                </>
            ) : null}
        </Box>
    );
});

export default withDragDropContext(ScheduleChart);
