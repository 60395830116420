import { useEffect } from 'react';
import { observer } from 'mobx-react';

import './App.scss';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import ThemeProvider from 'mui-themes-extand';

import ConfigurationStore from 'store/configurationStore';

import AuthPage from 'pages/Auth/AuthPage';
import MainPage from 'pages/MainPage/MainPage';
import ExternalForm from 'pages/ExternalForm/ExternalForm';
import TokenAuthPage from 'pages/Auth/TokenAuthPage';
import TestPage from 'pages/TestPage/TestPage';

import AlertDialog from 'components/Feedback/AlertDialog';
import ErrorList from 'components/Feedback/ErrorList';
import Confirmation from 'components/Feedback/Confirmation';
import BackdropPanel from 'components/Feedback/BackdropPanel';
import Editors from 'components/Editors';
import Loading from 'components/utils/Loading/Loading';

import useAppReady from 'hooks/appReady';
import useBackdrop from 'hooks/backdrop';
import useNotification from 'hooks/notification';
import useAppTheme from 'hooks/appTheme';

const routing = (token: string, refresh: string) => {
    const { isAuthenticated, returnUrl } = ConfigurationStore;

    if (token || refresh) {
        return isAuthenticated ? (
            <Switch>
                <Route path="/medialog/:form" component={ExternalForm} />
                <Route path="/" component={MainPage} />
            </Switch>
        ) : (
            <TokenAuthPage token={token} refreshToken={refresh} />
        );
    }
    if (isAuthenticated) {
        return (
            <Switch>
                <Route path="/" component={MainPage} />
            </Switch>
        );
    }
    if (returnUrl) {
        return window.location.replace(returnUrl);
    }
    return (
        <Switch>
            <Route exact path="/" component={AuthPage} />
            {process.env.REACT_APP_TEST_PAGE ? (
                <Route exact path="/test/*" component={TestPage} />
            ) : null}
            <Route render={() => <Redirect to="/" />} />
        </Switch>
    );
};

const App = observer(() => {
    const { basename, setApi, backdrop, title } = ConfigurationStore;

    const { isReady, themeName, accessToken, refreshToken } = useAppReady();

    const theme = useAppTheme(process.env?.REACT_APP_DEFAULT_THEME);

    setApi(process.env.REACT_APP_API_URL || '');

    useEffect(() => {
        if (title) {
            document.title = title;
        }
    }, [title]);

    useBackdrop(backdrop, 30000);

    useNotification();

    return (
        <ThemeProvider theme={theme} name={themeName ?? ''}>
            {isReady ? (
                <BrowserRouter basename={basename}>
                    <AlertDialog />
                    <Confirmation />
                    <ErrorList />
                    <BackdropPanel />
                    <Editors />
                    {routing(accessToken || '', refreshToken || '')}
                </BrowserRouter>
            ) : (
                <Loading />
            )}
        </ThemeProvider>
    );
});

export default App;
