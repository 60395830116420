import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import isEqual from 'lodash/isEqual';

import { Breadcrumbs, Typography, Link } from '@mui/material';

import useCtrlProps from 'hooks/ctrlProps';

import { ContainerType, ControlPropsType, EditModeType } from 'forms/interfaces';
import CustomDataset from 'dataObj/customDataset';
import CustomForm from 'forms/CustomForm';

import DataStore from 'store/dataStore';
import ActionStore from 'store/actionStore';

import { checkGuid } from 'utils/index';
import { v4 as uuidv4 } from 'uuid';

type PathItem = {
    name: string;
    guid: string;
    extParamVals?: { [key: string]: string };
    editMode?: EditModeType;
};

interface PropsType extends ControlPropsType {
    descr: ContainerType;
}

const Container: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { guid, params, sourceNameType, sourceName, iterable = false } = descr;

    const { ctrlVisible } = useCtrlProps(propContainer, guid);

    const [formGuid, setFormGuid] = useState('');
    const [extParamVals, setExtParamVals] = useState({});
    const [editMode, setEditMode] = useState<EditModeType | undefined>();

    const [dependDS, setDependDS] = useState<CustomDataset[]>([]);

    const [path, setPath] = useState<PathItem[]>([]);

    const propContainerGuid = useMemo(() => uuidv4(), []);
    const formActions = ActionStore.getFormActions(propContainerGuid);

    const checkSourceType = (source: string) => {
        if (checkGuid(source)) {
            return source;
        }

        const resLink = propContainer.getResourceLink(source);

        if (resLink) return resLink.resource.code;

        return source;
    };

    const addPath = (item: PathItem) => {
        setPath([...path, ...[item]]);
    };

    const handlePath = (index: number) => {
        const newPath = path[index];
        setPath(path.slice(0, index + 1));

        setFormGuid(newPath.guid);
        setExtParamVals(newPath.extParamVals || {});
        setEditMode(newPath.editMode);
    };

    useEffect(() => {
        if (sourceNameType === 'af') setFormGuid(DataStore.AF.getAF(sourceName));
        if (sourceNameType === 'guid') setFormGuid(checkSourceType(sourceName));
        if (sourceNameType === 'name') setFormGuid(checkSourceType(sourceName));

        iterable && setPath([]);
    }, [sourceName, DataStore.AF.getAF(sourceName)]);

    useEffect(() => {
        if (params?.length) {
            setExtParamVals(propContainer.dataStock.getParams(params));

            const paramDsList: CustomDataset[] = [];
            params.forEach(param => {
                if (param.paramType === 'field') {
                    const ds = propContainer.dataStock.getDatasetObj(param.sourceDataset);
                    if (ds) paramDsList.push(ds);
                }
            });

            if (paramDsList.length && !isEqual(paramDsList, dependDS)) {
                setDependDS(paramDsList);
            }
        }
    }, [JSON.stringify(propContainer.dataStock.getParams(params))]);

    useEffect(() => {
        if (dependDS.reduce((states: number, ds) => states + ds.state, 0) === dependDS.length)
            formActions?.setForceReload();
    }, [dependDS.reduce((states: number, ds) => states + ds.state, 0)]);

    useEffect(() => {
        if (
            iterable &&
            formActions?.getName &&
            (!path.length || (path.length === 1 && path[0]?.name !== formActions?.getName()))
        ) {
            setPath([
                {
                    name: formActions.getName(),
                    guid: formActions.getFormDescr().guid
                }
            ]);
        }
    }, [formActions, path.length]);

    useEffect(() => {
        if (formActions?.ratherForm && iterable) {
            setFormGuid(formActions.ratherForm.guid);
            setExtParamVals(formActions.ratherForm.extParamVals || {});
            addPath(formActions.ratherForm);
            setEditMode(formActions.ratherForm.editMode);
        }
    }, [formActions?.ratherForm]);

    if (formGuid) {
        return ctrlVisible ? (
            <>
                {iterable && (
                    <Breadcrumbs aria-label="breadcrumb" sx={{ pl: 5, pr: 5 }}>
                        {path.map((item, index) =>
                            item.guid === formGuid ? (
                                <Typography key={index} color="text.primary" className="caption-h1">
                                    {item.name}
                                </Typography>
                            ) : (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <Link
                                    className="caption-h1"
                                    key={index}
                                    underline="hover"
                                    color="inherit"
                                    onClick={() => handlePath(index)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {item.name}
                                </Link>
                            )
                        )}
                    </Breadcrumbs>
                )}
                <div
                    style={{
                        height:
                            iterable && path.filter(item => !!item.name).length
                                ? 'calc(100% - 32px)'
                                : '100%'
                    }}
                >
                    <CustomForm
                        key={formGuid}
                        formGuid={formGuid}
                        extParamVals={extParamVals}
                        initEditMode={editMode}
                        // cdoData={editor?.datasets}
                        parentDataStock={propContainer.dataStock}
                        parentPropContainer={propContainer}
                        propContainerGuid={propContainerGuid}
                    />
                </div>
            </>
        ) : (
            <div />
        );
    }

    return <div />;
});

export default Container;
