import { useState, FC } from 'react';
import { observer } from 'mobx-react';
import { Button, IconButton, Tooltip } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';

import CustomDataset from 'dataObj/customDataset';
import { FilterValues } from 'dataObj/CustomFilter';

import FilterDialog from 'forms/controls/Filter/FilterDialog';

import ConfigurationStore from 'store/configurationStore';

interface PropTypes {
    dataset: CustomDataset;
    fullWidth?: boolean;
}

const UserFilter: FC<PropTypes> = observer(({ dataset, fullWidth = true }: PropTypes) => {
    const { content, log } = ConfigurationStore;

    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const openUserFilter = () => setOpenDialog(true);

    const handleClose = (state: boolean, result?: FilterValues) => {
        setOpenDialog(false);
        if (result) {
            log(`Пользователь собрал фильтр: ${JSON.stringify(result)}`, 'log');
            dataset.filter.values = result;
            dataset.loadData();
        }
    };

    return dataset?.descr?.flUserFilter ? (
        <>
            {fullWidth ? (
                <Button
                    variant="outlined"
                    aria-label="filter"
                    onClick={openUserFilter}
                    startIcon={<FilterAlt />}
                    sx={{ ml: 'auto !important', minWidth: 'auto' }}
                >
                    {content.controls.grid.filter}
                </Button>
            ) : (
                <Tooltip title={content.controls.grid.filter}>
                    <IconButton
                        onClick={openUserFilter}
                        sx={{ ml: 'auto !important', padding: '6px' }}
                    >
                        <FilterAlt />
                    </IconButton>
                </Tooltip>
            )}
            {openDialog ? <FilterDialog handleClose={handleClose} dataset={dataset} /> : null}
        </>
    ) : null;
});

export default UserFilter;
