import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Box, Badge, Typography } from '@mui/material';

import styles from 'forms/form.module.scss';
import { ChildrenContainerPropsType, ControlPropsType } from 'forms/interfaces';

import useCtrlProps from 'hooks/ctrlProps';

import DataStore from 'store/dataStore';

import TabPanel from './TabPanel';
import StyledTabs from './StyledTabs';
import StyledTab from './StyledTab';

interface PageControlProps extends ChildrenContainerPropsType {
    descr: any;
}

const PageControl = observer(({ descr, propContainer, drawChildren }: PageControlProps) => {
    const [activePage, setActivePage] = useState(0);
    const { ctrlVisible } = useCtrlProps(propContainer, descr.guid);

    const setActiveFilter = useCallback(
        (index: number) => {
            if (DataStore?.AF && descr.afName) {
                DataStore.AF.setAF(descr.afName, descr.pages[index].name, {}, propContainer);
            }
        },
        [descr]
    );

    useEffect(() => {
        setActiveFilter(0);
    }, [setActiveFilter]);

    function handleChange(event: Event, newValue: number) {
        setActivePage(newValue);
        setActiveFilter(newValue);
    }

    function renderPageTab(pages: any) {
        const pageTab = pages.map((page: any, index: number) => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const { ctrlVisible, ctrlHidden, ctrlBadge } = useCtrlProps(propContainer, page.guid);
            const hiddenTab = !ctrlVisible || ctrlHidden;

            const pageRequire: string | boolean = propContainer.getPageRequirement(page.guid);

            return (
                <StyledTab
                    key={index}
                    className={`caption-${(page.captionVariant as string) || 'empty'}`}
                    sx={{ visibility: hiddenTab ? ('hidden' as any) : 'inherit' }}
                    label={
                        pageRequire || ctrlBadge ? (
                            <Badge
                                color="warning"
                                variant={pageRequire || ctrlBadge === true ? 'dot' : 'standard'}
                                sx={{
                                    '& .MuiBadge-badge': {
                                        right: pageRequire || ctrlBadge === true ? -8 : -4,
                                        padding: '0 4px'
                                    }
                                }}
                                badgeContent={
                                    ctrlBadge && ctrlBadge !== true ? ctrlBadge : undefined
                                }
                            >
                                {page.caption}
                            </Badge>
                        ) : (
                            page.caption
                        )
                    }
                />
            );
        });

        return pageTab;
    }

    return (
        <Box
            className={styles.pageControlWrapper}
            sx={{ visibility: ctrlVisible ? 'inherit' : ('hidden' as any) }}
        >
            {descr.caption ? (
                <Typography
                    className={classNames([
                        styles.caption,
                        `caption-${(descr.captionVariant as string) || 'empty'}`
                    ])}
                >
                    {descr.caption}
                </Typography>
            ) : null}
            <Box className={styles.tabContainer}>
                <StyledTabs value={activePage} onChange={handleChange as any}>
                    {renderPageTab(descr.pages)}
                </StyledTabs>
            </Box>
            <Box className={styles.tabPanel}>
                {descr.pages.map((page: any, index: number) => (
                    <TabPanel
                        key={index}
                        index={index}
                        value={activePage}
                        forceRender={page.forceRender}
                    >
                        {drawChildren(page)}
                    </TabPanel>
                ))}
            </Box>
        </Box>
    );
});

export default PageControl;
