import { makeObservable, observable, action } from 'mobx';

import DataStore from 'store/dataStore';

import PropContainer from 'dataObj/PropContainer';
import CustomDataset from 'dataObj/customDataset';
import { IQueryRow } from 'dataObj/dataInterfaces';

export type SelfTreeNode = {
    id: number | null;
    parentId: number | null;
    caption: string;
    children?: SelfTreeNode[];
};

export class SelfTreeStore {
    root: SelfTreeNode;
    activeNode: SelfTreeNode;
    propContainer: PropContainer;
    dsParent: CustomDataset;
    parentField: string;
    captionField: string;
    afName: string;

    constructor(
        propContainer: PropContainer,
        dsParent: CustomDataset,
        parentField: string,
        captionField: string,
        afName: string,
        caption: string
    ) {
        this.propContainer = propContainer;
        this.afName = afName;
        this.dsParent = dsParent;
        this.parentField = parentField;
        this.captionField = captionField;
        this.root = { id: null, parentId: -1, caption };
        this.activeNode = this.root;
        makeObservable(this, { root: observable, rebuildTree: action });
        DataStore.AF.setAF(this.afName, undefined, { noDistribute: true }, this.propContainer);
    }

    findElementById(node: SelfTreeNode, id: number | null): SelfTreeNode | undefined {
        if (node.id === id) {
            return node;
        }

        if (node.children) {
            for (const child of node.children) {
                const found = this.findElementById(child, id);
                if (found) {
                    return found;
                }
            }
        }
    }

    rebuildTree(ds: CustomDataset) {
        const node = this.findElementById(
            this.root,
            ds === this.dsParent ? this.activeNode.parentId : this.activeNode.id
        );
        if (node) {
            // node.children = [];
            const newChildren: SelfTreeNode[] = [];
            (ds.data as IQueryRow[]).forEach(rec => {
                newChildren.push({
                    id: rec[ds.keyField],
                    parentId: node.id,
                    caption: rec[this.captionField] || '-'
                });
            });

            const oldIds = (node.children || []).map((rec: SelfTreeNode) => rec.id);
            const newIds = newChildren.map((rec: SelfTreeNode) => rec.id);
            if (JSON.stringify(oldIds) !== JSON.stringify(newIds)) {
                node.children = newChildren;
            }
        }
    }

    selectNode(id: number) {
        const node = this.findElementById(this.root, id || null);
        if (node) {
            if (this.activeNode.parentId !== node.parentId) {
                DataStore.AF.setAF(this.afName, node.parentId, {}, this.propContainer);
            } else {
                this.dsParent.findById(id);
            }
            this.activeNode = node;
        }
    }
}
