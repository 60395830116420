import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import { Grid } from '@mui/material';

import ModalForm from 'hoc/ModalForm';
import CustomForm from 'forms/CustomForm';
import useEditorCustomButtons from 'hooks/editorCustomButtons';

import CustomDataset from 'dataObj/customDataset';
import { UpdateCDOResult, UpdateResult } from 'dataObj/dataInterfaces';

import useMaxWidth from 'hooks/maxWidth';
import useBackdrop from 'hooks/backdrop';

import Loading from 'components/utils/Loading/Loading';

import ConfigurationStore from 'store/configurationStore';
import EventsStore from 'store/eventsStore';
import { onNewRecord } from 'store/eventsStore/events';
import ActionStore from 'store/actionStore';

import { getControlClass } from 'forms/form-utils';

import { v4 as uuidv4 } from 'uuid';
import { getCreatedCDORecordId, getCreatedRecordId } from 'utils/utils';

interface EditorPropsType {
    dataset: CustomDataset;
}

interface ResultType {
    warn?: never[];
    err?: never[];
    noReload?: boolean;
    isValid?: boolean;
    result?: any[];
}

const Editor: FunctionComponent<EditorPropsType> = observer(({ dataset }) => {
    const [editorName, setEditorName] = useState('Редактор записи');
    const [error, setError] = useState(false);

    const { content } = ConfigurationStore;
    const datasetActions = ActionStore.getDatasetActions(dataset.guid);

    const { editor, setEditor, editAction, setEditAction, setEditorExtParamVals } = datasetActions;

    const propContainerGuid = useMemo(() => uuidv4(), []);
    const formActions = ActionStore.getFormActions(propContainerGuid);

    const formConfig = dataset?.dataStock?.ownerPropContainer?.find(editor?.guid, 'wuiForm')?.descr;

    useEffect(() => {
        if (formActions?.getName) {
            setEditorName(formActions.getName());
        }
    }, [formActions]);

    useEffect(() => {
        if (error) {
            setEditor(false);
        }
    }, [error]);

    const handleClose = async (state: boolean, resultArr?: any[]) => {
        if (state && formActions?.save) {
            formActions
                .save()
                .then((result?: ResultType | false) => {
                    if (result === false || !result?.isValid) return;

                    if (result?.err?.length === 0) {
                        setEditor(false);

                        if (result.noReload) {
                            dataset.accum.init();
                        } else {
                            dataset.loadData({ forceRefresh: true });
                        }

                        if (editAction === 'create')
                            EventsStore.getEvents(dataset.guid)
                                ?.triggerEvent(onNewRecord)
                                .catch(err => console.error(err.message));

                        editor?.callback && editor.callback(true);
                    }
                })
                .catch(err => {
                    editor?.callback && editor.callback(false);
                    console.error(err.message);
                });
        } else {
            editor?.callback && editor.callback(false);
            if (formActions?.cancel) formActions?.cancel();
            setEditor(false);
        }
    };

    const customButtons = useEditorCustomButtons(
        formActions,
        content,
        (result: any) => {
            if (result.noReload) {
                dataset.accum.init();
            } else {
                dataset.loadData();
            }

            if (['create', 'cdoCreate'].includes(formActions?.editMode as string)) {
                if (dataset.editType === 'detail') {
                    setEditor(false);
                } else {
                    const newRecId = !formActions?.getDataStock().CDOs?.length
                        ? getCreatedRecordId(result.resAccum as UpdateResult[], dataset.keyField)
                        : getCreatedCDORecordId(
                              result.resAccum as UpdateCDOResult[],
                              dataset.name,
                              dataset.keyField
                          );

                    if (newRecId) {
                        setEditorExtParamVals(
                            {
                                [dataset.keyField]: newRecId
                            },
                            true
                        );

                        setEditAction('edit');

                        formActions.setEditMode('edit');
                        formActions?.setForceReload();
                    } else {
                        console.error('Не найден идентификатор новой записи');
                    }
                }
            } else {
                formActions?.setForceReload();
            }
        },
        dataset.editType
    );

    const settled = useMemo(
        () => !!formActions && formActions.isReady,
        [formActions, formActions?.isReady, error]
    );

    const maxWidth = useMaxWidth(formActions);

    const minHeight = useMemo(() => {
        if (formActions) {
            const height = getControlClass(formActions.getFormDescr().classes, 'minHeight')?.split(
                '-'
            )?.[1] as string;

            const windowMargin = window.innerHeight * 0.01 * (100 - Number(height));

            return height
                ? `calc(${height}vh - ${(windowMargin < 64 ? 64 : windowMargin) + 116}px)`
                : undefined;
        }

        return;
    }, [formActions]);

    useBackdrop(!settled && !error);

    return editor?.guid ? (
        <ModalForm
            formGuid={editor.guid}
            maxWidth={maxWidth as false | 'xl' | 'md' | 'sm' | 'xs' | 'lg'}
            handleClose={handleClose}
            editorName={editorName}
            sx={{
                visibility: settled ? 'visible' : 'hidden',
                '& .MuiPaper-root': {
                    overflow: 'hidden'
                },
                '& .MuiDialogContent-root ': {
                    position: 'relative',
                    minHeight: minHeight ?? (!formActions?.isReady ? 300 : null)
                }
            }}
            customActions={customButtons}
            disabled={!formActions?.validated}
            propContainerGuid={propContainerGuid}
        >
            <Grid
                item
                sx={
                    minHeight
                        ? {
                              position: 'absolute',
                              height: '100%',
                              width: 'calc(100% - 48px)'
                          }
                        : {}
                }
            >
                {formConfig || editor?.guid ? (
                    <CustomForm
                        key={propContainerGuid}
                        formGuid={formConfig ? undefined : editor?.guid}
                        formConfig={formConfig}
                        extParamVals={editor?.extParamVals}
                        initEditMode={editAction}
                        parentDataStock={dataset?.dataStock}
                        cdoData={editor?.datasets}
                        parentPropContainer={editor?.parentPropContainer}
                        propContainerGuid={propContainerGuid}
                        handleClose={() => setEditor(false)}
                        handleError={(err: boolean) => setError(err)}
                        editor
                        modal
                    />
                ) : (
                    <Loading type="empty" message={content.controls.data.noEditor} />
                )}
            </Grid>
        </ModalForm>
    ) : null;
});

export default Editor;
