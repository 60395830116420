import { action, get, makeObservable, observable, remove, set, values } from 'mobx';

import PropContainer from 'dataObj/PropContainer';
import CustomDataset from 'dataObj/customDataset';

import { EditModeType, FormType } from 'forms/interfaces';

import FormActions from './form/FormActions';
import DatasetActions from './dataset/DatasetActions';

export class Actions {
    @observable actions: { [key: string]: FormActions | DatasetActions } = {};

    constructor() {
        makeObservable(this);
    }

    @action addFormActions = (
        descr: FormType,
        propContainer: PropContainer,
        handleClose?: () => void,
        editMode?: EditModeType,
        guid?: string,
        availableActions?: Array<string>
    ) => {
        set(this.actions, {
            [propContainer.guid]: new FormActions(
                descr,
                propContainer,
                handleClose,
                editMode,
                availableActions
            )
        });

        return this.getFormActions(guid || propContainer.guid);
    };

    @action addDatasetActions = (dataset: CustomDataset) => {
        if (!this.getDatasetActions(dataset.guid))
            set(this.actions, {
                [dataset.guid]: new DatasetActions(dataset)
            });

        return this.getDatasetActions(dataset.guid);
    };

    @action removeActions = (guid: string) => {
        for (const key in this.actions) {
            if (key.includes(guid)) {
                remove(this.actions, key);
            }
        }
    };

    getActions = (type?: string) => {
        if (type) return values(this.actions).filter(a => a.type === type);

        return values(this.actions);
    };

    getFormActions = (guid?: string): FormActions => (guid ? get(this.actions, guid) : null);

    getDatasetActions = (guid?: string): DatasetActions => (guid ? get(this.actions, guid) : null);
}

const ActionStore = new Actions();
export default ActionStore;
