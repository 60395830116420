import React, { MouseEvent, useRef } from 'react';
import { observable, action, makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view'

import glossStyles from './gloss.module.scss';
import { addTextToField } from './gloss-utils';

import { GlosElemType } from './interfaces';

let timerId: NodeJS.Timeout;

export class GlossElemObj {
    @observable level: number;
    @observable text: string;
    @observable active: boolean;
    @observable parent: GlossElemObj | null;
    @observable children: any[] | null;

    constructor({ level, text }: { level: number; text: string }) {
        this.level = level;
        this.text = text;
        this.active = true;
        this.children = [];
        this.parent = null;

        makeAutoObservable(this);
    }

    @action setActive(value: boolean) {
        this.active = value;
    }
}

export const GlossElem = observer(
    ({ nodeId, propContainer, activeCtrlDescr, elem }: GlosElemType) => {
        const treeItemRef = useRef<HTMLElement>(null) as any;

        function drawList(elems: any[]) {
            return (
                <>
                    {elems.map((childElem, index) => (
                        <GlossElem
                            nodeId={`${nodeId}${index}`}
                            propContainer={propContainer}
                            activeCtrlDescr={activeCtrlDescr}
                            elem={childElem}
                        />
                    ))}
                </>
            );
        }

        function getFullText(childElem: any) {
            const arr = [];

            do {
                arr.push(childElem.text);
                childElem.setActive(false);
                childElem = childElem.parent;
                if (!childElem) break;
                if (!childElem.active) break;
            } while (true);

            return arr.reverse().join(' ');
        }

        function addLineFromGloss(childElem: any) {
            addTextToField(activeCtrlDescr, propContainer, getFullText(childElem), true);
        }

        const clickHandler = (event: MouseEvent) => {
            event.stopPropagation();
        };

        const dblClickHandler = (event: MouseEvent, childElem: any) => {
            clearTimeout(timerId);
            addLineFromGloss(childElem);
            event.stopPropagation();
        };

        return (
            <TreeItem
                id={nodeId}
                ref={treeItemRef}
                nodeId={`${nodeId}`}
                label={
                    <Box
                        className={elem.active ? null : glossStyles.glossElemInactive}
                        onClick={clickHandler}
                        onDoubleClick={event => dblClickHandler(event, elem)}
                    >
                        {elem.text}
                    </Box>
                }
            >
                {elem.children?.length ? drawList(elem.children) : null}
            </TreeItem>
        );
    }
);

export default GlossElem;
