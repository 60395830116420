import React, { useState, FunctionComponent, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';

import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Tooltip
} from '@mui/material';

import { Theaters } from '@mui/icons-material';

import Loading from 'components/utils/Loading/Loading';

import { ResourceClass } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';

import { ResourceItem, ResourceType } from 'forms/interfaces';

import SearchPanel from './SearchPanel';

interface ResListType {
    types?: Array<string>;
    ResourceStore: ResourceClass;
    onResourceCreate?: any;
    onResourceSelect?: any;
}

const ResList: FunctionComponent<ResListType> = observer(
    ({ types, ResourceStore, onResourceCreate, onResourceSelect }) => {
        const {
            curGrp,
            setCurGrp,
            resList,
            resListLoading,
            parentResource,
            setPreviewResource,
            selectResource,
            setResLoading,
            initResource
        } = ResourceStore;
        const { resource } = ConfigurationStore.content;

        const [selectedItem, setSelectedItem] = useState<ResourceItem | null>(null);

        useEffect(() => {
            if (curGrp) {
                setCurGrp(curGrp);
            } else {
                setCurGrp(types?.length ? types[0] : '');
            }
        }, []);

        const showResource = (resItem: ResourceItem) => {
            setSelectedItem(resItem);

            if (curGrp === 'subForm') {
                initResource(
                    parentResource?.getSubForms()?.find(subForm => subForm.guid === resItem.guid) ??
                        null
                );
            } else {
                setResLoading(true);
                selectResource(resItem).finally(() => {
                    setResLoading(false);
                });
                if (onResourceSelect && resItem.key) onResourceSelect(resItem);
            }
        };

        const handleClick = (item: ResourceItem) => showResource(item);

        const renderRow = (item: ResourceItem) => (
            <ListItem
                button
                key={item.key}
                component="div"
                onClick={() => handleClick(item)}
                selected={item.key === selectedItem?.key}
            >
                <ListItemText primary={item.name} />
                {item.type === ResourceType.form ? (
                    <ListItemSecondaryAction>
                        <Tooltip title={resource.resList.show} aria-label="show">
                            <IconButton
                                edge="end"
                                aria-label="Theaters"
                                onClick={() => setPreviewResource(item.code)}
                                size="large"
                            >
                                <Theaters />
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                ) : null}
            </ListItem>
        );

        const renderList = useCallback(() => {
            if (!curGrp || !resList) {
                return null;
            }
            if (curGrp === 'subForm' && parentResource)
                return parentResource?.getSubForms().map((item: ResourceItem) => renderRow(item));

            const list = Object.values(resList);
            return list.map((item: ResourceItem, idx: number) => renderRow(item));
        }, [curGrp, resList, selectedItem?.key]);

        return (
            <>
                <SearchPanel
                    types={types}
                    createResource={onResourceCreate}
                    showResource={showResource}
                    ResourceStore={ResourceStore}
                />
                <div
                    style={{
                        position: 'relative',
                        marginTop: 16,
                        overflow: 'auto',
                        height: `calc(100% - ${
                            16 +
                            (document.getElementById(`resource-search-panel`)?.offsetHeight ?? 0)
                        }px)`
                    }}
                >
                    {resListLoading ? (
                        <Loading message={resource.loading} />
                    ) : (
                        <List>{renderList()}</List>
                    )}
                </div>
            </>
        );
    }
);

export default ResList;
