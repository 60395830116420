import { makeObservable, observable, action, set, get } from 'mobx';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import DataStock from './DataStock';

import FormulaCalculator from '../formulas/formula-calculator';

import ScriptStore from '../store/scriptStore';
import ReportStore from '../store/reportStore';

const cKind = { control: 0, data: 1, script: 2, subForm: 3 };

/**
 * @typedef {object} controlElement
 * @property {import('../forms/interfaces').ControlType} descr
 * @property {string} guid
 * @property {string} type
 * @property {string} name
 * @property {number} parentIndex
 * @property {boolean} enabled
 * @property {boolean} visible
 * @property {boolean} hidden
 * @property {string} kind
 * @property {string} [color]
 * @property {function} [func]
 * @property {object} [options]
 *
 */

class PropContainer {
    /**
     * @param {import('forms/interfaces').FormType} formDescr
     * @param {DataStock} [parentDataStock]
     * @param {PropContainer} [parentPropContainer]
     * @param {string} [guid]
     * @param {boolean} [editor=false]
     */
    constructor(formDescr, parentDataStock, parentPropContainer, guid, editor = false) {
        this.guid = guid || uuidv4();
        /** @type {import('forms/interfaces').FormType} */
        this.formDescr = formDescr;
        this.formGuid = formDescr.guid;
        this.formName = formDescr.name;
        this.editor = editor;

        this.isSubForm = formDescr.isSubForm;
        this.isLocalAFContainer = formDescr.isLocalAFContainer;

        /** @type {PropContainer} */
        this.parentPropContainer = this.isSubForm ? parentPropContainer : null;
        /** @type {PropContainer} */
        this.sourcePropContainer = parentPropContainer;

        this.activeCtrlDescr = null;
        /** @type {import('forms/interfaces').ResourceLinkType[]} */
        this.customResourceLinks = [];

        /** @type {controlElement[]} */
        this.ctrlArr = [];

        /** @type {Object.<string, ScriptStore>} */
        this.scripts = {};

        this.requiredPages = {};
        this.requiredControls = {};

        this.#scanDescr(formDescr, undefined, cKind.control);

        makeObservable(this, {
            ctrlArr: observable,
            scripts: observable,
            setProperty: action,
            setElemProperty: action,
            requiredPages: observable,
            requiredControls: observable,
            setPageRequirement: action,
            activeCtrlDescr: observable,
            setActiveCtrlDescr: action,
            customResourceLinks: observable,
            setCustomResourceLinks: action
        });

        this.dataStock =
            this.isSubForm && parentDataStock ? parentDataStock : new DataStock(formDescr, this);
        this.formulaCalculator = new FormulaCalculator(formDescr, this.dataStock, this);
        this.formulaCalculator.init();

        // в masterForm. Установить свойства dataset.editForm для subForm
        if (!this.isSubForm) {
            const editForms = this.ctrlArr.filter(
                c => c.kind === cKind.subForm && c.descr.editDatasetName
            );
            this.dataStock.setEditForms(editForms, this);
        }

        ReportStore.setFormReports(formDescr, this);
    }

    #awaitFunctionsTransfer(script) {
        //массив асинхронных функций
        const awaitFuncArr = [
            'showForm',
            'showSubForm',
            'showEditor',
            'showCreator',
            'executeCsbTask',
            'executeServerScript',
            'executeFormScript',
            'modalMessage',
            'executeDynamicForm',
            'confirmDialog',
            'encodeData',
            'refreshDataset',
            'saveData',
            'recalcFormulas',
            'wait',
            'logout',
            'checkData',
            'openLookup',
            'appendRecord',
            'executeBusinessProcess',
            'runReport',
            'getCdoForm',
            'deleteCdo',
            'fetch',
            'selectRowByOrder',
            'saveFile',
            'loadFile'
        ];

        awaitFuncArr.map(func => {
            const re = new RegExp(`Client.${func}`, 'g');
            script = script.replace(re, `await Client.${func}`);
        });

        return script;
    }

    #createFunction(script) {
        const AsyncFunction = Object.getPrototypeOf(async function () {}).constructor;
        return new AsyncFunction(
            'Client',
            'Event',
            `async function f(){${this.#awaitFunctionsTransfer(script)}}; return f()`
        );
    }

    #scanDescr(descr, parentIndex, kind) {
        const el = {};
        el.descr = _.cloneDeep(descr);
        el.parentIndex = parentIndex;

        el.guid = descr.guid;
        el.type = descr.type;
        if (el.type === 'datasetField' || el.type === 'column') {
            const parent = this.ctrlArr[parentIndex];
            el.name = `${parent.name}.${descr.name}`;
        } else el.name = descr.name;

        const objPropFormulas = {};
        const descrPropFormulas = descr?.propFormulas;
        descrPropFormulas &&
            descrPropFormulas.forEach(pf => {
                objPropFormulas[pf.prop] = pf.text;
            });

        el.enabled = !(descr.enabledFormula || objPropFormulas['enabled']);
        el.visible = !(descr.visibleFormula || objPropFormulas['visible']);
        el.hidden = !!(descr.hiddenFormula || objPropFormulas['hidden']);
        el.required = descr?.required;
        el.badge = undefined;
        el.icon = descr.icon;
        el.caption = descr.caption || descr.label;
        el.tooltip = descr.tooltip;
        el.colorToken = undefined;
        el.mask = descr?.mask;
        el.gridWidth = descr?.gridWidth;

        el.kind = kind;

        if (descr.required && descr.datasetName && descr.fieldName) {
            this.requiredControls[`${descr.datasetName}.${descr.fieldName}`] = true;
        }

        if (el.type === 'script') {
            let f;
            try {
                f = this.#createFunction(descr.text);

                this.scripts[descr.name] = new ScriptStore();
            } catch {
                f = undefined;
                console.error(`Script ${this.formName} -> ${el.name} cannot be compiled!`);
            }
            el.func = f;
        }

        if (el.type === 'grid' || el.type === 'chart') {
            function gridGetOptions(descr) {
                return descr.options;
            }

            const optObj = JSON.parse(gridGetOptions(descr) || '""');
            if (typeof optObj === 'object') {
                el.options = optObj;
                const dsName = descr.datasetName;
                if (dsName) {
                    const dsElem = this.find(dsName, 'dataset');
                    if (dsElem) dsElem.options = optObj;
                }
            }
        }

        let index = this.ctrlArr.push(el) - 1;

        // Сканирование только верхнего уровеня
        if (el.type === 'wuiForm') {
            descr.subForms?.forEach(c => this.#scanDescr(c, index, cKind.subForm));
            if (kind === cKind.subForm) {
                return;
            }
        }

        // сканируем дочерние коллекции
        descr.dataObjects?.forEach(c => this.#scanDescr(c, index, cKind.data));
        descr.datasets?.forEach(c => this.#scanDescr(c, index, cKind.data));
        [
            descr.controls,
            descr.items,
            descr.pages,
            descr.buttons,
            descr.menu,
            descr.columns,
            descr.appBar,
            descr.sideBar
        ].forEach(collection => collection?.forEach(c => this.#scanDescr(c, index, cKind.control)));
        descr.scripts?.forEach(c => this.#scanDescr(c, index, cKind.script));

        if (['dataset', 'clientDataset', 'niemcDataset'].includes(el.type)) {
            descr.fields?.forEach(c => this.#scanDescr(c, index, cKind.data));
        }
    }

    find(sNameOrGuid, sType) {
        const el = this.ctrlArr.find(
            el =>
                (el.name === sNameOrGuid || el.guid === sNameOrGuid) &&
                (!sType || sType === el.type)
        );

        // Ищем в родительском PropContainer'е
        return el ?? this.parentPropContainer?.find(sNameOrGuid, sType);
    }

    setElemProperty(propElem, propName, value, key) {
        const normalizeResult = (propName, val) => {
            if (['enabled', 'hidden', 'required'].includes(propName)) {
                return Boolean(val);
            }
            if (['visible'].includes(propName)) {
                console.log(this);
                return Boolean(val);
            }
            if (propName === 'badge') {
                if (typeof val === 'number' && isFinite(val)) {
                    return val > 9 ? '9+' : val.toString();
                }
                return Boolean(val);
            }
            return val;
        };

        if (propElem) {
            if (propElem.hasOwnProperty(propName)) {
                if (key !== undefined) {
                    if (typeof propElem[propName] !== 'object') propElem[propName] = {};
                    propElem[propName][key] = normalizeResult(propName, value);
                } else {
                    propElem[propName] = normalizeResult(propName, value);
                }

                if (
                    propName === 'required' &&
                    propElem.descr?.datasetName &&
                    propElem.descr?.fieldName
                ) {
                    this.requiredControls[
                        `${propElem.descr?.datasetName}.${propElem.descr?.fieldName}`
                    ] = propElem[propName];
                }
            } else {
                console.error(`Component ${propElem.name} does not have property '${propName}'`);
            }
        } else {
            console.error(`Cannot set property to Undefined element`);
        }
    }

    setProperty(ctrlName, propName, value, key) {
        const propElem = this.find(ctrlName);
        if (propElem) {
            this.setElemProperty(propElem, propName, value, key);
        } else {
            console.error(`Component ${ctrlName} not found`);
        }
    }

    /**
     * метод вычисляет свойство доступности или видимости по иерархии, если какой-либо из родителей элемента
     * недоступен, то сам элемент также считается enabled = false
     * @param {*} el
     * @param {string} propName
     * @param {string|number|undefined} key
     * @returns
     */
    getElemHierarchyProperty(el, propName = 'enabled', key = undefined) {
        if (el) {
            let res = this.getElemProperty(el, propName, key);
            while (res) {
                if (el.parentIndex !== undefined) {
                    el = this.ctrlArr[el.parentIndex];
                    res = this.getElemProperty(el, propName, key);
                } else break;
            }
            return res;
        }
    }

    getElemProperty(el, propName, key) {
        if (el) {
            const prop = el[propName];
            if (typeof prop === 'object') {
                return key !== undefined ? prop[key] : prop;
            }
            return prop;
        }
    }

    /**
     * метод вычисляет свойство доступности по иерархии, если какой-либо из родителей элемента
     * недоступен, то сам элемент также считается enabled = false
     * @param {string} ctrlName
     * @returns
     */
    getEnabledProperty(ctrlName) {
        let el = this.find(ctrlName);
        if (el) {
            return this.getElemHierarchyProperty(el);
        }
    }

    getChildrenCtrl(el) {
        if (el) {
            const elIndex = this.ctrlArr.map(ctrl => ctrl.descr.guid).indexOf(el.descr.guid);
            const children = this.ctrlArr.filter(ctrl => ctrl.parentIndex === elIndex);

            if (children.length) return children;

            return [];
        }
    }

    getIsChildrenEnabled(el, enabled = false) {
        if (el) {
            let children = this.getChildrenCtrl(el);

            if (children.length) {
                return children.reduce((prev, current) => {
                    return prev || current.enabled;
                }, enabled);
            }

            return enabled;
        }

        return enabled;
    }

    getProperty(ctrlName, propName) {
        const el = this.find(ctrlName);
        if (el) {
            return el[propName];
        }
    }

    /**
     * Метод получения описателя родительского элемента
     * @param {string} ctrlName
     * @param {string} [parentCtrlType]
     * @returns {*}
     */
    getParent = (ctrlName, parentCtrlType) => {
        let el = this.find(ctrlName);
        if (el) {
            const parent = this.ctrlArr[el.parentIndex];

            if (!parent) return null;

            if (!parentCtrlType || parent?.type === parentCtrlType) {
                return parent;
            } else {
                return this.getParent(parent?.name, parentCtrlType);
            }
        }
    };

    /**
     * метод возвращает элемент родительской формы
     * @returns {*}
     */
    getCurrentForm = () => {
        return this.ctrlArr[0];
    };

    /**
     * метод вовращает элемент первичной родительской формы
     * @returns {*}
     */
    getMainForm = () => {
        if (this.parentPropContainer) return this.parentPropContainer.getMainForm();

        return this.ctrlArr[0];
    };

    /**
     *
     * @param {string} guid
     * @returns {import('../forms/interfaces').FormType}
     */
    getSubForm = guid => {
        return this.subForms.find(form => form.guid === guid);
    };
    /**
     * Метод возвращает описатель поля
     * @param {string} fieldName
     * @param {string} datasetName
     */
    getFieldDescr = (fieldName, datasetName) => {
        const dataset = this.dataStock.getDatasetObj(datasetName);
        return dataset?.descr?.fields?.find(fld => fld.name === fieldName);
    };

    /**
     * Метод возвращает обязательность заполнения поля датасета
     * @param {string} fieldName
     * @param {string} datasetName
     *
     * @return {boolean} field requirement
     */
    getFieldRequirement = (fieldName, datasetName) =>
        this.dataStock.getDatasetObj(datasetName)?.needToSave() &&
        (this.getFieldDescr(fieldName, datasetName)?.required ||
            this.requiredControls[`${datasetName}.${fieldName}`]);

    /**
     * Метод возвращает количество чисел для поля
     * @param {string} fieldName
     * @param {string} datasetName
     *
     * @return {number} field size
     */
    getFieldSize = (fieldName, datasetName) => this.getFieldDescr(fieldName, datasetName)?.dataLen;

    /**
     * Метод возвращает количество чисел после запятой для поля
     * @param {string} fieldName
     * @param {string} datasetName
     *
     * @return {number} field scale
     */
    getFieldScale = (fieldName, datasetName) => this.getFieldDescr(fieldName, datasetName)?.scale;

    /**
     * Метод присваивания странице или аккордеону статуса обязательного заполнения
     *
     * @param {string} guid
     * @param {string} name
     * @param {boolean} required
     */
    setContainerRequirement = (guid, name, required) => {
        const controls = get(this.requiredPages, guid);

        if (controls?.length) {
            set(this.requiredPages, {
                [guid]: [...controls.filter(ctrl => ctrl.name !== name), ...[{ name, required }]]
            });
        } else
            set(this.requiredPages, {
                [guid]: [{ name, required }]
            });
    };

    /**
     * Метод поиска страницы или аккордеона, содержащих контрол
     *
     * @param {string} name - имя контрола, содержащего проверку на обязательность
     * @param {boolean} required - обязательность с учётом текущей заполненности
     */
    setPageRequirement = (name, required) => {
        const pageControl = this.getParent(name, 'page');
        const accordionControl = this.getParent(name, 'accordion');

        if (pageControl) this.setContainerRequirement(pageControl.guid, name, required);
        if (accordionControl) this.setContainerRequirement(accordionControl.guid, name, required);
    };

    /**
     *
     * @param {string} name
     * @param {number | boolean} badge
     * @returns {any}
     */
    setPageBadge = (name, badge = true) => {
        this.setProperty(name, 'badge', badge);
    };

    /**
     * Метод получения статуса обязательного заполнения страницы
     * @param guid - идентификатор страницы
     * @return {string | boolean}
     */
    getPageRequirement = guid => {
        const controls = get(this.requiredPages, guid);

        if (controls?.length) {
            return controls.reduce((prev, current) => current.required || prev, false);
        }

        return false;
    };

    /**
     * Метод фиксации описателя активного поля ввода
     * @param descr -описатель поля
     */
    setActiveCtrlDescr = descr => {
        this.activeCtrlDescr = descr;
    };

    /**
     * Метод добавления динамических ссылок на ресурсы
     * @param resourceLinks
     */
    setCustomResourceLinks = resourceLinks => {
        this.customResourceLinks = resourceLinks;
    };

    /**
     * Метод получения ссылки на ресурс
     *
     * @param {string} rlName
     * @param {string} [rlType]
     * @returns {import('forms/interfaces').ResourceLinkType}
     */
    getResourceLink = (rlName, rlType) => {
        return (
            (this.formDescr.resourceLinks.find(
                RL => RL.name === rlName && (!rlType || RL.resource?.type === rlType)
            ) ||
                this.customResourceLinks.find(
                    RL => RL.name === rlName && (!rlType || RL.resource?.type === rlType)
                )) ??
            this.parentPropContainer?.getResourceLink(rlName, rlType)
        );
    };

    /**
     * Метод получения ссылки на ресурс и формы-носителя для лукапа
     *
     * @param {string} rlName
     * @param {string} [rlType]
     * @returns {{formGuid: string, link: import('forms/interfaces').ResourceLinkType}}
     */
    getLookupResourceLink = (rlName, rlType) => {
        const link =
            this.formDescr.resourceLinks.find(
                RL => RL.name === rlName && (!rlType || RL.resource?.type === rlType)
            ) ||
            this.customResourceLinks.find(
                RL => RL.name === rlName && (!rlType || RL.resource?.type === rlType)
            );

        if (link)
            return {
                link,
                formGuid: this.formDescr.guid
            };

        return {
            link: this.parentPropContainer?.getResourceLink(rlName, rlType),
            formGuid: this.parentPropContainer?.formDescr.guid
        };
    };
}

export default PropContainer;
