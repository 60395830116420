import { FC, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import CustomForm from 'forms/CustomForm';
import validator from 'components/resources/clientResValidator';

import ActionStore from 'store/actionStore';

import SimpleDialog from './SimpleDialog';

const formConfig = (caption: string, mainName: string, detailName: string) => ({
    type: 'wuiForm',
    caption,
    datasets: [
        {
            type: 'clientDataset',
            name: 'Cache',
            keyField: 'idx',
            fields: [
                { type: 'datasetField', name: 'idx', dataType: 'KRN_STRING' },
                { type: 'datasetField', name: mainName, dataType: 'KRN_STRING' },
                { type: 'datasetField', name: detailName, dataType: 'KRN_STRING' }
            ]
        }
    ],
    controls: [
        {
            type: 'grid',
            datasetName: 'Cache',
            columns: [
                { type: 'column', fieldName: mainName },
                { type: 'column', fieldName: detailName }
            ],
            controls: [{ type: 'dbLabel', datasetName: 'Cache', fieldName: detailName }]
        }
    ]
});

interface PropsType {
    editorName: string;
    data: Record<string, string | number | null>[];
    mainName: string;
    detailName: string;
    handleClose: (state: boolean) => void;
    selectMessage?: (row: Record<string, string>) => void;
}

/**
 * Форма для вывода списка вида [{code,message,detail,idx}]
 *
 * idx: ключевое поле
 */
const MessageList: FC<PropsType> = observer(
    ({ editorName, handleClose, data, mainName, detailName, selectMessage }) => {
        const config = useMemo(
            () => validator.validate(formConfig(editorName, mainName, detailName)),
            [editorName, mainName, detailName]
        );

        const propContainerGuid = useMemo(() => uuidv4(), []);
        const formActions = ActionStore.getFormActions(propContainerGuid);

        const ds = useMemo(
            () =>
                formActions?.getDataStock
                    ? formActions.getDataStock().getDatasetObj('Cache')
                    : undefined,
            [formActions]
        );

        useEffect(() => {
            if (ds) {
                ds.groupFields.push(mainName);
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                ds.setCurrentDataChunk(ds.chunkContainer.assignDataChunk(undefined, data));
            }
        }, [ds, data, mainName]);

        const formClose = (state: boolean) => {
            if (state && ds && selectMessage) {
                selectMessage(ds.activeRec);
            }
            handleClose(state);
        };

        return (
            <SimpleDialog editorName="Сообщение" handleClose={formClose}>
                <CustomForm
                    key={config.guid}
                    formConfig={config}
                    propContainerGuid={propContainerGuid}
                />
            </SimpleDialog>
        );
    }
);

export default MessageList;
