import DataChunk from './DataChunk';
import { jsonFetch } from '../utils';
import cloneDeep from 'lodash/cloneDeep';
import DataStore from 'store/dataStore';
import Dataset, { dsStates } from './customDataset';
import { IQueryResponseType, IQueryParamsType, IQueryData } from './dataInterfaces';

export type TChunksCacheType = 'none' | 'cache' | 'join' | 'master' | 'split' | 'global';
export type TChunkKeyType = string | number;

const DEF_KEY: TChunkKeyType = '#';

export default class DataChunkContainer {
    #elemIndex: number = 0;
    ds: Dataset;
    chunks: { [key: TChunkKeyType]: DataChunk };
    cacheType: TChunksCacheType;
    maxCacheSize: number;

    constructor(ds: Dataset) {
        this.ds = ds;
        this.chunks = {};
        // -- this.cacheType --
        // none - без разбиения / кеширования
        // cache - запрос кеширующий элементы по параметрам загрузки
        // join - "объединение" страниц запроса
        // master - запрос подгружающий/сохраняющй элементы по мастер полю
        // split - единый запрос разрезаемый на части по splitField
        // global - запрос навсегда кешируюший все значения в глобальном кеше

        this.cacheType = ds.descr.cacheType || 'none';
        this.maxCacheSize = ds.descr.maxCacheSize || 1000000;

        if (ds.descr.type === 'clientDataset') this.cacheType = 'none';

        if (this.cacheType === 'none') {
            const chunk = new DataChunk(this, DEF_KEY);
            this.chunks[DEF_KEY] = chunk;
            this.ds.setCurrentDataChunk(chunk);
        }
    }

    #addData(key: TChunkKeyType, data: IQueryData, hash?: any) {
        let elem = this.chunks[key];
        if (!elem) {
            if (this.count >= this.maxCacheSize) {
                const mostOld = this.arrElems[0];
                mostOld.close();
                delete this.chunks[mostOld.key];
            }
            elem = new DataChunk(this, key);
        }

        elem.addData(data, hash);
        return elem;
    }

    #joinData(key: TChunkKeyType, data: IQueryData) {
        let elem = this.chunks[key];
        if (!elem) {
            elem = new DataChunk(this, key);
        }
        elem.joinData(data);
        return elem;
    }

    async #getDataFromServer(params: any): Promise<IQueryData> {
        // Если в родительский датасет запись только что добавлена,
        // нет смысла читать детали с сервера (их там пока не может быть)
        if (this.ds?.masterDS?.state === dsStates.dsAppend) {
            return [];
        }
        const requestResult = await jsonFetch<IQueryResponseType>('query', 'POST', params);
        return requestResult.data;
    }

    #getKeyFromParams(params: IQueryParamsType) {
        const keyObj = cloneDeep(params);
        keyObj.info = undefined;
        return JSON.stringify(keyObj);
    }

    // рассматриваем единственное мастер поле
    #getKeyFromMasterField() {
        if (!this.ds.masterDS) return DEF_KEY;

        const masterFieldDescr = this.ds.descr.fields.filter(fld => fld.masterField)[0];
        if (masterFieldDescr) return this.ds.masterDS.getFieldValue(masterFieldDescr.masterField);

        return DEF_KEY;
    }

    #splitData(data: IQueryData, splitField: string) {
        const objRes: { [key: TChunkKeyType]: IQueryData } = {};
        data.forEach(rec => {
            const key = rec[splitField];
            if (!objRes[key]) objRes[key] = [];
            objRes[key].push(rec);
        });
        return Object.keys(objRes).map(key => this.#addData(key, objRes[key]));
    }

    async getData(params: IQueryParamsType) {
        let needServerRead = true;
        let key: TChunkKeyType = DEF_KEY;
        let elem;

        if (this.ds.cdo) {
            needServerRead = false;
            key = this.#getKeyFromMasterField();
            if (key) {
                elem = this.chunks[key];
                // Новая запись с отрицательным идентификатором
                if (!elem && +key < 0) {
                    elem = this.#addData(key, []);
                }
            }
        } else if (this.cacheType === 'cache') {
            key = this.#getKeyFromParams(params);
            elem = this.chunks[key];
            if (elem && !elem.forceRefresh) {
                needServerRead = false;
            }
        } else if (this.cacheType === 'global') {
            key = this.#getKeyFromParams(params);
            elem = this.chunks[key];
            needServerRead = true;
        } else if (this.cacheType === 'master') {
            key = this.#getKeyFromMasterField();
            elem = this.chunks[key];
            if (+key < 0 || (elem && !elem.forceRefresh)) {
                needServerRead = false;
            }
            // Новая запись с отрицательным идентификатором
            if (!elem && +key < 0) {
                elem = this.#addData(key, []);
            }
        } else if (this.cacheType === 'split') {
            needServerRead = !this.count;
            //TODO: надо прочитать данные затем разделить их через this.#splitData
        } else if (this.cacheType === 'join') {
            key = DEF_KEY;
            needServerRead = true;
        }

        if (needServerRead) {
            let data;
            if (this.cacheType === 'global') {
                data = DataStore.getDataFromCache(key.toString());
                if (!data || elem?.forceRefresh) {
                    data = await this.#getDataFromServer(params);
                    DataStore.addDataToCache(key.toString(), data);
                }
            } else {
                data = await this.#getDataFromServer(params);
            }

            if (this.cacheType === 'join') {
                elem = this.#joinData(key, data);
            } else elem = this.#addData(key, data);
        }

        if (elem) {
            this.ds.setCurrentDataChunk(elem);
            elem.forceRefresh = false;
        }

        return elem?.data;
    }

    getNewElemIndex() {
        return this.#elemIndex++;
    }

    assignDataChunk(key: TChunkKeyType | undefined, data: IQueryData, hash?: any) {
        return this.#addData(key ?? DEF_KEY, data, hash);
    }

    get arrElems() {
        return Object.values(this.chunks).sort((el1, el2) => el1.index - el2.index);
    }

    get count() {
        return Object.keys(this.chunks).length;
    }
}
