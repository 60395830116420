import { useState, useEffect, useMemo, FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { Grid } from '@mui/material';
import { DataGrid, GridCellEditCommitParams } from '@mui/x-data-grid';

import ResourceStore, { SettingType } from 'store/resourceStore';

import { FormType, GridType, ColumnType } from 'forms/interfaces';
import { EditorProps } from '../ResInterfaces';

const tableColumns = [
    {
        field: 'visible',
        type: 'boolean',
        headerName: 'Видимость',
        description: 'Видимость поля в диаграмме',
        width: 120,
        disableColumnMenu: true,
        editable: true,
        sortable: false
    },
    {
        field: 'fieldName',
        headerName: 'Имя поля',
        description: 'Наименование поля',
        flex: 1,
        disableColumnMenu: true,
        editable: false,
        sortable: false
    },
    {
        field: 'caption',
        headerName: 'Легенда',
        description: 'Наименование поля на диаграмме',
        flex: 1,
        disableColumnMenu: true,
        editable: true,
        sortable: false
    },
    {
        field: 'color',
        headerName: 'Цвет поля',
        description: 'Цвет поля в диаграмме',
        flex: 1,
        disableColumnMenu: true,
        editable: true,
        sortable: false
    },
    {
        field: 'axis',
        type: 'singleSelect',
        headerName: 'Ось',
        description: 'Размещение данных на оси Y или X',
        flex: 1,
        disableColumnMenu: true,
        editable: true,
        valueOptions: ['Y', 'X'],
        sortable: false,
        width: 150
    }
];

const ChartSettings: FunctionComponent<EditorProps> = observer((props: EditorProps) => {
    // const content = ConfigurationStore.content.resource.chartProps; // TODO
    const { resource, control, setActions } = props;

    const [value, setValue] = useState(props.value as string | object | null);

    const [rowList, setRowList] = useState<any[]>([]);

    const form = resource as FormType;
    const grid = control as unknown as GridType;
    const mainDataset = form.datasets?.filter(ds => ds.name === grid.datasetName)[0];
    const resGuid = form.isGenerated ? form?.parentForm?.guid || form.guid : form.guid;
    const ctrlGuid = form.isGenerated
        ? mainDataset?.request?.guid || mainDataset?.request
        : grid.guid;

    const propValue: any = useMemo(() => {
        const defChartSettings = {
            columns: []
        };

        const obj =
            typeof value === 'string' && value ? (JSON.parse(value) as object) : value || {};
        return { ...defChartSettings, ...(obj as object) };
    }, [value]);

    useEffect(() => {
        if (!form || !grid) {
            return;
        }

        const ds = (form.datasets || []).find(dataset => dataset.name === grid.datasetName);

        const rows: any = (grid.columns || []).map((column: any) => {
            const pvColumn = propValue.columns.find(
                (pvc: any) => pvc.fieldName === column.fieldName
            );
            const dsField = ds?.fields.find(field => field.name === column.fieldName);
            return {
                id: column.fieldName,
                fieldName: column.fieldName,
                caption: pvColumn?.caption || column.caption || column.fieldName,
                visible: pvColumn ? pvColumn.visible : !dsField?.hidden,
                color: pvColumn?.color || '',
                axis: pvColumn?.axis || ''
            };
        });

        setRowList(rows);
    }, [form, grid]);

    const actions = useMemo(
        () => ({
            getName: () => grid.caption,
            getValue: () => {
                const res: any = {
                    columns: rowList.map((row: any) => ({
                        fieldName: row.fieldName,
                        caption: row.caption,
                        visible: !!row.visible,
                        color: row.color || '',
                        axis: row.axis || ''
                    }))
                };
                return JSON.stringify(res);
            },
            delete: async (type?: SettingType) => ResourceStore.dropSettings(resGuid, type),
            load: async (type?: SettingType) => {
                const settings = await ResourceStore.loadSettings(resGuid, ctrlGuid, type);
                setValue(settings);
            },
            save: async (type: SettingType = SettingType.user) =>
                ResourceStore.saveSettings(resGuid, ctrlGuid, type, actions.getValue())
        }),
        [rowList, grid]
    );

    useEffect(() => {
        if (setActions) {
            setActions(actions);
        }
    }, [setActions, actions]);

    const onCellEditCommit = (params: GridCellEditCommitParams) => {
        const row = Object.values(rowList).find(item => item.id === params.id);
        if (row) {
            row[params.field] = params.value;
        }
        setRowList(rowList);
    };

    return (
        <Grid container direction="column" sx={{ height: '100%' }}>
            <Grid item flex="1 1">
                <DataGrid
                    columns={tableColumns}
                    rows={rowList}
                    onCellEditCommit={onCellEditCommit}
                    sx={{ minHeight: '400px' }}
                    hideFooterPagination
                />
            </Grid>
        </Grid>
    );
});

export default ChartSettings;
