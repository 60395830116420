import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import Iframe from 'components/Iframe/Iframe';
import 'components/Iframe/iframe.css';

import useCtrlData from 'hooks/ctrlData';

import Loading from 'components/utils/Loading/Loading';

import { ControlPropsType, DBDocumentViewerType } from 'forms/interfaces';

import useDatasetLoading from 'hooks/datasetLoading';
import useCtrlProps from 'hooks/ctrlProps';

interface PropsType extends ControlPropsType {
    descr: DBDocumentViewerType;
    content?: string;
    minWidth?: number;
}

const DBHtmlViewer: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, content, minWidth }) => {
        const { guid, datasetName, fieldName, native = true } = descr;
        const { dataVal, dataset } = useCtrlData(propContainer, datasetName, fieldName);
        const { ctrlVisible } = useCtrlProps(propContainer, guid);
        const { loading, loadingType, loadingMessage } = useDatasetLoading(dataset);

        const [htmlCont, setHtmlCont] = useState('<b>...</b>');

        useEffect(() => {
            if (dataVal || content) {
                setHtmlCont(content || dataVal);
            } else setHtmlCont('<b>...</b>');
        }, [dataVal, content]);

        const paperStyle = {
            height: '100%'
        };

        const renderHTMLNative = () => <div dangerouslySetInnerHTML={{ __html: htmlCont }} />;

        const renderHTML = () =>
            native ? (
                renderHTMLNative()
            ) : (
                <Iframe content={htmlCont} styleSheet="table {width: 100%;}" minWidth={minWidth} />
            );

        return ctrlVisible ? (
            <Box sx={{ height: '100%' /* padding: '10px' */ }}>
                <Box sx={paperStyle}>
                    {loading ? (
                        <Loading message={loadingMessage} type={loadingType} delay />
                    ) : (
                        renderHTML()
                    )}
                </Box>
            </Box>
        ) : null;
    }
);

export default DBHtmlViewer;
