import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import useTaskTree from 'hooks/useTaskTree';

import FlowChart from 'components/Charts/FlowChart';
import Loading from 'components/utils/Loading/Loading';

import { makeNodes, makeEdges } from './utils';

import { FlowChartControlType, NodeElement, EdgeElement } from './interfaces';

import NodeFlow from './NodeFlow/NodeFlow';

const DBFlowChart: FunctionComponent<FlowChartControlType> = observer(
    ({ descr, propContainer }) => {
        const {
            render = 'elk',
            showMap,
            showControls,
            direction,
            directionSwitcher,
            fitView,
            zoom
        } = descr;

        const [selected, setSelected] = useState('');
        const [nodes, setNodes] = useState<NodeElement[]>([]);
        const [edges, setEdges] = useState<EdgeElement[]>([]);

        const { value, dataset, parentTask, loading, loadingType, loadingMessage } = useTaskTree(
            descr,
            propContainer
        );

        useEffect(() => {
            if (dataset?.recCount && parentTask && !loading) {
                setNodes(
                    makeNodes(
                        descr,
                        dataset,
                        `${(value as string | number) || ''}`,
                        propContainer,
                        parentTask
                    )
                );
                setEdges(makeEdges([...[parentTask], ...dataset.data]));
            } else if (nodes.length || edges.length) {
                setNodes([]);
                setEdges([]);
            }
        }, [loading, dataset, value, parentTask?.tt_node_id]);

        if (loading) return <Loading delay type={loadingType} message={loadingMessage} />;

        const onNodeClickHandle = (event: React.MouseEvent, node: any) => {
            setSelected(node.id);
            dataset?.findById(node.id, true);
        };

        return nodes?.length && edges?.length ? (
            <FlowChart
                render={render}
                initNodes={nodes}
                initEdges={edges}
                selectedNode={selected}
                zoom={zoom}
                fitChart={fitView}
                showMap={showMap}
                showControls={showControls}
                initDirection={direction}
                directionSwitcher={directionSwitcher}
                onNodeClick={onNodeClickHandle}
                NodeFlow={NodeFlow}
            />
        ) : (
            <Loading type="empty" message="Нет данных для построения" />
        );
    }
);

export default DBFlowChart;
