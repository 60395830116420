import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { SvgIconProps } from '@mui/material/SvgIcon';

import CustomSvgIcon from 'components/Image/CustomSvgIcon';
import WithTooltip from 'components/utils/withTooltip';

import PropContainer from 'dataObj/PropContainer';

import IconSetStore from 'store/iconSetStore';

import useIsMounted from 'hooks/isMounted';
import useCtrlProps from 'hooks/ctrlProps';

import { ResourceType } from 'forms/interfaces';

type SVGIconProps = SvgIconProps & {
    descr: { guid?: string; iconSet: string; icon: string; caption?: string; tooltip?: string };
    propContainer: PropContainer;
};

const SVGIcon: FunctionComponent<SVGIconProps> = observer(({ descr, propContainer, ...props }) => {
    const { guid, iconSet, tooltip } = descr;

    const {
        getLoadingIconSet,
        getIcon,
        checkIconSet,
        getLinkedIconSet,
        getLoadingIcon,
        getEmptyIcon
    } = IconSetStore;

    const { ctrlVisible, ctrlHidden, ctrlIcon } = useCtrlProps(propContainer, guid || '');

    const [icon, setIcon] = useState(iconSet && descr.icon ? getEmptyIcon() : '');
    const [iconSetID, setIconSetID] = useState('');

    const isLoading = getLoadingIconSet(iconSetID);
    const isMounted = useIsMounted();

    const iconSetter = (newIcon: string) => {
        isMounted() && setIcon(newIcon);
    };

    useEffect(() => {
        if (iconSet && (ctrlIcon || descr.icon)) {
            const resourceLink = propContainer.getResourceLink(
                iconSet,
                ResourceType.iconSet
            )?.resource;

            if (resourceLink) setIconSetID(resourceLink.code);
        }
    }, [ctrlIcon, descr, propContainer]);

    useEffect(() => {
        if (iconSetID && !isLoading) {
            const cachedIcon = getIcon(iconSetID, ctrlIcon || descr.icon);

            if (cachedIcon) {
                iconSetter(cachedIcon);
            } else if (!checkIconSet(iconSetID)) {
                getLinkedIconSet(iconSetID)
                    .then(() => iconSetter(getIcon(iconSetID, ctrlIcon || descr.icon)))
                    .catch(err => console.error(err.message));
            } else iconSetter('');
        } else {
            isLoading && iconSetter(getLoadingIcon());
        }
    }, [isLoading, descr, iconSetID, ctrlIcon]);

    if (ctrlHidden) return null;

    return (
        <WithTooltip title={tooltip} wrap={!!tooltip}>
            <CustomSvgIcon
                icon={icon}
                style={{ visibility: ctrlVisible ? 'visible' : 'hidden' }}
                {...props}
            />
        </WithTooltip>
    );
});

export default SVGIcon;
