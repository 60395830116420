import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import SimpleDialog from 'components/Feedback/SimpleDialog';
import Loading from 'components/utils/Loading/Loading';
import ModalForm from 'hoc/ModalForm';

import ActionStore from 'store/actionStore';
import ConfigurationStore from 'store/configurationStore';

import CustomForm from 'forms/CustomForm';
import { getControlClass } from 'forms/form-utils';
import FilterDialog from 'forms/controls/Filter/FilterDialog';

import useMaxWidth from 'hooks/maxWidth';
import useBackdrop from 'hooks/backdrop';
import useEditorCustomButtons from 'hooks/editorCustomButtons';

import { OutputType, ResultType } from './interfaces';

const ScriptButtonOutput: FunctionComponent<OutputType> = observer(({ scriptButtonStore }) => {
    const {
        form,
        formDescr,
        cb,
        parentPropContainer,
        parentDataStock,
        formName,
        values,
        editMode,
        modalMessage,
        setForm,
        setFormDescr,
        setValues,
        setModalMessage,
        customControl
    } = scriptButtonStore;

    const { content } = ConfigurationStore;

    const [error, setError] = useState(false);

    const propContainerGuid = useMemo(() => uuidv4(), []);
    const formActions = ActionStore.getFormActions(propContainerGuid);

    const hideForm = useCallback(() => {
        setForm(null);
        setFormDescr(null);
    }, [setForm, setFormDescr]);

    useEffect(() => {
        if (error) {
            hideForm();
        }
    }, [error]);

    const handleFormClose = async (state: boolean | object, payout?: any) => {
        if (state && typeof state === 'boolean' && formActions && editMode) {
            formActions
                .save()
                .then((result?: ResultType | false) => {
                    if (result === false) return;

                    if (!result?.err?.length) {
                        hideForm();
                    }
                })
                .catch(err => console.error(err.message));
        } else if (state) {
            setValues([state]);
            hideForm();
        } else {
            hideForm();
        }

        cb && cb()(state, formActions ? formActions.getDataStock() : null, payout);
    };

    const settled = useMemo(
        () => !!formActions && formActions?.isReady,
        [formActions, formActions?.isReady, error]
    );

    const maxWidth = useMaxWidth(formActions);

    const minHeight = useMemo(() => {
        if (formActions) {
            const height = getControlClass(formActions.getFormDescr().classes, 'minHeight')?.split(
                '-'
            )?.[1] as string;

            return height ? `calc(${height}vh - 148px)` : undefined;
        }

        return;
    }, [formActions]);

    useBackdrop(!settled && (form || formDescr?.guid) && !error);

    const customButtons = useEditorCustomButtons(formActions, content);

    const renderCustomControl = () => {
        const { type, props } = customControl || {};
        if (type === 'filterDialog') {
            return <FilterDialog {...props} />;
        }
    };

    return (
        <>
            {customControl?.type && renderCustomControl()}
            {(form || formDescr) && (
                <ModalForm
                    formGuid={form}
                    maxWidth={maxWidth as false | 'xl' | 'md' | 'sm' | 'xs' | 'lg'}
                    handleClose={handleFormClose}
                    editorName={formName}
                    sx={{
                        visibility: settled ? 'visible' : 'hidden',
                        '& .MuiPaper-root': {
                            overflow: 'hidden'
                        },
                        '& .MuiDialogContent-root ': {
                            position: 'relative',
                            minHeight: minHeight ?? (!formActions?.isReady ? 300 : null)
                        }
                    }}
                    customActions={customButtons}
                    disabled={!formActions?.validated}
                    propContainerGuid={propContainerGuid}
                >
                    <Grid
                        item
                        sx={
                            minHeight
                                ? {
                                      position: 'absolute',
                                      height: '100%',
                                      width: 'calc(100% - 48px)'
                                  }
                                : {}
                        }
                    >
                        {form || formDescr ? (
                            <CustomForm
                                key={propContainerGuid}
                                formGuid={form || formDescr?.guid}
                                formConfig={formDescr}
                                extParamVals={values}
                                initEditMode={editMode}
                                parentDataStock={parentDataStock}
                                parentPropContainer={parentPropContainer}
                                propContainerGuid={propContainerGuid}
                                handleClose={hideForm}
                                handleError={(err: boolean) => setError(err)}
                                modal
                            />
                        ) : (
                            <Loading type="empty" message={content.controls.data.noEditor} />
                        )}
                    </Grid>
                </ModalForm>
            )}
            {modalMessage && modalMessage.type ? (
                <SimpleDialog editorName="Сообщение" handleClose={() => setModalMessage({})}>
                    <Typography>{modalMessage.text}</Typography>
                </SimpleDialog>
            ) : null}
        </>
    );
});

export default ScriptButtonOutput;
