import {
    FunctionComponent,
    DetailedHTMLProps,
    HTMLAttributes,
    useEffect,
    forwardRef,
    RefAttributes,
    useRef,
    MutableRefObject
} from 'react';

import { EventType } from '../../../interfaces';

type EventDivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    'max-width'?: string;
    'column-index'?: string;
    'event-type'?: string;
    allocated?: boolean;
};

const EventDiv: FunctionComponent<Omit<EventDivProps, 'ref'> & RefAttributes<HTMLDivElement>> =
    forwardRef(({ children, ...props }, ref) => (
        <div ref={ref} {...props}>
            {children}
        </div>
    ));

const Event: FunctionComponent<EventType> = ({
    eventKey,
    eventId,
    ownerId,
    date,
    start,
    end,
    eventClickedHandler,
    type,
    exceptional = false,
    title,
    color,
    onRender,
    data,
    event,
    eventItemTemplateResolver
}) => {
    const columnId = `${ownerId}-${date.format('YYYY-MM-DD')}`;

    const currentElement = useRef(null) as MutableRefObject<any>;

    useEffect(() => {
        if (currentElement.current) {
            const element = currentElement.current;
            onRender && onRender(currentElement.current, true);

            return () => {
                onRender && onRender(element, false);
            };
        }
    }, []);

    const handleClick = () => {
        eventClickedHandler({
            id: eventId,
            title,
            date,
            start,
            end
        });
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <EventDiv
            id={`event-${eventKey}`}
            ref={currentElement}
            className="scheduler-event"
            onClick={handleClick}
            onKeyDown={handleClick}
            column-index={columnId}
            style={{ visibility: 'hidden' }}
            event-type={type || 'default'}
        >
            {eventItemTemplateResolver ? (
                eventItemTemplateResolver(
                    data,
                    event,
                    color || '#1274AC',
                    !!event.isStart,
                    !!event.isEnd
                )
            ) : (
                <div
                    className="event-body"
                    style={{
                        backgroundColor: color,
                        opacity: exceptional ? 0.5 : 1
                    }}
                >
                    <div title={title} className="event-title">
                        {title}
                    </div>
                </div>
            )}
        </EventDiv>
    );
};

export default Event;
