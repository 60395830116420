import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';

import PropContainer from 'dataObj/PropContainer';

import { PanelType } from 'forms/interfaces';
import { Badge } from '@mui/material';
import useCtrlProps from '../../../hooks/ctrlProps';

interface PropsType {
    caption: string;
    children?: ReactNode;
    defaultExpanded?: boolean;
    descr: PanelType;
    propContainer: PropContainer;
}

const Accordion: FunctionComponent<PropsType> = observer(
    ({ caption, descr, defaultExpanded = true, propContainer, children }) => {
        const { ctrlBadge } = useCtrlProps(propContainer, descr.guid);

        const [expanded, setExpanded] = useState(defaultExpanded);
        const [ready, setReady] = useState(defaultExpanded);

        useEffect(() => {
            if (!ready) setReady(!(!defaultExpanded && !expanded));
        }, [ready, defaultExpanded, expanded]);

        const handleExpanded = () => {
            setExpanded(prev => !prev);
        };

        const accordionRequire: string | boolean = propContainer.getPageRequirement(descr.guid);

        return (
            <MuiAccordion
                disableGutters
                expanded={expanded}
                onChange={handleExpanded}
                elevation={2}
                variant="outlined"
                sx={theme => ({
                    '&.MuiPaper-root': {
                        m: 2,
                        borderRadius: 1,
                        height: expanded ? `calc(100% - ${theme.spacing(5)}) !important` : 'auto'
                    },
                    '&:before': {
                        display: 'none'
                    },
                    '& .MuiCollapse-root': {
                        height: `${expanded ? 'calc(100% - 31px)' : 0} !important`
                    },
                    '& .MuiCollapse-wrapper': { height: '100%' },
                    '& .MuiAccordion-region': { height: '100%', overflow: 'auto' }
                })}
                TransitionProps={{ unmountOnExit: false }}
            >
                <AccordionSummary
                    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
                    aria-controls="panel-content"
                    id="panel-header"
                    sx={theme => ({
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? 'rgba(255, 255, 255, .12)'
                                : 'rgba(0, 0, 0, .09)',
                        flexDirection: 'row-reverse',
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            marginLeft: theme.spacing(1)
                        },
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                            transform: 'rotate(90deg)'
                        },
                        '& .MuiAccordionSummary-content': {
                            marginLeft: theme.spacing(2)
                        }
                    })}
                >
                    {accordionRequire || ctrlBadge ? (
                        <Badge
                            color="warning"
                            variant={accordionRequire || ctrlBadge === true ? 'dot' : 'standard'}
                            sx={{
                                '& .MuiBadge-badge': {
                                    right: -8,
                                    padding: '0 4px'
                                }
                            }}
                            badgeContent={
                                accordionRequire || ctrlBadge !== true ? ctrlBadge : undefined
                            }
                        >
                            <Typography>{caption}</Typography>
                        </Badge>
                    ) : (
                        <Typography>{caption}</Typography>
                    )}
                </AccordionSummary>
                <AccordionDetails
                    sx={theme => ({
                        pt: 4,
                        borderTop: '1px solid rgba(0, 0, 0, .125)',
                        '&.MuiAccordionDetails-root': {
                            height: `calc(100% - (${theme.spacing(6)} + 1px))`
                        } // фиксированная высота
                    })}
                >
                    {ready ? children : null}
                </AccordionDetails>
            </MuiAccordion>
        );
    }
);

export default Accordion;
