import moment from 'moment';

import { Event, SchedulerData, ViewTypes } from 'react-big-scheduler';

import DatasetActions from 'store/actionStore/dataset/DatasetActions';
import { jsonFetch } from 'utils/index';

export const getStateColor = (state: string) => {
    switch (state) {
        case 'R':
            return '#1B5DAA';

        case 'S':
            return '#1B5DAA';

        case 'B':
            return '#F3A505';

        case 'G':
            return '#1F8B24';

        case 'C':
            return '#aa0000';

        case 'F':
            return '#555';

        default:
            return '#555';
    }
};

export type ResourceType = {
    id: string;
    name: string;
    parentId?: string;
    groupOnly?: boolean;
};

export interface EventType extends Event {
    bgColor: string;
    state: string;
    datasetActions: DatasetActions;
    props: any;
}

export const makeResources = (
    data: any[],
    resourceFieldName?: string,
    resourceIdField?: string
) => {
    const resources: ResourceType[] = [];

    data.forEach(resource => {
        const yLabel = resource[resourceFieldName || 'y_label'];
        const hLabel = resource.h_label?.split('|');

        if (hLabel?.length) {
            // Проходим массив наследования в обратном порядке и добавляем ресурсы
            for (let i = hLabel.length - 1; i >= 0; i--) {
                if (!resources.find(r => r.id === hLabel[i])) {
                    resources.push({
                        id: hLabel[i],
                        name: hLabel[i],
                        parentId: hLabel[i - 1],
                        groupOnly: true
                    });
                }
            }
        }

        const parentId = hLabel?.length ? hLabel[hLabel.length - 1] : null;
        const resourceId = resource[resourceIdField || 'id'] || yLabel;

        if (!resources.find(r => r.id === resourceId)) {
            resources.push({
                id: resourceId,
                name: yLabel,
                parentId
            });
        }
    });

    return resources;
};

export const makeEvents = (
    data: any[],
    datasetActions: DatasetActions,
    resourceFieldName?: string,
    resourceIdField?: string,
    labelField?: string,
    roundDate = false
): EventType[] =>
    data
        .sort((e1: any, e2: any) => moment(e1.date_from).diff(moment(e2.date_from)))
        .map((event: any, id) => {
            const yLabel = event[resourceFieldName || 'y_label'];
            const label = event[labelField || 'data_label'];

            const resourceId = event[resourceIdField || 'id'] || yLabel;

            return {
                id,
                title: label,
                resourceId,
                start: (roundDate
                    ? moment(event.date_from).hour(0).minute(1)
                    : moment(event.date_from).local(false)
                ).format('YYYY-MM-DD HH:mm:ss'),
                end: (roundDate
                    ? moment(event.date_to).hour(0).minute(0)
                    : moment(event.date_to).local(false)
                ).format('YYYY-MM-DD HH:mm:ss'),
                bgColor: getStateColor(event.exec_state),
                state: event.exec_state,
                datasetActions,
                props: event
            };
        });

export const generateSchedulerData = (
    data: any,
    resourceName: string,
    wrapperWidth: number,
    wrapperHeight: number,
    resourceWidth: number,
    viewType: ViewTypes,
    startDate?: string
) => {
    const newSchedulerData = new SchedulerData(
        moment().format('YYYY-MM-DD'),
        viewType,
        false,
        false,

        {
            headerEnabled: false,
            resourceName,
            taskName: 'Работа',
            crossResourceMove: false,
            selectedAreaColor: 'rgba(150,150,150,0.21)',
            schedulerWidth: wrapperWidth,
            resourceTableWidth: resourceWidth,
            agendaResourceTableWidth: resourceWidth,
            dayResourceTableWidth: resourceWidth,
            weekResourceTableWidth: resourceWidth,
            monthResourceTableWidth: resourceWidth,
            yearResourceTableWidth: resourceWidth,
            quarterResourceTableWidth: resourceWidth,
            nonAgendaDayCellHeaderFormat: 'HH:mm',
            nonAgendaOtherCellHeaderFormat: 'ddd, DD.MM',
            dayStartFrom: 8,
            dayStopTo: 20,
            schedulerMaxHeight: wrapperHeight,
            dayCellWidth: (wrapperWidth - resourceWidth) / 26,
            weekCellWidth: (wrapperWidth - resourceWidth) / 7,
            monthCellWidth: (wrapperWidth - resourceWidth) / 15,
            quarterCellWidth: (wrapperWidth - resourceWidth) / 15,
            yearCellWidth: (wrapperWidth - resourceWidth) / 15,

            views: []
        } as any,
        {
            isNonWorkingTimeFunc: (curSchedulerData: any, time: any) => {
                const { localeMoment } = curSchedulerData;
                if (curSchedulerData.cellUnit === 1 /* CellUnits.Hour */) {
                    const hour = localeMoment(time).hour();
                    if (hour < 10 || hour > 19) return true;
                } else {
                    const dayOfWeek = localeMoment(time).weekday();
                    if (dayOfWeek === 5 || dayOfWeek === 6) return true;
                }

                return false;
            }
        }
    ) as any;

    if (startDate) newSchedulerData.setDate(startDate);

    newSchedulerData.localeMoment.locale('ru-ru');
    newSchedulerData.setResources(data.resources);
    newSchedulerData.setEvents(data.events);

    return newSchedulerData;
};

export const getMinutes = (minutes: number, dayLength = 8, relative = false) => {
    const days = Math.floor(minutes / (dayLength * 60));
    const rem = minutes % (dayLength * 60);
    const rest = rem && relative ? 24 * 60 * (minutes / (dayLength * 60)) : rem;

    return days * 24 * 60 + rest;
};

export const getRelativeMinutes = (minutes: number, dayLength = 8) =>
    getMinutes(minutes, dayLength, true);

export const getDuration = (minutes: number) => {
    const duration = moment.duration(minutes, 'minutes');

    if (minutes < 60) {
        return `${duration.minutes()} мин`;
    }
    if (minutes < 24 * 60) {
        return `${duration.hours()} ч ${duration.minutes()} мин`;
    }
    return `${duration.days()} д ${duration.hours()} ч ${duration.minutes()} мин`;
};

export const getExecRatio = (duration: number, execTime: number) =>
    Math.round((execTime / duration) * 100);

export const showEditor = async (
    eventItem: any,
    cdotype_id: number,
    tt_rec_id: number,
    setBackdrop: (backdrop: boolean) => unknown
) => {
    const body = { cdotype_id, formType: 'editor' };
    try {
        setBackdrop(true);

        const res = (await jsonFetch('dataobject/form', 'POST', body, {}, 'json')) as any;
        if (res) {
            const { code } = res;

            eventItem?.datasetActions.setEditAction('edit');
            eventItem?.datasetActions.setEditor(true, code, {
                tt_rec_id
            });
        }
    } catch (err: any) {
        console.error(err.message);
    }

    setBackdrop(false);
};
