import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { observer } from 'mobx-react';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Toolbar, Box, Typography, IconButton, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import { ControlPropsType, MenuType } from 'forms/interfaces';
import SVGIcon from 'forms/controls/SVGIcon';

import MainMenuStore from 'store/mainMenuStore';

import Splitter from './Splitter';
import CustomDrawer from './CustomDrawer';
import ListContent from './ListContent';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 4, 0, 4),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
}));

const MenuButtonWrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 4, 0, 4),
    zIndex: theme.zIndex.drawer,
    ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    })
}));

interface PropsType extends ControlPropsType {
    descr: MenuType;
    fixed?: boolean;
}

const SideBar: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, fixed = false }) => {
        const { open, defaultWidth, setOpen, initMenuSelections, setMenuVariant, setMenuPosition } =
            MainMenuStore;
        const history = useHistory();
        const location = useLocation();

        const [width, setWidth] = useState(defaultWidth);

        useEffect(() => {
            // Инициализируем только при получении пути
            if (['POP', 'REPLACE'].includes(history.action)) {
                initMenuSelections();
                setMenuVariant(descr.variant);
                setMenuPosition();
                setOpen(true);
            }
        }, [location, history, descr.variant]);

        const items = useMemo(() => {
            if (descr.items) {
                return descr.items;
            }
            return [];
        }, [descr?.guid]);

        const handleOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
            setWidth(defaultWidth);
        };

        return (
            <div>
                {fixed ? (
                    <AppBar key={`app-bar-${descr.guid}`} position="fixed">
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={open ? handleClose : handleOpen}
                                edge="start"
                                sx={{
                                    marginRight: 5,
                                    marginLeft: -1.5
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                variant="h5"
                                noWrap
                                component="div"
                                sx={{ mr: 'auto', display: 'inline-flex' }}
                            >
                                {descr.icon ? (
                                    <SVGIcon
                                        descr={descr}
                                        propContainer={propContainer}
                                        sx={{ m: 'auto', mr: 2 }}
                                        fontSize="inherit"
                                    />
                                ) : null}
                                {descr?.caption}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                ) : null}
                <CustomDrawer
                    id={`drawer-${descr.guid}`}
                    key={`drawer-${descr.guid}`}
                    variant={fixed ? 'permanent' : 'temporary'}
                    open={open}
                >
                    <DrawerHeader />
                    <Divider />
                    <Box
                        className="scrollbar-hidden"
                        sx={{
                            width,
                            overflowY: 'auto'
                        }}
                        role="presentation"
                    >
                        <ListContent items={items} propContainer={propContainer} />
                    </Box>
                    <Splitter setWidth={setWidth} />
                </CustomDrawer>
                {!fixed && (
                    <MenuButtonWrapper id={`open-menu-btn-${descr.guid}`}>
                        <IconButton onClick={handleOpen}>
                            <MenuIcon />
                        </IconButton>
                    </MenuButtonWrapper>
                )}
            </div>
        );
    }
);

export default SideBar;
