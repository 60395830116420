import React, { useEffect, useState, FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import ExtRefsPage from 'components/resources/ResEditor/ExtRefsPage';
import Empty from 'components/utils/Empty/Empty';

import CustomForm from 'forms/CustomForm';

import ConfigurationStore from 'store/configurationStore';
import RoutingStore from '../../store/routingStore';

import styles from './ExternalForm.module.scss';

interface GetComponentOptionsType {
    component: string;
    guid?: string;
    table?: string;
    tableId?: string;
    extParamVals: any;
}

const getComponent = ({
    component,
    guid,
    table,
    tableId,
    extParamVals
}: GetComponentOptionsType) => {
    const { content } = ConfigurationStore;

    switch (component) {
        case 'ext-ref-page':
            return <ExtRefsPage />;

        case 'form':
            return guid ? (
                <CustomForm key={guid} formGuid={guid} extParamVals={extParamVals} />
            ) : (
                <Empty message={content.application.external.error.formId} />
            );

        case 'edit':
            return table ? (
                <CustomForm
                    key={table}
                    formGuid={table}
                    tableId={tableId}
                    extParamVals={extParamVals}
                    initEditMode={tableId ? 'edit' : null}
                />
            ) : (
                <Empty message={content.application.external.error.gridName} />
            );

        default:
            return <Empty message={content.application.external.error.pageExists} />;
    }
};

export type ExternalComponentType = 'form' | 'edit' | 'ext-ref-page';

interface ExternalFormPropsType {
    component?: ExternalComponentType;
    guid?: string;
    table?: string;
    tableId?: string;
}

const ExternalForm: FunctionComponent<ExternalFormPropsType> = observer(
    ({ component, ...props }) => {
        interface ParamsType {
            [key: string]: any;
        }

        const history = useHistory();

        RoutingStore.setHistory(history);

        const params: any = useParams();

        const [guid, setGuid] = useState('');
        const [table, setTable] = useState('');
        const [tableId, setTableId] = useState('');
        const [extParamVals, setExtParamVals] = useState({});

        useEffect(() => {
            const queryParams = new URLSearchParams(window.location.search);

            if (ConfigurationStore.isAuthenticated) {
                const tmpParams: ParamsType = {};

                setGuid(queryParams.get('formGuid') || props.guid || '');
                setTable(queryParams.get('table') || props.table || '');
                setTableId(queryParams.get('id') || props.tableId || '');

                for (const key of queryParams.keys())
                    if (['token', 'formGuid', 'table', 'id'].indexOf(key) === -1)
                        tmpParams[key] = queryParams.get(key);

                setExtParamVals(tmpParams);
            }
        }, []);

        const getCurrentComponent = () => {
            // В случае когда авторизация прошла успешно выводим форму
            if (ConfigurationStore.isAuthenticated) {
                return getComponent({
                    component: component || params.form,
                    guid,
                    table,
                    tableId,
                    extParamVals
                });
            }

            // В противном случае делаем вывод о недействительности токена -
            // если при запросе вывалится 401 ошибка, то произойдёт логаут и мы попадаем сюда
            return <Empty message={ConfigurationStore.content.application.auth.tokenInvalid} />;
        };

        return <div className={styles.wrapper}>{getCurrentComponent()}</div>;
    }
);

export default ExternalForm;
