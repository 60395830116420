import { observable, action, makeObservable } from 'mobx';

import { EditModeType, MenuItemType } from 'forms/interfaces';

const findItem = (currentItem: string, items: MenuItemType[]) => {
    function findPath(item: MenuItemType, path: string[]): string[] | null {
        if (item.route === currentItem || item.name === currentItem) {
            return [...path, item.name];
        }
        if (item.items?.length) {
            for (const child of item.items) {
                const childPath = findPath(child, [...path, item.name]);
                if (childPath) {
                    return childPath;
                }
            }
        }
        return null;
    }

    for (const object of items) {
        const path = findPath(object, []);
        if (path) {
            return path;
        }
    }

    return null;
};

const OPEN_WIDTH = 300;

class MainForm {
    @observable selections: string[] = [];

    @observable currentMenuItem = '';
    @observable selectedItems: string[] = [];
    @observable selectedPath: string[] = [];
    @observable open = true;

    @observable modalForm = '';
    @observable modalFormValues: { [key: string]: any } = {};

    @observable formEditMode: EditModeType = 'view';

    public defaultWidth = OPEN_WIDTH;

    constructor() {
        makeObservable(this);
    }

    @action initSelections = (currentItem: string, items: MenuItemType[]) => {
        this.selections = findItem(currentItem, items) || [];
    };

    @action setCurrentItem = (guid: string) => {
        this.currentMenuItem = guid;
    };

    @action setSelectedItems = (path: string[]) => {
        this.selectedItems = path;
    };

    @action setOpen = (open: boolean) => {
        this.open = open;
    };

    @action setModalForm = (form = '') => {
        this.modalForm = form;
    };

    @action setModalFormValues = (values: { [key: string]: any } = {}) => {
        this.modalFormValues = values;
    };

    @action setFormEditMode = (editMode: EditModeType) => {
        this.formEditMode = editMode;
    };
}

const MainFormStore = new MainForm();
export default MainFormStore;
