import { FunctionComponent, MouseEvent } from 'react';

import MUIIconButton, { IconButtonProps } from '@mui/material/IconButton';

import WithTooltip from 'components/utils/withTooltip';

const IconButton: FunctionComponent<IconButtonProps> = props => {
    const { title } = props;

    const handleMouseUp = (event: MouseEvent<HTMLButtonElement>) => {
        event.currentTarget.blur();
    };

    return (
        <WithTooltip title={title}>
            <MUIIconButton onMouseUp={handleMouseUp} {...props} />
        </WithTooltip>
    );
};

export default IconButton;
