const langFra = {
    auth: {
        signIn: 'Se Connecter',
        username: 'Pseudo',
        password: 'Mot de passe',
        enter: 'Entrer',
        tokenAuth: 'Autorisation par jeton',
        tokenInvalid: "Jeton d'autorisation non valide",
        post: 'poste'
    },
    external: {
        error: {
            formId: 'Aucun identifiant de formulaire spécifié',
            gridName: 'Aucun nom de table spécifié',
            pageExists: "La page demandée n'existe pas",
            requestFailed: 'La demande a échoué!'
        }
    },
    settings: {
        clear: 'Réinitialiser',
        save: 'Enregistrer',
        load: 'Télécharger',
        delete: 'Effacer',
        user: `pour l'utilisateur`,
        allUsers: 'pour tous les utilisateurs',
        global: `à l'échelle mondiale`
    },
    buttons: {
        ok: 'Ok',
        cancel: 'Annuler',
        add: 'Ajouter',
        next: 'Prochain',
        back: 'Arrière',
        refresh: 'Rafraîchir',
        close: 'Fermer',
        save: 'Enregistrer',
        undo: 'Annuler',
        copy: 'Copie',
        paste: 'Coller',
        delete: 'Delete',
        export: 'Exporter',
        import: 'Importer',
        load: 'Télécharger',
        open: 'Ouvrir',
        selectAll: 'Sélectionner tout',
        clearAll: 'Décocher'
    },
    reports: {
        saveReport: 'Enregistrer le fichier de rapport',
        done: 'Rapport généré',
        notExist: "Le rapport demandé n'a pas été généré"
    },
    messages: {
        saveAnyway: 'Sauvegarder quand même ?'
    }
};

export default langFra;
