/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useRef, FunctionComponent, MutableRefObject, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Grid } from '@mui/material';

import useCtrlProps from 'hooks/ctrlProps';
import useNodeResize from 'hooks/nodeResize';
import AreaChart from 'components/Charts/AreaChart';
import BarChart from 'components/Charts/BarChart';
import LineChart from 'components/Charts/LineChart';
import PieChart from 'components/Charts/PieChart';
import VerticalBarChart from 'components/Charts/VerticalBarChart';

import { ControlPropsType } from 'forms/interfaces';

import Settings from './DataGrid/Settings';

interface PropsType extends ControlPropsType {
    descr: any;
}

const Chart: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { datasetName, columns, variant, chartHeight, chartWidth, chartData, guid } = descr;

    const { dataStock } = propContainer;
    const dataset = dataStock.getDatasetObj(datasetName);
    const { data, keyField } = dataset;

    const datasetData: any = useMemo(() => {
        const convertedData = data.map((item: any) => {
            for (const key in item) {
                if (!Number.isNaN(parseFloat(item[key]))) {
                    item[key] = parseFloat(item[key]);
                }
            }
            return item;
        });
        return convertedData;
    }, [data]);

    // Предпочтение данным, указанным в редакторе ресурсов (пеереопределение данных)
    const preparedData = chartData ? JSON.parse(chartData) : datasetData;

    // TODO: производить сортировку из редактора настроек
    const sortData = preparedData.slice().sort((a: any, b: any) => a[keyField] - b[keyField]);

    const gridRef = useRef(null) as MutableRefObject<any>;

    const [gridWidth] = useNodeResize(gridRef);

    const ctrlProps = useCtrlProps(propContainer, guid);

    const { ctrlOptions } = ctrlProps;

    const owner = useMemo(
        () => ({
            resource: descr,
            getOptions: () => ctrlOptions || {},
            setOptions: async (options: any) => {
                const el = propContainer.find(guid);
                propContainer.setElemProperty(el, 'options', options);

                const ds = propContainer.find(datasetName);
                if (ds) {
                    propContainer.setElemProperty(ds, 'options', options);
                    dataset.readOptions();
                    dataset.loadData();
                }
            }
        }),
        [descr, ctrlOptions, propContainer]
    );

    const chartOptions: any = useMemo(() => {
        const options = JSON.stringify(owner.getOptions());
        const obj =
            typeof options === 'string' && options
                ? (JSON.parse(options) as object)
                : options || {};
        return { ...(obj as object) };
    }, [owner]);

    // Данные для построения графика, не включают данные, предназначенные
    // для оси "Х" и скрытые колонки
    const chartElements: any = useMemo(() => {
        const optColumns = chartOptions?.columns;
        if (optColumns) {
            return optColumns.filter((column: any) => column.axis !== 'X' && column.visible);
        }
    }, [chartOptions]);

    const xAxis: any = useMemo(() => {
        const optColumns = chartOptions?.columns;
        if (optColumns) {
            return optColumns.find((column: any) => column.axis === 'X');
        }
    }, [chartOptions]);

    const yAxis: any = useMemo(() => {
        const optColumns = chartOptions?.columns;
        if (optColumns) {
            return optColumns.find((column: any) => column.axis === 'Y');
        }
    }, [chartOptions]);

    const chartSettings = { chartOptions, chartElements, xAxis, yAxis, chartHeight, chartWidth };

    const refreshGrid = () => {
        dataset.loadData({ forceRefresh: true });
    };

    const renderChart = () => {
        switch (variant) {
            case 'area':
                return <AreaChart data={sortData} columns={columns} settings={chartSettings} />;
            case 'bar':
                return <BarChart data={sortData} columns={columns} settings={chartSettings} />;
            case 'vertical bar':
                return (
                    <VerticalBarChart data={sortData} columns={columns} settings={chartSettings} />
                );
            case 'line':
                return <LineChart data={sortData} columns={columns} settings={chartSettings} />;
            case 'pie':
                return <PieChart data={sortData} columns={columns} settings={chartSettings} />;
            default:
                return null;
        }
    };

    return (
        <Grid container direction="column" ref={gridRef}>
            {renderChart()}
            <Grid item>
                <Settings
                    dataset={dataset}
                    refreshGrid={refreshGrid}
                    propContainer={propContainer}
                    owner={owner}
                    gridWidth={gridWidth}
                />
            </Grid>
        </Grid>
    );
});

export default Chart;
