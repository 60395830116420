import React, {
    FunctionComponent,
    ChangeEvent,
    useState,
    useRef,
    DragEvent,
    useEffect,
    useMemo,
    useCallback
} from 'react';
import { observer } from 'mobx-react';

import { Box, Grid, IconButton, Paper, Theme, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { red } from '@mui/material/colors';
import SvgIcon from '@mui/material/SvgIcon';

import useCtrlProps from 'hooks/ctrlProps';
import useCtrlData from 'hooks/ctrlData';

import { fileListToDataURL, dataURLtoFile } from 'utils/index';

import { ControlPropsType, FileUploaderType } from 'forms/interfaces';

import ConfigurationStore from 'store/configurationStore';

import UploaderTitle from './Title';
import AddFile from './Add';
import icons from './icons';

interface PropsType extends ControlPropsType {
    descr: FileUploaderType;
}

const checkFileType = (type: string) => {
    if (/pdf$/i.test(type)) return 'pdf';

    if (/image$/i.test(type)) return 'image';

    return 'image';
};

const FileUploader: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { content } = ConfigurationStore;

    const { guid, datasetName, fieldName } = descr;
    const { ctrlEnabled, ctrlVisible } = useCtrlProps(propContainer, guid);
    const { dataset, dataVal, fieldRequired } = useCtrlData(propContainer, datasetName, fieldName);

    const [files, setFiles] = useState<File[]>([]);
    const [dragActive, setDragActive] = React.useState(false);

    const disabled = useMemo(() => !ctrlEnabled, [ctrlEnabled]);
    const error = useMemo(() => fieldRequired && !files.length, [fieldRequired, files]);

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        fileListToDataURL(files)
            .then(list => {
                dataset?.trySetFieldValue(fieldName, list).catch(err => console.error(err.message));
            })
            .catch(err => console.error(err.message));
    }, [files]);

    useEffect(() => {
        if (dataVal) {
            if (dataVal instanceof Array) {
                setFiles(dataVal.map(file => dataURLtoFile(file.data, file.fileName)));
            } else {
                setFiles([dataURLtoFile(dataVal.data, dataVal.fileName)]);
            }
        }
    }, []);

    useEffect(() => {
        if ((!dataVal || !dataVal?.length) && files.length) setFiles([]);
    }, [dataVal]);

    const getBorderColor = useCallback(
        (theme: Theme) => {
            if (disabled) return theme.palette.action.disabled;

            if (error) return theme.palette.error.main;

            return theme.palette.grey[300];
        },
        [fieldRequired, files, ctrlEnabled]
    );

    const handleUploadClick = () => {
        if (!disabled) inputRef.current?.click();
    };

    const handleFile = (fileList: FileList) => {
        if (fileList) {
            for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i];
                setFiles(prevFiles => [...prevFiles, file]);
            }
        }
    };

    const handleDeleteFile = (index: number) => {
        const tmpFilesList = [...files];
        tmpFilesList.splice(index, 1);
        setFiles(tmpFilesList);
    };

    const handleFileDrag = (event: DragEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === 'dragenter' || event.type === 'dragover') {
            setDragActive(true);
        } else if (event.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleFileDrop = (event: DragEvent<HTMLInputElement>) => {
        if (!disabled) {
            event.preventDefault();
            event.stopPropagation();
            setDragActive(false);
            if (event.dataTransfer.files && event.dataTransfer.files[0]) {
                handleFile(event.dataTransfer.files);
            }
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
            handleFile(event.target.files);
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                p: 2,
                boxSizing: 'border-box',
                visibility: ctrlVisible ? 'inherit' : ('hidden' as any),
                position: 'relative'
            }}
        >
            <Paper
                elevation={0}
                variant="outlined"
                sx={{
                    p: 2,
                    transition: 'background-color 100ms linear;',
                    backgroundColor: dragActive ? '#afacac85' : 'initial',
                    borderColor: theme => getBorderColor(theme)
                }}
            >
                <Box
                    component="div"
                    onClick={() => !files.length && handleUploadClick()}
                    onDragEnter={handleFileDrag}
                    sx={{ cursor: !files.length && !disabled ? 'pointer' : 'initial' }}
                >
                    {files.length ? (
                        <Grid container direction="row" spacing={2} sx={{ pt: 2 }}>
                            {files.map((file, index) => (
                                <Grid item key={index}>
                                    <Box
                                        position="relative"
                                        sx={{
                                            ':hover': {
                                                '& .MuiButtonBase-root': {
                                                    opacity: '100%'
                                                }
                                            }
                                        }}
                                    >
                                        {checkFileType(file.type) === 'image' ? (
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt={`${file.name}`}
                                                width="100"
                                            />
                                        ) : (
                                            <Box maxWidth={100}>
                                                <SvgIcon sx={{ fontSize: 100 }}>
                                                    <g
                                                        dangerouslySetInnerHTML={{
                                                            __html: icons.pdfIcon
                                                        }}
                                                    />
                                                </SvgIcon>
                                                <Tooltip title={file.name}>
                                                    <Typography
                                                        whiteSpace="pre"
                                                        textOverflow="ellipsis"
                                                        overflow="hidden"
                                                    >
                                                        {file.name}
                                                    </Typography>
                                                </Tooltip>
                                            </Box>
                                        )}
                                        <Tooltip
                                            title={content.controls.fileUploader.delete}
                                            placement="top-end"
                                        >
                                            <IconButton
                                                onClick={() => !disabled && handleDeleteFile(index)}
                                                sx={{
                                                    opacity: 0,
                                                    position: 'absolute',
                                                    right: 2,
                                                    top: 2,
                                                    transition: 'all .2s',
                                                    background: '#e1e1e17a',
                                                    ':hover': {
                                                        background: '#e1e1e1a1'
                                                    }
                                                }}
                                            >
                                                <CloseIcon sx={{ color: red[500] }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                            ))}
                            <AddFile handleUpload={handleUploadClick} disabled={disabled} />
                        </Grid>
                    ) : (
                        <UploaderTitle error={error} disabled={disabled} />
                    )}
                    {dragActive && !disabled && (
                        <Box
                            component="div"
                            onDragEnter={handleFileDrag}
                            onDragLeave={handleFileDrag}
                            onDragOver={handleFileDrag}
                            onDrop={handleFileDrop}
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            }}
                        />
                    )}
                </Box>
            </Paper>

            <input
                ref={inputRef}
                type="file"
                accept="image/*,application/pdf"
                onChange={handleFileChange}
                multiple
                style={{ display: 'none' }}
            />
        </Box>
    );
});

export default FileUploader;
