import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { SplitPane } from '@calypso/react-multi-split-pane';

import {
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton
} from '@mui/material';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-sql';

import 'prismjs/components/prism-markup-templating'; // это для ejs
import 'prismjs/components/prism-ejs';

import 'prismjs/themes/prism.css';

import { ResourceClass } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';

import Editor from 'components/Inputs/HighlightEditor';

import { EventType } from '../ResInterfaces';
import ResScriptGlossary from './ResScriptGlossary';

interface PropTypes {
    name: string;
    text: string;
    language: string;
    onConfirm: any;
    showText?: boolean;
    resourceStore?: ResourceClass;
    bottomContent?: ReactNode;
}

const ResScriptEditor: FunctionComponent<PropTypes> = observer(
    ({ name, text, language, onConfirm, showText = false, resourceStore, bottomContent }) => {
        const useGlossary = language === 'javascript';
        const { resource }: any = ConfigurationStore.content;

        const [open, setOpen] = useState(false);
        const [code, setCode] = useState(text);
        const [fullScreen, setFullScreen] = useState(false);

        useEffect(() => {
            setCode(text);
        }, [text, open]);

        const handleOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
            setCode(text);
        };

        const handleFullScreen = () => {
            setFullScreen(!fullScreen);
        };

        const handleConfirm = () => {
            onConfirm({ target: { name, value: code } } as EventType);
            setOpen(false);
        };

        const handleSelectGlossItem = (newItem: string) => {
            setCode(code + newItem);
        };

        const renderTextEditor = () => (
            <>
                <Editor
                    value={code}
                    onValueChange={(codeText: string) => setCode(codeText)}
                    highlight={codeText =>
                        Prism.highlight(codeText || '', Prism.languages[language], language)
                    }
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 14
                    }}
                />
                {bottomContent}
            </>
        );

        const renderDialog = () => (
            <Dialog open={open} maxWidth="lg" fullWidth fullScreen={fullScreen}>
                <DialogTitle id="script-dialog-title">{resource.scriptEditor.title}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleFullScreen}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500]
                    }}
                >
                    {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
                <DialogContent dividers>
                    {useGlossary && resourceStore ? (
                        <Grid
                            container
                            sx={{ position: 'relative', minHeight: '500px', height: '100%' }}
                        >
                            <SplitPane split="vertical" defaultSizes={[8, 3]}>
                                <Grid item style={{ overflow: 'auto', width: '100%' }}>
                                    {renderTextEditor()}
                                </Grid>
                                <Grid item>
                                    <ResScriptGlossary
                                        resourceStore={resourceStore}
                                        onSelect={handleSelectGlossItem}
                                    />
                                </Grid>
                            </SplitPane>
                        </Grid>
                    ) : (
                        renderTextEditor()
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{resource.scriptEditor.cancel}</Button>
                    <Button onClick={handleConfirm}>{resource.scriptEditor.apply}</Button>
                </DialogActions>
            </Dialog>
        );

        return (
            <>
                <Stack spacing={1} direction="row">
                    <Button onClick={handleOpen} color={text ? 'info' : 'primary'}>
                        {text ? resource.scriptEditor.change : resource.buttons.add}
                    </Button>
                    {!!text && showText && (
                        <Button disabled>
                            {text.length < 50 ? text : `${text.slice(0, 50)}...`}
                        </Button>
                    )}
                </Stack>
                {open && renderDialog()}
            </>
        );
    }
);

export default ResScriptEditor;
