import { useEffect, useState } from 'react';

import routingStore from 'store/routingStore';
import FormActions from 'store/actionStore/form/FormActions';

import { useLocation } from 'react-router-dom';

function generateRoute(template: string, values: { [key: string]: string }): string {
    const variableNames = template.match(/{\s*(\w+)\s*}/g)?.map(match => match.slice(1, -1)) || [];

    let result = template;
    for (let i = 0; i < variableNames.length; i++) {
        result = result.replace(
            new RegExp(`{\\s*${variableNames[i]}\\s*}`, 'g'),
            values[variableNames[i]]
        );
    }

    return result;
}

export default function useRouting(formActions?: FormActions | null, modal?: boolean) {
    const history = routingStore.getHistory();
    const location = useLocation();

    const [current, setCurrent] = useState('');

    useEffect(() => {
        if (formActions?.getFormDescr().useRoute) {
            const route = routingStore.getRoute(formActions.getFormDescr().useRoute || '');

            if (route && modal && route?.viewMode === 'modal') {
                const routePath = generateRoute(
                    route.route,
                    formActions.getDataStock()?.extParamVals || {}
                );

                setCurrent(routePath);

                const pathArr = routingStore.getPathArr();
                const path = routingStore.getPathArr().pop();

                if (routePath !== path) {
                    routingStore.push(
                        `${pathArr.reduce(
                            (result: string, item: string) => `${result}/${item}`,
                            ''
                        )}/${routePath}`
                    );
                }
            }
        }
    }, [formActions, current]);

    useEffect(() => {
        if (current)
            return () => {
                history?.replace(
                    routingStore
                        .getPathArr()
                        .filter((item: string) => item !== current)
                        .reduce((result: string, item: string) => `${result}/${item}`, '')
                );
            };
    }, [current]);

    useEffect(() => {
        if (['POP', 'REPLACE'].includes(history.action) && formActions) {
            const currentPath = routingStore.getPathArr().pop();

            if (current && currentPath && currentPath !== current) {
                formActions.closeForm();
            }
        }
    }, [formActions, location, history]);
}
