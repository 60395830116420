import { FormulaGroup, FormulaObj, FormulaParam } from './formula-classes';
import ExternFuncContainer from './formula-extern-func';

import DataStore from '../store/dataStore';

// имя группы независимых формул
const FREE_GROUP = 'grFree';
// имя группы формул строк
const ROW_GROUP = 'grRow';

export default class FormulaCalculator {
    constructor(descr, dataStock, propContainer) {
        this.descr = descr;
        this.dataStock = dataStock;
        this.propContainer = propContainer;

        this.formulaGroups = {};
        this.externFuncContainer = new ExternFuncContainer(this);

        if (this.descr.extraData) {
            const groups = this.#loadFromJSON(this.descr.extraData);

            // 1 - общая формула контроля сохранения - относится к free-формулам
            // 2 - общая формула контроля редактирования - относится к free-формулам
            // 4 - доступность поля - относится к группе ctrlProps
            if (groups.fldValue) this.grFldValue = this.formulaGroups.fldValue = groups.fldValue; // 5 - значение поля при редактировании
            if (groups.fldDefault)
                this.grFldDefault = this.formulaGroups.fldDefault = groups.fldDefault; // 6 - значение поля по умолчанию

            // Формулы расчета свойств контролов
            if (groups.ctrlProps)
                this.grCtrlProps = this.formulaGroups.ctrlProps = groups.ctrlProps;

            // Формулы расчета свойств в строках
            if (groups[ROW_GROUP])
                this[ROW_GROUP] = this.formulaGroups[ROW_GROUP] = groups[ROW_GROUP];

            // независимые формулы
            if (groups[FREE_GROUP])
                this[FREE_GROUP] = this.formulaGroups[FREE_GROUP] = groups[FREE_GROUP];
        }

        this.descr.dataObjects.forEach(descr => {
            const groups = this.#loadFromJSON(descr.extraData);

            // 1 - общая формула контроля сохранения - относится к free-формулам
            // 2 - общая формула контроля редактирования - относится к free-формулам
            // 4 - доступность поля - относится к группе ctrlProps
            if (groups.fldValue) this.grFldValue = this.formulaGroups.fldValue = groups.fldValue; // 5 - значение поля при редактировании
            if (groups.fldDefault)
                this.grFldDefault = this.formulaGroups.fldDefault = groups.fldDefault; // 6 - значение поля по умолчанию

            // Формулы расчета свойств контролов
            if (groups.ctrlProps)
                this.grCtrlProps = this.formulaGroups.ctrlProps = groups.ctrlProps;

            // формулы строк
            if (groups[ROW_GROUP])
                this[ROW_GROUP] = this.formulaGroups[ROW_GROUP] = groups[ROW_GROUP];

            // независимые формулы
            if (groups[FREE_GROUP])
                this[FREE_GROUP] = this.formulaGroups[FREE_GROUP] = groups[FREE_GROUP];
        });
    }

    init() {
        // присваиваем датасет параметрам формул
        for (const key in this.formulaGroups) {
            this.formulaGroups[key].arrParam.forEach(par => {
                const match = par.text.match(/(.+)\.(.+)/);
                if (match) {
                    par.datasetName = match[1]; // eslint-disable-line prefer-destructuring
                    par.fieldName = match[2]; // eslint-disable-line prefer-destructuring

                    if (par.datasetName.toUpperCase() === 'AF') {
                        par.paramType = 'af';
                    } else if (par.datasetName.toUpperCase() === 'EXTERN') {
                        par.paramType = 'extern';
                        par.dataStock = this.dataStock;
                    } else {
                        par.paramType = 'field';
                        par.dataStock = this.dataStock;
                        par.ds = this.dataStock.getDatasetObj(par.datasetName);
                        if (!par.ds)
                            console.error(
                                `FormulaCalculator: dataset not found in param ${par.text}!`
                            );
                    }
                }
            });
        }

        // результирующий обект присваивается только формулам
        // присваивающим значения полей по умолчанию
        // и формулам пересчитывающим значения полей и их свойств
        [this.grFldDefault, this.grFldValue, this.grCtrlProps, this.grRow].forEach(gr => {
            if (gr) {
                gr.arrFormula.forEach(f => {
                    if (f.resultType === 'prop') {
                        f.propContainer = this.propContainer;
                        f.propElem = this.propContainer.find(f.ctrlGuid);
                    } else {
                        f.ds = this.dataStock.getDatasetObj(f.datasetName);
                    }
                });
            }
        });
    }

    #loadFromJSON(json) {
        const result = {};

        const { formulaGroups } = json && typeof json === 'string' ? JSON.parse(json) : json;

        for (const key in formulaGroups) {
            const gr0 = formulaGroups[key];
            if (!gr0.arrFormula || !gr0.arrParam)
                // проверяем наличие массивов формул и параметров
                break;

            const gr = new FormulaGroup(this, key);
            result[key] = gr;

            gr.formulaLang = gr0.formulaLang;
            gr.arrSortedFormulaIndexes = gr0.arrSortedFormulaIndexes;

            gr0.arrParam.forEach(p0 => {
                const p = new FormulaParam(p0.text);
                p.index = p0.index;
                p.group = gr;
                p.sourceFormulaIndex = p0.sourceFormulaIndex;
                p.ownedFormulaIndexes = p0.ownedFormulaIndexes;
                p.dependedFormulaIndexes = p0.dependedFormulaIndexes;
                p.friendlyParams = p0.friendlyParams;
                p.isRow = p0.isRow;

                gr.arrParam.push(p);
            });

            gr.arrParamVal.length = gr.arrParam.length;

            gr0.arrFormula.forEach(f0 => {
                const f = new FormulaObj();
                f.index = f0.index;
                f.code = f0.code;
                f.calculator = this;
                f.group = gr;

                f.resultType = f0.resultType; // field | prop
                // prop
                f.ctrlGuid = f0.ctrlGuid;
                f.propName = f0.propName;
                // field
                f.datasetName = f0.datasetName;
                f.resField = f0.resField;

                // присваиваем текст приготовленый для клиента в parsedText
                // на сервере эти выражение различаются, сервер вычисляет именно parsedText
                f.parsedText = f0.clientText;

                f.resultParamIndex = f0.resultParamIndex;

                f.ownedParamIndexes = f0.ownedParamIndexes;
                f.parentFormulaIndexes = f0.parentFormulaIndexes;

                f.createFunction();
                gr.arrFormula.push(f);
            });
        }
        return result;
    }

    async doFormulaTask(task) {
        const { taskType, datasetName, fieldName } = task;

        switch (taskType) {
            case 'onFormShow': {
                await this.onFormShow();
                break;
            }

            case 'setDefaults': {
                await this.setDefaults(datasetName);
                break;
            }

            case 'onBeginEdit': {
                await this.onBeginEdit(datasetName);
                break;
            }
            case 'onFieldModified': {
                await this.onFieldModified(datasetName, fieldName);
                break;
            }
            case 'onRecordChanged': {
                await this.onRecordChanged(datasetName);
                break;
            }
            default: {
                console.error(`FormulaCalculator.doFormulaTask: Unknown task: ${taskType}`);
                break;
            }
        }
    }

    /**
     * Объединение массивов вычисляемых формул или параметров
     * @param {array} arr // массив массивов индексов
     * @returns //  объединенный массив уникальных индексов с сохранением порядка следования
     */
    #joinIndexArrays(arr) {
        const arrUnited = arr.reduce((prev, cur) => prev.concat(cur), []);
        // аккуратно убираем дубликаты сохраняя порядок следования.
        return arrUnited.filter((fInd, i, a) => a.indexOf(fInd) === i);
    }

    /**
     * Собираем все friendly параметры всех параметров в единый массив
     * @param {array} arrParams // массив объектов параметров
     * @returns // объединенный массив индексов friendly параметров
     */
    #joinFriendlyParams(arrParams) {
        return this.#joinIndexArrays(arrParams.map(par => par.friendlyParams));
    }

    /**
     * Собираем зависимые формулы всех параметров в единый массив
     * @param {array} arrParams // массив объектов параметров
     * @returns // объединенный массив формул, зависящих от параметров в порядке расчета
     */
    #joinDependentFormulaIndexes(arrParams) {
        // собираем зависимые формулы всех параметров в единый массив
        return this.#joinIndexArrays(arrParams.map(par => par.dependedFormulaIndexes));
    }

    async #recalcOnOneFldChanged(fullFldName) {
        if (this.grFldValue) {
            const par = this.grFldValue.findParamByText(fullFldName);
            if (par) {
                this.grFldValue.loadParametersValues(par.friendlyParams);
                await this.grFldValue.recalcFormulas(par.dependedFormulaIndexes);
                this.grFldValue.writeFormulasResults(par.dependedFormulaIndexes);
            }
        }

        if (this.grCtrlProps) {
            const par = this.grCtrlProps.findParamByText(fullFldName);
            if (par) {
                this.grCtrlProps.loadParametersValues(par.friendlyParams);
                await this.grCtrlProps.recalcFormulas(par.dependedFormulaIndexes);
                this.grCtrlProps.writeFormulasResults(par.dependedFormulaIndexes);
            }
        }
    }

    /**
     * Пересчитываем формулы зависящие от данного датасета
     * @param {*} group
     * @param {*} datasetName
     */
    async #recalcFromDatasetFormulas(group, datasetName) {
        if (group) {
            const params = group.findParamsByDataset(datasetName);

            const friendlyParams = this.#joinFriendlyParams(params);
            const dependedFormulaIndexes = this.#joinDependentFormulaIndexes(params);

            group.loadParametersValues(friendlyParams);
            await group.recalcFormulas(dependedFormulaIndexes);
            group.writeFormulasResults(dependedFormulaIndexes);
        }
    }

    /**
     * Пересчитываем формулы записывающие в данный датасет
     * @param {*} group
     * @param {*} datasetName
     */
    async #recalcToDatasetFormulas(group, datasetName) {
        if (group) {
            const formulas = group.findFormulasOfDataset(datasetName);
            const idxFormulas = formulas.map(f => f.index);

            const idxParams = this.#joinIndexArrays(formulas.map(f => f.ownedParamIndexes));

            group.loadParametersValues(idxParams);
            await group.recalcFormulas(idxFormulas);
            group.writeFormulasResults(idxFormulas);
        }
    }

    async #recalcAllFormulas(group) {
        if (group) {
            group.loadParametersValues();
            const arrFormula = await group.recalcFormulas();
            group.writeFormulasResults(arrFormula);
        }
    }

    async onFormShow() {
        await this.#recalcAllFormulas(this.grCtrlProps);
    }

    // Здесь запускаем вычисления при изменении поля датасета
    async onFieldModified(datasetName, fieldName) {
        const fullFldName = `${datasetName}.${fieldName}`;
        await this.#recalcOnOneFldChanged(fullFldName);
    }

    async onBeginEdit(datasetName) {
        await this.#recalcToDatasetFormulas(this.grFldValue, datasetName);
        await this.#recalcFromDatasetFormulas(this.grCtrlProps, datasetName);
    }

    async setDefaults(datasetName) {
        await this.#recalcToDatasetFormulas(this.grFldDefault, datasetName);
        await this.#recalcToDatasetFormulas(this.grFldValue, datasetName);
    }

    async onRecordChanged(datasetName) {
        await this.#recalcFromDatasetFormulas(this.grCtrlProps, datasetName);
    }

    async onDataLoaded(ds, data) {
        const gr = this.grRow;
        if (gr) {
            const params = gr.findParamsByDataset(ds.name);
            const friendlyParams = this.#joinFriendlyParams(params);
            const dependedFormulaIndexes = this.#joinDependentFormulaIndexes(params);

            for await (const row of data) {
                gr.loadParametersValues(friendlyParams, row);
                await gr.recalcFormulas(dependedFormulaIndexes);
                gr.writeFormulasResults(dependedFormulaIndexes, row[ds.keyField]);
            }
        }
    }

    async validateRecord(errAccum) {
        this.externFuncContainer.clearWarnAndErr();
        await this.calcFormulaByCode(
            `wuiForm.${this.propContainer.formName}.validateFormula`,
            errAccum
        );
        return { isValid: !errAccum.err, ...errAccum };
    }

    getParamType(/* paramName */) {
        const res = '';
        /* if (paramName.indexOf(MAIN_DATASET_DEFAULT_NAME) === 0) {
            let fldName = paramName.substr(MAIN_DATASET_DEFAULT_NAME.length + 1)
            res = this._mainDataSet._dataObj.getFieldType(fldName).key
        } */
        return res;
    }

    async calcFormulaByCode(code, errAccum) {
        const gr = this.formulaGroups[FREE_GROUP];
        const f = gr?.arrFormula.find(f => f.code === code);
        if (f) {
            this.externFuncContainer.clearWarnAndErr();
            gr.loadParametersValues(f.ownedParamIndexes);
            await gr.recalcFormulas([f.index]);
            if (errAccum) {
                errAccum.err = [...this.externFuncContainer.arrErrors];
                errAccum.warn = [...this.externFuncContainer.arrWarnings];
                this.externFuncContainer.clearWarnAndErr();
                return f.lastResult;
            }
        }
    }
}
