import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import isEqual from 'lodash/isEqual';
import { encode, decode } from 'js-base64';

import { useHistory, useLocation } from 'react-router-dom';

import { Breadcrumbs, Typography, Link } from '@mui/material';

import useCtrlProps from 'hooks/ctrlProps';

import { IterableContainerType, ControlPropsType, EditModeType } from 'forms/interfaces';
import CustomDataset from 'dataObj/customDataset';
import CustomForm from 'forms/CustomForm';

import DataStore from 'store/dataStore';
import ActionStore from 'store/actionStore';

import { checkGuid } from 'utils/index';
import { v4 as uuidv4 } from 'uuid';

type IterationType = {
    name: string;
    guid: string;
    extParamVals?: { [key: string]: string };
    editMode?: EditModeType;
};

interface PropsType extends ControlPropsType {
    descr: IterableContainerType;
}

const IterableContainer: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { guid, params, sourceNameType, sourceName } = descr;
    const breadcrumbs = true;

    const { ctrlVisible } = useCtrlProps(propContainer, guid);

    const location = useLocation();
    const history = useHistory();

    const [mainFormGuid, setMainFormGuid] = useState('');
    const [mainFormName, setMainFormName] = useState('');

    const [formGuid, setFormGuid] = useState('');
    const [extParamVals, setExtParamVals] = useState({});
    const [editMode, setEditMode] = useState<EditModeType | undefined>();

    const [dependDS, setDependDS] = useState<CustomDataset[]>([]);

    const [iterations, setIterations] = useState<IterationType[]>([]);

    const propContainerGuid = useMemo(() => uuidv4(), []);
    const formActions = ActionStore.getFormActions(propContainerGuid);

    const checkSourceType = (source: string) => {
        if (checkGuid(source)) {
            return source;
        }

        const resLink = propContainer.getResourceLink(source);

        if (resLink) return resLink.resource.code;

        return source;
    };

    const generateIterableParams = () => ({
        extParamVals,
        currentRecords: {},
        iterations: iterations.length
            ? iterations
            : [
                  {
                      name: mainFormName,
                      guid: mainFormGuid
                  }
              ]
    });

    const getIterableParams = () => {
        const iterableEncodedString = location.hash.split(/#\/|#/).pop();

        if (!iterableEncodedString) {
            return;
        }

        const iterableString = decode(iterableEncodedString);

        try {
            return JSON.parse(iterableString);
        } catch (err: any) {
            console.error(err.message);
            return;
        }
    };

    const updateIterations = (newIterations: IterationType[], currentIteration: IterationType) => {
        setIterations(newIterations);

        let pathParams = '';

        if (newIterations?.length > 1) {
            const iterableParams = getIterableParams() || generateIterableParams();
            iterableParams.iterations = newIterations;
            pathParams = encode(JSON.stringify(iterableParams));
        }

        history.push({ hash: pathParams ? `#/${pathParams}` : '' });

        setFormGuid(currentIteration.guid);
        setExtParamVals(currentIteration.extParamVals || {});
        setEditMode(currentIteration.editMode);
    };

    const addIterations = (item: IterationType) => {
        const newIterations = [...iterations, ...[item]];

        updateIterations(newIterations, item);
    };

    const handleIterations = (index: number) => {
        const currentIteration = iterations[index];
        const newIterations = iterations.slice(0, index + 1);

        updateIterations(newIterations, currentIteration);
    };

    useEffect(() => {
        if (iterations?.length) {
            const iteration = iterations[iterations.length - 1];

            if (iteration.guid !== formGuid) {
                setFormGuid(iteration.guid);
                setExtParamVals(iteration.extParamVals || {});
                setEditMode(iteration.editMode);
            }
        }
    }, [iterations]);

    useEffect(() => {
        if (sourceNameType === 'af') {
            setMainFormGuid(DataStore.AF.getAF(sourceName));
            setFormGuid(DataStore.AF.getAF(sourceName));
        }
        if (['guid', 'name'].includes(sourceNameType)) {
            setFormGuid(checkSourceType(sourceName));
            setMainFormGuid(checkSourceType(sourceName));
        }

        setIterations([]);
    }, [sourceName, DataStore.AF.getAF(sourceName)]);

    useEffect(() => {
        if (formActions) {
            const formName = formActions.getName();

            setMainFormName(formName);

            const iterableParams = getIterableParams();

            if (!iterations.length) {
                if (iterableParams) {
                    setIterations(iterableParams.iterations);
                } else
                    setIterations([
                        {
                            name: formName,
                            guid: mainFormGuid
                        }
                    ]);
            }
        }
    }, [formActions]);

    useEffect(() => {
        if (params?.length) {
            setExtParamVals(propContainer.dataStock.getParams(params));

            const paramDsList: CustomDataset[] = [];
            params.forEach(param => {
                if (param.paramType === 'field') {
                    const ds = propContainer.dataStock.getDatasetObj(param.sourceDataset);
                    if (ds) paramDsList.push(ds);
                }
            });

            if (paramDsList.length && !isEqual(paramDsList, dependDS)) {
                setDependDS(paramDsList);
            }
        }
    }, [JSON.stringify(propContainer.dataStock.getParams(params))]);

    useEffect(() => {
        if (dependDS.reduce((states: number, ds) => states + ds.state, 0) === dependDS.length)
            formActions?.setForceReload();
    }, [dependDS.reduce((states: number, ds) => states + ds.state, 0)]);

    useEffect(() => {
        if (formActions?.ratherForm) {
            setFormGuid(formActions.ratherForm.guid);
            setExtParamVals(formActions.ratherForm.extParamVals || {});
            addIterations(formActions.ratherForm);
            setEditMode(formActions.ratherForm.editMode);
        }
    }, [formActions?.ratherForm]);

    if (formGuid) {
        return ctrlVisible ? (
            <>
                {breadcrumbs && (
                    <Breadcrumbs aria-label="breadcrumb" sx={{ pl: 5, pr: 5 }}>
                        {iterations.map((item, index) =>
                            item.guid === formGuid ? (
                                <Typography key={index} color="text.primary" className="caption-h1">
                                    {item.name}
                                </Typography>
                            ) : (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <Link
                                    className="caption-h1"
                                    key={index}
                                    underline="hover"
                                    color="inherit"
                                    onClick={() => handleIterations(index)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {item.name}
                                </Link>
                            )
                        )}
                    </Breadcrumbs>
                )}
                <div
                    style={{
                        height:
                            breadcrumbs && iterations.filter(item => !!item.name).length
                                ? 'calc(100% - 32px)'
                                : '100%'
                    }}
                >
                    <CustomForm
                        key={formGuid}
                        formGuid={formGuid}
                        extParamVals={extParamVals}
                        initEditMode={editMode}
                        // cdoData={editor?.datasets}
                        parentDataStock={propContainer.dataStock}
                        parentPropContainer={propContainer}
                        propContainerGuid={propContainerGuid}
                    />
                </div>
            </>
        ) : (
            <div />
        );
    }

    return <div />;
});

export default IterableContainer;
