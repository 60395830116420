import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { v4 as uuidv4 } from 'uuid';

import { IconButton, Tooltip, Typography } from '@mui/material';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ContentCutIcon from '@mui/icons-material/ContentCut';

import { saveObjectToClipboard } from 'utils/index';

import GlobalResourceStore, { getAvailableCtrlList, ResourceClass } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';
import NotificationStore from 'store/notificationStore';

import {
    ColumnType,
    DatasetFieldType,
    DatasetType,
    DBControlType,
    QueryFieldType,
    QueryFilterType,
    QueryParamType
} from 'forms/interfaces';

import ResCollectionList from './ResCollectionList';
import { Resource } from './interfaces';

interface PropsType {
    ResourceStore: ResourceClass;
    arr: Array<{ [key: string]: any } | DBControlType>;
    typeDescr: any;
    parent: Resource | null;
    parentSection?: string;
    ctrl: any;
    i: number;
    readOnly?: boolean;
}

const ResCtrl: FunctionComponent<PropsType> = observer(
    ({ ResourceStore, arr, typeDescr, parent, parentSection, ctrl, i, readOnly = false }) => {
        const {
            curRes,
            curCtrl,
            readResource,
            getDatasetList,
            updateCurRes,
            selectCtrl,
            deleteCtrl,
            upCtrl,
            downCtrl,
            topCtrl,
            bottomCtrl,
            updateCachedCurCtrl
        } = ResourceStore;
        const { copyCtrl } = GlobalResourceStore;

        type CloneTypes =
            | DatasetFieldType
            | QueryFieldType
            | QueryParamType
            | ColumnType
            | QueryFilterType;
        const setNewElements = (
            type: string,
            ctrlElList: Array<CloneTypes>,
            newElList: Array<CloneTypes>
        ) =>
            (newElList || [])
                .filter(
                    newEl =>
                        !ctrlElList.map((ctrlEl: CloneTypes) => ctrlEl.name).includes(newEl.name)
                )
                .map(newEl => {
                    const props =
                        newEl.type === 'queryFilter'
                            ? {
                                  fieldName: (newEl as QueryFilterType).fieldName,
                                  paramName: (newEl as QueryFilterType).paramName
                              }
                            : { fieldName: newEl.name };

                    return {
                        // TODO Переделать на заранее прописанные поля
                        ...newEl,
                        guid: uuidv4(),
                        ...props,
                        type
                    };
                });

        const importDataSet = async (request: any) => {
            if (!request?.code)
                return NotificationStore.showAlert(
                    ConfigurationStore.content.resource.alert.idRequest
                );

            return readResource(ctrl.request?.type, ctrl.request?.code).then((resource: any) => {
                const newFields = setNewElements('datasetField', ctrl.fields, resource.fields);
                const newParams = setNewElements('param', ctrl.params, resource.params);

                const addedCount = newFields?.length ?? 0;
                const addedParams = newParams?.length ?? 0;

                ctrl.fields = [...ctrl.fields, ...newFields];
                ctrl.params = [...ctrl.params, ...newParams];

                updateCurRes(curRes);

                NotificationStore.enqueueSnackbar({
                    message:
                        ConfigurationStore.content.resource.snackbars.import.fields(addedCount),
                    options: { variant: 'info' }
                });

                if (addedParams)
                    NotificationStore.enqueueSnackbar({
                        message:
                            ConfigurationStore.content.resource.snackbars.import.params(
                                addedParams
                            ),
                        options: { variant: 'info' }
                    });
            });
        };

        const importGridFields = (datasetName: string) => {
            if (!datasetName)
                return NotificationStore.showAlert(
                    ConfigurationStore.content.resource.alert.specDS
                );

            const datasets = getDatasetList();

            const dataset = datasets?.filter((ds: DatasetType) => ds.name === datasetName)[0];

            if (!dataset)
                return NotificationStore.showAlert(
                    ConfigurationStore.content.resource.alert.missingDS
                );

            const newFields = setNewElements('column', ctrl.columns, dataset.fields);

            ctrl.columns = [...ctrl.columns, ...newFields];

            updateCurRes(curRes);

            NotificationStore.enqueueSnackbar({
                message: ConfigurationStore.content.resource.snackbars.import.columns(
                    newFields?.length
                ),
                options: { variant: 'info' }
            });
        };

        const buttons = () =>
            readOnly ? null : (
                <>
                    {ctrl.type === 'dataset' ? (
                        <Tooltip title={ConfigurationStore.content.resource.loadReqFields}>
                            <IconButton size="small" onClick={() => importDataSet(ctrl.request)}>
                                <CloudDownloadIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    {ctrl.type === 'grid' ? (
                        <Tooltip title={ConfigurationStore.content.resource.loadDSColumns}>
                            <IconButton
                                size="small"
                                onClick={() => importGridFields(ctrl.datasetName)}
                            >
                                <SystemUpdateAltIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    {parent ? (
                        <>
                            <Tooltip title={ConfigurationStore.content.resource.buttons.copy}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        saveObjectToClipboard(ctrl).catch(err =>
                                            console.error(err.message)
                                        );
                                        copyCtrl(arr, i, ctrl);
                                    }}
                                >
                                    <FileCopyRoundedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            &nbsp;
                        </>
                    ) : null}
                    {parent ? (
                        <>
                            <Tooltip title={ConfigurationStore.content.resource.buttons.cut}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        saveObjectToClipboard(ctrl).catch(err =>
                                            console.error(err.message)
                                        );
                                        copyCtrl(arr, i, ctrl);
                                        deleteCtrl(arr, i, ctrl);
                                    }}
                                >
                                    <ContentCutIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            &nbsp;
                        </>
                    ) : null}
                    {parent ? (
                        <>
                            <Tooltip title={ConfigurationStore.content.resource.buttons.delete}>
                                <IconButton size="small" onClick={() => deleteCtrl(arr, i, ctrl)}>
                                    <DeleteForeverRoundedIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            &nbsp;
                        </>
                    ) : null}
                    {arr.length > 1 && i > 0 ? (
                        <Tooltip title={ConfigurationStore.content.resource.buttons.upward}>
                            <IconButton size="small" onClick={() => upCtrl(arr, i, ctrl)}>
                                <ArrowUpwardRoundedIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    {arr.length > 1 && i < arr.length - 1 ? (
                        <Tooltip title={ConfigurationStore.content.resource.buttons.downward}>
                            <IconButton size="small" onClick={() => downCtrl(arr, i, ctrl)}>
                                <ArrowDownwardRoundedIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    {arr.length > 1 && i === arr.length - 1 && arr.length > 2 ? (
                        <Tooltip title={ConfigurationStore.content.resource.buttons.upward}>
                            <IconButton size="small" onClick={() => topCtrl(arr, i, ctrl)}>
                                <VerticalAlignTopIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                    {arr.length > 1 && i === 0 && arr.length > 2 ? (
                        <Tooltip title={ConfigurationStore.content.resource.buttons.downward}>
                            <IconButton size="small" onClick={() => bottomCtrl(arr, i, ctrl)}>
                                <VerticalAlignBottomIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </>
            );

        return (
            <li key={ctrl.guid}>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <Typography
                        style={{
                            cursor: 'pointer',
                            color: curCtrl?.guid === ctrl.guid ? '#6157FF' : 'inherit',
                            fontSize: 14
                        }}
                        onClick={() => {
                            selectCtrl(ctrl, getAvailableCtrlList(parent, parentSection));
                            updateCachedCurCtrl();
                        }}
                        component="span"
                    >
                        {typeDescr.caption || ctrl.type}:&nbsp;
                        <strong>{ctrl.name}</strong>
                    </Typography>
                    &nbsp;
                    {buttons()}
                </div>
                <ResCollectionList
                    ResourceStore={ResourceStore}
                    ctrl={ctrl}
                    fields={typeDescr.fields}
                    readOnly={readOnly}
                />
            </li>
        );
    }
);

export default ResCtrl;
