import { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import { ChatIndex } from '@pmt/chat';

import { ControlPropsType } from 'forms/interfaces';

import ConfigurationStore from 'store/configurationStore';
import { Box } from '@mui/material';

interface PropsType extends ControlPropsType {
    descr: any;
}

const TelemedChat: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { locale } = ConfigurationStore;
    const { url, ws, wsPath } = descr;

    const lang = useMemo(() => {
        switch (locale) {
            case 'rus':
                return 'ru';
            case 'eng':
                return 'en';
            case 'fra':
                return 'fr';
            default:
                return 'ru';
        }
    }, [locale]);

    return (
        <Box
            sx={theme => ({
                height: `calc(100% - ${theme.spacing(2)})`,
                overflow: 'hidden'
            })}
        >
            <ChatIndex
                token="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjI3MjIzNCwicm9sZSI6MSwiYXBwQ29kZSI6ImNvbS5wb3N0bW9kZXJuLm1vYmltZWRSZWFjdCIsIm1lZGljYWxOZXRJZCI6MTUsImxhbmdDb2RlIjoicnVzIiwiaWF0IjoxNzExNDMyOTM3LCJleHAiOjE3NDI5Njg5MzcsImF1ZCI6ImFwaSIsImlzcyI6Ii9hdXRoL2xvZ2luIiwic3ViIjoiMTA5LjIyNi4yMDQuMTE3IiwianRpIjoiYmUzM2U5NjYtODgxZC00NWMyLTgyYTktNDhmYzc3Yjg5ZmNhIn0.NtO0cVA_cmRlCZsvRuJvsmhyQ0pUUINBR03COjeA1BruovxvUQ6LPWGoh6hMFgHmIK0jTHozajb6rHU7ymSwLd4W5AHEPtWksPWeMsOMFTGv7msIEZgugfhV4Pl4pjHTpiM35tzNe7SBLhnpPScCw4RRweMy60q4lvO7tabQ3JcZPjWTBWWjWTphKDntB2HYubpIaxGz9gA2zgW2ELUdX--ae0RQePQG3XJCjhBkdMsD8ZCymmJLyElVWS6_kvFBgQ6lbkoZDpGjNVqoX2KNLWXHqwrym2AysOuZBVp0Ww-FNHiLwer7HMuffFQkG29Ud40SlKITUbjf6b_S4fTT2g"
                lang={lang}
                chatBaseURLApi={url}
                chatWsUrl={ws}
                chatWsPath={wsPath}
            />
        </Box>
    );
});

export default TelemedChat;
