import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import {
    Dialog,
    Box,
    IconButton,
    Tooltip,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';

import ResourceStore, { ResourceClass } from 'store/resourceStore';
import ConfigurationStore from 'store/configurationStore';

import Loading from 'components/utils/Loading/Loading';
import { ResourceItem } from 'forms/interfaces';

import SimpleDialog from 'components/Feedback/SimpleDialog';
import ResList from './ResList/ResList';
import ResEditor from './index';

interface SelectResourcePropsType {
    onSelect: any;
    property: string;
    resourceItem: ResourceItem;
    guid: string;
    types?: Array<any>;
    ResourceStore?: ResourceClass;
}

const SelectResource: FunctionComponent<SelectResourcePropsType> = observer(props => {
    const CurrentResourceStore = useMemo(
        () => new ResourceClass(props.ResourceStore),
        [props.property]
    );

    const { resourceItem } = props;

    const { curRes, curResCode, setCurGrp, setFilterName } = CurrentResourceStore;
    const { content } = ConfigurationStore;

    const [searchOpen, setSearchOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [editResource, setEditResource] = useState<null | ResourceClass>(null);
    const [loading, setLoading] = useState(false);

    const handleSearchOpen = () => {
        setSearchOpen(true);
    };

    const handleSearchClose = (result: boolean) => {
        setSearchOpen(false);
        if (result && props.onSelect) {
            props.onSelect(props.property, curResCode, curRes);
        }
    };

    const handleEditOpen = () => {
        if (ResourceStore) {
            const {
                selectResource,
                setResLoading,
                setChildResource,
                cacheChildResourceList,
                setTab
            } = ResourceStore;

            setLoading(true);

            setResLoading(true);
            selectResource(resourceItem).finally(() => {
                setResLoading(false);

                const childRS = new ResourceClass(props.ResourceStore);
                childRS
                    .selectResource(resourceItem)
                    .then(() => {
                        const key = resourceItem.key || `${resourceItem.type}:${resourceItem.code}`;

                        setChildResource(key, childRS);
                        cacheChildResourceList(key);
                        setTab(key);
                    })
                    .catch((err: any) => {
                        console.error(err.message);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });
        } else {
            setEditOpen(true);
            const childRS = new ResourceClass(props.ResourceStore);
            childRS
                .selectResource(resourceItem)
                .then(() => {
                    setEditResource(childRS);
                })
                .catch(err => console.error(err.message));
        }
    };

    const handleEditClose = () => {
        setEditOpen(false);
        setEditResource(null);
    };

    const handleSaveResource = () => {
        editResource
            ?.saveResource()
            .then(() => {
                handleEditClose();
            })
            .catch(err => console.error(err.message));
    };

    useEffect(() => {
        if (resourceItem?.type) {
            setCurGrp(resourceItem.type);
            setFilterName(resourceItem.name);
        } else {
            setCurGrp('');
            setFilterName('');
        }
    }, [resourceItem]);

    return (
        <>
            {resourceItem &&
                !props.ResourceStore?.getSubForms()?.find(
                    subForm => subForm.code === resourceItem.code
                ) && (
                    <Tooltip title={content.resource.buttons.editor}>
                        {loading ? (
                            <IconButton disabled>
                                <CircularProgress size={20} />
                            </IconButton>
                        ) : (
                            <IconButton onClick={handleEditOpen}>
                                <EditIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Tooltip>
                )}
            <Tooltip title={content.resource.buttons.search}>
                <IconButton onClick={handleSearchOpen}>
                    <SearchIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            {searchOpen ? (
                <SimpleDialog
                    editorName={content.resource.resSelect}
                    handleClose={handleSearchClose}
                    maxWidth="sm"
                >
                    <Box sx={{ p: 1, height: '70vh' }}>
                        <ResList types={props.types} ResourceStore={CurrentResourceStore} />
                    </Box>
                </SimpleDialog>
            ) : null}
            <Dialog
                fullWidth
                open={editOpen}
                maxWidth="xl"
                sx={{ '& .MuiDialog-paper': { height: '90vh' } }}
                onClose={handleEditClose}
            >
                <DialogContent>
                    {editResource ? <ResEditor ResourceStore={editResource} /> : <Loading />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose}>{content.application.buttons.cancel}</Button>
                    <Button
                        disabled={!editResource?.curRes || !editResource?.modified}
                        onClick={handleSaveResource}
                    >
                        {content.application.buttons.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default SelectResource;
