import { FunctionComponent } from 'react';
import Calendar from './Calendar';

import { SchedulerControlsType } from '../../interfaces';

const SchedulerControls: FunctionComponent<SchedulerControlsType> = ({ currentDate, setDate }) => {
    console.log('controls');

    return (
        <div className="scheduler-controls">
            <Calendar currentDate={currentDate} setDate={setDate} />
        </div>
    );
};

export default SchedulerControls;
