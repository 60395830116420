import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { ImageList, ImageListItem } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useCtrlData from 'hooks/ctrlData';

import Image from 'components/Image/Image';
import Empty from 'components/utils/Empty/Empty';

import { ControlPropsType, DBImageType } from 'forms/interfaces';

import ConfigurationStore from 'store/configurationStore';

interface PropsType extends ControlPropsType {
    descr: DBImageType;
}

const DBImageList: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { dataset } = useCtrlData(propContainer, descr.datasetName, descr.fieldName);
    const theme = useTheme();

    const { fit, position } = descr;
    const { api, token, content } = ConfigurationStore;

    const [items, setItems] = useState(dataset?.data);

    const styles = {
        list: {
            padding: theme.spacing(2),
            maxHeight: `calc(100% - ${theme.spacing(4)}`,
            maxWidth: `calc(100% - ${theme.spacing(4)}`
        }
    };

    useEffect(() => {
        if (dataset) setItems(dataset.data);
    }, [dataset, dataset?.data]);

    return (
        <ImageList sx={styles.list} cols={3} rowHeight={164}>
            {items?.length ? (
                items.map((item: any, index: number) => (
                    <ImageListItem key={`${descr.guid}image-list-item-${index}`}>
                        <Image
                            src={
                                descr.srcType === 'blob'
                                    ? item[descr.fieldName]
                                    : `${api ?? '/'}getfile/${encodeURIComponent(
                                          item[descr.fieldName]
                                      )}/?token=${token ?? ''}`
                            }
                            alt={descr.labelFieldName ? item[descr.labelFieldName] : ''}
                            title={descr.showTitle}
                            position={position || 'unset'}
                            fit={fit || 'unset'}
                        />
                    </ImageListItem>
                ))
            ) : (
                <Empty message={content.controls.data.noData} />
            )}
        </ImageList>
    );
});

export default DBImageList;
