import React, { FunctionComponent } from 'react';

import { observer } from 'mobx-react';

import { DatasetType, DBControlType } from 'forms/interfaces';
import { ResourceClass } from 'store/resourceStore';

import ResCtrl from './ResCtrl';
import resValidator from '../clientResValidator';
import styles from './res.module.scss';
import { Resource } from './interfaces';

interface PropsType {
    ResourceStore: ResourceClass;
    parent: null | Resource;
    parentSection?: string;
    arr: Array<{ [key: string]: any } | DBControlType | DatasetType>;
    readOnly?: boolean;
}

const ResCtrlList: FunctionComponent<PropsType> = observer(
    ({ ResourceStore, parent, parentSection, arr, readOnly }) =>
        arr?.length ? (
            <ul className={styles.list}>
                {arr.map((ctrl, i) => {
                    const typeDescr = resValidator.getTypeDescriptor(ctrl?.type);

                    if (typeDescr) {
                        return (
                            <ResCtrl
                                ResourceStore={ResourceStore}
                                key={ctrl?.guid}
                                arr={arr}
                                typeDescr={typeDescr}
                                parent={parent}
                                parentSection={parentSection}
                                ctrl={ctrl}
                                i={i}
                                readOnly={readOnly}
                            />
                        );
                    }

                    const err = `Unknown resource type: ${ctrl?.type as string}`;
                    console.error(err);

                    return <li key={i}>{err}</li>;
                })}
            </ul>
        ) : null
);

export default ResCtrlList;
