import {
    DetailedHTMLProps,
    forwardRef,
    FunctionComponent,
    HTMLAttributes,
    RefAttributes
} from 'react';

import { SchedulerCellType } from '../../interfaces';

type CellDivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    'column-id'?: string;
    'slot-start'?: string;
    'slot-end'?: string;
};

const CellDiv: FunctionComponent<Omit<CellDivProps, 'ref'> & RefAttributes<HTMLDivElement>> =
    forwardRef(({ children, ...props }, ref) => (
        <div ref={ref} {...props}>
            {children}
        </div>
    ));

const SchedulerCell: FunctionComponent<SchedulerCellType> = ({
    header = false,
    dateStart,
    dateEnd,
    disabled = false,
    children,
    onDoubleClick,
    slotId,
    columnId,
    className
}) => {
    const handleDoubleClick = (event: any) => {
        if (!header && !disabled && className !== 'side-cell' && onDoubleClick) {
            onDoubleClick({
                id: slotId,
                title: event.target.textContent
            });
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <CellDiv
            id={slotId}
            role="gridcell"
            tabIndex={0}
            className={`scheduler-cell${header ? ' heading-cell' : ''}${
                disabled ? ' disabled' : ''
            }${className ? ` ${className}` : ''}`}
            column-id={columnId}
            slot-start={dateStart.format('YYYY-MM-DD HH:mm')}
            slot-end={dateEnd.format('YYYY-MM-DD HH:mm')}
            onDoubleClick={handleDoubleClick}
        >
            {children}
        </CellDiv>
    );
};

export default SchedulerCell;
