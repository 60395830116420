import { FunctionComponent, MutableRefObject, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';
import MUIRadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import useDBEdit from 'hooks/dbEdit';

import { ChildrenContainerPropsType } from 'forms/interfaces';

import RadioGroupButton from './RadioGroupButton';

interface PropsType extends ChildrenContainerPropsType {
    descr: any;
}

const DBRadioGroup: FunctionComponent<PropsType> = observer(({ descr, propContainer }) => {
    const { caption, fieldName, direction, labelPlacement, useLookupData, items } = descr;

    const editRef = useRef(undefined) as MutableRefObject<HTMLInputElement | undefined>;

    const { dataVal, ctrlVisible, ctrlEnabled, fieldDescr, dataset } = useDBEdit(
        descr,
        propContainer
    );

    const lookupData = useMemo(() => {
        if (!useLookupData || !fieldDescr?.lookupData) return null;

        if (typeof fieldDescr.lookupData === 'string') {
            try {
                return JSON.parse(fieldDescr.lookupData);
            } catch (err: any) {
                console.error(err.message);
                return null;
            }
        }

        return fieldDescr.lookupData;
    }, [fieldDescr]);

    const [value, setValue] = useState(() => dataVal);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (dataset?.inEdit) {
            // Новое значение
            const val = event.target.value;
            // Запоминаем старое значение
            const oldValue = value;

            // Обновляем значение
            setValue(val);

            dataset
                .trySetFieldValue(fieldName, val)
                .then(allowed => {
                    // Восстанавливаем старое значение, если изменение недопустимо
                    if (!allowed) setValue(oldValue);
                })
                .catch(err => console.error(err.message));
        }
    };

    return (
        <Box
            ref={editRef}
            sx={{
                width: '100%',
                p: 2,
                boxSizing: 'border-box',
                visibility: ctrlVisible ? 'inherit' : ('hidden' as any),
                position: 'relative'
            }}
        >
            <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">{caption}</FormLabel>
                <MUIRadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={dataVal}
                    name="radio-buttons-group"
                    row={direction === 'row'}
                    onChange={handleChange}
                >
                    {lookupData?.length
                        ? lookupData.map((item: any) => (
                              <FormControlLabel
                                  key={item.key}
                                  value={item.key}
                                  control={<Radio sx={{ m: 1, p: 2 }} />}
                                  label={item.val}
                                  labelPlacement={labelPlacement}
                                  disabled={!ctrlEnabled}
                              />
                          ))
                        : items.map((item: any) => (
                              <RadioGroupButton
                                  key={item.guid}
                                  descr={item}
                                  propContainer={propContainer}
                                  labelPlacement={labelPlacement}
                                  disabled={!ctrlEnabled}
                              />
                          ))}
                </MUIRadioGroup>
            </FormControl>
        </Box>
    );
});

export default DBRadioGroup;
