import { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { CSSObject, Theme } from '@mui/material/styles';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';

const Drawer: FunctionComponent<DrawerProps> = observer(({ ...otherProps }) => {
    const { open, variant } = otherProps;

    const openedMixin = (theme: Theme): CSSObject => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        overflowX: 'hidden'
    });

    const closedMixin = (theme: Theme): CSSObject => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(11)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(13)} + 1px)`
        }
    });

    return (
        <MuiDrawer
            {...otherProps}
            sx={theme => ({
                flexShrink: 0,
                whiteSpace: 'nowrap',
                boxSizing: 'border-box',
                '& .MuiPaper-root': { borderRight: 'none', overflow: 'hidden' },
                ...(open && {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme)
                }),
                ...(!open &&
                    variant === 'permanent' && {
                        ...closedMixin(theme),
                        '& .MuiDrawer-paper': closedMixin(theme)
                    })
            })}
        />
    );
});

export default Drawer;
