import React, { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react';

import moment, { Moment } from 'moment';

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

interface PropsType {
    date: any;
    setDate: any;
    handleClose: any;
}

const Day: FunctionComponent<PropsType> = observer(({ date, setDate, handleClose }) => {
    const value = useMemo(() => moment(date), [date]) as Moment | null;

    return (
        <DateCalendar
            value={value}
            onChange={newValue => {
                setDate(newValue.format('YYYY-MM-DD'));
                handleClose();
            }}
        />
    );
});

export default Day;
