import { useCallback, useMemo } from 'react';

import { createTheme } from '@mui/material/styles';

import teamTaskTheme from 'theme/teamtask';
import calypsoTheme from 'theme/calypso';

export default function useAppTheme(themeName?: string) {
    const getTheme = useCallback(() => {
        switch (themeName) {
            case 'teamtask':
                return teamTaskTheme;

            case 'calypso':
                return calypsoTheme;

            default:
                return calypsoTheme;
        }
    }, [themeName]);

    return useMemo(() => createTheme(getTheme()), [getTheme]);
}
