import React, { FunctionComponent, useCallback } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import PropContainer from 'dataObj/PropContainer';
import { PanelType } from 'forms/interfaces';

import Accordion from './Accordion';

interface PropsType {
    caption?: string;
    descr: PanelType;
    paper: boolean;
    accordion: boolean;
    defaultExpandedAccordion: boolean;
    groupBox: boolean;
    style: { [key: string]: any };
    propContainer: PropContainer;
}

const PanelWrapper: FunctionComponent<PropsType> = ({
    children,
    caption,
    accordion,
    defaultExpandedAccordion,
    paper,
    groupBox,
    style,
    descr,
    propContainer
}) => {
    const getContent = useCallback(
        () =>
            paper || groupBox ? (
                <Paper
                    elevation={paper ? 1 : 0}
                    variant={groupBox ? 'outlined' : 'elevation'}
                    sx={style}
                >
                    {children}
                </Paper>
            ) : (
                <Box sx={style}>{children}</Box>
            ),
        [children, accordion, paper, groupBox, style]
    );

    return accordion && caption ? (
        <Accordion
            caption={caption}
            defaultExpanded={defaultExpandedAccordion}
            descr={descr}
            propContainer={propContainer}
        >
            {getContent()}
        </Accordion>
    ) : (
        getContent()
    );
};
export default PanelWrapper;
