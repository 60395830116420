// Модуль утилит, перетаскивать из js

import { UpdateCDOResult, UpdateResult } from 'dataObj/dataInterfaces';
import { FormType } from 'forms/interfaces';

/**
 * Беcшумно преобразовать из строки в T
 */
export const safeJSONParse = <T = any>(injson: any, defaultValue: T): T => {
    try {
        return (injson ? JSON.parse(injson) : defaultValue) || defaultValue;
    } catch (e) {
        return defaultValue;
    }
};

/**
 * Вычисление идентификатора добавленной записи
 */
export const getCreatedRecordId = (result: UpdateResult[], keyField: string) => {
    const newRec = result.find(resArr =>
        resArr.find(res => res?.records.find(record => record.keyFields[keyField] === -1))
    );
    return newRec?.[0]?.records?.find(rec => rec.keyFields[keyField] === -1)?.diff[keyField];
};

/**
 * Вычисление идентификатора добавленной записи CDO
 */
export const getCreatedCDORecordId = (
    result: UpdateCDOResult[],
    datasetName: string,
    keyField: string
) => {
    const newRec = result.find(res =>
        res?.datasets
            ?.find(dataset => dataset?.datasetName === datasetName)
            ?.records.find(record => {
                const keyValue = record.keyFields?.[keyField];
                return typeof keyValue === 'number' ? +record.keyFields[keyField] < 0 : false;
            })
    );

    const ds = newRec?.datasets?.find(item => item.datasetName === datasetName);
    return ds ? ds.records[0].diff[keyField] : undefined;
};

export const getLangString = (descr: FormType, key: string, args: (string | number)[]) => {
    const { langStrings } = descr;
    if (langStrings) {
        const langString = langStrings.find(ls => ls.name === key);
        if (langString) {
            let { text } = langString;
            if (args?.length) {
                args.forEach(arg => {
                    text = text.replace('%s', arg.toString());
                });
            }
            return text;
        }
    }
    console.error(`Translation for key "${key}" not found!`);
};

type HttpRequestList = {
    url: string;
    method: string;
    type: string;
    body: string | undefined | null;
}[];

export const requests: HttpRequestList = [];
