const langRus = {
    auth: {
        signIn: 'Авторизация',
        username: 'Имя пользователя',
        password: 'Пароль',
        enter: 'Войти',
        tokenAuth: 'Авторизация по токену',
        tokenInvalid: 'Недействительный авторизационный токен',
        post: 'Должность'
    },
    external: {
        error: {
            formId: 'Не указан идентификатор формы',
            gridName: 'Не указано имя таблицы',
            pageExists: 'Запрашиваемая страница не существует',
            requestFailed: 'Запрос не выполнен!'
        }
    },
    settings: {
        clear: 'Сбросить',
        save: 'Сохранить',
        load: 'Загрузить',
        delete: 'Удалить',
        user: 'для текущего пользователя',
        allUsers: 'для всех пользователей',
        global: 'глобально'
    },
    buttons: {
        ok: 'Ok',
        cancel: 'Отмена',
        add: 'Добавить',
        next: 'Дальше',
        back: 'Назад',
        refresh: 'Обновить',
        close: 'Закрыть',
        save: 'Сохранить',
        undo: 'Отменить',
        copy: 'Копировать',
        paste: 'Вставить',
        delete: 'Удалить',
        export: 'Экспорт',
        import: 'Импорт',
        load: 'Загрузить',
        open: 'Открыть',
        selectAll: 'Выделить всё',
        clearAll: 'Снять выделение'
    },
    reports: {
        saveReport: 'Сохранить файл отчёта',
        done: 'Отчёт сформирован',
        notExist: 'Запрашиваемый отчёт не сформирован'
    },
    messages: {
        saveAnyway: 'Все равно сохранить?'
    }
};

export default langRus;
