import { DatasetType, FormEventType } from 'forms/interfaces';

import PropContainer from 'dataObj/PropContainer';

import Event from './Event';

export default class ResourceEvents {
    private descr;
    private events: { [triggerName: string]: Event[] } = {};

    /**
     * Создает новый экземпляр конструктора событий ресурса.
     *
     * @param {DatasetType} descr - описатель ресурса
     * @param {FormEventType[]} frmEvents - массив типов событий формы
     * @param {PropContainer} propContainer - контейнер свойств
     */
    constructor(descr: DatasetType, frmEvents: FormEventType[], propContainer: PropContainer) {
        this.descr = descr;

        if (frmEvents?.length) {
            frmEvents.map(event => {
                const events = this.events[event.trigger];

                if (events?.length) {
                    this.events[event.trigger] = [...events, ...[new Event(event, propContainer)]];
                    return;
                }

                this.events[event.trigger] = [new Event(event, propContainer)];
                return;
            });
        }
    }

    addEvents = (frmEvents: FormEventType[], propContainer: PropContainer) => {
        if (frmEvents?.length) {
            frmEvents.map(event => {
                // Исключаем добавление дублирующихся событий в рамках триггера
                const events = this.events[event.trigger]?.filter(e => e.guid !== event.guid);

                if (events?.length) {
                    this.events[event.trigger] = [...events, ...[new Event(event, propContainer)]];
                    return;
                }

                this.events[event.trigger] = [new Event(event, propContainer)];
                return;
            });
        }
    };

    /**
     * Запускает доступные события на основе заданного имени триггера и данных события.
     *
     * @param {string} triggerName - Имя триггера.
     * @param {Object} eventPayload - (опционально) Данные события для инъекции в скрипт.
     * @param {string} eventPayload.fieldName - Имя поля, является фиксированным фильтрующим свойством для вычисления событий, завязанных на этом поле (для событий датасетов).
     * @return {Promise<Array | undefined>} Промис, разрешающийся в массив результатов выполнения событий.
     */
    triggerEvent = async (
        triggerName: string,
        eventPayload?: { [key: string]: string | boolean | any[] }
    ): Promise<Array<any> | undefined> => {
        const eventList = eventPayload?.fieldName
            ? this.events[triggerName]?.filter(event =>
                  event.checkFieldName(eventPayload.fieldName as string)
              )
            : this.events[triggerName];

        if (eventList?.length) {
            return Promise.all(eventList.map(async event => event.execute(eventPayload)));
        }
    };
}
