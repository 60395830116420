import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { Box } from '@mui/material';

import ConfigurationStore from 'store/configurationStore';

import useCtrlData from 'hooks/ctrlData';
import useNodeResize from 'hooks/nodeResize';
import useDatasetLoading from 'hooks/datasetLoading';

import Loading from 'components/utils/Loading/Loading';

import { ControlDataRowType, DBDocumentViewerType } from '../interfaces';

import DBHtmlViewer from './DBHtmlViewer';
import DBRtfViewer from './DBRtfViewer';

interface PropsType extends ControlDataRowType {
    descr: DBDocumentViewerType;
}

const DBDocumentViewer: FunctionComponent<PropsType> = observer(
    ({ descr, propContainer, dataRow }) => {
        const documentWrapperRef = useRef(null) as any;

        const { datasetName, fieldName } = descr;

        const { dataset, dataVal } = useCtrlData(propContainer, datasetName, fieldName, dataRow);
        const { loading, loadingType, loadingMessage } = useDatasetLoading(dataset);

        const [minWidth] = useNodeResize(documentWrapperRef);

        const checkRTF = (str: string) => /^{\\rtf/i.test(str);

        const [content, setContent] = useState('');

        const styles = {
            height: '100%',
            width: '100%',
            position: 'relative'
        };

        useEffect(() => {
            if (dataset) {
                setContent(dataVal);
            }
        }, [dataset, dataVal]);

        const renderDocument = () => {
            if (!content) return <Loading type="empty" />;

            if (checkRTF(content))
                return (
                    <DBRtfViewer
                        descr={descr}
                        propContainer={propContainer}
                        content={content}
                        minWidth={minWidth}
                    />
                );
            return (
                <DBHtmlViewer
                    descr={descr}
                    propContainer={propContainer}
                    content={content}
                    minWidth={minWidth}
                />
            );
        };

        return loading ? (
            <Loading delay type={loadingType} message={loadingMessage} />
        ) : (
            <Box sx={styles} ref={documentWrapperRef}>
                {renderDocument()}
            </Box>
        );
    }
);

export default DBDocumentViewer;
