import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';

import { ControlPropsType, ScriptButtonType } from 'forms/interfaces';
import { ClickAwayListener, Menu, MenuList } from '@mui/material';
import MenuItem from '../ScriptButton/MenuButton/MenuItem';

interface PropsType extends ControlPropsType {
    open: boolean;
    anchor: {
        mouseX: number;
        mouseY: number;
    } | null;
    items: ScriptButtonType[];
    onClose: () => unknown;
}

const ContextMenu: FunctionComponent<PropsType> = observer(
    ({ open, anchor, items, onClose, propContainer }) => {
        const handleClose = () => {
            onClose();
        };

        return (
            <Menu
                open={open}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    anchor !== null ? { top: anchor.mouseY, left: anchor.mouseX } : undefined
                }
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                        {items?.map((item, index) => (
                            <MenuItem
                                key={index}
                                descr={item}
                                propContainer={propContainer}
                                parentClose={handleClose}
                            >
                                {item.caption}
                            </MenuItem>
                        ))}
                    </MenuList>
                </ClickAwayListener>
            </Menu>
        );
    }
);

export default ContextMenu;
