import { useEffect, useCallback, useState, useMemo } from 'react';

import { useNodesState, useEdgesState, useReactFlow, addEdge } from 'reactflow';

import dagreLayout from './dagre';
import elkLayout from './elk';

type DagreDirection = 'TB' | 'LR';
type ElkDirection = 'DOWN' | 'RIGHT';

const convertDirection = (
    render: 'elk' | 'dagre',
    direction: 'top-bottom' | 'left-right'
): DagreDirection | ElkDirection => {
    switch (direction) {
        case 'left-right':
            return render === 'dagre' ? 'LR' : 'RIGHT';

        case 'top-bottom':
            return render === 'dagre' ? 'TB' : 'DOWN';

        default:
            return render === 'dagre' ? 'TB' : 'DOWN';
    }
};

const useLayout = (
    initNodes: any[],
    initEdges: any[],
    selectedNode: string,
    render: 'elk' | 'dagre' = 'elk',
    initDirection: 'top-bottom' | 'left-right' = 'top-bottom',
    zoom = 1,
    wrapper?: HTMLDivElement
) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [visible, setVisible] = useState(false);
    const [direction, setDirection] = useState(initDirection);

    const defaultViewport = useMemo(() => ({ x: 0, y: 0, zoom }), [zoom]);

    const reactFlowInstance = useReactFlow();

    const calcViewport = (layoutedNodes?: any[]) => {
        const nodeList = layoutedNodes || [...reactFlowInstance.toObject().nodes];

        if (!wrapper) return { x: 0, y: 0, zoom };

        // Для вертикальной ориентации
        if (direction === 'top-bottom') {
            const sortedX = nodeList.sort((n1: any, n2: any) => n1.position.x - n2.position.x);
            return {
                x:
                    wrapper.offsetWidth / 2 -
                    (sortedX[sortedX.length - 1].position.x - sortedX[0].position.x) / 2 -
                    150 * zoom,
                y: 0,
                zoom
            };
        }

        // Для горизонтальной ориентации
        const sortedY = nodeList.sort((n1: any, n2: any) => n1.position.y - n2.position.y);
        return {
            x: 0,
            y:
                wrapper.offsetHeight / 2 -
                (sortedY[sortedY.length - 1].position.y - sortedY[0].position.y) / 2 -
                100 * zoom,
            zoom
        };
    };

    const fitView = (layoutedNodes?: any[]) => {
        reactFlowInstance.setViewport(calcViewport(layoutedNodes));
        setTimeout(() => setVisible(true), 200);
    };

    const elkOptions = useMemo(
        () => ({
            // 'elk.algorithm': 'layered',
            'elk.layered.spacing.nodeNodeBetweenLayers': '100',
            'elk.spacing.nodeNode': '80',
            'elk.contentAlignment': 'V_CENTER',
            'elk.nodeLabels.placement': 'INSIDE V_CENTER H_RIGHT',
            'elk.algorithm': 'org.eclipse.elk.layered',
            'org.eclipse.elk.layered.layering.strategy': 'INTERACTIVE',
            'org.eclipse.elk.edgeRouting': 'ORTHOGONAL',
            'elk.layered.unnecessaryBendpoints': 'true',
            'elk.layered.spacing.edgeNodeBetweenLayers': '30',
            'org.eclipse.elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
            'org.eclipse.elk.layered.nodePlacement.bk.edgeStraightening': 'IMPROVE_STRAIGHTNESS',
            'org.eclipse.elk.layered.cycleBreaking.strategy': 'DEPTH_FIRST',
            'org.eclipse.elk.insideSelfLoops.activate': 'true',
            separateConnectedComponents: 'false',
            'spacing.componentComponent': '70',
            spacing: '75',
            'spacing.nodeNodeBetweenLayers': '70',
            'org.eclipse.elk.layered.nodePlacement.favorStraightEdges': 'true',
            'org.eclipse.elk.layered.considerModelOrder.strategy': 'NODES_AND_EDGES',
            'org.eclipse.elk.layered.considerModelOrder.crossingCounterNodeInfluence': '0.001',
            'nodePlacement.strategy': 'BRANDES_KOEPF',
            'org.eclipse.elk.spacing.edgeLabel': '0',
            'org.eclipse.elk.spacing.edgeNode': '24',
            'org.eclipse.elk.layered.edgeLabels.sideSelection': 'ALWAYS_UP',
            'org.eclipse.elk.spacing.portPort': '10'
        }),
        []
    );

    const onLayout = useCallback(
        (fit = false) => {
            if (render === 'elk') {
                const opts = {
                    'elk.direction': convertDirection(render, direction) as ElkDirection,
                    ...elkOptions
                };

                elkLayout(initNodes, initEdges, opts)
                    .then(({ layoutedNodes, layoutedEdges }) => {
                        setNodes(layoutedNodes as any);
                        setEdges(layoutedEdges as any);

                        fit && setTimeout(() => fitView(layoutedNodes), 100);
                    })
                    .catch(err => console.error(err.message));
            } else if (render === 'dagre') {
                const [layoutedNodes, layoutedEdges] = dagreLayout(
                    initNodes,
                    initEdges,
                    convertDirection(render, direction) as DagreDirection
                );

                setNodes([...layoutedNodes]);
                setEdges([...layoutedEdges]);

                fit && setTimeout(() => fitView(layoutedNodes), 100);
            }
        },
        [render, initNodes, initEdges, direction, selectedNode]
    );

    useEffect(() => {
        setVisible(false);
        setTimeout(() => {
            reactFlowInstance.setViewport(defaultViewport);
            onLayout(true);
        }, 10);
    }, [direction]);

    useEffect(() => {
        onLayout();
    }, [onLayout]);

    const onConnect = useCallback(
        params => setEdges(eds => addEdge({ ...params, style: { stroke: '#fff' } }, eds)),
        []
    );

    const handleChangeDirection = () => {
        if (direction === 'top-bottom') {
            setDirection('left-right');
        } else setDirection('top-bottom');
    };

    const handleInit = () => {
        fitView();
    };

    return {
        visible,
        nodes,
        onNodesChange,
        onEdgesChange,
        edges,
        defaultViewport,
        onConnect,
        direction,
        handleChangeDirection,
        handleInit
    };
};

export default useLayout;
